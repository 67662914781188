<template>
     <footer class="site-footer fixed-bottom">
        <div class="site-footer-wrap container-fluid d-flex justify-content-between align-items-center">
            <ul class="nav">
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Terms & Conditions</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Privacy Policy</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Contact Us</a>
                </li>
            </ul>
        </div>
    </footer>
</template>
<script>
export default {
    name: "main-footer"
}
</script>