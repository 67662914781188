<template>
    <div>
        <main data-aos="fade" class="pb-3">
            <section class="ticket-area site-sec bg-light">
                <div class="site-sec-wrap container-xxl">
                    <div class="bg-white my-3 px-3 px-md-5 py-5 border min-vh-100">
                        <div class="">
                            <h2>Season Ticket </h2>
                            
                            <p><span v-show="loading" class="spinner-border text-warning"></span></p>
                        </div>
                        <div class="alert alert-error" v-if="errorMessage.length > 0">
                            <p>{{ errorMessage }}</p>
                        </div>
                        
                        <div class="row" >
                            <div class="col-md-8">
                                <h5>Ticket Details</h5>
                                <div class="row">
                                    <div class="col">
                                        <strong>Team:</strong> {{ seasonTicket.season_pricing.organiser.name }}
                                    </div>
                                    <div class="col">
                                        <strong>Ticket:</strong> {{ seasonTicket.season_pricing.name }}
                                    </div>
                                    <div class="col">
                                        <strong>Valid Until:</strong> {{ seasonTicket.season_pricing.valid_to }}
                                    </div>
                                    <div class="col">
                                        <strong>Benefits:</strong> {{ seasonTicket.season_pricing.notes }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button @click="generateTicket()" class="btn btn-info">Generate Upcoming Match ticket</button>
                            </div>
                            
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>My Match Tickets</h5>

                                <div class="table-responsive">
                                <table class="table table-stripped">
                                    <thead>
                                        <tr>
                                            <th>ticket number</th>
                                            <th>Match</th>
                                            <th>Venue</th>
                                            <th>Time</th>
                                            <th>seat</th>
                                            <th>Attended</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="ticket in seasonTicket.match_tickets" :key="ticket.id">
                                            <td>{{ ticket.ticket_number }}</td>
                                            <td>{{ ticket.event_name }}</td>
                                            <td>{{ ticket.venue }}</td>
                                            <td>{{ ticket.event_start_date }}</td>
                                            <td>{{ ticket.seat_number }}</td>
                                            <td>{{ ticket.used}}</td>
                                        </tr>

                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import PalmEvent from '../apis/PalmEvent';

export default {
    components: {
        
    },
    data() {
        return {
            loading: false,
            seasonTicket: null,
            errorMessage: '',
            user: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        logout() {
            localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
        }, 
        fetchTicket(id) {
            PalmEvent.getSingleSeasonTicket(id).then(
                (response) => {
                    this.seasonTicket = response.data.data
                },
                (error) => {
                    console.log(error.response)
                }
            )
        },
        generateTicket() {
            this.loading = true
            this.errorMessage = ''
            PalmEvent.generateMatchTicket(this.seasonTicket.id).then(
                (response) => {
                    this.seasonTicket = response.data.data
                    this.loading = false
                },
                (error) => {
                    this.errorMessage = error.response
                    this.loading = false
                }
            )
        }
    },
    
  
  created() {
    let isLoggedIn = !!localStorage.getItem("token");    
      if(!isLoggedIn){
        this.$router.push({ name: "Login" });
      }
    let ticketId = this.$route.params.id 
    if(ticketId !== undefined) {
        this.fetchTicket(ticketId);
    }
      
  }
}
</script>