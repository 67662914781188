import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
     isLoggedIn: false,
     user: {},
     featuredCategories: [
        {
            name: "All Events",
            link: "all"
        },
        {
            name: "Today",
            link: "today"
        },
        {
            name: "This Weekend",
            link: "thisweekend"
        }
     ]
 },
 getters: {
     getUserName: state =>{
        return state.user.name ? state.user.name : ''
     },
     getUserLanguage: state =>{
         return state.user.current_language ? state.user.current_language : 'eng'
     },
     getUserEmail: state =>{
        return state.user.email ? state.user.email : ''
     },
     getUserPhone: state => {
        return state.user.phone_number ? state.user.phone_number : ''
     },
     getFeaturedCategories: state => {
         return state.featuredCategories
     }
 },
 mutations: {
     updateUser(state,newUser){
         state.user = newUser;
     },
     updateTranslations(state,newTranslation){
         state.translations = newTranslation
     },
     loginUser(state){
         state.isLoggedIn = true;
     },
     logoutUser(state){
         state.isLoggedIn = false;
     },
     addFeaturedCategory(state,newCategory){
         //check if category exists
         const categoryExists = state.featuredCategories.find(cat => cat.name === newCategory.name)
         if(categoryExists === undefined){
            state.featuredCategories.push(newCategory)
         }
        
    },
 },
 actions: {}
});