<template>
    <div>
        <main data-aos="fade" class="pb-3">
            <section class="ticket-area site-sec bg-light">
                <div class="site-sec-wrap container-xxl">
                    <div class="bg-white my-3 px-3 px-md-5 py-5 border min-vh-100">
                        <div class="">
                            <h2>Register account</h2>
                            
                            <p><span v-show="loading" class="spinner-border text-warning"></span></p>
                            <div class="alert alert-danger alert-dismissible" v-if="apiErrors" @click="apiErrors = false">
                                <ul v-if="validationErrors">
                                    <li v-for="(verrors, field) in errors" :key="field">
                                        {{ field }} 
                                        
                                        <ul>
                                            <li v-for="error in verrors" :key="error.message">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p v-if="generalError">{{errors}}</p>
                            </div>
                        </div>
                        
                        <div class="row" >
                            <div>
                                <label>First Name</label>
                                <input class="form-control" v-model="user.first_name" >
                            </div>
                            <div>
                                <label>Last Name</label>
                                <input class="form-control" v-model="user.last_name" >
                            </div>
                            <div>
                                <label>Phone Number</label>
                                <input class="form-control" v-model="user.phone_number">
                            </div>
                            <div>
                                <label>Gender</label>
                                <select class="form-control" v-model="user.gender">
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                            <div>
                                <label>Email</label>
                                <input class="form-control" v-model="user.email" type="email">
                            </div>
                            <div>
                                <label>Password</label>
                                <input class="form-control" v-model="user.password" type="password">
                            </div>
                            <div>
                                <label>Verify you are a human</label>
                                <img :src="captcha.img" /> <a href="#" @click.prevent="getCaptcha()">Reload</a>
                                <input class="form-control" v-model="user.captcha" type="text">
                            </div>
                            
                            <div><br>
                                <button @click.prevent="register()" class="btn btn-info">Submit</button> or <a href="/login">Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Auth from '../apis/User'

export default {
    components: {
        
    },
    data() {
        return {
            loading: false,
            apiErrors: false,
            generalError: false,
            validationErrors: false,
            errors: [],
            user: {
                email: '',
                password: '',
                first_name: '',
                last_name: '',
                gender: 'male',
                phone_number: '',
                key: '',
                captcha: '',
            },
            captcha: {}
        }
    },
    methods: {
        register() {
        this.apiErrors = false; 
        this.loading = true;    
        this.validationErrors = false;
        Auth.register(this.user)
            .then(() => {
              this.loading = false;
              this.$router.push({ name: "Login" });
            })
            .catch(error => {
            if (error.toJSON().message === 'Network Error') {
            // client never received a response, or request never left
            this.loading = false
            this.apiErrors = true;
            this.generalError = true;
            this.errors = JSON.stringify({NetworkError:"Error! Check network"})
            }
            else if (error.response.status === 422) {
                this.errors = error.response.data;
                this.apiErrors = true
                this.validationErrors = true
                this.loading = false;
            }
            else {
              // anything else
              console.log(error);
              this.loading = false;
              this.apiErrors = true;
              this.generalError = true;
              this.errors = JSON.stringify({generalError:"Server Error"})
            }
            });
        },
        getCaptcha() {
            Auth.getCaptcha().then(
                (response) => {
                    this.captcha = response.data
                    this.user.key = response.data.key
                },
                (error) => {
                    console.log(error.response)
                }
            )
            

        }
    },
    
  
  created() {
    let isLoggedIn = !!localStorage.getItem("token");
      if(isLoggedIn){
        this.$router.push({ name: "Account" });
      }
      this.getCaptcha()
  }
}
</script>