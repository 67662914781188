<template>
    <div>
        <main data-aos="fade" class="pb-5">
        <section class="site-hero-main site-sec pb-0">
            <div class="site-sec-wrap container-xxl flex-column d-flex pt-5 align-items-center">
                <div class="txt mt-4 mb-5">
                    <h1 class="text-white">All Events</h1>
                </div>
            </div>
        </section>
        <section class="tickets-area site-sec">
            <div class="site-sec-wrap container-xxl">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="">
                            <!-- <div class="ads-space d-block rounded bg-primary mb-4 p-1 text-center">
                                <img src="~@/assets/00.png" class="img-fluid" alt="">
                            </div> -->
                            <div class="tickets-area-account">
                                <h5 class="border-bottom pb-2 mb-3">Your Account</h5>
                                <div class="border rounded px-3 py-3">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="tickets-area-wrap">
                            <div class="tickets-area-filter border rounded px-2 py-3 mb-4">
                                <div class="site-cats row gx-3 justify-content-end">
                                    <div class="col-auto ms-2 me-auto">
                                        <h4 class="m-0">Filter</h4>
                                    </div>
                                    <div v-for="(featuredCategory,i) in $store.getters.getFeaturedCategories"
                                        :key="i" class="col-auto">
                                        
                                            <a @click.prevent="filterEvents(featuredCategory.link)" href="#" v-bind:class="{ active: featuredCategory.link==categoryId }" class="px-3 py-2 bg-light rounded">{{featuredCategory.name}}</a>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="tickets-area-list row gx-4 gy-4">
                                <div v-for="filteredEvent in events" :key="filteredEvent.id" class="com-sm-6 col-lg-4">
                                    <div class="card card-ticket border-0">
                                        <div class="card-header p-0">
                                            <div class="img img-cover rounded">
                                                <img :src="filteredEvent.banner_image" alt="">
                                            </div>
                                            <a @click.prevent="goToEvent(filteredEvent.id)" href="" class="link-to"></a>

                                        </div>
                                        <div class="card-body bg-transparent">
                                            <h5 class="card-title mb-0 fw-bold">{{filteredEvent.name}}</h5>
                                            <p class="card-text">{{filteredEvent.start}}</p>
                                        </div>
                                    </div>
                                </div>                               
                                
                            </div>
                             <div class="tickets-area-list row gx-4 gy-4" v-if="loading">
                                    <all-event-card-skeleton v-for="n in 3" :key="n" />
                                   
                                </div>
                            <div class="tickets-area-pagination mt-4 pt-4 border-top">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" v-for="k in numberOfPages" :key="k">
                                            <a class="page-link" @click.prevent="fetchEvents(categoryId,k)" href="#">{{k}}</a>
                                        </li>                                        
                                        
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </div>
</template>

<script>
import PalmEvent from '../apis/PalmEvent'
import AllEventCardSkeleton from '../components/common/AllEventCardSkeleton.vue'
export default {
    components: {
        AllEventCardSkeleton
    },
    data() {
        return {
            loading: true,
            events: [],
            numberOfPages: 1,
            categoryId: 'all',
            nextPage: 1,
            previousPage: null,
        }
    },
    methods: {
        filterEvents(link)
        {
            this.$router.push({name:'Events',params:{categoryId:link}})
            this.events = [];
            this.fetchEvents(link,1);

        },
        fetchEvents(category,page){
            this.categoryId=category;
            this.loading = true;
            this.nextPage = 1;
            PalmEvent.filterEvents(category,page).then(
                (result) => {
                    let content = result.data.data;
                    this.events = content;
                let nexLink = result.data?.links?.next;
                if(nexLink != null){
                    const splitString = nexLink.split("=");
                    this.nextPage = parseInt(splitString[1]);
                }
                else if(nexLink == null){
                    this.nextPage = null;
                }
                this.numberOfPages = result.data?.meta?.last_page;
                console.log(result.data.data)
                this.loading = false;
                },
                (error) => {
                    const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(resMessage) 
                    this.loading = false; 
                }
            )
        },
        goToEvent(eventId){
          this.$router.push({ name: "Event",params: {id: eventId} });
        },
    },
    created() {
        let catId = (this.$route.params.categoryId !== undefined) ? this.$route.params.categoryId : 'all';
        if(catId !== undefined){
            this.categoryId = catId;
        }
        this.fetchEvents(catId,1);
        console.log(catId);
    }
}
</script>