<template>
    <nav class="site-nav">
        <div class="site-nav-wrap container-fluid d-flex justify-content-between align-items-center">
            <div class="site-nav-logo">
                <a href="/" class="img-contain">
                    <img src="~@/assets/pk-symbol.svg" alt="">
                    <span><small>PalmKash</small>Events</span>
                </a>
            </div>
            <div class="dropdown site-search rounded-3 bg-white border w-100 d-none d-md-block">
                <form class="d-flex justify-content-center align-items-center">
                    <div class="w-100 input-group">
                        
                        <input type="text" class="form-control px-2 py-2 border-0" id="autoSizingInput"
                            placeholder="Search" :value="searchString" @input="searchEvents">
                            <span @click="clearSearch" v-show="showSearchResults" class="input-group-text"><i class="bi bi-x-lg"></i></span>
                    </div>
                </form>
                <div class="dropdown-menu" v-bind:class="{ show: showSearchResults}" style="max-height:300px;overflow-y:scroll;">
                    <div class="card card-ticket border-0" v-for="searchEvent in events" :key="searchEvent.id">
                        <div class="row hva mx-2" @click.prevent="goToEvent(searchEvent.id)">
                            <div class="col-md-4">
                            <img height="20px" :src="searchEvent.banner_image" class="img-thumbnail" alt="...">
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">&nbsp;&nbsp;{{searchEvent.name}}</h5>
                                <p class="card-text">&nbsp;&nbsp;{{searchEvent.start}}</p>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="site-nav-menu d-none d-md-block">
                <ul class="nav">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" @click.prevent="filterEvents('all')" href="">All Events</a>                        
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page"  href="/season-tickets">Season Tickets</a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link d-flex align-items-center" href="/account">
                            <i class="bi bi-person-fill"></i>
                            <span class="ms-1">Account</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="site-nav-btns d-block d-md-none">
                <button class="btn px-2 py-1 text-uppercase" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <span></span>
                </button>
            </div>
        </div>
    </nav>

</template>

<script>
import PalmEvent from '../../apis/PalmEvent';
export default {
    name: "main-header",
    data () {
      return {
        searchString: "",
        events: [],
        showSearchResults: false
      }
    },
    methods: {
        filterEvents(link){
          this.$router.push({name:'Events',params:{categoryId:link}});
      },
      clearSearch(){
          this.searchString ="";
          this.showSearchResults = false;
          this.events = [];
      },
      goToEvent(eventId){
          this.searchString ="";
          this.showSearchResults = false;
          this.$router.push({ name: "Event",params: {id: eventId} });
     },
      searchEvents(event){
          const inputValue = event.target.value;
          this.searchString = inputValue;
          if(inputValue.length > 5){
              PalmEvent.searchEvents(this.searchString).then(
                  (results) => {
                      this.events = results.data.data;
                      this.showSearchResults = true;
                  },
                  (error) => {
                    const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(resMessage) 
                    this.loading = false; 
                }
              )
          }
      }
    },
    created() {
        //console.log(this.$backendUrl);
    }
}
</script>
<style scoped>
    .hva {
        cursor: pointer;
        
    }
</style>