<template>
    <div class="bg-white my-3 px-3 px-md-5 py-5 border rounded row loading-skeleton">
        <div class="abstract col-12">
            <div class="row img-cover">
                <img src="~@/assets/200.jpg" alt="">
                <a href="p-checkout.html" class="link-pay btn btn-warning w-auto">Pay for this</a>
            </div>
            <h3 class="mt-4">Event Name</h3>
            <hr class="my-3">
            <div class="row g-5 event-details">
                <div class="col-auto">
                    <strong class="">Date</strong>
                    <h5>Sat, December 11, 2021</h5>
                </div>
                <div class="col-auto">
                    <strong class="">Location</strong>
                    <h5 class="border-bottom pb-2 pe-5">Kigali Arena</h5>
                    <a href="">View Map</a>
                </div>
                <div class="col-auto">
                    <strong class="">Refund Policy</strong>
                    <h5 class="border-bottom pb-2 pe-5">Contact the organizer to request a refund.</h5>
                    <a href="">Contact Advertiser</a>
                </div>
            </div>
        </div>
        <div class="details mt-5 col-12">
            <div class="event-header">
                <h3 class="mb-0">Event Details</h3>
                <hr class="my-3">
                <p>Description of event goes here</p>
            </div>
            <div class="event-body"></div>
        </div>
    </div>
</template>
<script>
export default {
    name:"event-detail-skeleton"
}
</script>

<style scoped>

.container {
  margin-top: 4em;
  margin-bottom: 4em;
}

.loading-skeleton h1, .loading-skeleton h2, .loading-skeleton h3, .loading-skeleton h4, .loading-skeleton h5, .loading-skeleton h6,
.loading-skeleton p, .loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder, .loading-skeleton h2::placeholder, .loading-skeleton h3::placeholder, .loading-skeleton h4::placeholder, .loading-skeleton h5::placeholder, .loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder, .loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
    
  }
  to {
    opacity: 1;
    
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>