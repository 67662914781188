<template>
    <div>
        <main data-aos="fade" class="pb-3">
            <section class="ticket-area site-sec bg-light">
                <div class="site-sec-wrap container-xxl">
                    <div class="bg-white my-3 px-3 px-md-5 py-5 border min-vh-100">
                        <div class="">
                            <h2>Login to your account</h2>
                            
                            <p><span v-show="loading" class="spinner-border text-warning"></span></p>
                            <div class="alert alert-danger alert-dismissible" v-if="apiErrors" @click="apiErrors = false">
                                <ul v-if="validationErrors">
                                    <li v-for="(verrors, field) in errors" :key="field">
                                        {{ field }} 
                                        
                                        <ul>
                                            <li v-for="error in verrors" :key="error.message">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p v-if="generalError">{{errors}}</p>
                            </div>
                        </div>
                        
                        <div class="row" >
                            <div>
                                <label>Email</label>
                                <input class="form-control" v-model="user.email" type="email">
                            </div>
                            <div>
                                <label>Password</label>
                                <input class="form-control" v-model="user.password" type="password">
                            </div>
                            
                            <div><br>
                                <button @click.prevent="login()" class="btn btn-info">Submit</button> or <a href="/register">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Auth from '../apis/User'

export default {
    components: {
        
    },
    data() {
        return {
            loading: false,
            apiErrors: false,
            validationErrors: false,
            generalError: false,
            user: {
                email: '',
                password: '',
                device_name: ''
            }
        }
    },
    methods: {
        login() {
            this.loading = true;
            this.errors = [];
            this.apiErrors = false
            this.validationErrors = false
            
            Auth.login(this.user).then(
                (response) => {
                    localStorage.setItem('user',JSON.stringify(response.data.user))
                    localStorage.setItem('token', response.data.token)
                    this.$router.push({ name: "Account" });
                },
                (error) => {
                    this.errors = (error.response.data?.errors != null) ? error.response.data?.errors :error.response.data;
                    this.apiErrors = true
                    this.validationErrors = true
                    this.loading = false;
                }
            )
            
        }
    },
    
  
  created() {
      let isLoggedIn = !!localStorage.getItem("token");
      if(isLoggedIn){
        this.$router.push({ name: "Account" });
      }
      this.user.device_name = "browser_"+Math.random();
  }
}
</script>