<template>
  <div>
  
  <main data-aos="fade" class="pb-5">
     <section class="site-hero-main site-sec">
            <div class="site-sec-wrap container-xxl flex-column d-flex pt-5 align-items-center">
                <div class="txt mb-5 mt-5">
                    <h1 class="text-white">Experience Awesome Events</h1>
                </div>
                <div class="site-cats mb-5 row flex-nowrap gx-3">
                    <div v-for="(featuredCategory,i) in $store.getters.getFeaturedCategories"
                    :key="i"
                     class="col-auto">
                        <a @click.prevent="filterEvents(featuredCategory.link)" href="#" class="px-5 py-2 bg-primary rounded text-white border-0">{{featuredCategory.name}}</a>
                    </div>
                  
                </div>
            </div>
        </section>
        <section class="brief-tickets featured site-sec">
            <div class="site-sec-wrap container-xxl">
                <h4 class="mb-4">Featured Events</h4>
                <div class="row gx-3 gy-5" v-if="loading">
                    <event-card-skeleton v-for="n in 4" :key="n" />
                </div>
                <div class="row gx-3 gy-5" >
                    <div v-for="featuredEvent in featuredEvents" :key="featuredEvent.id" class="col-md-3">
                        <div class="card card-ticket border-0">
                            <div class="card-header p-0">
                                <div class="img img-cover rounded">
                                    <img :src="featuredEvent.banner_image" alt="">
                                </div>
                                <a @click.prevent="goToEvent(featuredEvent.id)" href="" class="link-to"></a>

                            </div>
                            <div class="card-body bg-transparent">
                                <h5 class="card-title mb-0 fw-bold">{{featuredEvent.name}}</h5>
                                <p class="card-text">{{featuredEvent.start}}</p>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
        <section class="brief-tickets site-sec">
            <div class="site-sec-wrap container-xxl">
                <h4 class="mb-4">Upcoming Events</h4>
                <div class="row gx-3 gy-5">
                    <div v-for="upcomingEvent in upcomingEvents" :key="upcomingEvent.id"  class="col-md-3">
                        <div class="card card-ticket border-0">
                            <div class="card-header p-0">
                                <div class="img img-cover rounded">
                                    <img :src="upcomingEvent.banner_image" alt="">
                                </div>
                                <a @click.prevent="goToEvent(upcomingEvent.id)" href="" class="link-to"></a>

                            </div>
                            <div class="card-body bg-transparent">
                                <h5 class="card-title mb-0 fw-bold">{{upcomingEvent.name}}</h5>
                                <p class="card-text">{{upcomingEvent.start}}</p>
                            </div>
                        </div>
                    </div>   
                  
                </div>
                <div class="row gx-3 gy-5" v-if="loading">
                    <event-card-skeleton v-for="n in 4" :key="n" />
                </div>
                <button :disabled="!nextPage" @click.prevent="fetchUpcomingEvents(nextPage)"  class="btn btn-primary mt-5">See More</button>
            </div>
        </section>
  </main>
  
  </div>
</template>

<script>

import EventCardSkeleton from '../components/common/EventCardSkeleton.vue'
import PalmEvent from '../apis/PalmEvent'

export default {
  name: 'Home',
  components: {    
    EventCardSkeleton,
    
  },
   data() {
    return {
      valid: false,
      loading: false,  
      featuredEvents: [], 
      featuredEvents2: [],
      upcomingEvents: [], 
      nextPage: 1,
      prevPage: 1,  
    };
  },
  created(){
    this.fetchFeaturedCategories();
    this.fetchFeaturedEvents();
    this.fetchUpcomingEvents();
  },
  methods: {
      filterEvents(link){
          this.$router.push({name:'Events',params:{categoryId:link}});
      },
      goToEvent(eventId){
          this.$router.push({ name: "Event",params: {id: eventId} });
      },
      fetchFeaturedCategories(){
        PalmEvent.getFeaturedCategories().then(
          (result) => {
                let content = result.data
                content.forEach((element) => {
                this.$store.commit('addFeaturedCategory',element)
                })
               // console.log(content)
          },
          (error) => {
              const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
              console.log(resMessage)      
          }
        )
      },
      fetchFeaturedEvents(){
        this.loading = true
         PalmEvent.getFeaturedEvents().then(
          (result) => {
                this.featuredEvents=result.data.data;                
                console.log(result.data.data)
               this.loading = false
          },
          (error) => {
            this.loading = false
              const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
              console.log(resMessage)      
          }
        ) 
      },
      fetchUpcomingEvents(){
          this.loading = true;
          console.log("nextpage",this.nextPage);
         PalmEvent.getUpcomingEvents(this.nextPage).then(
          (result) => {
                let content = result.data.data;
                content.forEach((element) => {
                    this.upcomingEvents.push(element);
                })
                let nexLink = result.data.links.next;
                if(nexLink != null){
                    const splitString = nexLink.split("=");
                    this.nextPage = parseInt(splitString[1]);
                }
                else if(nexLink == null){
                    this.nextPage = null;
                }
                //this.nextPage = result.data.links.next
                console.log(result.data.data)
                this.loading = false;
               
          },
          (error) => {
              const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
              console.log(resMessage) 
              this.loading = false;     
          }
        ) 
      }
  }
  
}
</script>

   