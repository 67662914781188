/* eslint-disable no-parsing-error */
/* eslint-disable no-irregular-whitespace */
<template>
    <div>
        <main data-aos="fade" class="pb-5">
          <section class="site-hero-main site-sec pb-0">
              <div class="site-sec-wrap container-xxl flex-column d-flex pt-5 align-items-center">
                  <div class="txt mt-4 mb-5">
                      <h1 class="text-white">Venue</h1>
                  </div>
              </div>
          </section>
          <section class="tickets-area site-sec">
              <div class="site-sec-wrap container-xxl">
                <div class="row">
                  <div class="col-lg-4" style="margin-bottom: 10px;">
                      <div class="tickets-area-account">
                          <h5 class="border-bottom pb-2 text-center mb-3">SEATS SELECTED</h5>
                          <div class="border rounded px-3 py-3" style="background-color: #e4f2f7">
                            <div v-if="isDivSeatVisible">
                              <p>{{ selectedSeats.join() }}</p>
                            </div>
                          </div>
                      </div>
                      <hr class="my-4" />
                      <form class="needs-validation" @submit.prevent="handleSubmit">
                          <a @click.prevent="handleSeatSelection()"  href="" class="w-100 btn btn-warning btn-lg" type="submit">Selected Seats</a>
                      </form>
                  </div>
                  <div class="col-lg-8">
                    <div class="site-cats row gx-3 justify-content-end" style="background-color: #e4f2f7">
                      <svg ref="svg" id="stadium" xmlns="http://www.w3.org/2000/svg" style="width: 950px; height: 567px;" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 675 464">
                          <g id="pitch" v-once>
                              <polyline class="cls-7" points="532.08 132.75 156.58 132.75 156.58 327.63 532.08 327.63 532.08 132.75"/>
                              <path class="cls-3" d="m532.99,328.54h0v-.91.91h.91-.91m-376.4-.91v-194.88h375.49v194.88s-375.49,0-375.49,0m377.31-196.69H154.77v198.51h379.12v-198.51"/>
                              <path class="cls-1" d="m377.33,230.19h-.91c0,9.21-3.73,17.54-9.77,23.58-6.04,6.04-14.37,9.77-23.58,9.77-9.21,0-17.54-3.73-23.58-9.77-6.04-6.04-9.77-14.37-9.77-23.58,0-9.21,3.73-17.54,9.77-23.58,6.04-6.04,14.37-9.77,23.58-9.77,9.21,0,17.54,3.73,23.58,9.77,6.04,6.04,9.77,14.37,9.77,23.58h1.81c0-19.42-15.74-35.16-35.16-35.16-19.42,0-35.16,15.74-35.16,35.16,0,19.42,15.74,35.16,35.16,35.16,19.42,0,35.16-15.74,35.16-35.16h-.91Z"/>
                              <polyline class="cls-1" points="342.16 132.08 342.16 328.31 343.98 328.31 343.98 132.08"/>
                              <polygon class="cls-1" points="203.69 302.03 203.69 301.12 156.58 301.12 156.58 159.27 202.78 159.27 202.78 302.03 203.69 302.03 203.69 301.12 203.69 302.03 204.6 302.03 204.6 157.45 154.77 157.45 154.77 302.93 204.6 302.93 204.6 302.03 203.69 302.03"/>
                              <polygon class="cls-1" points="532.99 302.03 532.99 301.12 485.88 301.12 485.88 159.27 532.08 159.27 532.08 302.03 532.99 302.03 532.99 301.12 532.99 302.03 533.89 302.03 533.89 157.45 484.07 157.45 484.07 302.93 533.89 302.93 533.89 302.03 532.99 302.03"/>
                              <polygon class="cls-1" points="173.68 265.47 173.68 264.56 156.58 264.56 156.58 195.82 172.78 195.82 172.78 265.47 173.68 265.47 173.68 264.56 173.68 265.47 174.59 265.47 174.59 194.01 154.77 194.01 154.77 266.38 174.59 266.38 174.59 265.47 173.68 265.47"/>
                              <polygon class="cls-1" points="532.99 265.47 532.99 264.56 515.89 264.56 515.89 195.82 532.08 195.82 532.08 265.47 532.99 265.47 532.99 264.56 532.99 265.47 533.89 265.47 533.89 194.01 514.07 194.01 514.07 266.38 533.89 266.38 533.89 265.47 532.99 265.47"/>
                              <path class="cls-1" d="m203.27,203.18h0c.17.09,3.78,2.04,7.32,5.91,1.77,1.94,3.52,4.35,4.82,7.26,1.3,2.91,2.17,6.3,2.17,10.22,0,3.98-.89,8.52-3.16,13.66-2.27,5.13-5.91,10.86-11.41,17.19l1.37,1.19c5.6-6.44,9.35-12.31,11.71-17.64,2.36-5.33,3.31-10.13,3.31-14.39,0-4.26-.96-7.97-2.39-11.11-2.15-4.72-5.37-8.18-8.04-10.47-2.67-2.29-4.81-3.4-4.85-3.43l-.84,1.61h0Z"/>
                          </g>
                          <g id="groupVvip" v-once> 
                            <path class="cls-10" d="m467.12,438.13l-.04.03c.1.16.27.25.45.25.09,0,.19-.03.28-.08.16-.1.25-.27.25-.45,0-.09-.03-.19-.08-.28-.1-.16-.27-.25-.45-.25-.09,0-.19.03-.28.08-.16.1-.25.27-.25.45,0,.09.03.19.08.28l.04-.03.04-.03c-.04-.07-.06-.15-.06-.22,0-.14.07-.28.2-.36.07-.04.15-.06.22-.06.14,0,.28.07.36.2.04.07.06.15.06.22,0,.14-.07.28-.2.36-.07.04-.15.06-.22.06-.14,0-.28-.07-.36-.2l-.04.03Z"/>
                            <rect class="cls-15" x="407.56" y="48.22" width="5.26" height="8.62" rx=".38" ry=".38" transform="translate(330.79 458.38) rotate(-86.23)"/>
                            <path class="cls-29" d="m413.94,55.41v-.05s-7.84-.52-7.84-.52c-.17-.01-.31-.16-.31-.33v-.02s.3-4.48.3-4.48c.01-.17.16-.31.33-.31h.02s7.84.52,7.84.52c.17.01.31.16.31.33v.02s-.3,4.48-.3,4.48c-.01.17-.16.31-.33.31h-.02s0,.05,0,.05v.05s.03,0,.03,0c.23,0,.42-.18.43-.41l.3-4.48v-.03c0-.23-.17-.42-.4-.43l-7.84-.52h-.03c-.23,0-.42.17-.43.4l-.3,4.48v.03c0,.23.17.42.4.43l7.84.52v-.05Z"/>
                            <text class="cls-4" transform="translate(406.32 53.41) rotate(3.77) scale(1 1.14)"><tspan x="0" y="0">VB16 5</tspan></text>
                            <rect class="cls-19" x="417.07" y="48.94" width="5.26" height="8.43" rx=".38" ry=".38" transform="translate(339.05 468.45) rotate(-86.23)"/>
                            <path class="cls-20" d="m423.34,56.03v-.05s-7.64-.5-7.64-.5c-.17-.01-.31-.16-.31-.33v-.02s.3-4.48.3-4.48c.01-.17.16-.31.33-.31h.02s7.65.5,7.65.5c.17.01.31.16.31.33v.02s-.3,4.48-.3,4.48c-.01.17-.16.31-.33.31h-.02s0,.05,0,.05v.05s.03,0,.03,0c.23,0,.42-.18.43-.41l.3-4.48v-.03c0-.23-.17-.42-.4-.43l-7.64-.5h-.03c-.23,0-.42.17-.43.4l-.3,4.48v.03c0,.23.17.42.4.43l7.64.5v-.05Z"/>
                            <text class="cls-12" transform="translate(416.73 54.16) rotate(3.77) scale(1 1.14)"><tspan x="0" y="0">VB8 3</tspan></text>
                            <rect class="cls-27" x="426.51" y="49.52" width="5.26" height="8.52" rx=".38" ry=".38" transform="translate(347.25 478.46) rotate(-86.23)"/>
                            <path class="cls-10" d="m432.84,56.66v-.05s-7.74-.51-7.74-.51c-.17-.01-.31-.16-.31-.33v-.02s.3-4.48.3-4.48c.01-.17.16-.31.33-.31h.02s7.74.51,7.74.51c.17.01.31.16.31.33v.02s-.3,4.48-.3,4.48c-.01.17-.16.31-.33.31h-.02s0,.05,0,.05v.05s.03,0,.03,0c.23,0,.42-.18.43-.41l.3-4.48v-.03c0-.23-.17-.42-.4-.43l-7.74-.51h-.03c-.23,0-.42.17-.43.4l-.3,4.48v.03c0,.23.17.42.4.43l7.74.51v-.05Z"/>
                            <text class="cls-4" transform="translate(425.88 54.22) rotate(3.77) scale(1 1.14)"><tspan x="0" y="0">VB8 4</tspan></text>
                            <text class="cls-2" transform="translate(320.75 23.64) rotate(1.06) scale(1 1.14)"><tspan x="0" y="0">PRESS </tspan><tspan class="cls-24" x="12.32" y="0">C</tspan><tspan x="14.65" y="0">OMMEN</tspan><tspan class="cls-14" x="29.61" y="0">T</tspan><tspan class="cls-16" x="31.58" y="0">A</tspan><tspan class="cls-18" x="33.94" y="0">T</tspan><tspan x="36.08" y="0">OR P</tspan><tspan class="cls-21" x="44.7" y="0">L</tspan><tspan class="cls-17" x="46.83" y="0">A</tspan><tspan x="49.43" y="0">CE</tspan></text>
                            <text class="cls-5" transform="translate(340.33 54.51) scale(1 1.14)"><tspan x="0" y="0">PRESIDENTIAL</tspan></text>
                            <rect @click="openModal('LOGGIA_01')" :class="{ 'enabled': isSectionEnabled('VB8 1') , 'disabled': !isSectionEnabled('VB8 1') }" class="cls-9" x="266.32" y="46.28" width="8.62" height="5.26" rx=".38" ry=".38"/>
                            <text class="cls-6" transform="translate(266.9 50.08) scale(1 1.14)"><tspan x="0" y="0">VB8 1</tspan></text>
                            <rect @click="openModal('LOGGIA_02')" :class="{ 'enabled': isSectionEnabled('VB8 1') , 'disabled': !isSectionEnabled('VB8 2') }" class="cls-9" x="275.94" y="46.28" width="8.43" height="5.26" rx=".38" ry=".38"/>
                            <text class="cls-6" transform="translate(276.76 49.84) scale(1 1.14)"><tspan x="0" y="0">VB8 2</tspan></text>
                            <rect @click="openModal('LOGGIA_03')" :class="{ 'enabled': isSectionEnabled('VB8 1') , 'disabled': !isSectionEnabled('VB6 1') }" class="cls-9" x="285.36" y="46.28" width="8.52" height="5.26" rx=".38" ry=".38"/>
                            <text class="cls-6" transform="translate(285.94 50.08) scale(1 1.14)"><tspan x="0" y="0">VB6 1</tspan></text>
                            <polygon class="cls-9" points="198.08 48.57 229.48 45.59 236.58 45.59 238.63 76.26 207.57 80.51 198.08 48.57"/>
                            <polygon class="cls-22" points="198.08 48.57 198.13 49.17 229.51 46.2 236.01 46.2 237.98 75.73 208.01 79.84 198.66 48.39 198.08 48.57 198.13 49.17 198.08 48.57 197.5 48.74 207.14 81.18 239.27 76.78 237.14 44.99 229.45 44.99 197.29 48.03 197.5 48.74 198.08 48.57"/>
                   
                               <!-- <path class="cls-1" d="m484.67,201.57c-.06.03-3.84,2-7.64,6.11-1.9,2.05-3.81,4.64-5.25,7.79-1.44,3.15-2.39,6.85-2.39,11.11,0,4.26.96,9.06,3.31,14.39,2.36,5.34,6.11,11.21,11.71,17.64l1.37-1.19c-5.5-6.33-9.15-12.05-11.41-17.19-2.27-5.13-3.16-9.67-3.16-13.66,0-3.99.89-7.42,2.23-10.36,2.01-4.4,5.04-7.68,7.57-9.84,1.26-1.08,2.4-1.89,3.22-2.42.41-.27.74-.46.96-.59.11-.06.2-.11.25-.14l.06-.03h.01s0,0,0,0l-.84-1.61h0Z"/> -->
                              <!-- <rect class="cls-26" x="268.91" y="45.52" width="7.99" height="2.89"/>
                              <rect class="cls-26" x="278.3" y="45.52" width="7.99" height="3.91"/>
                              <rect class="cls-26" x="288.31" y="45.52" width="4.65" height="3.91"/> -->
                              <path class="cls-9" @click="openModal('LOGGIA_04')" d="m296.61,48.4h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <text class="cls-4" transform="translate(296.55 53.76) rotate(3.77) scale(1 1.14)"><tspan x="0" y="0">VB16 1</tspan></text>
                              
                              <path class="cls-19" @click="onSelectSeat('VB12+2  1', 1000)" d="m306.11,48.4h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <path class="cls-20" d="m306.11,48.4v.05h6.86c.39,0,.72.39.72.87v.03s-.21,7.52-.21,7.52c-.01.48-.34.85-.72.85h-6.86c-.39,0-.72-.39-.72-.87v-.03s.21-7.52.21-7.52c.01-.48.34-.85.72-.85v-.1c-.45,0-.81.42-.82.95l-.21,7.52v.03c0,.53.36.98.82.98h6.86c.45,0,.81-.42.82-.95l.21-7.52v-.03c0-.53-.36-.98-.82-.98h-6.86v.05Z"/>
                              <text class="cls-11" transform="translate(306.05 54.55) rotate(-2.32) scale(1 1.5)"><tspan x="0" y="0" xml:space="preserve">VB12+2  1</tspan></text>
  
                              <path class="cls-27" @click="onSelectSeat('VB16 2', 68)" d="m315.61,48.4h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <path class="cls-10" d="m315.61,48.4v.05h6.86c.39,0,.72.39.72.87v.03s-.21,7.52-.21,7.52c-.01.48-.34.85-.72.85h-6.86c-.39,0-.72-.39-.72-.87v-.03s.21-7.52.21-7.52c.01-.48.34-.85.72-.85v-.1c-.45,0-.81.42-.82.95l-.21,7.52v.03c0,.53.36.98.82.98h6.86c.45,0,.81-.42.82-.95l.21-7.52v-.03c0-.53-.36-.98-.82-.98h-6.86v.05Z"/>
                              <text class="cls-5" transform="translate(315.49 54.22) scale(1 1.14)"><tspan x="0" y="0" xml:space="preserve">VB16  2</tspan></text>
                              <path class="cls-8" @click="onSelectSeat('VB16 3', 900)" d="m325.11,48.4h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <text class="cls-5" transform="translate(325.03 54.32) scale(1 1.14)"><tspan x="0" y="0" xml:space="preserve">VB16  3</tspan></text>
                              <path class="cls-8" @click="onSelectSeat('VB16 4', 900)" d="m365.41,49.09h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <text class="cls-4" transform="translate(365.25 54.32) rotate(3.77) scale(1 1.14)"><tspan x="0" y="0" xml:space="preserve">VB16  4</tspan></text>
                              <path class="cls-27" @click="onSelectSeat('VB16 5', 200)" d="m375.56,50.06h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <path class="cls-10" d="m375.56,50.06v.05h6.86c.39,0,.72.39.72.87v.03s-.21,7.52-.21,7.52c-.01.48-.34.85-.72.85h-6.86c-.39,0-.72-.39-.72-.87v-.03s.21-7.52.21-7.52c.01-.48.34-.85.72-.85v-.1c-.45,0-.81.42-.82.95l-.21,7.52v.03c0,.53.36.98.82.98h6.86c.45,0,.81-.42.82-.95l.21-7.52v-.03c0-.53-.36-.98-.82-.98h-6.86v.05Z"/>
                              <text class="cls-4" @click="showPopup = true" transform="translate(375.13 55.87) rotate(3.77) scale(1 1.14)"><tspan x="0" y="0">VB16 5</tspan></text>
                              <path class="cls-19" @click="onSelectSeat('VB12+2 2', 8900)" d="m385.39,50.4h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <text class="cls-11" transform="translate(385.15 56.36) rotate(-2.32) scale(1 1.5)"><tspan x="0" y="0">VB12+2 2</tspan></text>
                              <path class="cls-20" d="m385.39,50.4v.05h6.86c.39,0,.72.39.72.87v.03s-.21,7.52-.21,7.52c-.01.48-.34.85-.72.85h-6.86c-.39,0-.72-.39-.72-.87v-.03s.21-7.52.21-7.52c.01-.48.34-.85.72-.85v-.1c-.45,0-.81.42-.82.95l-.21,7.52v.03c0,.53.36.98.82.98h6.86c.45,0,.81-.42.82-.95l.21-7.52v-.03c0-.53-.36-.98-.82-.98h-6.86v.05Z"/>

                              <path class="cls-15" @click="onSelectSeat('VB16 6')" d="m395.21,50.72h6.86c.44,0,.79.43.77.96l-.21,7.52c-.01.5-.35.9-.77.9h-6.86c-.44,0-.79-.43-.77-.96l.21-7.52c.01-.5.35-.9.77-.9Z"/>
                              <text class="cls-5" transform="translate(395.05 56.27) scale(1 1.14)"><tspan x="0" y="0">VB16 6</tspan></text>
                              
                              <path class="cls-29" d="m395.21,50.72v.05h6.86c.39,0,.72.39.72.87v.03s-.21,7.52-.21,7.52c-.01.48-.34.85-.72.85h-6.86c-.39,0-.72-.39-.72-.87v-.03s.21-7.52.21-7.52c.01-.48.34-.85.72-.85v-.1c-.45,0-.81.42-.82.95l-.21,7.52v.03c0,.53.36.98.82.98h6.86c.45,0,.81-.42.82-.95l.21-7.52v-.03c0-.53-.36-.98-.82-.98h-6.86v.05Z"/>
                             
                              <path class="cls-8" d="m336.7,48.89l23.42.22c.8,0,1.44.68,1.41,1.48l-.23,6.11c-.03.77-.67,1.38-1.44,1.37l-23.25-.22c-.79,0-1.42-.65-1.41-1.44l.06-6.11c0-.79.65-1.42,1.44-1.41Z"/>

                          </g>
                            <g id="group101" v-once>  
                              <polygon id="section101" class="cls-9" points="321.67 60.16 321.67 70.62 375.67 70.62 375.67 60.34 321.67 60.16"/>

                            </g>  
                            <g id="group136" v-once>  
                              <polygon id="section136" @click="isSectionEnabled('136') ? handleZoom('section136') : null" :class="{ 'enabled': isSectionEnabled('136') , 'disabled': !isSectionEnabled('136') }" class="cls-9" points="90.47 154.13 131.54 159.52 139.49 132.51 103.04 120.24 93.41 143.45 90.47 154.13"/>
                              <polygon class="cls-22" points="90.47 154.13 90.39 154.73 131.98 160.18 140.23 132.12 102.7 119.49 92.83 143.25 89.71 154.64 90.39 154.73 90.47 154.13 91.06 154.29 93.98 143.65 103.39 120.99 138.74 132.9 131.11 158.85 90.55 153.53 90.47 154.13 91.06 154.29 90.47 154.13"/>
                              <text class="cls-5" transform="translate(105.46 145.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">136</tspan></text>
                       
                              <path v-for="seat in seats.section136" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section136'" @click="onSelectSeat('136', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            
                            </g>  
                            <g id="group134" v-once>  
                              <polygon id="section134" @click="isSectionEnabled('134') ? handleZoom('section134') : null" :class="{ 'enabled': isSectionEnabled('134') , 'disabled': !isSectionEnabled('134') }" points="84.5 188.44 119.97 191.5 116.34 217.14 82.55 215.32 84.5 188.44"/>
                              <polygon class="cls-22" points="84.45 189.05 119.29 192.05 115.82 216.51 83.19 214.75 85.11 188.49 83.9 188.4 81.9 215.89 116.87 217.77 120.66 190.96 84.55 187.84 84.45 189.05 84.45 189.05"/>
                              <text class="cls-5" transform="translate(90.46 205.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">134</tspan></text>
                              
                              <path id="U4" @click="onSelectSeat('134','U4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('U4') }" d="m87.95,190.86c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="U3" @click="onSelectSeat('134','U3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('U3') }" d="m87.7,193.01c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="U2" @click="onSelectSeat('134','U2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('U2') }" d="m87.45,195.16c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="U1" @click="onSelectSeat('134','U1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('U1') }" d="m87.2,197.31c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              
                              <path id="T18" @click="onSelectSeat('134','T18')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T18') }" d="m89.77,192.39c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T17" @click="onSelectSeat('134','T17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T17') }" d="m89.62,193.7c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="T16" @click="onSelectSeat('134','T16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T16') }" d="m89.47,195c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T15" @click="onSelectSeat('134','T15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T15') }" d="m89.32,196.31c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T14" @click="onSelectSeat('134','T14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T14') }" d="m89.16,197.62c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="T13" @click="onSelectSeat('134','T13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T13') }" d="m89.01,198.93c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="T12" @click="onSelectSeat('134','T12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T12') }" d="m88.86,200.23c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="T11" @click="onSelectSeat('134','T11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T11') }" d="m88.71,201.54c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="T10" @click="onSelectSeat('134','T10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T10') }" d="m88.56,202.85c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="T9" @click="onSelectSeat('134','T9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T9') }" d="m88.41,204.15c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="T8" @click="onSelectSeat('134','T8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T8') }" d="m88.25,205.46c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="T7" @click="onSelectSeat('134','T7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T7') }" d="m88.1,206.77c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="T6" @click="onSelectSeat('134','T6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T6') }" d="m87.95,208.07c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T5" @click="onSelectSeat('134','T5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T5') }" d="m87.8,209.38c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T4" @click="onSelectSeat('134','T4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T4') }" d="m87.65,210.69c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T3" @click="onSelectSeat('134','T3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T3') }" d="m87.5,211.99c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="T2" @click="onSelectSeat('134','T2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T2') }" d="m87.34,213.32c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="T1" @click="onSelectSeat('134','T1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('T1') }" d="m87.19,214.62c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              
                              <path id="S20" @click="onSelectSeat('134','S20')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S20') }" d="m91.29,191.24c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="S19" @click="onSelectSeat('134','S19')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S19') }" d="m91.13,192.55c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S18" @click="onSelectSeat('134','S18')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S18') }" d="m90.98,193.86c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="S17" @click="onSelectSeat('134','S17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S17') }" d="m90.83,195.16c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="S16" @click="onSelectSeat('134','S16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S16') }" d="m90.68,196.47c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="S15" @click="onSelectSeat('134','S15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S15') }" d="m90.53,197.78c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="S14" @click="onSelectSeat('134','S14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S14') }" d="m90.38,199.08c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="S13" @click="onSelectSeat('134','S13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S13') }" d="m90.22,200.39c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="S12" @click="onSelectSeat('134','S12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S12') }" d="m90.07,201.7c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="S11" @click="onSelectSeat('134','S11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S11') }" d="m89.92,203c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="S10" @click="onSelectSeat('134','S10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S10') }" d="m89.77,204.31c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S9" @click="onSelectSeat('134','S9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S9') }" d="m89.62,205.62c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S8" @click="onSelectSeat('134','S8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S8') }" d="m89.46,206.93c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S7" @click="onSelectSeat('134','S7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S7') }" d="m89.31,208.23c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="S6" @click="onSelectSeat('134','S6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S6') }" d="m89.16,209.54c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S5" @click="onSelectSeat('134','S5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S5') }" d="m89.01,210.85c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="S4" @click="onSelectSeat('134','S4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S4') }" d="m88.86,212.15c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S3" @click="onSelectSeat('134','S3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S3') }" d="m88.7,213.48c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="S2" @click="onSelectSeat('134','S2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S2') }" d="m88.55,214.78c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="S1" @click="onSelectSeat('134','S1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('S1') }" d="m89.92,191.08c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              
                              <path id="R19" @click="onSelectSeat('134','R19')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R19') }" d="m92.65,191.4c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="R18" @click="onSelectSeat('134','R18')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R18') }" d="m92.5,192.71c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R17" @click="onSelectSeat('134','R17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R17') }" d="m92.34,194.01c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="R16" @click="onSelectSeat('134','R16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R16') }" d="m92.19,195.32c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R15" @click="onSelectSeat('134','R15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R15') }" d="m92.04,196.63c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="R14" @click="onSelectSeat('134','R14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R14') }" d="m91.89,197.94c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="R13" @click="onSelectSeat('134','R13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R13') }" d="m91.74,199.24c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="R12" @click="onSelectSeat('134','R12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R12') }" d="m91.59,200.55c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="R11" @click="onSelectSeat('134','R11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R11') }" d="m91.43,201.86c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R10" @click="onSelectSeat('134','R10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R10') }" d="m91.28,203.16c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="R9" @click="onSelectSeat('134','R9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R9') }" d="m91.13,204.47c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R8" @click="onSelectSeat('134','R8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R8') }" d="m90.98,205.78c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R7" @click="onSelectSeat('134','R7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R7') }" d="m90.83,207.08c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="R6" @click="onSelectSeat('134','R6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R6') }" d="m90.67,208.39c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R5" @click="onSelectSeat('134','R5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R5') }" d="m90.52,209.7c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="R4" @click="onSelectSeat('134','R4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R4') }" d="m90.37,211c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="R3" @click="onSelectSeat('134','R3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R3') }" d="m90.22,212.31c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R2" @click="onSelectSeat('134','R2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R2') }" d="m90.07,213.64c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="R1" @click="onSelectSeat('134','R1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('R1') }" d="m89.92,214.93c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="Q19" @click="onSelectSeat('134','Q19')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q19') }" d="m94.01,191.56c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="Q18" @click="onSelectSeat('134','Q18')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q18') }" d="m93.86,192.87c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="Q17" @click="onSelectSeat('134','Q17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q17') }" d="m93.71,194.17c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="Q16" @click="onSelectSeat('134','Q16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q16') }" d="m93.55,195.48c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="Q15" @click="onSelectSeat('134','Q15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q15') }" d="m93.4,196.79c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="Q14" @click="onSelectSeat('134','Q14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q14') }" d="m93.25,198.09c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="Q13" @click="onSelectSeat('134','Q13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q13') }" d="m93.1,199.4c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="Q12" @click="onSelectSeat('134','Q12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q12') }" d="m92.95,200.71c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="Q11" @click="onSelectSeat('134','Q11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q11') }" d="m92.8,202.01c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="Q10" @click="onSelectSeat('134','Q10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q10') }" d="m92.64,203.32c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="Q9" @click="onSelectSeat('134','Q9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q9') }" d="m92.49,204.63c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="Q8" @click="onSelectSeat('134','Q8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q8') }" d="m92.34,205.93c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="Q7" @click="onSelectSeat('134','Q7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q7') }" d="m92.19,207.24c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="Q6" @click="onSelectSeat('134','Q6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q6') }" d="m92.04,208.55c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="Q5" @click="onSelectSeat('134','Q5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q5') }" d="m91.89,209.86c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="Q4" @click="onSelectSeat('134','Q4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q4') }" d="m91.73,211.16c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="Q3" @click="onSelectSeat('134','Q3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q3') }" d="m91.58,212.47c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="Q2" @click="onSelectSeat('134','Q2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q2') }" d="m91.43,213.8c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="Q1" @click="onSelectSeat('134','Q1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('Q1') }" d="m91.28,215.09c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="P17" @click="onSelectSeat('134','P17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P17') }" d="m95.22,193.02c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="P16" @click="onSelectSeat('134','P16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P16') }" d="m95.07,194.33c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="P15" @click="onSelectSeat('134','P15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P15') }" d="m94.92,195.64c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="P14" @click="onSelectSeat('134','P14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P14') }" d="m94.77,196.94c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="P13" @click="onSelectSeat('134','P13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P13') }" d="m94.61,198.25c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="P12" @click="onSelectSeat('134','P12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P12') }" d="m94.46,199.56c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="P11" @click="onSelectSeat('134','P11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P11') }" d="m94.31,200.87c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="P10" @click="onSelectSeat('134','P10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P10') }" d="m94.16,202.17c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="P9" @click="onSelectSeat('134','P9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P9') }" d="m94.01,203.48c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="P8" @click="onSelectSeat('134','P8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P8') }" d="m93.85,204.79c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="P7" @click="onSelectSeat('134','P7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P7') }" d="m93.7,206.09c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="P6" @click="onSelectSeat('134','P6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P6') }" d="m93.55,207.4c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="P5" @click="onSelectSeat('134','P5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P5') }" d="m93.4,208.71c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="P4" @click="onSelectSeat('134','P4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P4') }" d="m93.25,210.01c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="P3" @click="onSelectSeat('134','P3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P3') }" d="m93.1,211.32c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="P2" @click="onSelectSeat('134','P2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P2') }" d="m92.94,212.63c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="P1" @click="onSelectSeat('134','P1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('P1') }" d="m92.79,213.95c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              
                              <path id="O17" @click="onSelectSeat('134','O17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O17') }" d="m96.58,193.18c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="O16" @click="onSelectSeat('134','O16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O16') }" d="m96.43,194.49c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="O15" @click="onSelectSeat('134','O15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O15') }" d="m96.28,195.8c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="O14" @click="onSelectSeat('134','O14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O14') }" d="m96.13,197.1c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="O13" @click="onSelectSeat('134','O13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O13') }" d="m95.98,198.41c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="O12" @click="onSelectSeat('134','O12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O12') }" d="m95.82,199.72c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="O11" @click="onSelectSeat('134','O11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O11') }" d="m95.67,201.02c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="O10" @click="onSelectSeat('134','O10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O10') }" d="m95.52,202.33c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="O9" @click="onSelectSeat('134','O9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O9') }" d="m95.37,203.64c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="O8" @click="onSelectSeat('134','O8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O8') }" d="m95.22,204.94c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="O7" @click="onSelectSeat('134','O7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O7') }" d="m95.06,206.25c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="O6" @click="onSelectSeat('134','O6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O6') }" d="m94.91,207.56c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="O5" @click="onSelectSeat('134','O5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O5') }" d="m94.76,208.86c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="O4" @click="onSelectSeat('134','O4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O4') }" d="m94.61,210.17c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="O3" @click="onSelectSeat('134','O3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O3') }" d="m94.46,211.48c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="O2" @click="onSelectSeat('134','O2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O2') }" d="m94.31,212.79c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="O1" @click="onSelectSeat('134','O1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('O1') }" d="m94.15,214.11c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              
                              <path id="N17" @click="onSelectSeat('134','N17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N17') }" d="m97.94,193.34c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="N16" @click="onSelectSeat('134','N16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N16') }" d="m97.79,194.65c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="N15" @click="onSelectSeat('134','N15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N15') }" d="m97.64,195.95c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="N14" @click="onSelectSeat('134','N14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N14') }" d="m97.49,197.26c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="N13" @click="onSelectSeat('134','N13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N13') }" d="m97.34,198.57c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="N12" @click="onSelectSeat('134','N12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N12') }" d="m97.19,199.87c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="N11" @click="onSelectSeat('134','N11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N11') }" d="m97.03,201.18c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="N10" @click="onSelectSeat('134','N10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N10') }" d="m96.88,202.49c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="N9" @click="onSelectSeat('134','N9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N9') }" d="m96.73,203.8c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="N8" @click="onSelectSeat('134','N8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N8') }" d="m96.58,205.1c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="N7" @click="onSelectSeat('134','N7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N7') }" d="m96.43,206.41c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="N6" @click="onSelectSeat('134','N6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N6') }" d="m96.28,207.72c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="N5" @click="onSelectSeat('134','N5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N5') }" d="m96.12,209.02c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="N4" @click="onSelectSeat('134','N4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N4') }" d="m95.97,210.33c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="N3" @click="onSelectSeat('134','N3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N3') }" d="m95.82,211.64c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="N2" @click="onSelectSeat('134','N2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N2') }" d="m95.67,212.94c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="N1" @click="onSelectSeat('134','N1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('N1') }" d="m95.51,214.27c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              
                              <path id="M17" @click="onSelectSeat('134','M17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M17') }" d="m99.31,193.5c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="M16" @click="onSelectSeat('134','M16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M16') }" d="m99.16,194.81c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="M15" @click="onSelectSeat('134','M15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M15') }" d="m99,196.11c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="M14" @click="onSelectSeat('134','M14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M14') }" d="m98.85,197.42c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="M13" @click="onSelectSeat('134','M13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M13') }" d="m98.7,198.73c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="M12" @click="onSelectSeat('134','M12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M12') }" d="m98.55,200.03c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="M11" @click="onSelectSeat('134','M11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M11') }" d="m98.4,201.34c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="M10" @click="onSelectSeat('134','M10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M10') }" d="m98.24,202.65c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="M9" @click="onSelectSeat('134','M9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M9') }" d="m98.09,203.95c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="M8" @click="onSelectSeat('134','M8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M8') }" d="m97.94,205.26c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="M7" @click="onSelectSeat('134','M7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M7') }" d="m97.79,206.57c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="M6" @click="onSelectSeat('134','M6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M6') }" d="m97.64,207.87c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="M5" @click="onSelectSeat('134','M5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M5') }" d="m97.49,209.18c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="M4" @click="onSelectSeat('134','M4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M4') }" d="m97.33,210.49c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="M3" @click="onSelectSeat('134','M3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M3') }" d="m97.18,211.79c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="M2" @click="onSelectSeat('134','M2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M2') }" d="m97.03,213.1c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="M1" @click="onSelectSeat('134','M1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('M1') }" d="m96.88,214.43c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              
                              <path id="L17" @click="onSelectSeat('134','L17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L17') }" d="m100.67,193.66c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="L16" @click="onSelectSeat('134','L16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L16') }" d="m100.52,194.96c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="L15" @click="onSelectSeat('134','L15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L15') }" d="m100.37,196.27c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="L14" @click="onSelectSeat('134','L14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L14') }" d="m100.21,197.58c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="L13" @click="onSelectSeat('134','L13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L13') }" d="m100.06,198.88c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="L12" @click="onSelectSeat('134','L12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L12') }" d="m99.91,200.19c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="L11" @click="onSelectSeat('134','L11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L11') }" d="m99.76,201.5c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="L10" @click="onSelectSeat('134','L10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L10') }" d="m99.61,202.8c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="L9" @click="onSelectSeat('134','L9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L9') }" d="m99.46,204.11c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="L8" @click="onSelectSeat('134','L8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L8') }" d="m99.3,205.42c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="L7" @click="onSelectSeat('134','L7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L7') }" d="m99.15,206.73c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="L6" @click="onSelectSeat('134','L6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L6') }" d="m99,208.03c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="L5" @click="onSelectSeat('134','L5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L5') }" d="m98.85,209.34c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="L4" @click="onSelectSeat('134','L4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L4') }" d="m98.7,210.65c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="L3" @click="onSelectSeat('134','L3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L3') }" d="m98.54,211.95c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="L2" @click="onSelectSeat('134','L2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L2') }" d="m98.39,213.26c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="L1" @click="onSelectSeat('134','L1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('L1') }" d="m98.24,214.59c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              
                              <path id="K17" @click="onSelectSeat('134','K17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K17') }" d="m102.03,193.81c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="K16" @click="onSelectSeat('134','K16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K16') }" d="m101.88,195.12c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="K15" @click="onSelectSeat('134','K15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K15') }" d="m101.73,196.43c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="K14" @click="onSelectSeat('134','K14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K14') }" d="m101.58,197.74c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="K13" @click="onSelectSeat('134','K13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K13') }" d="m101.42,199.04c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="K12" @click="onSelectSeat('134','K12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K12') }" d="m101.27,200.35c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="K11" @click="onSelectSeat('134','K11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K11') }" d="m101.12,201.66c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="K10" @click="onSelectSeat('134','K10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K10') }" d="m100.97,202.96c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="K9" @click="onSelectSeat('134','K9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K9') }" d="m100.82,204.27c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="K8" @click="onSelectSeat('134','K8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K8') }" d="m100.67,205.58c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="K7" @click="onSelectSeat('134','K7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K7') }" d="m100.51,206.88c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="K6" @click="onSelectSeat('134','K6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K6') }" d="m100.36,208.19c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="K5" @click="onSelectSeat('134','K5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K5') }" d="m100.21,209.5c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="K4" @click="onSelectSeat('134','K4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K4') }" d="m100.06,210.8c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="K3" @click="onSelectSeat('134','K3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K3') }" d="m99.91,212.11c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="K2" @click="onSelectSeat('134','K2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K2') }" d="m99.75,213.42c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="K1" @click="onSelectSeat('134','K1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('K1') }"  d="m99.6,214.72c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="J17" @click="onSelectSeat('134','J17')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J17') }" d="m103.39,193.97c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="J16" @click="onSelectSeat('134','J16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J16') }" d="m103.24,195.28c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="J15" @click="onSelectSeat('134','J15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J15') }" d="m103.09,196.59c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="J14" @click="onSelectSeat('134','J14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J14') }" d="m102.94,197.89c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="J13" @click="onSelectSeat('134','J13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J13') }" d="m102.79,199.2c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="J12" @click="onSelectSeat('134','J12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J12') }" d="m102.63,200.51c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="J11" @click="onSelectSeat('134','J11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J11') }" d="m102.48,201.81c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="J10" @click="onSelectSeat('134','J10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J10') }" d="m102.33,203.12c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="J9" @click="onSelectSeat('134','J9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J9') }" d="m102.18,204.43c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="J8" @click="onSelectSeat('134','J8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J8') }" d="m102.03,205.73c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="J7" @click="onSelectSeat('134','J7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J7') }" d="m101.88,207.04c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="J6" @click="onSelectSeat('134','J6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J6') }" d="m101.72,208.35c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="J5" @click="onSelectSeat('134','J5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J5') }" d="m101.57,209.66c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="J4" @click="onSelectSeat('134','J4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J4') }" d="m101.42,210.96c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="J3" @click="onSelectSeat('134','J3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J3') }" d="m101.27,212.27c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="J2" @click="onSelectSeat('134','J2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J2') }" d="m101.12,213.58c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="J1" @click="onSelectSeat('134','J1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('J1') }" d="m100.97,214.88c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="I16" @click="onSelectSeat('134','I16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I16') }" d="m104.76,194.13c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="I15" @click="onSelectSeat('134','I15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I15') }" d="m104.6,195.44c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="I14" @click="onSelectSeat('134','I14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I14') }" d="m104.45,196.74c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="I13" @click="onSelectSeat('134','I13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I13') }" d="m104.3,198.05c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="I12" @click="onSelectSeat('134','I12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I12') }" d="m104.15,199.36c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="I11" @click="onSelectSeat('134','I11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I11') }" d="m104,200.67c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="I10" @click="onSelectSeat('134','I10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I10') }" d="m103.85,201.97c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="I9" @click="onSelectSeat('134','I9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I9') }" d="m103.69,203.28c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="I8" @click="onSelectSeat('134','I8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I8') }" d="m103.54,204.59c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="I7" @click="onSelectSeat('134','I7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I7') }" d="m103.39,205.89c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="I6" @click="onSelectSeat('134','I6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I6') }" d="m103.24,207.2c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="I5" @click="onSelectSeat('134','I5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I5') }" d="m103.09,208.51c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="I4" @click="onSelectSeat('134','I4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I4') }" d="m102.93,209.81c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="I3" @click="onSelectSeat('134','I3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I3') }" d="m102.78,211.12c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="I2" @click="onSelectSeat('134','I2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I2') }" d="m102.63,212.43c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="I1" @click="onSelectSeat('134','I1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('I1') }" d="m102.48,213.73c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                  
                              <path id="H16" @click="onSelectSeat('134','H16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H16') }" d="m106.12,194.29c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="H15" @click="onSelectSeat('134','H15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H15') }" d="m105.97,195.6c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="H14" @click="onSelectSeat('134','H14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H14') }" d="m105.81,196.9c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="H13" @click="onSelectSeat('134','H13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H13') }" d="m105.66,198.21c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="H12" @click="onSelectSeat('134','H12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H12') }" d="m105.51,199.52c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="H11" @click="onSelectSeat('134','H11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H11') }" d="m105.36,200.82c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="H10" @click="onSelectSeat('134','H10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H10') }" d="m105.21,202.13c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="H9" @click="onSelectSeat('134','H9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H9') }" d="m105.06,203.44c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="H8" @click="onSelectSeat('134','H8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H8') }" d="m104.9,204.74c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="H7" @click="onSelectSeat('134','H7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H7') }" d="m104.75,206.05c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="H6" @click="onSelectSeat('134','H6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H6') }" d="m104.6,207.36c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="H5" @click="onSelectSeat('134','H5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H5') }" d="m104.45,208.66c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="H4" @click="onSelectSeat('134','H4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H4') }" d="m104.3,209.97c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="H3" @click="onSelectSeat('134','H3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H3') }" d="m104.14,211.28c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="H2" @click="onSelectSeat('134','H2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H2') }" d="m103.99,212.59c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="H1" @click="onSelectSeat('134','H1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('H1') }" d="m103.84,213.89c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              
                              <path id="G16" @click="onSelectSeat('134','G16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G16') }" d="m107.48,194.45c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="G15" @click="onSelectSeat('134','G15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G15') }" d="m107.33,195.75c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="G14" @click="onSelectSeat('134','G14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G14') }" d="m107.18,197.06c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="G13" @click="onSelectSeat('134','G13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G13') }" d="m107.02,198.37c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="G12" @click="onSelectSeat('134','G12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G12') }" d="m106.87,199.67c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="G11" @click="onSelectSeat('134','G11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G11') }" d="m106.72,200.98c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="G10" @click="onSelectSeat('134','G10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G10') }" d="m106.57,202.29c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="G9" @click="onSelectSeat('134','G9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G9') }" d="m106.42,203.6c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="G8" @click="onSelectSeat('134','G8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G8') }" d="m106.27,204.9c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="G7" @click="onSelectSeat('134','G7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G7') }" d="m106.11,206.21c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="G6" @click="onSelectSeat('134','G6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G6') }" d="m105.96,207.52c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="G5" @click="onSelectSeat('134','G5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G5') }" d="m105.81,208.82c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="G4" @click="onSelectSeat('134','G4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G4') }" d="m105.66,210.13c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="G3" @click="onSelectSeat('134','G3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G3') }" d="m105.51,211.44c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="G2" @click="onSelectSeat('134','G2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G2') }" d="m105.36,212.74c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="G1" @click="onSelectSeat('134','G1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('G1') }" d="m105.2,214.05c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="F16" @click="onSelectSeat('134','F16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F16') }" d="m108.84,194.61c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="F15" @click="onSelectSeat('134','F15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F15') }" d="m108.69,195.91c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="F14" @click="onSelectSeat('134','F14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F14') }" d="m108.54,197.22c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="F13" @click="onSelectSeat('134','F13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F13') }" d="m108.39,198.53c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="F12" @click="onSelectSeat('134','F12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F12') }" d="m108.24,199.83c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="F11" @click="onSelectSeat('134','F11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F11') }" d="m108.08,201.14c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="F10" @click="onSelectSeat('134','F10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F10') }" d="m107.93,202.45c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="F9" @click="onSelectSeat('134','F9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F9') }" d="m107.78,203.75c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="F8" @click="onSelectSeat('134','F8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F8') }" d="m107.63,205.06c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="F7" @click="onSelectSeat('134','F7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F7') }" d="m107.48,206.37c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="F6" @click="onSelectSeat('134','F6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F6') }" d="m107.32,207.67c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="F5" @click="onSelectSeat('134','F5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F5') }" d="m107.17,208.98c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="F4" @click="onSelectSeat('134','F4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F4') }" d="m107.02,210.29c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="F3" @click="onSelectSeat('134','F3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F3') }" d="m106.87,211.6c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="F2" @click="onSelectSeat('134','F2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F2') }" d="m106.72,212.9c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="F1" @click="onSelectSeat('134','F1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('F1') }" d="m106.57,214.21c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="E16" @click="onSelectSeat('134','E16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E16') }" d="m110.2,194.76c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="E15" @click="onSelectSeat('134','E15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E15') }" d="m110.05,196.07c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="E14" @click="onSelectSeat('134','E14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E14') }" d="m109.9,197.38c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="E13" @click="onSelectSeat('134','E13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E13') }" d="m109.75,198.68c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="E12" @click="onSelectSeat('134','E12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E12') }" d="m109.6,199.99c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="E11" @click="onSelectSeat('134','E11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E11') }" d="m109.45,201.3c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="E10" @click="onSelectSeat('134','E10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E10') }" d="m109.29,202.61c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="E9" @click="onSelectSeat('134','E9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E9') }" d="m109.14,203.91c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="E8" @click="onSelectSeat('134','E8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E8') }" d="m108.99,205.22c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="E7" @click="onSelectSeat('134','E7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E7') }" d="m108.84,206.53c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="E6" @click="onSelectSeat('134','E6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E6') }" d="m108.69,207.83c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="E5" @click="onSelectSeat('134','E5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E5') }" d="m108.53,209.14c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="E4" @click="onSelectSeat('134','E4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E4') }" d="m108.38,210.45c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="E3" @click="onSelectSeat('134','E3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E3') }" d="m108.23,211.75c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="E2" @click="onSelectSeat('134','E2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E2') }" d="m108.08,213.06c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="E1" @click="onSelectSeat('134','E1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('E1') }" d="m107.93,214.37c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              
                              <path id="D16" @click="onSelectSeat('134','D16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D16') }" d="m111.57,194.92c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="D15" @click="onSelectSeat('134','D15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D15') }" d="m111.41,196.23c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="D14" @click="onSelectSeat('134','D14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D14') }" d="m111.26,197.54c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="D13" @click="onSelectSeat('134','D13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D13') }" d="m111.11,198.84c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="D12" @click="onSelectSeat('134','D12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D12') }" d="m110.96,200.15c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="D11" @click="onSelectSeat('134','D11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D11') }" d="m110.81,201.46c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="D10" @click="onSelectSeat('134','D10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D10') }" d="m110.66,202.76c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="D9" @click="onSelectSeat('134','D9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D9') }" d="m110.5,204.07c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="D8" @click="onSelectSeat('134','D8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D8') }" d="m110.35,205.38c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="D7" @click="onSelectSeat('134','D7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D7') }" d="m110.2,206.68c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="D6" @click="onSelectSeat('134','D6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D6') }" d="m110.05,207.99c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="D5" @click="onSelectSeat('134','D5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D5') }" d="m109.9,209.3c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="D4" @click="onSelectSeat('134','D4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D4') }" d="m109.75,210.6c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="D3" @click="onSelectSeat('134','D3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D3') }" d="m109.59,211.91c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="D2" @click="onSelectSeat('134','D2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D2') }" d="m109.44,213.22c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="D1" @click="onSelectSeat('134','D1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('D1') }" d="m109.29,214.53c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              
                              <path id="C16" @click="onSelectSeat('134','C16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C16') }" d="m112.93,195.08c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="C15" @click="onSelectSeat('134','C15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C15') }" d="m112.78,196.39c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="C14" @click="onSelectSeat('134','C14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C14') }" d="m112.63,197.69c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="C13" @click="onSelectSeat('134','C13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C13') }" d="m112.47,199c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="C12" @click="onSelectSeat('134','C12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C12') }" d="m112.32,200.31c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="C11" @click="onSelectSeat('134','C11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C11') }" d="m112.17,201.61c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="C10" @click="onSelectSeat('134','C10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C10') }" d="m112.02,202.92c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="C9" @click="onSelectSeat('134','C9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C9') }" d="m111.87,204.23c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="C8" @click="onSelectSeat('134','C8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C8') }" d="m111.71,205.54c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="C7" @click="onSelectSeat('134','C7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C7') }" d="m111.56,206.84c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="C6" @click="onSelectSeat('134','C6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C6') }" d="m111.41,208.15c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="C5" @click="onSelectSeat('134','C5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C5') }" d="m111.26,209.46c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="C4" @click="onSelectSeat('134','C4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C4') }" d="m111.11,210.76c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="C3" @click="onSelectSeat('134','C3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C3') }" d="m110.96,212.07c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="C2" @click="onSelectSeat('134','C2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C2') }" d="m110.8,213.38c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="C1" @click="onSelectSeat('134','C1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('C1') }" d="m110.65,214.68c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>

                              <path id="B16" @click="onSelectSeat('134','B16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B16') }" d="m114.29,195.24c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="B15" @click="onSelectSeat('134','B15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B15') }" d="m114.14,196.55c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="B14" @click="onSelectSeat('134','B14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B14') }" d="m113.99,197.85c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="B13" @click="onSelectSeat('134','B13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B13') }" d="m113.84,199.16c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="B12" @click="onSelectSeat('134','B12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B12') }" d="m113.68,200.47c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="B11" @click="onSelectSeat('134','B11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B11') }" d="m113.53,201.77c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="B10" @click="onSelectSeat('134','B10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B10') }" d="m113.38,203.08c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="B9" @click="onSelectSeat('134','B9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B9') }" d="m113.23,204.39c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="B8" @click="onSelectSeat('134','B8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B8') }" d="m113.08,205.69c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="B7" @click="onSelectSeat('134','B7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B7') }" d="m112.92,207c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="B6" @click="onSelectSeat('134','B6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B6') }" d="m112.77,208.31c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="B5" @click="onSelectSeat('134','B5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B5') }" d="m112.62,209.61c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="B4" @click="onSelectSeat('134','B4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B4') }" d="m112.47,210.92c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="B3" @click="onSelectSeat('134','B3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B3') }" d="m112.32,212.23c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="B2" @click="onSelectSeat('134','B2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B2') }" d="m112.17,213.53c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="B1" @click="onSelectSeat('134','B1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('B1') }" d="m112.01,214.84c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              
                              <path id="A16" @click="onSelectSeat('134','A16')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A16') }" d="m115.65,195.4c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="A15" @click="onSelectSeat('134','A15')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A15') }" d="m115.5,196.7c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="A14" @click="onSelectSeat('134','A14')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A14') }" d="m115.35,198.01c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="A13" @click="onSelectSeat('134','A13')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A13') }" d="m115.2,199.32c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="A12" @click="onSelectSeat('134','A12')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A12') }" d="m115.05,200.62c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="A11" @click="onSelectSeat('134','A11')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A11') }" d="m114.89,201.93c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="A10" @click="onSelectSeat('134','A10')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A10') }" d="m114.74,203.24c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                              <path id="A9" @click="onSelectSeat('134','A9')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A9') }" d="m114.59,204.54c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58.33.38.58.31Z"/>
                              <path id="A8" @click="onSelectSeat('134','A8')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A8') }" d="m114.44,205.85c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="A7" @click="onSelectSeat('134','A7')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A7') }" d="m114.29,207.16c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31s-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="A6" @click="onSelectSeat('134','A6')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A6') }" d="m114.14,208.47c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="A5" @click="onSelectSeat('134','A5')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A5') }" d="m113.98,209.77c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="A4" @click="onSelectSeat('134','A4')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A4') }" d="m113.83,211.08c.25-.07.38-.33.31-.58s-.33-.38-.58-.31-.38.33-.31.58c.07.25.33.38.58.31Z"/>
                              <path id="A3" @click="onSelectSeat('134','A3')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A3') }" d="m113.68,212.39c.25-.07.38-.33.31-.58-.07-.25-.33-.38-.58-.31-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="A2" @click="onSelectSeat('134','A2')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A2') }" d="m113.53,213.69c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58.07.25.33.38.58.31Z"/>
                              <path id="A1" @click="onSelectSeat('134','A1')" v-show="isSeatVisible === 'section134'" :class="{ 'selected': isSelected('A1') }" d="m113.38,215c.25-.07.38-.33.31-.58s-.33-.38-.58-.31c-.25.07-.38.33-.31.58s.33.38.58.31Z"/>
                            
                            </g>  
                              
                            <g id="group126" v-once>  
                              <polygon id="section126" @click="isSectionEnabled('126') ? handleZoom('section126') : null" :class="{ 'enabled': isSectionEnabled('126') , 'disabled': !isSectionEnabled('126') }" points="223.99 413.04 227.51 384.8 193.03 377.99 181.8 411.68 223.99 413.04"/>
                              <polygon class="cls-22" points="223.99 413.04 224.59 413.11 228.18 384.31 192.62 377.29 180.96 412.26 224.52 413.66 224.59 413.11 223.99 413.04 224.01 412.44 182.63 411.1 193.43 378.69 226.84 385.28 223.39 412.97 223.99 413.04 224.01 412.44 223.99 413.04"/>
                              <text class="cls-5" transform="translate(200.46 400.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">126</tspan></text>

                              <path v-for="seat in seats.section126" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section126'" @click="onSelectSeat('126', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            
                              <circle id="U26" @click="onSelectSeat('126','U26')" v-show="isSeatVisible === 'section126'" :class="{ 'selected': isSelected('U26') }" cx="190.67" cy="407.87" r=".45"/>
                            </g>  
                           
                              <!-- <polyline class="cls-22" points="289.82 43.13 289.82 6.38 288.61 6.38 288.61 43.13"/>
                              <line class="cls-9" x1="469.69" y1="50.77" x2="473.09" y2="14.32"/>
                              <polyline class="cls-22" points="470.29 50.83 473.7 14.38 472.49 14.26 469.09 50.71"/> -->
                          
                              <!-- upper ground -->
  
                              <g id="group201" v-once>

                                    <polygon id="section201" @click="handleZoom('section201')" :class="{ 'enabled': isSectionEnabled('201') , 'disabled': !isSectionEnabled('201') }" points="371.19 28.92 370.85 47.17 390.03 47.54 390.17 29.29 371.19 28.92"/>
                                    <polygon class="cls-22" points="371.19 28.92 370.59 28.91 370.24 47.76 390.63 48.16 390.78 28.7 370.6 28.31 370.59 28.91 371.19 28.92 371.18 29.53 389.56 29.89 389.43 46.93 371.47 46.58 371.8 28.93 371.19 28.92 371.18 29.53 371.19 28.92"/>
                                    <text class="cls-5" transform="translate(373.23 40.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">201</tspan></text>

                                    <path id="A1" @click="onSelectSeat('201','A1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A1') }" d="m372.98,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A2" @click="onSelectSeat('201','A2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A2') }" d="m374.23,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A3" @click="onSelectSeat('201','A3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A3') }" d="m375.48,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A4" @click="onSelectSeat('201','A4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A4') }" d="m376.74,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A5" @click="onSelectSeat('201','A5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A5') }" d="m377.99,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A6" @click="onSelectSeat('201','A6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A6') }" d="m379.22,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A7" @click="onSelectSeat('201','A7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A7') }" d="m380.48,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A8" @click="onSelectSeat('201','A8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A8') }" d="m381.73,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A9" @click="onSelectSeat('201','A9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A9') }" d="m382.99,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A10" @click="onSelectSeat('201','A10')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A10') }" d="m384.24,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A11" @click="onSelectSeat('201','A11')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A11') }" d="m385.5,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A12" @click="onSelectSeat('201','A12')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A12') }" d="m386.75,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="A13" @click="onSelectSeat('201','A13')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('A13') }" d="m388,43.24c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    
                                    <path id="B1" @click="onSelectSeat('201','B1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B1') }" d="m377.99,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B2" @click="onSelectSeat('201','B2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B2') }" d="m379.22,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B3" @click="onSelectSeat('201','B3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B3') }" d="m380.48,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B4" @click="onSelectSeat('201','B4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B4') }" d="m381.73,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B5" @click="onSelectSeat('201','B5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B5') }" d="m382.99,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B6" @click="onSelectSeat('201','B6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B6') }" d="m384.24,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B7" @click="onSelectSeat('201','B7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B7') }" d="m385.5,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B8" @click="onSelectSeat('201','B8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B8') }" d="m386.75,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="B9" @click="onSelectSeat('201','B9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('B9') }" d="m388,41.93c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    
                                    <path id="C1" @click="onSelectSeat('201','C1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C1') }" d="m377.99,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C2" @click="onSelectSeat('201','C2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C2') }" d="m379.22,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C3" @click="onSelectSeat('201','C3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C3') }" d="m380.48,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C4" @click="onSelectSeat('201','C4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C4') }" d="m381.73,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C5" @click="onSelectSeat('201','C5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C5') }" d="m382.99,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C6" @click="onSelectSeat('201','C6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C6') }" d="m384.24,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C7" @click="onSelectSeat('201','C7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C7') }" d="m385.5,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C8" @click="onSelectSeat('201','C8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C8') }" d="m386.75,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="C9" @click="onSelectSeat('201','C9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('C9') }" d="m388,40.63c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    
                                    <path id="D1" @click="onSelectSeat('201','D1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D1') }" d="m377.99,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D2" @click="onSelectSeat('201','D2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D2') }" d="m379.22,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D3" @click="onSelectSeat('201','D3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D3') }" d="m380.48,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D4" @click="onSelectSeat('201','D4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D4') }" d="m381.73,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D5" @click="onSelectSeat('201','D5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D5') }" d="m382.99,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D6" @click="onSelectSeat('201','D6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D6') }" d="m384.24,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D7" @click="onSelectSeat('201','D7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D7') }" d="m385.5,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D8" @click="onSelectSeat('201','D8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D8') }" d="m386.75,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="D9" @click="onSelectSeat('201','D9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('D9') }" d="m388,39.32c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    
                                    <path id="E1" @click="onSelectSeat('201','E1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E1') }" d="m377.99,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="E2" @click="onSelectSeat('201','E2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E2') }" d="m379.22,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="E3" @click="onSelectSeat('201','E3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E3') }"  d="m380.48,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="E4" @click="onSelectSeat('201','E4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E4') }" d="m381.73,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="E5" @click="onSelectSeat('201','E5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E5') }" d="m382.99,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="E6" @click="onSelectSeat('201','E6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E6') }" d="m384.24,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="E7" @click="onSelectSeat('201','E7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E7') }" d="m385.5,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="E8" @click="onSelectSeat('201','E8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E8') }" d="m386.75,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="E9" @click="onSelectSeat('201','E9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('E9') }" d="m388,38.01c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    
                                    <path id="F1" @click="onSelectSeat('201','F1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F1') }" d="m377.99,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="F2" @click="onSelectSeat('201','F2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F2') }" d="m379.22,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="F3" @click="onSelectSeat('201','F3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F3') }" d="m380.48,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="F4" @click="onSelectSeat('201','F4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F4') }" d="m381.73,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="F5" @click="onSelectSeat('201','F5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F5') }" d="m382.99,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="F6" @click="onSelectSeat('201','F6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F6') }" d="m384.24,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="F7" @click="onSelectSeat('201','F7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F7') }" d="m385.5,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="F8" @click="onSelectSeat('201','F8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F8') }" d="m386.75,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="F9" @click="onSelectSeat('201','F9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('F9') }" d="m388,36.71c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    
                                    <path id="G1" @click="onSelectSeat('201','G1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G1') }" d="m377.99,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="G2" @click="onSelectSeat('201','G2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G2') }" d="m379.22,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="G3" @click="onSelectSeat('201','G3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G3') }" d="m380.48,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="G4" @click="onSelectSeat('201','G4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G4') }" d="m381.73,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="G5" @click="onSelectSeat('201','G5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G5') }" d="m382.99,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="G6" @click="onSelectSeat('201','G6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G6') }" d="m384.24,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="G7" @click="onSelectSeat('201','G7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G7') }" d="m385.5,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="G8" @click="onSelectSeat('201','G8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G8') }" d="m386.75,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="G9" @click="onSelectSeat('201','G9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('G9') }" d="m388,35.4c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    
                                    <path id="H1" @click="onSelectSeat('201','H1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H1') }" d="m372.98,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23s.33.36.23.58Z"/>
                                    <path id="H2" @click="onSelectSeat('201','H2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H2') }" d="m374.23,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H3" @click="onSelectSeat('201','H3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H3') }" d="m375.48,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H4" @click="onSelectSeat('201','H4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H4') }" d="m376.74,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H5" @click="onSelectSeat('201','H5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H5') }" d="m377.99,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H6" @click="onSelectSeat('201','H6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H6') }" d="m379.22,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23s.33.36.23.58Z"/>
                                    <path id="H7" @click="onSelectSeat('201','H7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H7') }" d="m380.48,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H8" @click="onSelectSeat('201','H8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H8') }" d="m381.73,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H9" @click="onSelectSeat('201','H9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H9') }" d="m382.99,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="H10" @click="onSelectSeat('201','H10')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H10') }" d="m384.24,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H11" @click="onSelectSeat('201','H11')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H11') }" d="m385.5,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H12" @click="onSelectSeat('201','H12')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H12') }" d="m386.75,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    <path id="H13" @click="onSelectSeat('201','H13')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('H13') }" d="m388,34.09c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58s.36-.33.58-.23c.22.1.33.36.23.58Z"/>
                                    
                                    <path id="I1" @click="onSelectSeat('201','I1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I1') }" d="m372.98,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I2" @click="onSelectSeat('201','I2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I2') }" d="m374.23,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I3" @click="onSelectSeat('201','I3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I3') }" d="m375.48,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I4" @click="onSelectSeat('201','I4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I4') }" d="m376.74,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I5" @click="onSelectSeat('201','I5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I5') }" d="m377.99,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I6" @click="onSelectSeat('201','I6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I6') }" d="m379.22,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I7" @click="onSelectSeat('201','I7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I7') }" d="m380.48,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I8" @click="onSelectSeat('201','I8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I8') }" d="m381.73,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I9" @click="onSelectSeat('201','I9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I9') }" d="m382.99,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I10" @click="onSelectSeat('201','I10')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I10') }" d="m384.24,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I11" @click="onSelectSeat('201','I11')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I11') }" d="m385.5,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I12" @click="onSelectSeat('201','I12')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I12') }" d="m386.75,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="I13" @click="onSelectSeat('201','I13')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('I13') }" d="m388,32.78c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    
                                    <path id="J1" @click="onSelectSeat('201','J1')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J1') }" d="m372.98,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J2" @click="onSelectSeat('201','J2')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J2') }" d="m374.23,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J3" @click="onSelectSeat('201','J3')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J3') }" d="m375.48,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J4" @click="onSelectSeat('201','J4')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J4') }" d="m376.74,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J5" @click="onSelectSeat('201','J5')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J5') }" d="m377.99,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J6" @click="onSelectSeat('201','J6')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J6') }" d="m379.22,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J7" @click="onSelectSeat('201','J7')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J7') }" d="m380.48,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J8" @click="onSelectSeat('201','J8')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J8') }" d="m381.73,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J9" @click="onSelectSeat('201','J9')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J9') }" d="m382.99,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J10" @click="onSelectSeat('201','J10')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J10') }" d="m384.24,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J11" @click="onSelectSeat('201','J11')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J11') }" d="m385.5,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J12" @click="onSelectSeat('201','J12')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J12') }" d="m386.75,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    <path id="J13" @click="onSelectSeat('201','J13')" v-show="isSeatVisible  === 'section201'" :class="{ 'selected': isSelected('J13') }" d="m388,31.48c-.1.22-.36.33-.58.23-.22-.1-.33-.36-.23-.58.1-.22.36-.33.58-.23.22.1.33.36.23.58Z"/>
                                    
                              </g>
                              <g id="group202" v-once>
                                  <polygon id="section202" @click="isSectionEnabled('202') ? handleZoom('section202') : null" :class="{ 'enabled': isSectionEnabled('202') , 'disabled': !isSectionEnabled('202') }" points="390.17 14.32 390.03 47.54 409.46 48.92 409.23 14.77 390.17 14.32"/>
                                  <text id="section202" class="cls-5" transform="translate(392.23 37.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">202</tspan></text>
                                 
                                  <path id="V1" @click="onSelectSeat('202','V1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V1') }" d="m393.58,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U1" @click="onSelectSeat('202','U1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U1') }" d="m393.58,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T1" @click="onSelectSeat('202','T1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T1') }" d="m393.58,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S1" @click="onSelectSeat('202','S1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S1') }" d="m393.58,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="R1" @click="onSelectSeat('202','R1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R1') }" d="m393.58,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q1" @click="onSelectSeat('202','Q1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q1') }" d="m393.58,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P1" @click="onSelectSeat('202','P1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P1') }" d="m393.58,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O1" @click="onSelectSeat('202','O1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O1') }" d="m393.58,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N1" @click="onSelectSeat('202','N1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N1') }" d="m393.58,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M1" @click="onSelectSeat('202','M1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M1') }" d="m393.58,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L1" @click="onSelectSeat('202','L1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L1') }" d="m393.58,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="K1" @click="onSelectSeat('202','K1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K1') }" d="m393.58,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J1" @click="onSelectSeat('202','J1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J1') }" d="m393.58,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I1" @click="onSelectSeat('202','I1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I1') }" d="m393.58,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H1" @click="onSelectSeat('202','H1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H1') }" d="m393.58,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G1" @click="onSelectSeat('202','G1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G1') }" d="m393.58,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F1" @click="onSelectSeat('202','F1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F1') }" d="m393.58,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E1" @click="onSelectSeat('202','E1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E1') }" d="m393.58,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                             
                                  <path id="V2" @click="onSelectSeat('202','V2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V2') }" d="m395.21,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U2" @click="onSelectSeat('202','U2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U2') }" d="m395.21,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T2" @click="onSelectSeat('202','T2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T2') }" d="m395.21,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S2" @click="onSelectSeat('202','S2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S2') }" d="m395.21,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                                  <path id="R2" @click="onSelectSeat('202','R2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R2') }" d="m395.21,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q2" @click="onSelectSeat('202','Q2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q2') }" d="m395.21,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P2" @click="onSelectSeat('202','P2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P2') }" d="m395.21,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O2" @click="onSelectSeat('202','O2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O2') }" d="m395.21,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N2" @click="onSelectSeat('202','N2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N2') }" d="m395.21,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M2" @click="onSelectSeat('202','M2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M2') }" d="m395.21,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L2" @click="onSelectSeat('202','L2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L2') }" d="m395.21,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                                  <path id="K2" @click="onSelectSeat('202','K2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K2') }" d="m395.21,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J2" @click="onSelectSeat('202','J2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J2') }" d="m395.21,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I2" @click="onSelectSeat('202','I2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I2') }" d="m395.21,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H2" @click="onSelectSeat('202','H2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H2') }" d="m395.21,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G2" @click="onSelectSeat('202','G2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G2') }" d="m395.21,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F2" @click="onSelectSeat('202','F2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F2') }" d="m395.21,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E2" @click="onSelectSeat('202','E2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E2') }" d="m395.21,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  
                                  <path id="V3" @click="onSelectSeat('202','V3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V3') }" d="m396.85,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="U3" @click="onSelectSeat('202','U3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U3') }" d="m396.85,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T3" @click="onSelectSeat('202','T3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T3') }" d="m396.85,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="S3" @click="onSelectSeat('202','S3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S3') }" d="m396.85,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                                  <path id="R3" @click="onSelectSeat('202','R3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R3') }" d="m396.85,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="Q3" @click="onSelectSeat('202','Q3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q3') }" d="m396.85,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P3" @click="onSelectSeat('202','P3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P3') }" d="m396.85,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="O3" @click="onSelectSeat('202','O3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O3') }" d="m396.85,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="N3" @click="onSelectSeat('202','N3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N3') }" d="m396.85,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="M3" @click="onSelectSeat('202','M3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M3') }" d="m396.85,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L3" @click="onSelectSeat('202','L3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L3') }" d="m396.85,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                                  <path id="K3" @click="onSelectSeat('202','K3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K3') }" d="m396.85,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="J3" @click="onSelectSeat('202','J3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J3') }" d="m396.85,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I3" @click="onSelectSeat('202','I3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I3') }" d="m396.85,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="H3" @click="onSelectSeat('202','H3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H3') }" d="m396.85,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="G3" @click="onSelectSeat('202','G3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G3') }" d="m396.85,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="F3" @click="onSelectSeat('202','F3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F3') }" d="m396.85,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E3" @click="onSelectSeat('202','E3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E3') }" d="m396.85,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>

                                  <path id="V4" @click="onSelectSeat('202','V4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V4') }" d="m398.48,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="U4" @click="onSelectSeat('202','U4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U4') }" d="m398.48,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T4" @click="onSelectSeat('202','T4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T4') }" d="m398.48,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="S4" @click="onSelectSeat('202','S4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S4') }" d="m398.48,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="R4" @click="onSelectSeat('202','R4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R4') }" d="m398.48,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="Q4" @click="onSelectSeat('202','Q4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q4') }" d="m398.48,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P4" @click="onSelectSeat('202','P4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P4') }" d="m398.48,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="O4" @click="onSelectSeat('202','O4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O4') }" d="m398.48,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="N4" @click="onSelectSeat('202','N4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N4') }" d="m398.48,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="M4" @click="onSelectSeat('202','M4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M4') }" d="m398.48,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L4" @click="onSelectSeat('202','L4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L4') }" d="m398.48,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="K4" @click="onSelectSeat('202','K4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K4') }" d="m398.48,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="J4" @click="onSelectSeat('202','J4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J4') }" d="m398.48,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I4" @click="onSelectSeat('202','I4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I4') }" d="m398.48,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="H4" @click="onSelectSeat('202','H4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H4') }" d="m398.48,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="G4" @click="onSelectSeat('202','G4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G4') }" d="m398.48,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="F4" @click="onSelectSeat('202','F4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F4') }" d="m398.48,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E4" @click="onSelectSeat('202','E4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E4') }" d="m398.48,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                               
                                  <path id="V5" @click="onSelectSeat('202','V5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V5') }" d="m400.12,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U5" @click="onSelectSeat('202','U5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U5') }" d="m400.12,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T5" @click="onSelectSeat('202','T5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T5') }" d="m400.12,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S5" @click="onSelectSeat('202','S5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S5') }" d="m400.12,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="R5" @click="onSelectSeat('202','R5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R5') }" d="m400.12,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q5" @click="onSelectSeat('202','Q5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q5') }" d="m400.12,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P5" @click="onSelectSeat('202','P5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P5') }" d="m400.12,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O5" @click="onSelectSeat('202','O5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O5') }" d="m400.12,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N5" @click="onSelectSeat('202','N5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N5') }" d="m400.12,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M5" @click="onSelectSeat('202','M5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M5') }" d="m400.12,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L5" @click="onSelectSeat('202','L5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L5') }" d="m400.12,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="K5" @click="onSelectSeat('202','K5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K5') }" d="m400.12,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J5" @click="onSelectSeat('202','J5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J5') }" d="m400.12,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I5" @click="onSelectSeat('202','I5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I5') }" d="m400.12,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H5" @click="onSelectSeat('202','H5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H5') }" d="m400.12,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G5" @click="onSelectSeat('202','G5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G5') }" d="m400.12,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F5" @click="onSelectSeat('202','F5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F5') }" d="m400.12,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E5" @click="onSelectSeat('202','E5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E5') }" d="m400.12,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                                  <path id="V6" @click="onSelectSeat('202','V6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V6') }" d="m401.75,17.28c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U6" @click="onSelectSeat('202','U6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U6') }" d="m401.75,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T6" @click="onSelectSeat('202','T6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T6') }" d="m401.75,19.95c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S6" @click="onSelectSeat('202','S6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S6') }" d="m401.75,21.29c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="R6" @click="onSelectSeat('202','R6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R6') }" d="m401.75,22.62c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q6" @click="onSelectSeat('202','Q6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q6') }" d="m401.75,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P6" @click="onSelectSeat('202','P6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P6') }" d="m401.75,25.29c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O6" @click="onSelectSeat('202','O6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O6') }" d="m401.75,26.63c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N6" @click="onSelectSeat('202','N6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N6') }" d="m401.75,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M6" @click="onSelectSeat('202','M6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M6') }" d="m401.75,29.3c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L6" @click="onSelectSeat('202','L6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L6') }" d="m401.75,30.63c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="K6" @click="onSelectSeat('202','K6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K6') }" d="m401.75,31.97c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J6" @click="onSelectSeat('202','J6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J6') }" d="m401.75,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I6" @click="onSelectSeat('202','I6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I6') }" d="m401.75,34.64c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H6" @click="onSelectSeat('202','H6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H6') }" d="m401.75,35.97c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G6" @click="onSelectSeat('202','G6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G6') }" d="m401.75,37.31c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F6" @click="onSelectSeat('202','F6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F6') }" d="m401.75,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E6" @click="onSelectSeat('202','E6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E6') }" d="m401.75,39.98c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                 
                                  <path id="V7" @click="onSelectSeat('202','V7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V7') }" d="m403.39,17.28c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U7" @click="onSelectSeat('202','U7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U7') }" d="m403.39,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T7" @click="onSelectSeat('202','T7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T7') }" d="m403.39,19.95c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S7" @click="onSelectSeat('202','S7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S7') }" d="m403.39,21.29c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="R7" @click="onSelectSeat('202','R7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R7') }" d="m403.39,22.62c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q7" @click="onSelectSeat('202','Q7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q7') }" d="m403.39,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P7" @click="onSelectSeat('202','P7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P7') }" d="m403.39,25.29c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O7" @click="onSelectSeat('202','O7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O7') }" d="m403.39,26.63c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N7" @click="onSelectSeat('202','N7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N7') }" d="m403.39,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M7" @click="onSelectSeat('202','M7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M7') }" d="m403.39,29.3c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L7" @click="onSelectSeat('202','L7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L7') }" d="m403.39,30.63c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="K7" @click="onSelectSeat('202','K7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K7') }" d="m403.39,31.97c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J7" @click="onSelectSeat('202','J7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J7') }" d="m403.39,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I7" @click="onSelectSeat('202','I7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I7') }" d="m403.39,34.64c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H7" @click="onSelectSeat('202','H7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H7') }" d="m403.39,35.97c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G7" @click="onSelectSeat('202','G7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G7') }" d="m403.39,37.31c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F7" @click="onSelectSeat('202','F7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F7') }" d="m403.39,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E7" @click="onSelectSeat('202','E7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E7') }" d="m403.39,39.98c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>    

                                  <path id="V8" @click="onSelectSeat('202','V8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V8') }" d="m405.02,17.28c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U8" @click="onSelectSeat('202','U8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U8') }" d="m405.02,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T8" @click="onSelectSeat('202','T8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T8') }" d="m405.02,19.95c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S8" @click="onSelectSeat('202','S8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S8') }" d="m405.02,21.29c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="R8" @click="onSelectSeat('202','R8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R8') }" d="m405.02,22.62c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q8" @click="onSelectSeat('202','Q8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q8') }" d="m405.02,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P8" @click="onSelectSeat('202','P8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P8') }" d="m405.02,25.29c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O8" @click="onSelectSeat('202','O8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O8') }" d="m405.02,26.63c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N8" @click="onSelectSeat('202','N8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N8') }" d="m405.02,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M8" @click="onSelectSeat('202','M8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M8') }" d="m405.02,29.3c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L8" @click="onSelectSeat('202','L8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L8') }" d="m405.02,30.63c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="K8" @click="onSelectSeat('202','K8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K8') }" d="m405.02,31.97c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J8" @click="onSelectSeat('202','J8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J8') }" d="m405.02,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I8" @click="onSelectSeat('202','I8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I8') }" d="m405.02,34.64c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H8" @click="onSelectSeat('202','H8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H8') }" d="m405.02,35.97c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G8" @click="onSelectSeat('202','G8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G8') }" d="m405.02,37.31c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F8" @click="onSelectSeat('202','F8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F8') }" d="m405.02,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E8" @click="onSelectSeat('202','E8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E8') }" d="m405.02,39.98c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  
                                  <path id="V9" @click="onSelectSeat('202','V9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V9') }" d="m406.65,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="U9" @click="onSelectSeat('202','U9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U9') }" d="m406.65,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T9" @click="onSelectSeat('202','T9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T9') }" d="m406.65,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="S9" @click="onSelectSeat('202','S9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S9') }" d="m406.65,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="R9" @click="onSelectSeat('202','R9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R9') }" d="m406.65,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="Q9" @click="onSelectSeat('202','Q9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q9') }" d="m406.65,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P9" @click="onSelectSeat('202','P9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P9') }" d="m406.65,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="O9" @click="onSelectSeat('202','O9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O9') }" d="m406.65,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="N9" @click="onSelectSeat('202','N9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N9') }" d="m406.65,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="M9" @click="onSelectSeat('202','M9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M9') }" d="m406.65,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L9" @click="onSelectSeat('202','L9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L9') }" d="m406.65,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="K9" @click="onSelectSeat('202','K9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K9') }" d="m406.65,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="J9" @click="onSelectSeat('202','J9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J9') }" d="m406.65,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I9" @click="onSelectSeat('202','I9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I9') }" d="m406.65,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="H9" @click="onSelectSeat('202','H9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H9') }" d="m406.65,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="G9" @click="onSelectSeat('202','G9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G9') }" d="m406.65,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="F9" @click="onSelectSeat('202','F9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F9') }" d="m406.65,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E9" @click="onSelectSeat('202','E9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E9') }" d="m406.65,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                                  <path id="V10" @click="onSelectSeat('202','V10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('V10') }" d="m408.29,17.28c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="U10" @click="onSelectSeat('202','U10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('U10') }" d="m408.29,18.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="T10" @click="onSelectSeat('202','T10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('T10') }" d="m408.29,19.95c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="S10" @click="onSelectSeat('202','S10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('S10') }" d="m408.29,21.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                                  <path id="R10" @click="onSelectSeat('202','R10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('R10') }" d="m408.29,22.62c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="Q10" @click="onSelectSeat('202','Q10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('Q10') }" d="m408.29,23.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="P10" @click="onSelectSeat('202','P10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('P10') }" d="m408.29,25.29c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="O10" @click="onSelectSeat('202','O10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('O10') }" d="m408.29,26.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="N10" @click="onSelectSeat('202','N10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('N10') }" d="m408.29,27.96c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="M10" @click="onSelectSeat('202','M10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('M10') }" d="m408.29,29.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="L10" @click="onSelectSeat('202','L10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('L10') }" d="m408.29,30.63c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                                  <path id="K10" @click="onSelectSeat('202','K10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('K10') }" d="m408.29,31.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="J10" @click="onSelectSeat('202','J10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('J10') }" d="m408.29,33.3c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="I10" @click="onSelectSeat('202','I10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('I10') }" d="m408.29,34.64c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="H10" @click="onSelectSeat('202','H10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('H10') }" d="m408.29,35.97c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="G10" @click="onSelectSeat('202','G10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('G10') }" d="m408.29,37.31c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="F10" @click="onSelectSeat('202','F10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('F10') }" d="m408.29,38.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="E10" @click="onSelectSeat('202','E10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('E10') }" d="m408.29,39.98c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  
                                  <path id="D10" @click="onSelectSeat('202','D10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D10') }" d="m408.29,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="D9" @click="onSelectSeat('202','D9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D9') }" d="m406.65,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="D8" @click="onSelectSeat('202','D8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D8') }" d="m405.02,41.32c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="D7" @click="onSelectSeat('202','D7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D7') }" d="m403.39,41.32c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="D6" @click="onSelectSeat('202','D6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D6') }" d="m401.75,41.32c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>                       
                                  <path id="D5" @click="onSelectSeat('202','D5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D5') }" d="m400.12,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="D4" @click="onSelectSeat('202','D4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D4') }" d="m398.48,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="D3" @click="onSelectSeat('202','D3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D3') }" d="m396.85,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="D2" @click="onSelectSeat('202','D2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D2') }" d="m395.21,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="D1" @click="onSelectSeat('202','D1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('D1') }" d="m393.58,41.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                                  <path id="C10" @click="onSelectSeat('202','C10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C10') }" d="m408.29,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="C9" @click="onSelectSeat('202','C9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C9') }" d="m406.65,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="C8" @click="onSelectSeat('202','C8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C8') }" d="m405.02,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="C7" @click="onSelectSeat('202','C7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C7') }" d="m403.39,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="C6" @click="onSelectSeat('202','C6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C6') }" d="m401.75,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="C5" @click="onSelectSeat('202','C5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C5') }" d="m400.12,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="C4" @click="onSelectSeat('202','C4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C4') }" d="m398.48,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="C3" @click="onSelectSeat('202','C3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C3') }" d="m396.85,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="C2" @click="onSelectSeat('202','C2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C2') }" d="m395.21,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="C1" @click="onSelectSeat('202','C1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('C1') }" d="m393.58,42.65c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                                  <path id="B10" @click="onSelectSeat('202','B10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B10') }" d="m408.29,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B9" @click="onSelectSeat('202','B9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B9') }" d="m406.65,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B8" @click="onSelectSeat('202','B8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B8') }" d="m405.02,43.99c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B7" @click="onSelectSeat('202','B7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B7') }" d="m403.39,43.99c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B6" @click="onSelectSeat('202','B6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B6') }" d="m401.75,43.99c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B5" @click="onSelectSeat('202','B5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B5') }" d="m400.12,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B4" @click="onSelectSeat('202','B4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B4') }" d="m398.48,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B3" @click="onSelectSeat('202','B3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B3') }" d="m396.85,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B2" @click="onSelectSeat('202','B2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B2') }" d="m395.21,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="B1" @click="onSelectSeat('202','B1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('B1') }" d="m393.58,43.99c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                                  <path id="A10" @click="onSelectSeat('202','A10')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A10') }" d="m408.29,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                                  <path id="A9" @click="onSelectSeat('202','A9')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A9') }" d="m406.65,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="A8" @click="onSelectSeat('202','A8')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A8') }" d="m405.02,45.32c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="A7" @click="onSelectSeat('202','A7')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A7') }" d="m403.39,45.32c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="A6" @click="onSelectSeat('202','A6')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A6') }" d="m401.75,45.32c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="A5" @click="onSelectSeat('202','A5')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A5') }" d="m400.12,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                  <path id="A4" @click="onSelectSeat('202','A4')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A4') }" d="m398.48,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                  <path id="A3" @click="onSelectSeat('202','A3')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A3') }" d="m396.85,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                                  <path id="A2" @click="onSelectSeat('202','A2')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A2') }" d="m395.21,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                                  <path id="A1" @click="onSelectSeat('202','A1')" v-show="isSeatVisible === 'section202'" :class="{ 'selected': isSelected('A1') }" d="m393.58,45.32c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                              </g>
                            <g id="group203" v-once>
                              <polygon id="section203" @click="handleZoom('section203')" :class="{ 'enabled': isSectionEnabled('203') , 'disabled': !isSectionEnabled('203') }" points="409.46 48.92 409.23 10.01 435.55 11.22 434.98 49.94 409.46 48.92"/>
                              <polygon class="cls-22" @click="handleZoom('section')" points="409.46 48.92 409.76 48.92 409.53 10.33 435.24 11.51 434.68 49.62 409.47 48.62 409.46 48.92 409.76 48.92 409.46 48.92 409.44 49.22 435.28 50.25 435.85 10.93 408.93 9.69 409.16 49.21 409.44 49.22 409.46 48.92"/>
                              <text id="section203" class="cls-5" transform="translate(416.46 35.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">203</tspan></text>
                            </g>
                  
                            <g id="group204" v-once>
                              <polygon class="cls-9" points="435.55 11.22 434.98 49.94 469.69 51.3 473.41 13.36 435.55 11.22"/>
                              <polygon class="cls-22" points="435.55 11.22 435.24 11.22 434.67 50.23 469.96 51.61 473.75 13.07 435.25 10.9 435.24 11.22 435.55 11.22 435.53 11.52 473.08 13.64 469.42 50.99 435.29 49.65 435.85 11.22 435.55 11.22 435.53 11.52 435.55 11.22"/>
                              <text class="cls-5" transform="translate(445.46 35.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">204</tspan></text>

                            </g>
                            <g id="group205" v-once>
                              <polygon id="section205" class="cls-9" @click="handleZoom('section205')" points="473.41 13.36 469.69 51.3 497.59 56.06 511 19.22 473.41 13.36"/>
                              <polygon id="section205" class="cls-22" @click="handleZoom('section205')" points="473.41 13.36 473.11 13.33 469.36 51.55 497.79 56.41 511.41 18.97 473.14 13.01 473.11 13.33 473.41 13.36 473.37 13.66 510.59 19.46 497.4 55.72 470.02 51.05 473.72 13.39 473.41 13.36 473.37 13.66 473.41 13.36"/>
                              <text class="cls-5" transform="translate(480.46 35.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">205</tspan></text>

                          </g>
                            <g id="group206" v-once>
                              <polygon id="section206" class="cls-9" @click="handleZoom('section206')" points="497.59 56.06 526.52 65.93 545.58 32.47 511 19.22 497.59 56.06"/>
                              <polygon id="section206" class="cls-22" @click="handleZoom('section206')" points="497.59 56.06 497.5 56.35 526.66 66.3 546.01 32.31 510.83 18.82 497.2 56.25 497.5 56.35 497.59 56.06 497.88 56.17 511.18 19.61 545.14 32.63 526.38 65.57 497.69 55.78 497.59 56.06 497.88 56.17 497.59 56.06"/>
                              <text class="cls-5" transform="translate(512.46 46.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">206</tspan></text>
                        
                            </g>
                            <g id="group207" v-once>  
                              <polygon id="section207" @click="handleZoom('section207')" class="cls-9" points="545.58 32.47 577.45 53.91 548.64 82.27 526.52 65.93 545.58 32.47"/>
                              <polygon id="section207" @click="handleZoom('section207')" class="cls-22" points="545.58 32.47 545.41 32.72 576.98 53.95 548.61 81.87 526.92 65.85 545.84 32.62 545.58 32.47 545.41 32.72 545.58 32.47 545.31 32.32 526.12 66.02 548.67 82.66 577.93 53.86 545.48 32.04 545.31 32.32 545.58 32.47"/>
                              <text class="cls-5" transform="translate(540.46 60.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">207</tspan></text>

                        
                            </g>
                            <g id="group208" v-once>
                              <polygon id="section208" class="cls-9" @click="handleZoom('section208')" points="577.45 53.91 603.88 80.11 567.36 103.71 548.64 82.27 577.45 53.91"/>
                              <polygon id="section208" class="cls-22" @click="handleZoom('section208')" points="577.45 53.91 577.24 54.12 603.4 80.06 567.41 103.31 549.06 82.28 577.67 54.12 577.45 53.91 577.24 54.12 577.45 53.91 577.24 53.69 548.22 82.25 567.3 104.1 604.36 80.16 577.45 53.48 577.24 53.69 577.45 53.91"/>
                              <text class="cls-5" transform="translate(565.46 83.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">208</tspan></text>

                            </g>
                            <g id="group209" v-once>
                              <polygon id="section209" class="cls-9" @click="handleZoom('section209')" points="582.6 129.17 582.6 129.17 567.36 103.71 603.88 80.11 621.8 112.78 582.6 129.17"/>
                              <polygon id="section209" class="cls-22" @click="handleZoom('section209')" points="582.6 129.17 582.86 129.01 567.77 103.8 603.77 80.54 621.38 112.63 582.49 128.89 582.6 129.17 582.86 129.01 582.6 129.17 582.72 129.44 622.23 112.93 603.99 79.68 566.95 103.61 582.48 129.55 582.72 129.44 582.6 129.17"/>
                              <text class="cls-5" transform="translate(585.46 110.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">209</tspan></text>

                            </g>
                            <g id="group210" v-once>
                              <polygon id="section210" @click="handleZoom('section210')" class="cls-9" points="591.18 156.68 632.92 146.81 621.8 112.78 582.6 129.17 591.18 156.68"/>
                              <polygon id="section210" @click="handleZoom('section210')" class="cls-22" points="591.18 156.68 591.25 156.97 633.31 147.03 621.99 112.38 582.23 128.99 590.97 157.04 591.25 156.97 591.18 156.68 591.47 156.59 582.98 129.34 621.62 113.19 632.53 146.59 591.11 156.38 591.18 156.68 591.47 156.59 591.18 156.68"/>
                              <text class="cls-5" transform="translate(600.46 140.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">210</tspan></text>

                            </g>  
                            <g id="group211" v-once>
                              <polygon id="section211" class="cls-9" @click="handleZoom('section211')" points="595.94 185.38 638.63 182.3 632.92 146.81 591.18 156.68 595.94 185.38 638.63 182.3"/>
                              <polygon id="section211" class="cls-22" @click="handleZoom('section211')" points="595.96 185.68 638.98 182.58 633.17 146.44 590.83 156.45 595.69 185.7 638.65 182.61 638.61 182 596.19 185.06 591.52 156.91 632.67 147.18 638.28 182.03 595.92 185.08 595.96 185.68 595.96 185.68"/>
                              <text class="cls-5" transform="translate(610.46 170.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">211</tspan></text>
                    
                          </g>
                          <g id="group212" v-once>  
                              <polygon id="section212" class="cls-9" @click="handleZoom('section212')" points="640.19 217.48 638.64 182.3 595.95 185.38 597.99 218.05 640.19 217.48"/>
                              <polygon id="section212" class="cls-22" @click="handleZoom('section212')" points="640.19 217.48 640.49 217.47 638.92 181.98 595.63 185.1 597.71 218.35 640.5 217.78 640.49 217.47 640.19 217.48 640.18 217.18 598.28 217.74 596.27 185.66 638.35 182.63 639.89 217.49 640.19 217.48 640.18 217.18 640.19 217.48"/>
                              <text class="cls-5" transform="translate(610.46 200.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">212</tspan></text>

                          </g>  
                          <g id="group213" v-once>  
                            <polygon id="section213"  class="cls-9" @click="handleZoom('section213')" points="640.18 252.08 640.19 217.48 597.99 218.05 597.87 249.24 640.18 252.08"/>
                            <polygon id="section213"  class="cls-22" @click="handleZoom('section213')" points="640.48 252.08 640.49 217.17 597.69 217.75 597.57 249.52 640.16 252.38 640.2 251.78 598.17 248.96 598.29 218.35 639.89 217.79 639.88 252.08 640.48 252.08 640.48 252.08"/>
                            <text class="cls-5" transform="translate(610.46 235.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">213</tspan></text>

                          
                          </g>  
                          <g id="group214" v-once>   
                              <polygon id="section214" class="cls-9" @click="handleZoom('section214')" points="595.49 282.02 638.37 287.02 640.18 252.08 597.87 249.24 595.49 282.02"/>
                              <polygon id="section214" class="cls-22" @click="handleZoom('section214')" points="595.49 282.02 595.45 282.32 638.65 287.35 640.5 251.8 597.59 248.92 595.16 282.29 595.45 282.32 595.49 282.02 595.79 282.05 598.15 249.56 639.86 252.36 638.08 286.68 595.52 281.72 595.49 282.02 595.79 282.05 595.49 282.02"/>
                              <text class="cls-5" transform="translate(610.46 270.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">214</tspan></text>

                          </g>  

                          <g id="group215" v-once>  
                            <polygon id="section215"  class="cls-9" @click="handleZoom('section215')" points="633.15 323.99 590.28 315.05 595.49 282.02 638.37 287.02 633.15 323.99"/>
                            <polygon id="section215"  class="cls-22" @click="handleZoom('section215')" points="633.21 323.7 590.62 314.82 595.74 282.36 638.02 287.28 632.85 323.95 633.45 324.04 638.71 286.75 595.23 281.69 589.93 315.29 633.09 324.29 633.21 323.7 633.21 323.7"/>
                            <text class="cls-5" transform="translate(610.46 305.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">215</tspan></text>

                          </g>
                          <g id="group216">  
                            <polygon id="section216" class="cls-9" @click="handleZoom('section216')" points="622.6 357.91 581.42 343.51 590.28 315.05 633.15 323.99 622.6 357.91"/>
                            <polygon id="section216" class="cls-22" @click="handleZoom('section216')" points="622.6 357.91 622.7 357.63 581.8 343.32 590.48 315.41 632.76 324.22 622.31 357.82 622.6 357.91 622.7 357.63 622.6 357.91 622.89 358 633.54 323.77 590.07 314.7 581.05 343.7 622.79 358.3 622.89 358 622.6 357.91"/>
                            <text class="cls-5" transform="translate(600.46 335.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">216</tspan></text>
                          </g>
                          <g id="group217">    
                            <polygon id="section217" class="cls-9" @click="handleZoom('section217')" points="602.52 393.87 566.68 368.8 581.42 343.51 622.6 357.91 602.52 393.87"/>
                            <polygon id="section217" class="cls-22" @click="handleZoom('section217')"  points="602.52 393.87 602.69 393.62 567.08 368.71 581.56 343.88 622.16 358.08 602.26 393.72 602.52 393.87 602.69 393.62 602.52 393.87 602.78 394.02 623.04 357.75 581.29 343.14 566.27 368.89 602.62 394.31 602.78 394.02 602.52 393.87"/>
                            <text class="cls-5" transform="translate(590.46 370.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">217</tspan></text>
                          </g>
                            <g id="group218">
                              <polygon class="cls-9" @click="handleZoom('')" points="572.26 421.05 544.1 386.6 566.68 368.8 602.52 393.87 572.26 421.05"/>
                              <polygon class="cls-22" @click="handleZoom('')" points="572.26 421.05 572.49 420.86 544.53 386.65 566.69 369.18 602.04 393.9 572.05 420.83 572.26 421.05 572.49 420.86 572.26 421.05 572.46 421.28 603.01 393.84 566.66 368.42 543.67 386.56 572.22 421.49 572.46 421.28 572.26 421.05"/>
                              <text class="cls-5" transform="translate(565.46 395.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">218</tspan></text>
                            </g>
                            <g id="group219">
                              <polyline class="cls-9" points="537.18 441.29 517.51 402.52 544.1 386.6 572.26 421.05 537.18 441.29"/>
                              <polygon class="cls-22" points="537.45 441.15 517.91 402.63 544.03 387 571.8 420.97 537.03 441.02 537.33 441.55 572.72 421.14 544.17 386.21 517.12 402.41 536.91 441.42 537.45 441.15 537.45 441.15"/>
                              <text class="cls-5" transform="translate(535.46 415.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">219</tspan></text>
                            </g>
                              <g id="group220">     
                              <polyline class="cls-9" points="499.52 453.64 487.81 412.71 517.51 402.52 537.18 441.29 499.52 453.64"/>
                              <polygon class="cls-22" points="499.81 453.55 488.18 412.9 517.36 402.89 536.75 441.11 499.43 453.35 499.62 453.93 537.61 441.46 517.67 402.15 487.44 412.52 499.23 453.72 499.81 453.55 499.81 453.55"/>
                              <text class="cls-5" transform="translate(505.46 430.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">220</tspan></text>
                           
                            </g>  
                            <g id="group221">
                              <polygon id="section221" class="cls-9" @click="handleZoom('section221')" points="464.13 415.34 488.23 413.53 499.52 453.64 467.53 456.03 464.13 415.34"/>
                              <polygon id="section221" class="cls-22" @click="handleZoom('section221')" points="464.13 415.34 464.15 415.64 488.01 413.85 499.13 453.36 467.81 455.71 464.43 415.32 464.13 415.34 464.15 415.64 464.13 415.34 463.83 415.37 467.26 456.36 499.91 453.91 488.46 413.21 463.81 415.06 463.83 415.37 464.13 415.34"/>
                              <text class="cls-5" transform="translate(475.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">221</tspan></text>

                            </g> 
                            <g id="group222">
                              <polygon id="section222" class="cls-9" @click="handleZoom('section222')" points="439.29 414.74 463.9 414.74 467.53 456.03 438.72 458.07 439.29 414.74"/>
                              <polygon id="section222" class="cls-22" @click="handleZoom('section222')" points="439.29 414.74 439.29 415.04 463.62 415.04 467.21 455.75 439.03 457.75 439.59 414.75 439.29 414.74 439.29 415.04 439.29 414.74 438.99 414.74 438.42 458.4 467.86 456.31 464.18 414.44 438.99 414.44 438.99 414.74 439.29 414.74"/>
                              <text class="cls-5" transform="translate(445.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">222</tspan></text>
                           
                            </g>
                              <g id="group223">
                              <polygon id="section223" class="cls-9" @click="handleZoom('section223')" points="417.13 414.74 439.29 414.74 438.72 458.07 416.94 458.07 417.13 414.74"/>
                              <polygon id="section223" class="cls-22" @click="handleZoom('section223')" points="417.13 414.74 417.13 415.04 438.98 415.04 438.42 457.77 417.25 457.77 417.43 414.74 417.13 414.74 417.13 415.04 417.13 414.74 416.83 414.74 416.64 458.38 439.02 458.38 439.6 414.44 416.83 414.44 416.83 414.74 417.13 414.74"/>
                              <text class="cls-5" transform="translate(420.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">223</tspan></text>

                            </g>
                            
                            <g id="group224">
                              <polygon id="section224" class="cls-9" @click="handleZoom('section224')" points="395.28 414.74 417.13 414.74 416.94 458.07 395.28 458.07 395.28 414.74"/>
                              <polygon id="section224" class="cls-22" @click="handleZoom('section224')" points="395.28 414.74 395.28 415.35 416.52 415.35 416.34 457.47 395.88 457.47 395.88 414.74 395.28 414.74 395.28 415.35 395.28 414.74 394.67 414.74 394.67 458.68 417.55 458.68 417.74 414.14 394.67 414.14 394.67 414.74 395.28 414.74"/>
                              <text class="cls-5" transform="translate(400.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">224</tspan></text>

                            </g>  
                            <g id="group225">
                              <polygon id="section225" class="cls-9" @click="handleZoom('section225')" points="395.28 414.74 395.28 458.07 372.58 458.07 372.17 414.74 395.28 414.74"/>
                              <polygon id="section225" class="cls-22" @click="handleZoom('section225')" points="395.28 414.74 394.67 414.74 394.67 457.47 373.18 457.47 372.78 415.35 395.28 415.35 395.28 414.74 394.67 414.74 395.28 414.74 395.28 414.14 371.56 414.14 371.98 458.68 395.88 458.68 395.88 414.14 395.28 414.14 395.28 414.74"/>
                              <text class="cls-5" transform="translate(377.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">225</tspan></text>

                            </g>
                            <g id="group226">                             
                              <polygon id="section226" class="cls-9" @click="handleZoom('section226')" points="372.58 458.07 372.17 414.74 342.08 414.74 341.4 458.07 372.58 458.07"/>
                              <polygon id="section226" class="cls-22" @click="handleZoom('section226')" points="372.58 458.07 373.19 458.07 372.77 414.14 341.48 414.14 340.78 458.68 373.19 458.68 373.19 458.07 372.58 458.07 372.58 457.47 342.01 457.47 342.67 415.35 371.57 415.35 371.98 458.08 372.58 458.07 372.58 457.47 372.58 458.07"/>
                              <text class="cls-5" transform="translate(350.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">226</tspan></text>
                            </g>
                            <g id="group227">
                              <polygon id="section227" class="cls-9" @click="handleZoom('section227')" points="341.4 458.07 342.08 414.74 311.69 414.74 310.95 458.07 341.4 458.07"/>
                              <polygon id="section227" class="cls-22" @click="handleZoom('section227')" points="341.4 458.07 342 458.08 342.69 414.14 311.1 414.14 310.33 458.68 341.99 458.68 342 458.08 341.4 458.07 341.4 457.47 311.57 457.47 312.29 415.35 341.46 415.35 340.79 458.06 341.4 458.07 341.4 457.47 341.4 458.07"/>
                              <text class="cls-5" transform="translate(320.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">227</tspan></text>

                            </g>
                            <g id="group228">
                              <polygon id="section228" class="cls-9" @click="handleZoom('section228')" points="310.95 458.07 311.69 414.74 288.38 414.74 286.95 458.07 310.95 458.07"/>
                              <polygon id="section228" class="cls-22" @click="handleZoom('section228')" points="310.95 458.07 311.55 458.08 312.31 414.14 287.8 414.14 286.32 458.68 311.54 458.68 311.55 458.08 310.95 458.07 310.95 457.47 287.57 457.47 288.97 415.35 311.08 415.35 310.34 458.06 310.95 458.07 310.95 457.47 310.95 458.07"/>
                              <text class="cls-5" transform="translate(290.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">228</tspan></text>

                            </g>
                            <g id="group229">
                              <polygon id="section229" class="cls-9" @click="handleZoom('section229')" points="286.95 458.07 288.38 414.74 264.15 414.74 263.58 458.07 286.95 458.07"/>
                              <polygon id="section229" class="cls-22" @click="handleZoom('section229')" points="286.95 458.07 287.55 458.09 289.01 414.14 263.55 414.14 262.97 458.68 287.53 458.68 287.55 458.09 286.95 458.07 286.95 457.47 264.19 457.47 264.75 415.35 287.76 415.35 286.34 458.05 286.95 458.07 286.95 457.47 286.95 458.07"/>
                              <text class="cls-5" transform="translate(270.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">229</tspan></text>
                           
                            </g>
                            <g id="group230">                              
                              <polygon id="section230" class="cls-9" @click="handleZoom('section230')" points="263.58 458.07 264.15 414.74 239.56 413.74 236.81 458.07 263.58 458.07"/>
                              <polygon id="section230" class="cls-22" @click="handleZoom('section230')" points="263.58 458.07 264.19 458.08 264.76 414.16 238.99 413.11 236.17 458.68 264.18 458.68 264.19 458.08 263.58 458.07 263.58 457.47 237.45 457.47 240.13 414.37 263.54 415.32 262.98 458.07 263.58 458.07 263.58 457.47 263.58 458.07"/>
                              <text class="cls-5" transform="translate(240.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">230</tspan></text>
                            
                            </g>
                            <g id="group231">
                              <polygon id="section231" class="cls-9" @click="handleZoom('section231')" points="213.11 412.81 239.56 413.74 236.81 458.07 206.64 458.07 213.11 412.81"/>
                              <polygon id="section231" class="cls-22" @click="handleZoom('section231')" points="213.11 412.81 213.09 413.42 238.92 414.32 236.24 457.47 207.34 457.47 213.71 412.9 213.11 412.81 213.09 413.42 213.11 412.81 212.51 412.73 205.94 458.68 237.38 458.68 240.2 413.16 212.59 412.19 212.51 412.73 213.11 412.81"/>
                              <text class="cls-5" transform="translate(215.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">231</tspan></text>
                            
                            </g>
                            <g id="group232">
                              <polygon id="section232" class="cls-9" @click="handleZoom('section232')" points="206.64 458.07 213.11 412.81 179.86 411.91 169.2 453.53 206.64 458.07"/>
                              <polygon id="section232" class="cls-22" @click="handleZoom('section232')" points="206.64 458.07 207.24 458.16 213.81 412.23 179.39 411.29 168.44 454.05 207.15 458.75 207.24 458.16 206.64 458.07 206.71 457.47 169.96 453.01 180.33 412.52 212.42 413.4 206.04 457.99 206.64 458.07 206.71 457.47 206.64 458.07"/>
                              <text class="cls-5" transform="translate(185.46 440.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">232</tspan></text>

                            </g>
                            <g id="group233">
                              <polygon id="section233" class="cls-9" @click="handleZoom('section233')" points="169.2 453.53 180.1 411.1 149.92 399.77 129.05 440.38 169.2 453.53"/>
                              <polygon id="section233" class="cls-22" @click="handleZoom('section233')" points="169.2 453.53 169.79 453.68 180.82 410.73 149.63 399.01 128.19 440.73 169.63 454.3 169.79 453.68 169.2 453.53 169.39 452.95 129.91 440.02 150.21 400.52 179.38 411.48 168.62 453.38 169.2 453.53 169.39 452.95 169.2 453.53"/>
                              <text class="cls-5" transform="translate(146.46 430.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">233</tspan></text>

                          
                          </g>      
                           <g id="group234">   
                              <polygon id="section234" @click="isSectionEnabled('234') ? handleZoom('section234') : null" class="cls-9" points="127.35 384.11 148.11 399.09 149.92 399.77 129.05 440.38 95.47 417.92 127.35 384.11"/>
                              <polygon id="section234" @click="isSectionEnabled('234') ? handleZoom('section234') : null" class="cls-22" points="127.35 384.11 126.99 384.61 147.82 399.63 149.07 400.1 128.82 439.5 96.4 417.81 127.79 384.53 127.35 384.11 126.99 384.61 127.35 384.11 126.91 383.7 94.54 418.02 129.28 441.26 150.77 399.44 148.39 398.55 127.27 383.31 126.91 383.7 127.35 384.11"/>
                              <text class="cls-5" transform="translate(116.46 415.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">234</tspan></text>

                           </g>
                           <g id="group235">  

                              <polygon id="section235" @click="isSectionEnabled('235') ? handleZoom('section235') : null" class="cls-9" points="127.35 384.11 95.47 417.92 69.84 388.88 107.95 360.97 127.35 384.11"/>
                              <polygon id="section235" @click="isSectionEnabled('235') ? handleZoom('section235') : null" class="cls-22" points="127.35 384.11 126.91 383.7 95.49 417.02 70.73 388.98 107.85 361.8 126.88 384.5 127.35 384.11 126.91 383.7 127.35 384.11 127.81 383.73 108.05 360.15 68.94 388.78 95.46 418.82 128.16 384.14 127.81 383.73 127.35 384.11"/>
                              <text class="cls-5" transform="translate(90.46 390.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">235</tspan></text>
  
                          </g>
                          <g id="group236">  
                              <polygon class="cls-9" points="69.84 388.88 107.95 360.97 93.66 336.47 51.69 354.17 69.84 388.88"/>
                              <polygon class="cls-22" points="69.84 388.88 70.19 389.37 108.75 361.14 93.91 335.71 50.85 353.87 69.63 389.78 70.19 389.37 69.84 388.88 70.37 388.6 52.53 354.47 93.4 337.24 107.15 360.81 69.48 388.39 69.84 388.88 70.37 388.6 69.84 388.88"/>
                              <text class="cls-5" transform="translate(70.46 365.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">236</tspan></text>
                          </g>    
                          <g id="group237">  
                              <polygon class="cls-9" points="93.66 336.47 51.69 354.17 41.25 315.37 85.72 303.23 93.66 336.47"/>
                              <polygon class="cls-22" points="93.66 336.47 93.42 335.91 52.09 353.34 41.99 315.8 85.28 303.98 93.07 336.61 93.66 336.47 93.42 335.91 93.66 336.47 94.25 336.33 86.16 302.48 40.51 314.95 51.28 354.99 94.37 336.83 94.25 336.33 93.66 336.47"/>
                              <text class="cls-5" transform="translate(60.46 330.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">237</tspan></text>
                          </g>    
                           <g id="group238">   
                              <polygon id="section238" @click="isSectionEnabled('238') ? handleZoom('section238') : null" class="cls-9" points="41.25 315.37 85.72 303.23 80.98 271.49 34.82 277.6 41.25 315.37"/>
                              <polygon id="section238" @click="isSectionEnabled('238') ? handleZoom('section238') : null" class="cls-22" points="41.25 315.37 41.41 315.96 86.4 303.67 81.49 270.81 34.12 277.08 40.77 316.13 41.41 315.96 41.25 315.37 41.85 315.27 35.52 278.12 80.47 272.17 85.04 302.79 41.09 314.79 41.25 315.37 41.85 315.27 41.25 315.37"/>
                              <text class="cls-5" transform="translate(50.46 300.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">238</tspan></text>
                            </g>
                            <g id="group239">

                              <polygon id="section239" @click="isSectionEnabled('239') ? handleZoom('section239') : null" class="cls-9" points="34.82 277.6 80.98 271.49 80.8 236.87 35.76 239.39 34.82 277.6"/>
                              <polygon id="section239" @click="isSectionEnabled('239') ? handleZoom('section239') : null" class="cls-22" points="34.82 277.6 34.9 278.2 81.59 272.02 81.4 236.23 35.17 238.82 34.2 278.29 34.9 278.2 34.82 277.6 35.43 277.62 36.36 239.96 80.19 237.51 80.37 270.96 34.74 277 34.82 277.6 35.43 277.62 34.82 277.6"/>
                              <text class="cls-5" transform="translate(50.46 260.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">239</tspan></text>

                            
                            </g>
                              
                            <g id="group240">
                              <polygon id="section240" @click="handleZoom('section240')" class="cls-9" points="36.71 201.71 35.76 239.39 80.8 236.87 83.28 204.44 36.71 201.71"/>
                              <polygon id="section240" @click="handleZoom('section240')" class="cls-22" points="36.71 201.71 36.11 201.7 35.14 240.03 81.36 237.45 83.93 203.87 36.12 201.07 36.11 201.7 36.71 201.71 36.68 202.32 82.63 205 80.23 236.3 36.39 238.75 37.32 201.73 36.71 201.71 36.68 202.32 36.71 201.71"/>
                              <text class="cls-5" transform="translate(50.46 225.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">240</tspan></text>

                          </g>
                          <g id="group241">
                              <polygon id="section241" @click="isSectionEnabled('241') ? handleZoom('section241') : null" class="cls-9" points="36.71 201.71 83.28 204.44 85.81 172.11 41.25 164.96 36.71 201.71"/>
                              <polygon id="section241" @click="isSectionEnabled('241') ? handleZoom('section241') : null" class="cls-22" points="36.71 201.71 36.68 202.32 83.84 205.07 86.46 171.6 40.73 164.26 36.03 202.28 36.68 202.32 36.71 201.71 37.31 201.79 41.77 165.66 85.17 172.62 82.72 203.8 36.75 201.11 36.71 201.71 37.31 201.79 36.71 201.71"/>
                              <text class="cls-5" transform="translate(50.46 190.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">241</tspan></text>

                          </g>
                          <g id="group242">
                              <polygon id="section242" @click="isSectionEnabled('242') ? handleZoom('section242') : null" class="cls-9" points="41.25 164.96 85.81 172.11 93.41 143.45 50.78 131.27 41.25 164.96"/>
                              <polygon id="section242" @click="isSectionEnabled('242') ? handleZoom('section242') : null" class="cls-22" points="41.25 164.96 41.16 165.56 86.26 172.8 94.14 143.03 50.36 130.52 40.48 165.45 41.16 165.56 41.25 164.96 41.83 165.13 51.2 132.02 92.67 143.87 85.37 171.43 41.35 164.36 41.25 164.96 41.83 165.13 41.25 164.96"/>
                              <text class="cls-5" transform="translate(55.46 155.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">242</tspan></text>
                            
                           </g>
                          <g id="group243">
                              <polygon id="section243" @click="isSectionEnabled('243') ? handleZoom('section243') : null" class="cls-9" points="50.78 131.27 93.41 143.45 105.13 115.2 65.3 98.26 50.78 131.27"/>
                              <polygon id="section243" @click="isSectionEnabled('243') ? handleZoom('section243') : null" class="cls-22" points="50.78 131.27 50.62 131.85 93.76 144.18 105.92 114.88 64.99 97.47 49.95 131.66 50.62 131.85 50.78 131.27 51.34 131.51 65.61 99.05 104.35 115.52 93.05 142.72 50.95 130.69 50.78 131.27 51.34 131.51 50.78 131.27"/>
                              <text class="cls-5" transform="translate(65.46 125.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">243</tspan></text>
                          </g>
                          <g id="group244">
                              <polygon id="section244" @click="isSectionEnabled('244') ? handleZoom('section244') : null" class="cls-9" points="120.2 90.09 105.13 115.2 65.3 98.26 85.49 66.27 120.2 90.09"/>
                              <polygon id="section244" @click="isSectionEnabled('244') ? handleZoom('section244') : null" class="cls-22" points="120.2 90.09 119.68 89.78 104.89 114.44 66.19 97.98 85.67 67.13 119.86 90.59 120.2 90.09 119.68 89.78 120.2 90.09 120.54 89.6 85.31 65.42 64.41 98.54 105.38 115.96 121.01 89.92 120.54 89.6 120.2 90.09"/>
                              <text class="cls-5" transform="translate(80.46 95.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">244</tspan></text>
                          </g>
                          <g id="group245">
                              <polygon id="section245" @click="isSectionEnabled('245') ? handleZoom('section245') : null" class="cls-9" points="85.49 66.27 120.2 90.09 141.98 70.36 114.08 39.28 85.49 66.27"/>
                              <polygon id="section245" @click="isSectionEnabled('245') ? handleZoom('section245') : null" class="cls-22" points="85.49 66.27 85.15 66.77 120.25 90.86 142.83 70.4 114.11 38.41 84.53 66.35 85.15 66.77 85.49 66.27 85.91 66.71 114.04 40.14 141.13 70.31 120.15 89.33 85.83 65.77 85.49 66.27 85.91 66.71 85.49 66.27"/>
                              <text class="cls-5" transform="translate(105.46 70.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">245</tspan></text>
                            </g>
                          <g id="group246">
                              <polygon id="section246" @click="isSectionEnabled('246') ? handleZoom('section246') : null" class="cls-9" points="141.98 70.36 114.08 39.28 148.11 22.49 168.98 57.19 141.98 70.36"/>
                              <polygon class="cls-22" points="141.98 70.36 142.43 69.95 115.06 39.47 147.87 23.28 168.12 56.94 141.72 69.81 141.98 70.36 142.43 69.95 141.98 70.36 142.25 70.9 169.84 57.44 148.34 21.7 113.09 39.09 141.84 71.1 142.25 70.9 141.98 70.36"/>
                              <text class="cls-5" transform="translate(135.46 50.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">246</tspan></text>

                          </g>
                          <g id="group247">
                              <polygon id="section247" @click="sSectionEnabled('247') ? handleZoom('section247') : null" class="cls-9" points="148.11 22.49 168.98 57.19 198.08 48.57 187.13 10.92 148.11 22.49"/>
                              <polygon class="cls-22" points="148.11 22.49 147.59 22.8 168.7 57.9 198.82 48.97 187.54 10.16 147.18 22.13 147.59 22.8 148.11 22.49 148.28 23.07 186.72 11.67 197.33 48.16 169.26 56.48 148.62 22.18 148.11 22.49 148.28 23.07 148.11 22.49"/>
                              <text class="cls-5" transform="translate(165.46 35.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">247</tspan></text>
                                                            
                              <path v-for="seat in seats.section247" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section247'" @click="onSelectSeat('247', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                          </g>
                          <g id="group248">
                              <polygon id="section248" @click="isSectionEnabled('248') ? handleZoom('section248') : null" class="cls-9" points="187.13 10.92 225.35 7.04 229.14 45.59 198.08 48.57 187.13 10.92"/>
                              <polygon class="cls-22" points="187.13 10.92 187.19 11.52 224.81 7.7 228.48 45.05 198.52 47.92 187.71 10.75 187.13 10.92 187.19 11.52 187.13 10.92 186.55 11.09 197.63 49.22 229.8 46.14 225.9 6.38 186.34 10.39 186.55 11.09 187.13 10.92"/>
                              <text class="cls-5" transform="translate(200.46 30.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">248</tspan></text>

                              <circle id="Z27" @click="onSelectSeat('248','Z27')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z27') }" cx="222.33" cy="9.99" r="0.46"/>
                              <circle id="Z26" @click="onSelectSeat('248','Z26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z26') }" cx="223.57" cy="9.86" r="0.46"/>                              
                              <circle id="Z25" @click="onSelectSeat('248','Z25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z25') }" cx="221.11" cy="10.12" r="0.46"/>
                              <circle id="Z24" @click="onSelectSeat('248','Z24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z24') }" cx="219.89" cy="10.25" r="0.46"/>
                              <circle id="Z23" @click="onSelectSeat('248','Z23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z23') }" cx="218.67" cy="10.38" r="0.46"/>
                              <circle id="Z22" @click="onSelectSeat('248','Z22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z22') }" cx="217.45" cy="10.52" r="0.46"/>
                              <circle id="Z21" @click="onSelectSeat('248','Z21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z21') }" cx="216.22" cy="10.65" r="0.46"/>
                              <circle id="Z20" @click="onSelectSeat('248','Z20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z20') }" cx="215.10" cy="10.78" r="0.46"/>
                              <circle id="Z19" @click="onSelectSeat('248','Z19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z19') }" cx="213.78" cy="10.91" r="0.46"/>
                              <circle id="Z18" @click="onSelectSeat('248','Z18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z18') }" cx="212.56" cy="11.04" r="0.46"/>
                              <circle id="Z17" @click="onSelectSeat('248','Z17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z17') }" cx="211.34" cy="11.17" r="0.46"/>
                              <circle id="Z16" @click="onSelectSeat('248','Z16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z16') }" cx="210.12" cy="11.3" r="0.46"/>
                              <circle id="Z15" @click="onSelectSeat('248','Z15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z15') }" cx="208.89" cy="11.43" r="0.46"/>
                              <circle id="Z14" @click="onSelectSeat('248','Z14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z14') }" cx="207.67" cy="11.57" r="0.46"/>
                              <circle id="Z13" @click="onSelectSeat('248','Z13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z13') }" cx="206.45" cy="11.7" r="0.46"/>
                              <circle id="Z12" @click="onSelectSeat('248','Z12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z12') }" cx="205.23" cy="11.83" r="0.46"/>
                              <circle id="Z11" @click="onSelectSeat('248','Z11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z11') }" cx="204.01" cy="11.96" r="0.46"/> 
                              <circle id="Z10" @click="onSelectSeat('248','Z10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z10') }" cx="202.79" cy="12.09" r="0.46"/>
                              <circle id="Z9" @click="onSelectSeat('248','Z9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z9') }" cx="201.57" cy="12.22" r="0.46"/>
                              <circle id="Z8" @click="onSelectSeat('248','Z8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z8') }" cx="200.34" cy="12.35" r="0.46"/>
                              <circle id="Z7" @click="onSelectSeat('248','Z7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z7') }" cx="199.12" cy="12.48" r="0.46"/>
                              <circle id="Z6" @click="onSelectSeat('248','Z6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z6') }" cx="197.9" cy="12.62" r="0.46"/>
                              <circle id="Z5" @click="onSelectSeat('248','Z5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z5') }" cx="196.68" cy="12.75" r="0.46"/>
                              <circle id="Z4" @click="onSelectSeat('248','Z4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z4') }" cx="194.24" cy="13.01" r="0.46"/>
                              <circle id="Z3" @click="onSelectSeat('248','Z3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z3') }" cx="193.01" cy="13.14" r="0.46"/>
                              <circle id="Z2" @click="onSelectSeat('248','Z2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z2') }" cx="191.79" cy="13.27" r="0.46"/>
                              <circle id="Z1" @click="onSelectSeat('248','Z1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Z1') }" cx="190.44" cy="13.42" r="0.46"/>

                              <circle id="Y26" @click="onSelectSeat('248','Y26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y26') }" cx="222.47" cy="11.26" r="0.46"/>
                              <circle id="Y25" @click="onSelectSeat('248','Y25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y25') }" cx="221.25" cy="11.39" r="0.46"/>
                              <circle id="Y24" @click="onSelectSeat('248','Y24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y24') }" cx="220.03" cy="11.53" r="0.46"/>
                              <circle id="Y23" @click="onSelectSeat('248','Y23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y23') }" cx="218.8" cy="11.66" r="0.46"/>
                              <circle id="Y22" @click="onSelectSeat('248','Y22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y22') }" cx="217.58" cy="11.79" r="0.46"/>
                              <circle id="Y21" @click="onSelectSeat('248','Y21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y21') }" cx="216.36" cy="11.92" r="0.46"/>
                              <circle id="Y20" @click="onSelectSeat('248','Y20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y20') }" cx="215.14" cy="12.05" r="0.46"/>
                              <circle id="Y19" @click="onSelectSeat('248','Y19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y19') }" cx="213.92" cy="12.18" r="0.46"/>
                              <circle id="Y18" @click="onSelectSeat('248','Y18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y18') }" cx="212.70" cy="12.31" r="0.46"/>
                              <circle id="Y17" @click="onSelectSeat('248','Y17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y17') }" cx="211.47" cy="12.45" r="0.46"/>
                              <circle id="Y16" @click="onSelectSeat('248','Y16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y16') }" cx="210.25" cy="12.58" r="0.46"/>
                              <circle id="Y15" @click="onSelectSeat('248','Y15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y15') }" cx="209.03" cy="12.71" r="0.46"/>
                              <circle id="Y14" @click="onSelectSeat('248','Y14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y14') }" cx="207.81" cy="12.84" r="0.46"/>
                              <circle id="Y13" @click="onSelectSeat('248','Y13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y13') }" cx="206.59" cy="12.97" r="0.46"/>
                              <circle id="Y12" @click="onSelectSeat('248','Y12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y12') }" cx="205.37" cy="13.10" r="0.46"/>
                              <circle id="Y11" @click="onSelectSeat('248','Y11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y11') }" cx="204.15" cy="13.23" r="0.46"/>
                              <circle id="Y10" @click="onSelectSeat('248','Y10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y10') }" cx="202.92" cy="13.36" r="0.46"/>
                              <circle id="Y9" @click="onSelectSeat('248','Y9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y9') }" cx="201.70" cy="13.50" r="0.46"/>
                              <circle id="Y8" @click="onSelectSeat('248','Y8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y8') }" cx="200.48" cy="13.63" r="0.46"/>
                              <circle id="Y7" @click="onSelectSeat('248','Y7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y7') }" cx="199.26" cy="13.76" r="0.46"/>
                              <circle id="Y6" @click="onSelectSeat('248','Y6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y6') }" cx="198.04" cy="13.89" r="0.46"/>
                              <circle id="Y5" @click="onSelectSeat('248','Y5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y5') }" cx="196.82" cy="14.02" r="0.46"/>
                              <circle id="Y4" @click="onSelectSeat('248','Y4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y4') }" cx="195.59" cy="14.15" r="0.46"/>
                              <circle id="Y3" @click="onSelectSeat('248','Y3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y3') }" cx="194.37" cy="14.28" r="0.46"/>
                              <circle id="Y2" @click="onSelectSeat('248','Y2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y2') }" cx="193.15" cy="14.41" r="0.46"/>
                              <circle id="Y1" @click="onSelectSeat('248','Y1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Y1') }" cx="191.93" cy="14.55" r="0.46"/>

                              <circle id="X26" @click="onSelectSeat('248','X26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X26') }" cx="222.61" cy="12.54" r="0.46"/>
                              <circle id="X25" @click="onSelectSeat('248','X25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X25') }" cx="221.38" cy="12.67" r="0.46"/>
                              <circle id="X24" @click="onSelectSeat('248','X24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X24') }" cx="220.16" cy="12.80" r="0.46"/>
                              <circle id="X23" @click="onSelectSeat('248','X23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X23') }" cx="218.94" cy="12.93" r="0.46"/>
                              <circle id="X22" @click="onSelectSeat('248','X22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X22') }" cx="217.72" cy="13.06" r="0.46"/>
                              <circle id="X21" @click="onSelectSeat('248','X21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X21') }" cx="216.5" cy="13.19" r="0.46"/>
                              <circle id="X20" @click="onSelectSeat('248','X20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X20') }" cx="215.28" cy="13.32" r="0.46"/>
                              <circle id="X19" @click="onSelectSeat('248','X19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X19') }" cx="214.05" cy="13.46" r="0.46"/>
                              <circle id="X18" @click="onSelectSeat('248','X18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X18') }" cx="212.83" cy="13.59" r="0.46"/>
                              <circle id="X17" @click="onSelectSeat('248','X17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X17') }" cx="211.61" cy="13.72" r="0.46"/>
                              <circle id="X16" @click="onSelectSeat('248','X16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X16') }" cx="210.39" cy="13.85" r="0.46"/>
                              <circle id="X15" @click="onSelectSeat('248','X15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X15') }" cx="209.17" cy="13.98" r="0.46"/>
                              <circle id="X14" @click="onSelectSeat('248','X14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X14') }" cx="207.95" cy="14.11" r="0.46"/>
                              <circle id="X13" @click="onSelectSeat('248','X13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X13') }" cx="206.73" cy="14.24" r="0.46"/>
                              <circle id="X12" @click="onSelectSeat('248','X12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X12') }" cx="205.5" cy="14.37" r="0.46"/>
                              <circle id="X11" @click="onSelectSeat('248','X11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X11') }" cx="204.28" cy="14.51" r="0.46"/>
                              <circle id="X10" @click="onSelectSeat('248','X10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X10') }" cx="203.06" cy="14.64" r="0.46"/>
                              <circle id="X9" @click="onSelectSeat('248','X9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X9') }" cx="201.84" cy="14.77" r="0.46"/>
                              <circle id="X8" @click="onSelectSeat('248','X8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X8') }" cx="200.62" cy="14.9" r="0.46"/>
                              <circle id="X7" @click="onSelectSeat('248','X7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X7') }" cx="199.4" cy="15.03" r="0.46"/>
                              <circle id="X6" @click="onSelectSeat('248','X6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X6') }" cx="198.17" cy="15.16" r="0.46"/>
                              <circle id="X5" @click="onSelectSeat('248','X5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X5') }" cx="196.95" cy="15.29" r="0.46"/>
                              <circle id="X4" @click="onSelectSeat('248','X4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X4') }" cx="195.73" cy="15.42" r="0.46"/>
                              <circle id="X3" @click="onSelectSeat('248','X3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X3') }" cx="194.51" cy="15.56" r="0.46"/>
                              <circle id="X2" @click="onSelectSeat('248','X2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X2') }" cx="193.29" cy="15.69" r="0.46"/>
                              <circle id="X1" @click="onSelectSeat('248','X1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('X1') }" cx="192.07" cy="15.82" r="0.46"/>
  
                              <circle id="W26" @click="onSelectSeat('248','W26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W26') }" cx="222.74" cy="13.81" r="0.46"/>
                              <circle id="W25" @click="onSelectSeat('248','W25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W25') }" cx="221.52" cy="13.94" r="0.46"/>
                              <circle id="W24" @click="onSelectSeat('248','W24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W24') }" cx="220.30" cy="14.07" r="0.46"/>
                              <circle id="W23" @click="onSelectSeat('248','W23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W23') }" cx="219.08" cy="14.20" r="0.46"/>
                              <circle id="W22" @click="onSelectSeat('248','W22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W22') }" cx="217.86" cy="14.34" r="0.46"/>
                              <circle id="W21" @click="onSelectSeat('248','W21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W21') }" cx="216.63" cy="14.47" r="0.46"/>
                              <circle id="W20" @click="onSelectSeat('248','W20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W20') }" cx="215.41" cy="14.60" r="0.46"/>
                              <circle id="W19" @click="onSelectSeat('248','W19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W19') }" cx="214.19" cy="14.73" r="0.46"/>
                              <circle id="W18" @click="onSelectSeat('248','W18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W18') }" cx="212.97" cy="14.86" r="0.46"/>
                              <circle id="W17" @click="onSelectSeat('248','W17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W17') }" cx="211.75" cy="14.99" r="0.46"/>
                              <circle id="W16" @click="onSelectSeat('248','W16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W16') }" cx="210.53" cy="15.12" r="0.46"/>
                              <circle id="W15" @click="onSelectSeat('248','W15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W15') }" cx="209.31" cy="15.25" r="0.46"/>
                              <circle id="W14" @click="onSelectSeat('248','W14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W14') }" cx="208.08" cy="15.39" r="0.46"/>
                              <circle id="W13" @click="onSelectSeat('248','W13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W13') }" cx="206.86" cy="15.52" r="0.46"/>
                              <circle id="W12" @click="onSelectSeat('248','W12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W12') }" cx="205.64" cy="15.65" r="0.46"/>
                              <circle id="W11" @click="onSelectSeat('248','W11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W11') }" cx="204.42" cy="15.78" r="0.46"/>
                              <circle id="W10" @click="onSelectSeat('248','W10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W10') }" cx="203.20" cy="15.91" r="0.46"/>
                              <circle id="W9" @click="onSelectSeat('248','W9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W9') }" cx="201.98" cy="16.04" r="0.46"/>
                              <circle id="W8" @click="onSelectSeat('248','W8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W8') }" cx="200.75" cy="16.17" r="0.46"/>
                              <circle id="W7" @click="onSelectSeat('248','W7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W7') }" cx="199.53" cy="16.30" r="0.46"/>
                              <circle id="W6" @click="onSelectSeat('248','W6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W6') }" cx="198.31" cy="16.44" r="0.46"/>
                              <circle id="W5" @click="onSelectSeat('248','W5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W5') }" cx="197.09" cy="16.57" r="0.46"/>
                              <circle id="W4" @click="onSelectSeat('248','W4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W4') }" cx="195.87" cy="16.70" r="0.46"/>
                              <circle id="W3" @click="onSelectSeat('248','W3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W3') }" cx="194.65" cy="16.83" r="0.46"/>
                              <circle id="W2" @click="onSelectSeat('248','W2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W2') }" cx="193.42" cy="16.96" r="0.46"/>
                              <circle id="W1" @click="onSelectSeat('248','W1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('W1') }" cx="192.20" cy="17.09" r="0.46"/>

                              <circle id="V26" @click="onSelectSeat('248','V26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V26') }" cx="222.88" cy="15.08" r="0.46"/>
                              <circle id="V25" @click="onSelectSeat('248','V25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V25') }" cx="221.66" cy="15.21" r="0.46"/>
                              <circle id="V24" @click="onSelectSeat('248','V24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V24') }" cx="220.44" cy="15.35" r="0.46"/>
                              <circle id="V23" @click="onSelectSeat('248','V23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V23') }" cx="219.21" cy="15.48" r="0.46"/>
                              <circle id="V22" @click="onSelectSeat('248','V22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V22') }" cx="217.99" cy="15.61" r="0.46"/>
                              <circle id="V21" @click="onSelectSeat('248','V21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V21') }" cx="216.77" cy="15.74" r="0.46"/>
                              <circle id="V20" @click="onSelectSeat('248','V20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V20') }" cx="215.55" cy="15.87" r="0.46"/>
                              <circle id="V19" @click="onSelectSeat('248','V19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V19') }" cx="214.33" cy="16.00" r="0.46"/>
                              <circle id="V18" @click="onSelectSeat('248','V18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V18') }" cx="213.11" cy="16.13" r="0.46"/>
                              <circle id="V17" @click="onSelectSeat('248','V17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V17') }" cx="211.89" cy="16.26" r="0.46"/>
                              <circle id="V16" @click="onSelectSeat('248','V16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V16') }" cx="210.66" cy="16.40" r="0.46"/>
                              <circle id="V15" @click="onSelectSeat('248','V15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V15') }" cx="209.44" cy="16.53" r="0.46"/>
                              <circle id="V14" @click="onSelectSeat('248','V14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V14') }" cx="208.22" cy="16.66" r="0.46"/>
                              <circle id="V13" @click="onSelectSeat('248','V13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V13') }" cx="207.00" cy="16.79" r="0.46"/>
                              <circle id="V12" @click="onSelectSeat('248','V12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V12') }" cx="205.78" cy="16.92" r="0.46"/>
                              <circle id="V11" @click="onSelectSeat('248','V11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V11') }" cx="204.56" cy="17.05" r="0.46"/>
                              <circle id="V10" @click="onSelectSeat('248','V10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V10') }" cx="203.33" cy="17.18" r="0.46"/>
                              <circle id="V9" @click="onSelectSeat('248','V9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V9') }" cx="202.11" cy="17.32" r="0.46"/>
                              <circle id="V8" @click="onSelectSeat('248','V8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V8') }" cx="200.89" cy="17.45" r="0.46"/>
                              <circle id="V7" @click="onSelectSeat('248','V7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V7') }" cx="199.67" cy="17.58" r="0.46"/>
                              <circle id="V6" @click="onSelectSeat('248','V6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V6') }" cx="198.45" cy="17.71" r="0.46"/>
                              <circle id="V5" @click="onSelectSeat('248','V5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V5') }" cx="197.23" cy="17.84" r="0.46"/>
                              <circle id="V4" @click="onSelectSeat('248','V4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V4') }" cx="196.00" cy="17.97" r="0.46"/>
                              <circle id="V3" @click="onSelectSeat('248','V3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V3') }" cx="194.78" cy="18.10" r="0.46"/>
                              <circle id="V2" @click="onSelectSeat('248','V2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V2') }" cx="193.56" cy="18.23" r="0.46"/>
                              <circle id="V1" @click="onSelectSeat('248','V1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('V1') }" cx="192.34" cy="18.37" r="0.46"/>

                              <circle id="U26" @click="onSelectSeat('248','U26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U26') }" cx="223.02" cy="16.36" r="0.46" />
                              <circle id="U25" @click="onSelectSeat('248','U25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U25') }" cx="221.79" cy="16.49" r="0.46" />
                              <circle id="U24" @click="onSelectSeat('248','U24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U24') }" cx="220.57" cy="16.62" r="0.46" />        
                              <circle id="U23" @click="onSelectSeat('248','U23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U23') }" cx="219.35" cy="16.75" r="0.46"/>
                              <circle id="U22" @click="onSelectSeat('248','U22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U22') }" cx="218.13" cy="16.88" r="0.46"/>
                              <circle id="U21" @click="onSelectSeat('248','U21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U21') }" cx="216.91" cy="17.01" r="0.46"/>
                              <circle id="U20" @click="onSelectSeat('248','U20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U20') }" cx="215.69" cy="17.14" r="0.46"/>
                              <circle id="U19" @click="onSelectSeat('248','U19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U19') }" cx="214.47" cy="17.28" r="0.46"/>
                              <circle id="U18" @click="onSelectSeat('248','U18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U18') }" cx="213.24" cy="17.41" r="0.46"/>
                              <circle id="U17" @click="onSelectSeat('248','U17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U17') }" cx="212.02" cy="17.54" r="0.46"/>
                              <circle id="U16" @click="onSelectSeat('248','U16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U16') }" cx="210.8" cy="17.67" r="0.46"/>
                              <circle id="U15" @click="onSelectSeat('248','U15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U15') }" cx="209.58" cy="17.80" r="0.46"/>
                              <circle id="U14" @click="onSelectSeat('248','U14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U14') }" cx="208.36" cy="17.93" r="0.46"/>
                              <circle id="U13" @click="onSelectSeat('248','U13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U13') }" cx="207.14" cy="18.06" r="0.46"/>
                              <circle id="U12" @click="onSelectSeat('248','U12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U12') }" cx="205.91" cy="18.19" r="0.46"/>
                              <circle id="U11" @click="onSelectSeat('248','U11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U11') }" cx="204.69" cy="18.33" r="0.46"/>
                              <circle id="U10" @click="onSelectSeat('248','U10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U10') }" cx="203.47" cy="18.46" r="0.46"/>
                              <circle id="U9" @click="onSelectSeat('248','U9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U9') }" cx="202.25" cy="18.59" r="0.46"/>
                              <circle id="U8" @click="onSelectSeat('248','U8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U8') }" cx="201.03" cy="18.72" r="0.46"/>
                              <circle id="U7" @click="onSelectSeat('248','U7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U7') }" cx="199.81" cy="18.85" r="0.46"/>
                              <circle id="U6" @click="onSelectSeat('248','U6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U6') }" cx="198.58" cy="18.98" r="0.46"/>
                              <circle id="U5" @click="onSelectSeat('248','U5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U5') }" cx="197.36" cy="19.11" r="0.46"/>
                              <circle id="U4" @click="onSelectSeat('248','U4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U4') }" cx="196.14" cy="19.24" r="0.46"/>
                              <circle id="U3" @click="onSelectSeat('248','U3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U3') }" cx="194.92" cy="19.38" r="0.46" />
                              <circle id="U2" @click="onSelectSeat('248','U2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U2') }" cx="193.7" cy="19.51" r="0.46" />
                              <circle id="U1" @click="onSelectSeat('248','U1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('U1') }" cx="192.48" cy="19.64" r="0.46" />

                              <circle id="T26" @click="onSelectSeat('248','T26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T26') }" cx="223.15" cy="17.63" r="0.46" />
                              <circle id="T25" @click="onSelectSeat('248','T25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T25') }" cx="221.93" cy="17.76" r="0.46" />
                              <circle id="T24" @click="onSelectSeat('248','T24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T24') }" cx="220.71" cy="17.89" r="0.46" />
                              <circle id="T23" @click="onSelectSeat('248','T23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T23') }" cx="219.49" cy="18.02" r="0.46" />
                              <circle id="T22" @click="onSelectSeat('248','T22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T22') }" cx="218.27" cy="18.15" r="0.46" />
                              <circle id="T21" @click="onSelectSeat('248','T21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T21') }" cx="217.05" cy="18.29" r="0.46" />
                              <circle id="T20" @click="onSelectSeat('248','T20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T20') }" cx="215.82" cy="18.42" r="0.46" />
                              <circle id="T19" @click="onSelectSeat('248','T19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T19') }" cx="214.60" cy="18.55" r="0.46" />
                              <circle id="T18" @click="onSelectSeat('248','T18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T18') }" cx="213.38" cy="18.68" r="0.46" />
                              <circle id="T17" @click="onSelectSeat('248','T17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T17') }" cx="212.16" cy="18.81" r="0.46" />
                              <circle id="T16" @click="onSelectSeat('248','T16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T16') }" cx="210.94" cy="18.94" r="0.46" />
                              <circle id="T15" @click="onSelectSeat('248','T15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T15') }" cx="209.72" cy="19.07" r="0.46" />
                              <circle id="T14" @click="onSelectSeat('248','T14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T14') }" cx="208.49" cy="19.21" r="0.46" />
                              <circle id="T13" @click="onSelectSeat('248','T13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T13') }" cx="207.27" cy="19.34" r="0.46" />
                              <circle id="T12" @click="onSelectSeat('248','T12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T12') }" cx="206.05" cy="19.47" r="0.46" />
                              <circle id="T11" @click="onSelectSeat('248','T11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T11') }" cx="204.83" cy="19.6" r="0.46" />
                              <circle id="T10" @click="onSelectSeat('248','T10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T10') }" cx="203.61" cy="19.73" r="0.46" />
                              <circle id="T9" @click="onSelectSeat('248','T9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T9') }" cx="202.39" cy="19.86" r="0.46" />
                              <circle id="T8" @click="onSelectSeat('248','T8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T8') }" cx="201.16" cy="19.99" r="0.46" />
                              <circle id="T7" @click="onSelectSeat('248','T7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T7') }" cx="199.94" cy="20.12" r="0.46" />
                              <circle id="T6" @click="onSelectSeat('248','T6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T6') }" cx="198.72" cy="20.26" r="0.46" />
                              <circle id="T5" @click="onSelectSeat('248','T5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T5') }" cx="197.5" cy="20.39" r="0.46" />
                              <circle id="T4" @click="onSelectSeat('248','T4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T4') }" cx="196.28" cy="20.52" r="0.46" />
                              <circle id="T3" @click="onSelectSeat('248','T3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T3') }" cx="195.06" cy="20.65" r="0.46" />
                              <circle id="T2" @click="onSelectSeat('248','T2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T2') }" cx="193.84" cy="20.78" r="0.46" />
                              <circle id="T1" @click="onSelectSeat('248','T1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('T1') }" cx="192.61" cy="20.91" r="0.46" />

                              <circle id="S26" @click="onSelectSeat('248','S26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S26') }" cx="223.29" cy="18.9" r="0.46"/>                              
                              <circle id="S25" @click="onSelectSeat('248','S25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S25') }" cx="222.07" cy="19.03" r="0.46"/>                              
                              <circle id="S24" @click="onSelectSeat('248','S24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S24') }" cx="220.85" cy="19.3" r="0.46"/>                              
                              <circle id="S23" @click="onSelectSeat('248','S23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S23') }" cx="219.63" cy="19.3" r="0.46"/>
                              <circle id="S22" @click="onSelectSeat('248','S22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S22') }" cx="218.4" cy="19.43" r="0.46"/>
                              <circle id="S21" @click="onSelectSeat('248','S21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S21') }" cx="217.18" cy="19.56" r="0.46"/>
                              <circle id="S20" @click="onSelectSeat('248','S20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S20') }" cx="215.96" cy="19.69" r="0.46"/>
                              <circle id="S19" @click="onSelectSeat('248','S19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S19') }" cx="214.74" cy="19.82" r="0.46"/>
                              <circle id="S18" @click="onSelectSeat('248','S18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S18') }" cx="213.52" cy="19.95" r="0.46"/>
                              <circle id="S17" @click="onSelectSeat('248','S17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S17') }" cx="212.3" cy="20.08" r="0.46"/>
                              <circle id="S16" @click="onSelectSeat('248','S16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S16') }" cx="211.07" cy="20.22" r="0.46"/>
                              <circle id="S15" @click="onSelectSeat('248','S15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S15') }" cx="209.85" cy="20.35" r="0.46"/>
                              <circle id="S14" @click="onSelectSeat('248','S14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S14') }" cx="208.63" cy="20.48" r="0.46"/>
                              <circle id="S13" @click="onSelectSeat('248','S13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S13') }" cx="207.41" cy="20.61" r="0.46"/>
                              <circle id="S12" @click="onSelectSeat('248','S12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S12') }" cx="206.19" cy="20.74" r="0.46"/>
                              <circle id="S11" @click="onSelectSeat('248','S11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S11') }" cx="204.97" cy="20.87" r="0.46"/>
                              <circle id="S10" @click="onSelectSeat('248','S10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S10') }" cx="203.74" cy="21" r="0.46"/>
                              <circle id="S9" @click="onSelectSeat('248','S9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S9') }" cx="202.52" cy="21.13" r="0.46"/>
                              <circle id="S8" @click="onSelectSeat('248','S8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S8') }" cx="201.3" cy="21.27" r="0.46"/>
                              <circle id="S7" @click="onSelectSeat('248','S7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S7') }" cx="200.08" cy="21.4" r="0.46"/>
                              <circle id="S6" @click="onSelectSeat('248','S6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S6') }" cx="198.86" cy="21.53" r="0.46"/>
                              <circle id="S5" @click="onSelectSeat('248','S5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S5') }" cx="197.64" cy="21.66" r="0.46"/>
                              <circle id="S4" @click="onSelectSeat('248','S4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S4') }" cx="196.42" cy="21.79" r="0.46"/>
                              <circle id="S3" @click="onSelectSeat('248','S3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S3') }" cx="195.19" cy="21.92" r="0.46"/>
                              <circle id="S2" @click="onSelectSeat('248','S2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S2') }" cx="193.97" cy="22.05" r="0.46"/>
                              <circle id="S1" @click="onSelectSeat('248','S1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('S1') }" cx="192.75" cy="22.19" r="0.46"/>
                              
                              <circle id="R26" @click="onSelectSeat('248','R26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R26') }" cx="223.43" cy="20.18" r="0.46"/>
                              <circle id="R25" @click="onSelectSeat('248','R25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R25') }" cx="222.21" cy="20.31" r="0.46"/>
                              <circle id="R24" @click="onSelectSeat('248','R24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R24') }" cx="220.98" cy="20.44" r="0.46"/>
                              <circle id="R23" @click="onSelectSeat('248','R23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R23') }" cx="219.76" cy="20.57" r="0.46"/>
                              <circle id="R22" @click="onSelectSeat('248','R22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R22') }" cx="218.54" cy="20.7" r="0.46"/>
                              <circle id="R21" @click="onSelectSeat('248','R21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R21') }" cx="217.32" cy="20.83" r="0.46"/>
                              <circle id="R20" @click="onSelectSeat('248','R20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R20') }" cx="216.1" cy="20.96" r="0.46"/>
                              <circle id="R19" @click="onSelectSeat('248','R19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R19') }" cx="214.88" cy="21.1" r="0.46"/>
                              <circle id="R18" @click="onSelectSeat('248','R18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R18') }" cx="213.65" cy="21.23" r="0.46"/>
                              <circle id="R17" @click="onSelectSeat('248','R17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R17') }" cx="212.43" cy="21.36" r="0.46"/>
                              <circle id="R16" @click="onSelectSeat('248','R16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R16') }" cx="211.21" cy="21.49" r="0.46"/>
                              <circle id="R15" @click="onSelectSeat('248','R15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R15') }" cx="209.99" cy="21.62" r="0.46"/>
                              <circle id="R14" @click="onSelectSeat('248','R14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R14') }" cx="208.77" cy="21.75" r="0.46"/>
                              <circle id="R13" @click="onSelectSeat('248','R13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R13') }" cx="207.55" cy="21.88" r="0.46"/>
                              <circle id="R12" @click="onSelectSeat('248','R12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R12') }" cx="206.32" cy="22.01" r="0.46"/>
                              <circle id="R11" @click="onSelectSeat('248','R11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R11') }" cx="205.10" cy="22.15" r="0.46"/>
                              <circle id="R10" @click="onSelectSeat('248','R10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R10') }" cx="203.88" cy="22.28" r="0.46"/>
                              <circle id="R9" @click="onSelectSeat('248','R9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R9') }" cx="202.66" cy="22.41" r="0.46"/>
                              <circle id="R8" @click="onSelectSeat('248','R8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R8') }" cx="201.44" cy="22.54" r="0.46"/>
                              <circle id="R7" @click="onSelectSeat('248','R7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R7') }" cx="200.22" cy="22.67" r="0.46"/>
                              <circle id="R6" @click="onSelectSeat('248','R6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R6') }" cx="199.00" cy="22.80" r="0.46"/>
                              <circle id="R5" @click="onSelectSeat('248','R5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R5') }" cx="197.77" cy="22.93" r="0.46"/>
                              <circle id="R4" @click="onSelectSeat('248','R4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R4') }" cx="196.55" cy="23.06" r="0.46"/>
                              <circle id="R3" @click="onSelectSeat('248','R3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R3') }" cx="195.33" cy="23.20" r="0.46"/>
                              <circle id="R2" @click="onSelectSeat('248','R2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R2') }" cx="194.11" cy="23.33" r="0.46"/>
                              <circle id="R1" @click="onSelectSeat('248','R1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('R1') }" cx="192.89" cy="23.46" r="0.46"/>
                                
                              <circle id="Q26" @click="onSelectSeat('248','Q26')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q26') }" cx="223.56" cy="21.45" r="0.44"/>
                              <circle id="Q25" @click="onSelectSeat('248','Q25')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q25') }" cx="222.34" cy="21.58" r="0.44"/>
                              <circle id="Q24" @click="onSelectSeat('248','Q24')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q24') }" cx="221.12" cy="21.71" r="0.44"/>
                              <circle id="Q23" @click="onSelectSeat('248','Q23')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q23') }" cx="219.90" cy="21.84" r="0.44"/>
                              <circle id="Q22" @click="onSelectSeat('248','Q22')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q22') }" cx="218.68" cy="21.97" r="0.44"/>
                              <circle id="Q21" @click="onSelectSeat('248','Q21')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q21') }" cx="217.46" cy="22.11" r="0.44"/>
                              <circle id="Q20" @click="onSelectSeat('248','Q20')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q20') }" cx="216.23" cy="22.24" r="0.44"/>
                              <circle id="Q19" @click="onSelectSeat('248','Q19')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q19') }" cx="215.01" cy="22.37" r="0.44"/>
                              <circle id="Q18" @click="onSelectSeat('248','Q18')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q18') }" cx="213.79" cy="22.50" r="0.44"/>
                              <circle id="Q17" @click="onSelectSeat('248','Q17')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q17') }" cx="212.57" cy="22.63" r="0.44"/>
                              <circle id="Q16" @click="onSelectSeat('248','Q16')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q16') }" cx="211.35" cy="22.76" r="0.44"/>
                              <circle id="Q15" @click="onSelectSeat('248','Q15')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q15') }" cx="210.13" cy="22.89" r="0.44"/>
                              <circle id="Q14" @click="onSelectSeat('248','Q14')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q14') }" cx="208.90" cy="23.03" r="0.44"/>
                              <circle id="Q13" @click="onSelectSeat('248','Q13')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q13') }" cx="207.68" cy="23.16" r="0.44"/>
                              <circle id="Q12" @click="onSelectSeat('248','Q12')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q12') }" cx="206.46" cy="23.29" r="0.44"/>
                              <circle id="Q11" @click="onSelectSeat('248','Q11')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q11') }" cx="205.24" cy="23.42" r="0.44"/>
                              <circle id="Q10" @click="onSelectSeat('248','Q10')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q10') }" cx="204.02" cy="23.55" r="0.44"/>
                              <circle id="Q9" @click="onSelectSeat('248','Q9')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q9') }" cx="202.80" cy="23.68" r="0.44"/>
                              <circle id="Q8" @click="onSelectSeat('248','Q8')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q8') }" cx="201.58" cy="23.81" r="0.44"/>
                              <circle id="Q7" @click="onSelectSeat('248','Q7')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q7') }" cx="200.36" cy="23.95" r="0.44"/>
                              <circle id="Q6" @click="onSelectSeat('248','Q6')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q6') }" cx="199.13" cy="24.08" r="0.44"/>
                              <circle id="Q5" @click="onSelectSeat('248','Q5')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q5') }" cx="197.91" cy="24.21" r="0.44"/> 
                              <circle id="Q4" @click="onSelectSeat('248','Q4')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q4') }" cx="196.69" cy="24.35" r="0.44"/>
                              <circle id="Q3" @click="onSelectSeat('248','Q3')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q3') }" cx="195.47" cy="24.51" r="0.44"/>
                              <circle id="Q2" @click="onSelectSeat('248','Q2')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q2') }" cx="194.25" cy="24.61" r="0.44"/>
                              <circle id="Q1" @click="onSelectSeat('248','Q1')" v-show="isSeatVisible === 'section248'" :class="{ 'selected': isSelected('Q1') }" cx="193.02" cy="24.73" r="0.44"/>

                              <path v-for="seat in seats.section248" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section248'" @click="onSelectSeat('248', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                          </g>
                          <g id="group249">
                              <polygon id="section249" @click="isSectionEnabled('249') ? handleZoom('section249') : null" class="cls-9" points="229.09 45.3 225.35 7.04 261.54 5.93 262.22 45.59 229.09 45.3"/>
                              <polygon class="cls-22" points="229.09 45.3 229.69 45.25 226.02 7.62 260.94 6.55 261.6 44.98 229.1 44.7 229.09 45.3 229.69 45.25 229.09 45.3 229.09 45.91 262.84 46.2 262.13 5.3 224.69 6.45 228.54 45.91 229.09 45.91 229.09 45.3"/>
                              <text class="cls-5" transform="translate(235.46 30.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">249</tspan></text>
<!-- 
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259.06,17.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.78,17.41c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.5,17.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.22,17.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.94,17.45c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.66,17.47c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.38,17.48c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.09,17.5c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.81,17.51c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.53,17.53c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.25,17.54c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.97,17.56c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.69,17.58c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.41,17.59c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.13,17.61c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.85,17.62c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.57,17.64c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.28,17.65c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236,17.67c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.72,17.68c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.44,17.7c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.16,17.71c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.88,17.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.6,17.74c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.32,17.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m227.04,17.78c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.58,16.41c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.3,16.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m227.02,16.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.76,16.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.48,16.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.2,16.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.92,16.12c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.64,16.13c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.36,16.15c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.08,16.16c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.8,16.18c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.52,16.19c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.24,16.21c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.95,16.22c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.67,16.24c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.39,16.26c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.11,16.27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.83,16.29c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.55,16.3c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.27,16.32c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m235.99,16.33c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.71,16.35c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.43,16.36c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.14,16.38c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.86,16.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259.03,14.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.57,15.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.29,15.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m227,15.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.75,14.74c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.47,14.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.19,14.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.91,14.78c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.62,14.8c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.34,14.81c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.06,14.83c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.78,14.84c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.5,14.86c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.22,14.87c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.94,14.89c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.66,14.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.38,14.92c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.1,14.94c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.81,14.95c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.53,14.97c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.25,14.98c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m235.97,15c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.69,15.01c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.41,15.03c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.13,15.04c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.85,15.06c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259.01,13.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.55,13.74c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.27,13.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m226.99,13.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.73,13.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.45,13.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.17,13.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.89,13.45c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.61,13.46c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.33,13.48c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.05,13.49c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.77,13.51c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.48,13.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.2,13.54c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.92,13.55c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.64,13.57c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.36,13.59c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.08,13.6c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.8,13.62c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.52,13.63c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.24,13.65c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m235.96,13.66c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.67,13.68c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.39,13.69c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.11,13.71c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.83,13.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259,12.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.53,12.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.25,12.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m226.97,12.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.72,12.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.44,12.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.15,12.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.87,12.11c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.59,12.13c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.31,12.14c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.03,12.16c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.75,12.17c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.47,12.19c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.19,12.2c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.91,12.22c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.63,12.23c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.34,12.25c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.06,12.27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.78,12.28c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.5,12.3c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.22,12.31c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m235.94,12.33c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.66,12.34c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.38,12.36c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.1,12.37c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.82,12.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m258.98,10.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.52,11.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.24,11.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m226.96,11.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.7,10.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.42,10.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.14,10.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.86,10.78c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.58,10.79c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.3,10.81c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.01,10.82c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.73,10.84c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.45,10.85c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.17,10.87c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.89,10.88c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.61,10.9c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.33,10.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.05,10.93c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.77,10.95c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.49,10.96c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.2,10.98c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m235.92,10.99c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.64,11.01c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.36,11.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.08,11.04c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.8,11.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m258.97,9.38c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m260.26,9.36c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.5,9.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.22,9.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m226.94,9.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.68,9.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.4,9.41c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.12,9.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.84,9.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.56,9.46c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.28,9.47c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250,9.49c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.72,9.5c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.44,9.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.16,9.53c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.87,9.55c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.59,9.56c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.31,9.58c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.03,9.6c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.75,9.61c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.47,9.63c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.19,9.64c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m235.91,9.66c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.63,9.67c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.35,9.69c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.06,9.7c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.78,9.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259.08,18.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.8,18.74c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.52,18.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.23,18.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.95,18.79c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.67,18.8c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.39,18.82c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.11,18.83c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.83,18.85c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.55,18.86c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.27,18.88c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m244.99,18.9c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.71,18.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.42,18.93c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.14,18.94c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.86,18.96c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.58,18.97c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.3,18.99c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.02,19c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.74,19.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.46,19.03c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.18,19.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.9,19.06c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.61,19.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.33,19.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259.09,20.06c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.81,20.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.53,20.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.25,20.11c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.97,20.12c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.69,20.14c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.41,20.15c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.13,20.17c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.85,20.18c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.56,20.2c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.28,20.22c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245,20.23c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.72,20.25c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.44,20.26c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.16,20.28c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.88,20.29c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.6,20.31c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.32,20.32c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.04,20.34c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.75,20.35c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.47,20.37c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.19,20.38c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.91,20.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.63,20.41c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.35,20.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m259.11,21.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.83,21.41c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.55,21.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.27,21.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m253.99,21.46c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.7,21.47c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.42,21.49c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.14,21.5c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.86,21.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.58,21.53c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.3,21.55c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.02,21.57c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.74,21.58c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.46,21.6c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.18,21.61c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.89,21.63c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.61,21.64c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.33,21.66c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.05,21.67c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.77,21.69c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.49,21.7c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.21,21.72c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.93,21.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.65,21.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.37,21.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.84,22.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.56,22.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.28,22.78c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254,22.79c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.72,22.81c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.44,22.82c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.16,22.84c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.88,22.85c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.6,22.87c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.32,22.89c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.03,22.9c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.75,22.92c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.47,22.93c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.19,22.95c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.91,22.96c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.63,22.98c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.35,22.99c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.07,23.01c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.79,23.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.51,23.04c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.22,23.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.94,23.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.66,23.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.86,24.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.58,24.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.3,24.11c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.02,24.13c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.74,24.14c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.46,24.16c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.17,24.17c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.89,24.19c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.61,24.21c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.33,24.22c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.05,24.24c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.77,24.25c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.49,24.27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.21,24.28c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.93,24.3c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.65,24.31c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.36,24.33c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.08,24.34c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.8,24.36c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.52,24.37c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.24,24.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.96,24.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.68,24.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.6,25.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.31,25.45c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.03,25.46c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.75,25.48c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.47,25.49c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.19,25.51c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.91,25.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.63,25.54c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.35,25.56c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.07,25.57c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.79,25.59c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.5,25.6c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.22,25.62c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.94,25.63c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.66,25.65c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.38,25.66c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.1,25.68c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.82,25.69c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.54,25.71c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.26,25.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.61,26.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.33,26.78c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.05,26.8c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.77,26.81c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.49,26.83c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.21,26.84c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.93,26.86c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.64,26.88c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.36,26.89c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.08,26.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.8,26.92c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.52,26.94c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.24,26.95c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.96,26.97c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.68,26.98c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.4,27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                          <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.12,27.01c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.83,27.03c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.55,27.04c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.27,27.06c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.35,28.12c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.07,28.13c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.78,28.15c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.5,28.16c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.22,28.18c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.94,28.2c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.66,28.21c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.38,28.23c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.1,28.24c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.82,28.26c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.54,28.27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.26,28.29c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.97,28.3c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.69,28.32c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.41,28.33c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.13,28.35c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.85,28.36c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.57,28.38c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.29,28.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.36,29.45c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.08,29.47c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.8,29.48c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.52,29.5c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.24,29.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.96,29.53c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.68,29.55c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.4,29.56c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.11,29.58c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.83,29.59c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.55,29.61c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.27,29.62c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m239.99,29.64c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.71,29.65c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.43,29.67c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.15,29.68c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.87,29.7c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.59,29.71c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.3,29.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.38,30.79c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.1,30.8c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.82,30.82c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.54,30.83c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.25,30.85c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.97,30.87c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.69,30.88c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.41,30.9c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.13,30.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.85,30.93c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.57,30.94c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.29,30.96c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m240.01,30.97c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.73,30.99c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.44,31c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                           <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.16,31.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.88,31.03c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.6,31.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.32,31.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m255.39,32.12c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.63,28.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.64,29.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.66,30.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m256.68,32.11c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.88,25.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.89,26.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m257.91,28.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m254.11,32.14c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.83,32.15c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.55,32.17c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.27,32.19c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m248.99,32.2c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.71,32.22c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.43,32.23c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.15,32.25c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.87,32.26c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.58,32.28c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.3,32.29c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m240.02,32.31c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.74,32.32c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.46,32.34c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.18,32.35c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.9,32.37c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.62,32.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m232.34,32.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.85,33.49c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.57,33.51c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.29,33.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m249.01,33.54c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.72,33.55c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.44,33.57c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.16,33.58c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.88,33.6c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.6,33.61c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.32,33.63c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m240.04,33.64c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.76,33.66c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.48,33.67c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.2,33.69c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.91,33.71c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.63,33.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.86,34.83c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.58,34.84c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.3,34.86c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m249.02,34.87c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.74,34.89c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.46,34.9c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.18,34.92c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.9,34.93c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.62,34.95c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.34,34.96c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m240.05,34.98c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.77,34.99c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.49,35.01c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.21,35.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.93,35.04c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.65,35.06c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.88,36.16c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.6,36.18c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.32,36.19c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m249.04,36.21c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.76,36.22c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.48,36.24c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.19,36.25c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.91,36.27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.63,36.28c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.35,36.3c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m240.07,36.31c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.79,36.33c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.51,36.34c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.23,36.36c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.95,36.38c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.67,36.39c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m252.9,37.5c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m251.62,37.51c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m250.33,37.53c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m249.05,37.54c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m247.77,37.56c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m246.49,37.57c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m245.21,37.59c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m243.93,37.6c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m242.65,37.62c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m241.37,37.63c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m240.09,37.65c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m238.81,37.66c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m237.52,37.68c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m236.24,37.7c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m234.96,37.71c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m233.68,37.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.98,25.74c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m230.99,27.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m231.01,28.41c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m231.02,29.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m231.04,31.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m231.06,32.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m252.91,38.83c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m251.63,38.85c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m250.35,38.86c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m249.07,38.88c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m247.79,38.89c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m246.51,38.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m245.23,38.92c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m243.95,38.94c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m242.66,38.95c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m241.38,38.97c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m240.1,38.98c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m238.82,39c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m237.54,39.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m236.26,39.03c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m234.98,39.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                            <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m233.7,39.06c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m252.93,40.17c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m251.65,40.18c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m250.37,40.2c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m249.09,40.21c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m247.8,40.23c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m246.52,40.24c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m245.24,40.26c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m243.96,40.27c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m242.68,40.29c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m241.4,40.3c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m240.12,40.32c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m238.84,40.33c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m237.56,40.35c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m236.28,40.37c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m234.99,40.38c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m259.35,41.42c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m258.07,41.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m256.79,41.45c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m255.51,41.47c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m254.23,41.49c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m252.94,41.5c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m251.66,41.52c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m250.38,41.53c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m249.1,41.55c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m247.82,41.56c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m246.54,41.58c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m245.26,41.59c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m243.98,41.61c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m242.7,41.62c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                             <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m241.42,41.64c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m240.13,41.65c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m238.85,41.67c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m237.57,41.69c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m236.29,41.7c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m235.01,41.72c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m259.37,42.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m258.08,42.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m256.8,42.79c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m255.52,42.81c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m254.24,42.82c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m252.96,42.84c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m251.68,42.85c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m250.4,42.87c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m249.12,42.88c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m247.84,42.9c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m246.56,42.91c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23s-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m245.27,42.93c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m243.99,42.94c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m242.71,42.96c.1.23.37.33.6.23s.33-.37.23-.6c-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m241.43,42.97c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m240.15,42.99c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m238.87,43.01c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m237.59,43.02c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m236.31,43.04c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m235.03,43.05c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m233.71,40.4c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m233.73,41.73c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m233.75,43.07c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m232.45,41.75c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m232.46,43.08c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m231.17,41.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path v-show="isSeatVisible === 'section249'" class="cls-19" d="m231.18,43.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.69,25.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.71,27.09c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.73,28.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.74,29.76c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.76,31.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m229.77,32.43c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.38,23.1c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.4,24.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.41,25.77c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.43,27.11c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m228.45,28.44c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m227.05,19.11c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m227.07,20.45c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/>
                              <path id="B1" @click="onSelectSeat('249','B1')" v-show="isSeatVisible === 'section249'" :class="{ 'selected': isSelected('B1') }" d="m227.08,21.78c.1.23.37.33.6.23.23-.1.33-.37.23-.6-.1-.23-.37-.33-.6-.23-.23.1-.33.37-.23.6Z"/> -->
                              
                          </g>
                          <g id="group250">
                              <polygon class="cls-9" points="261.65 8.08 262.22 43.13 262.22 45.59 289.27 45.52 289.22 5.93 261.54 5.93 261.65 8.08"/>
                              <polygon class="cls-22" points="261.65 8.08 261.05 8.09 261.61 43.14 261.61 46.2 289.88 46.12 289.82 5.32 260.9 5.32 261.05 8.11 261.65 8.08 261.05 8.09 261.65 8.08 262.26 8.05 262.18 6.53 288.61 6.53 288.66 44.91 262.82 44.99 262.82 43.13 262.26 8.06 262.26 8.05 261.65 8.08"/>
                              <text class="cls-5" transform="translate(270.46 30.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">250</tspan></text>
                          </g>
                              <g id="group251">

                                <polygon title="251" id="section251" @click="isSectionEnabled('251') ? handleZoom('section251') : null" :class="{ 'enabled': isSectionEnabled('251') , 'disabled': !isSectionEnabled('251') }" points="289.22 5.93 289.27 45.52 308.51 45.52 309.18 11.6 290.35 11.37 290.35 5.93 289.22 5.93"/>
                                <polygon title="251" id="section251" @click="isSectionEnabled('251') ? handleZoom('section251') : null" class="cls-22" points="289.22 5.93 288.61 5.93 288.67 46.12 309.1 46.12 309.8 11 290.96 10.77 290.96 5.32 288.61 5.32 288.61 5.93 289.22 5.93 289.22 6.53 289.75 6.53 289.75 11.97 308.56 12.2 307.91 44.91 289.87 44.91 289.82 5.93 289.22 5.93 289.22 6.53 289.22 5.93"/>
                                <text class="cls-5" transform="translate(292.46 30.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">251</tspan></text>
    
                                <!-- ROW B-->
                                <path id="B1" @click="onSelectSeat('251','B1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B1') }" d="m292.09,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="B2" @click="onSelectSeat('251','B2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B2') }" d="m293.72,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="B3" @click="onSelectSeat('251','B3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B3') }" d="m295.36,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="B4" @click="onSelectSeat('251','B4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B4') }" d="m296.99,40.79c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="B5" @click="onSelectSeat('251','B5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B5') }" d="m298.63,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="B6" @click="onSelectSeat('251','B6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B6') }" d="m300.26,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="B7" @click="onSelectSeat('251','B7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B7') }" d="m301.9,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="B8" @click="onSelectSeat('251','B8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B8') }" d="m303.53,40.79c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="B9" @click="onSelectSeat('251','B9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B9') }" d="m305.17,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="B10" @click="onSelectSeat('251','B10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('B10') }" d="m306.8,40.79c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW C-->
                                <path id="C1" @click="onSelectSeat('251','C1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C1') }" d="m292.09,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="C2" @click="onSelectSeat('251','C2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C2') }" d="m293.72,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="C3" @click="onSelectSeat('251','C3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C3') }" d="m295.36,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                                <path id="C4" @click="onSelectSeat('251','C4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C4') }" d="m296.99,39.45c-.1.23-.37.33-.6.23s-.33-.37-.23-.6.37-.33.6-.23.33.37.23.6Z"/>
                                <path id="C5" @click="onSelectSeat('251','C5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C5') }" d="m298.63,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="C6" @click="onSelectSeat('251','C6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C6') }" d="m300.26,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="C7" @click="onSelectSeat('251','C7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C7') }" d="m301.9,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                                <path id="C8" @click="onSelectSeat('251','C8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C8') }" d="m303.53,39.45c-.1.23-.37.33-.6.23s-.33-.37-.23-.6.37-.33.6-.23.33.37.23.6Z"/>
                                <path id="C9" @click="onSelectSeat('251','C9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C9') }" d="m305.17,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="C10" @click="onSelectSeat('251','C10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('C10') }" d="m306.8,39.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW D-->
                                <path id="D1" @click="onSelectSeat('251','D1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D1') }" d="m292.09,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D2" @click="onSelectSeat('251','D2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D2') }" d="m293.72,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D3" @click="onSelectSeat('251','D3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D3') }" d="m295.36,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D4" @click="onSelectSeat('251','D4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D4') }" d="m296.99,38.12c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D5" @click="onSelectSeat('251','D5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D5') }" d="m298.63,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D6" @click="onSelectSeat('251','D6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D6') }" d="m300.26,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D7" @click="onSelectSeat('251','D7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D7') }" d="m301.9,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D8" @click="onSelectSeat('251','D8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D8') }" d="m303.53,38.12c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D9" @click="onSelectSeat('251','D9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D9') }" d="m305.17,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="D10" @click="onSelectSeat('251','D10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('D10') }" d="m306.8,38.12c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW E-->
                                <path id="E1" @click="onSelectSeat('251','E1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E1') }" d="m292.09,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E2" @click="onSelectSeat('251','E2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E2') }" d="m293.72,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E3" @click="onSelectSeat('251','E3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E3') }" d="m295.36,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E4" @click="onSelectSeat('251','E4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E4') }" d="m296.99,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E5" @click="onSelectSeat('251','E5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E5') }" d="m298.63,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E6" @click="onSelectSeat('251','E6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E6') }" d="m300.26,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E7" @click="onSelectSeat('251','E7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E7') }" d="m301.9,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E8" @click="onSelectSeat('251','E8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E8') }" d="m303.53,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E9" @click="onSelectSeat('251','E9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E9') }" d="m305.17,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="E10" @click="onSelectSeat('251','E10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('E10') }" d="m306.8,36.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW F-->
                                <path id="F1" @click="onSelectSeat('251','F1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F1') }" d="m292.09,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="F2" @click="onSelectSeat('251','F2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F2') }" d="m293.72,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="F3" @click="onSelectSeat('251','F3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F3') }" d="m295.36,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="F4" @click="onSelectSeat('251','F4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F4') }" d="m296.99,35.45c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="F5" @click="onSelectSeat('251','F5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F5') }" d="m298.63,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="F6" @click="onSelectSeat('251','F6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F6') }" d="m300.26,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="F7" @click="onSelectSeat('251','F7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F7') }" d="m301.9,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="F8" @click="onSelectSeat('251','F8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F8') }" d="m303.53,35.45c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="F9" @click="onSelectSeat('251','F9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F9') }" d="m305.17,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="F10" @click="onSelectSeat('251','F10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('F10') }" d="m306.8,35.45c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW G -->
                                <path id="G1" @click="onSelectSeat('251','G1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G1') }" d="m292.09,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="G2" @click="onSelectSeat('251','G2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G2') }" d="m293.72,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="G3" @click="onSelectSeat('251','G3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G3') }" d="m295.36,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="G4" @click="onSelectSeat('251','G4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G4') }" d="m296.99,34.11c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="G5" @click="onSelectSeat('251','G5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G5') }" d="m298.63,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="G6" @click="onSelectSeat('251','G6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G6') }" d="m300.26,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="G7" @click="onSelectSeat('251','G7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G7') }" d="m301.9,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="G8" @click="onSelectSeat('251','G8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G8') }" d="m303.53,34.11c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="G9" @click="onSelectSeat('251','G9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G9') }" d="m305.17,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="G10" @click="onSelectSeat('251','G10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('G10') }" d="m306.8,34.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW H -->
                                <path id="H1" @click="onSelectSeat('251','H1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H1') }" d="m292.09,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H2" @click="onSelectSeat('251','H2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H2') }" d="m293.72,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H3" @click="onSelectSeat('251','H3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H3') }" d="m295.36,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H4" @click="onSelectSeat('251','H4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H4') }" d="m296.99,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H5" @click="onSelectSeat('251','H5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H5') }" d="m298.63,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H6" @click="onSelectSeat('251','H6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H6') }" d="m300.26,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H7" @click="onSelectSeat('251','H7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H7') }" d="m301.9,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H8" @click="onSelectSeat('251','H8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H8') }" d="m303.53,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H9" @click="onSelectSeat('251','H9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H9') }" d="m305.17,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="H10" @click="onSelectSeat('251','H10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('H10') }" d="m306.8,32.78c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW I -->
                                <path id="I1" @click="onSelectSeat('251','I1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I1') }" d="m292.09,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="I2" @click="onSelectSeat('251','I2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I2') }" d="m293.72,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="I3" @click="onSelectSeat('251','I3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I3') }" d="m295.36,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="I4" @click="onSelectSeat('251','I4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I4') }" d="m296.99,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="I5" @click="onSelectSeat('251','I5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I5') }" d="m298.63,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="I6" @click="onSelectSeat('251','I6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I6') }" d="m300.26,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="I7" @click="onSelectSeat('251','I7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I7') }" d="m301.9,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="I8" @click="onSelectSeat('251','I8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I8') }" d="m303.53,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="I9" @click="onSelectSeat('251','I9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I9') }" d="m305.17,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="I10" @click="onSelectSeat('251','I10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('I10') }" d="m306.8,31.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW J -->
                                <path id="J1" @click="onSelectSeat('251','J1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J1') }" d="m292.09,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="J2" @click="onSelectSeat('251','J2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J2') }" d="m293.72,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="J3" @click="onSelectSeat('251','J3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J3') }" d="m295.36,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="J4" @click="onSelectSeat('251','J4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J4') }" d="m296.99,30.11c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="J5" @click="onSelectSeat('251','J5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J5') }" d="m298.63,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="J6" @click="onSelectSeat('251','J6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J6') }" d="m300.26,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="J7" @click="onSelectSeat('251','J7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J7') }" d="m301.9,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="J8" @click="onSelectSeat('251','J8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J8') }" d="m303.53,30.11c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="J9" @click="onSelectSeat('251','J9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J9') }" d="m305.17,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="J10" @click="onSelectSeat('251','J10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('J10') }" d="m306.8,30.11c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW K -->
                                <path id="K1" @click="onSelectSeat('251','K1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K1') }" d="m292.09,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="K2" @click="onSelectSeat('251','K2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K2') }" d="m293.72,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="K3" @click="onSelectSeat('251','K3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K3') }" d="m295.36,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="K4" @click="onSelectSeat('251','K4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K4') }" d="m296.99,28.77c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="K5" @click="onSelectSeat('251','K5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K5') }" d="m298.63,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="K6" @click="onSelectSeat('251','K6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K6') }" d="m300.26,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="K7" @click="onSelectSeat('251','K7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K7') }" d="m301.9,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="K8" @click="onSelectSeat('251','K8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K8') }" d="m303.53,28.77c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="K9" @click="onSelectSeat('251','K9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K9') }" d="m305.17,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="K10" @click="onSelectSeat('251','K10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('K10') }" d="m306.8,28.77c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                
                                <!-- ROW L -->
                                <path id="L1" @click="onSelectSeat('251','L1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L1') }" d="m292.09,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L2" @click="onSelectSeat('251','L2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L2') }" d="m293.72,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L3" @click="onSelectSeat('251','L3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L3') }" d="m295.36,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L4" @click="onSelectSeat('251','L4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L4') }" d="m296.99,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L5" @click="onSelectSeat('251','L5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L5') }" d="m298.63,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L6" @click="onSelectSeat('251','L6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L6') }" d="m300.26,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L7" @click="onSelectSeat('251','L7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L7') }" d="m301.9,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L8" @click="onSelectSeat('251','L8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L8') }" d="m303.53,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L9" @click="onSelectSeat('251','L9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L9') }" d="m305.17,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="L10" @click="onSelectSeat('251','10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('L10') }" d="m306.8,27.44c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                               
                                <!-- ROW M -->
                                <path id="M1" @click="onSelectSeat('251','M1')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M1') }" d="m292.09,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="M2" @click="onSelectSeat('251','M2')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M2') }" d="m293.72,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="M3" @click="onSelectSeat('251','M3')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M3') }" d="m295.36,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="M4" @click="onSelectSeat('251','M4')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M4') }" d="m296.99,26.1c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="M5" @click="onSelectSeat('251','M5')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M5') }" d="m298.63,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="M6" @click="onSelectSeat('251','M6')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M6') }" d="m300.26,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="M7" @click="onSelectSeat('251','M7')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M7') }" d="m301.9,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="M8" @click="onSelectSeat('251','M8')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M8') }" d="m303.53,26.1c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                                <path id="M9" @click="onSelectSeat('251','M9')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M9') }" d="m305.17,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                <path id="M10" @click="onSelectSeat('251','M10')" v-show="isSeatVisible === 'section251'" :class="{ 'selected': isSelected('M10') }" d="m306.8,26.1c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                            
                              </g>

                              <g id="group252">
  
                              <polygon id="section252" @click="isSectionEnabled('252') ? handleZoom('section252') : null" :class="{ 'enabled': isSectionEnabled('252') , 'disabled': !isSectionEnabled('252') }" points="309.18 27.25 308.51 45.52 326.76 45.59 327.25 27.78 309.18 27.25"/>
                              <polygon class="cls-22" points="309.18 27.25 308.58 27.23 307.88 46.12 327.35 46.2 327.87 27.19 308.6 26.63 308.58 27.23 309.18 27.25 309.16 27.86 326.63 28.37 326.17 44.99 309.14 44.91 309.79 27.27 309.18 27.25 309.16 27.86 309.18 27.25"/>
                              <text class="cls-5" transform="translate(310.46 40.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">252</tspan></text>
  
                              <path id="A1" @click="onSelectSeat('252','A1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A1') }" d="m309.88,41.66c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z" />
                              <path id="A2" @click="onSelectSeat('252','A2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A2') }" d="m311.13,41.68c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A3" @click="onSelectSeat('252','A3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A3') }" d="m312.39,41.7c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A4" @click="onSelectSeat('252','A4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A4') }" d="m313.64,41.72c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="A5" @click="onSelectSeat('252','A5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A5') }" d="m314.9,41.74c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="A6" @click="onSelectSeat('252','A6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A6') }" d="m316.15,41.77c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="A7" @click="onSelectSeat('252','A7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A7') }" d="m317.4,41.79c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A8" @click="onSelectSeat('252','A8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A8') }" d="m318.66,41.81c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A9" @click="onSelectSeat('252','A9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A9') }" d="m319.89,41.83c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="A10" @click="onSelectSeat('252','A10')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A10') }" d="m321.14,41.85c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A11" @click="onSelectSeat('252','A11')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A11') }" d="m322.4,41.88c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A12" @click="onSelectSeat('252','A12')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A12') }" d="m323.65,41.9c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="A13" @click="onSelectSeat('252','A13')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('A13') }" d="m324.9,41.92c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              
                              <!-- ROW B-->
                              <path id="B1" @click="onSelectSeat('252','B1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B1') }" d="m309.9,40.35c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="B2" @click="onSelectSeat('252','B2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B2') }" d="m311.16,40.37c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24c-.23.09-.33.35-.24.58Z"/>
                              <path id="B3" @click="onSelectSeat('252','B3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B3') }" d="m312.41,40.39c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="B4" @click="onSelectSeat('252','B4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B4') }" d="m313.66,40.41c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="B5" @click="onSelectSeat('252','B5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B5') }" d="m314.92,40.44c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="B6" @click="onSelectSeat('252','B6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B6') }" d="m316.17,40.46c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="B7" @click="onSelectSeat('252','B7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B7') }" d="m317.43,40.48c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="B8" @click="onSelectSeat('252','B8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B8') }" d="m318.68,40.5c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="B9" @click="onSelectSeat('252','B9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('B9') }" d="m319.91,40.52c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
  
                              <!-- ROW C -->
                              <path id="C1" @click="onSelectSeat('252','C1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C1') }" d="m309.92,39.04c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="C2" @click="onSelectSeat('252','C2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C2') }" d="m311.18,39.06c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="C3" @click="onSelectSeat('252','C3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C3') }" d="m312.43,39.09c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="C4" @click="onSelectSeat('252','C4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C4') }" d="m313.69,39.11c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="C5" @click="onSelectSeat('252','C5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C5') }" d="m314.94,39.13c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="C6" @click="onSelectSeat('252','C6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C6') }" d="m316.2,39.15c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="C7" @click="onSelectSeat('252','C7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C7') }" d="m317.45,39.17c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="C8" @click="onSelectSeat('252','C8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C8') }" d="m318.7,39.2c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="C9" @click="onSelectSeat('252','C9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('C9') }" d="m319.93,39.22c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24c-.23.09-.33.35-.24.58Z"/>
  
                              <!-- ROW D -->
                              <path id="D1" @click="onSelectSeat('252','D1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D1') }" d="m309.95,37.73c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="D2" @click="onSelectSeat('252','D2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D2') }" d="m311.2,37.76c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="D3" @click="onSelectSeat('252','D3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D3') }" d="m312.46,37.78c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="D4" @click="onSelectSeat('252','D4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D4') }" d="m313.71,37.8c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="D5" @click="onSelectSeat('252','D5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D5') }" d="m314.96,37.82c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="D6" @click="onSelectSeat('252','D6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D6') }" d="m316.22,37.84c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="D7" @click="onSelectSeat('252','D7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D7') }" d="m317.47,37.87c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="D8" @click="onSelectSeat('252','D8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D8') }" d="m318.73,37.89c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="D9" @click="onSelectSeat('252','D9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('D9') }" d="m319.96,37.91c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
  
                              <!-- ROW E -->
                              <path id="E1" @click="onSelectSeat('252','E1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E1') }" d="m309.97,36.43c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="E2" @click="onSelectSeat('252','E2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E2') }" d="m311.22,36.45c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="E3" @click="onSelectSeat('252','E3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E3') }" d="m312.48,36.47c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="E4" @click="onSelectSeat('252','E4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E4') }" d="m313.73,36.49c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="E5" @click="onSelectSeat('252','E5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E5') }" d="m314.99,36.52c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="E6" @click="onSelectSeat('252','E6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E6') }" d="m316.24,36.54c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="E7" @click="onSelectSeat('252','E7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E7') }" d="m317.5,36.56c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="E8" @click="onSelectSeat('252','E8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E8') }" d="m318.75,36.58c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="E9" @click="onSelectSeat('252','E9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('E9') }" d="m319.98,36.6c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
  
                              <!-- ROW F -->
                              <path id="F1" @click="onSelectSeat('252','F1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F1') }" d="m309.99,35.12c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F2" @click="onSelectSeat('252','F2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F2') }" d="m311.25,35.14c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="F3" @click="onSelectSeat('252','F3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F3') }" d="m312.5,35.16c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F4" @click="onSelectSeat('252','F4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F4') }" d="m313.76,35.19c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F5" @click="onSelectSeat('252','F5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F5') }" d="m315.01,35.21c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F6" @click="onSelectSeat('252','F6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F6') }" d="m316.26,35.23c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F7" @click="onSelectSeat('252','F7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F7') }" d="m317.52,35.25c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F8" @click="onSelectSeat('252','F8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F8') }" d="m318.77,35.27c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="F9" @click="onSelectSeat('252','F9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('F9') }" d="m320,35.3c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              
                              <!-- ROW G -->
                              <path id="G1" @click="onSelectSeat('252','G1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G1') }" d="m310.02,33.81c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="G2" @click="onSelectSeat('252','G2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G2') }" d="m311.27,33.84c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="G3" @click="onSelectSeat('252','G3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G3') }" d="m312.52,33.86c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="G4" @click="onSelectSeat('252','G4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G4') }" d="m313.78,33.88c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="G5" @click="onSelectSeat('252','G5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G5') }" d="m315.03,33.9c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="G6" @click="onSelectSeat('252','G6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G6') }" d="m316.29,33.92c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24c-.23.09-.33.35-.24.58Z"/>
                              <path id="G7" @click="onSelectSeat('252','G7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G7') }" d="m317.54,33.95c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="G8" @click="onSelectSeat('252','G8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G8') }" d="m318.8,33.97c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="G9" @click="onSelectSeat('252','G9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('G9') }" d="m320.03,33.99c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              
                              <!-- ROW H -->
                              <path id="H1" @click="onSelectSeat('252','H1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H1') }" d="m310.04,32.51c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="H2" @click="onSelectSeat('252','H2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H2') }" d="m311.29,32.53c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="H3" @click="onSelectSeat('252','H3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H3') }" d="m312.55,32.55c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="H4" @click="onSelectSeat('252','H4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H4') }" d="m313.8,32.57c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H5" @click="onSelectSeat('252','H5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H5') }" d="m315.06,32.59c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H6" @click="onSelectSeat('252','H6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H6') }" d="m316.31,32.62c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H7" @click="onSelectSeat('252','H7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H7') }" d="m317.56,32.64c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H8" @click="onSelectSeat('252','H8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H8') }" d="m318.82,32.66c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H9" @click="onSelectSeat('252','H9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H9') }" d="m320.05,32.68c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H10" @click="onSelectSeat('252','H10')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H10') }" d="m321.3,32.7c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H11" @click="onSelectSeat('252','H11')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H11') }" d="m322.56,32.73c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="H12" @click="onSelectSeat('252','H12')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H12') }" d="m323.81,32.75c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="H13" @click="onSelectSeat('252','H13')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('H13') }" d="m325.07,32.77c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24c-.23.09-.33.35-.24.58Z"/>
                              
                              <!-- ROW I -->
                              <path id="I1" @click="onSelectSeat('252','I1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I1') }" d="m310.06,31.2c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I2" @click="onSelectSeat('252','I2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I2') }" d="m311.32,31.22c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I3" @click="onSelectSeat('252','I3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I3') }" d="m312.57,31.24c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I4" @click="onSelectSeat('252','I4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I4') }" d="m313.82,31.27c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I5" @click="onSelectSeat('252','I5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I5') }" d="m315.08,31.29c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="I6" @click="onSelectSeat('252','I6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I6') }" d="m316.33,31.31c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="I7" @click="onSelectSeat('252','I7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I7') }" d="m317.59,31.33c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="I8" @click="onSelectSeat('252','I8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I8') }" d="m318.84,31.35c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I9" @click="onSelectSeat('252','I9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I9') }" d="m320.07,31.37c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="I10" @click="onSelectSeat('252','I10')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I10') }" d="m321.33,31.4c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24c-.23.09-.33.35-.24.58Z"/>
                              <path id="I11" @click="onSelectSeat('252','I11')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I11') }" d="m322.58,31.42c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I12" @click="onSelectSeat('252','I12')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I12') }" d="m323.83,31.44c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="I13" @click="onSelectSeat('252','I13')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('I13') }" d="m325.09,31.46c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              
                              <!-- ROW J -->
                              <path id="J1" @click="onSelectSeat('252','J1')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J1') }" d="m310.09,29.89c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="J2" @click="onSelectSeat('252','J2')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J2') }" d="m311.34,29.91c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24c-.23.09-.33.35-.24.58Z"/>
                              <path id="J3" @click="onSelectSeat('252','J3')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J3') }" d="m312.59,29.94c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
                              <path id="J4" @click="onSelectSeat('252','J4')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J4') }" d="m313.85,29.96c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J5" @click="onSelectSeat('252','J5')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J5') }" d="m315.1,29.98c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J6" @click="onSelectSeat('252','J6')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J6') }" d="m316.36,30c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J7" @click="onSelectSeat('252','J7')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J7') }" d="m317.61,30.02c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J8" @click="onSelectSeat('252','J8')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J8') }" d="m318.86,30.05c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J9" @click="onSelectSeat('252','J9')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J9') }" d="m320.1,30.07c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J10" @click="onSelectSeat('252','J10')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J10') }" d="m321.35,30.09c.09.23.35.33.58.24.23-.09.33-.35.24-.58-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J11" @click="onSelectSeat('252','J11')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J11') }" d="m322.6,30.11c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24-.23.09-.33.35-.24.58Z"/>
                              <path id="J12" @click="onSelectSeat('252','J12')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J12') }" d="m323.86,30.13c.09.23.35.33.58.24s.33-.35.24-.58c-.09-.23-.35-.33-.58-.24s-.33.35-.24.58Z"/>
                              <path id="J13" @click="onSelectSeat('252','J13')" v-show="isSeatVisible === 'section252'" :class="{ 'selected': isSelected('J13') }" d="m325.11,30.16c.09.23.35.33.58.24.23-.09.33-.35.24-.58s-.35-.33-.58-.24-.33.35-.24.58Z"/>
         
                              </g>

                               <g id="group253">
  
                                  <polygon id="section253" @click="isSectionEnabled('253') ? handleZoom('section253') : null" :class="{ 'enabled': isSectionEnabled('253') , 'disabled': !isSectionEnabled('253') }" points="327.25 27.78 326.76 45.59 370.85 47.17 371.19 28.92 327.25 27.78"/>
                                  <polygon class="cls-22" points="327.25 27.78 326.65 27.77 326.14 46.18 371.45 47.8 371.81 28.33 326.66 27.16 326.65 27.77 327.25 27.78 327.24 28.39 370.58 29.51 370.26 46.54 327.38 45.01 327.86 27.8 327.25 27.78 327.24 28.39 327.25 27.78"/>
                                  <text class="cls-5" transform="translate(342.46 40.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">253</tspan></text>
                                  
                                  <path id="J1" @click="onSelectSeat('253','J1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J1') }" d="m329.36,30.16c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J2" @click="onSelectSeat('253','J2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J2') }" d="m330.56,30.19c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="J3" @click="onSelectSeat('253','J3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J3') }" d="m331.77,30.21c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J4" @click="onSelectSeat('253','J4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J4') }" d="m332.97,30.24c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J5" @click="onSelectSeat('253','J5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J5') }" d="m334.17,30.27c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J6" @click="onSelectSeat('253','J6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J6') }" d="m335.37,30.3c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J7" @click="onSelectSeat('253','J7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J7') }" d="m336.57,30.33c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="J8" @click="onSelectSeat('253','J8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J8') }" d="m337.78,30.36c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="J9" @click="onSelectSeat('253','J9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J9') }" d="m338.98,30.39c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J10" @click="onSelectSeat('253','J10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J10') }" d="m340.18,30.41c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="J11" @click="onSelectSeat('253','J11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J11') }" d="m341.38,30.44c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J12" @click="onSelectSeat('253','J12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J12') }" d="m342.58,30.47c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J13" @click="onSelectSeat('253','J13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J13') }" d="m343.79,30.5c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="J14" @click="onSelectSeat('253','J14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J14') }" d="m344.99,30.53c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J15" @click="onSelectSeat('253','J15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J15') }" d="m346.19,30.56c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="J16" @click="onSelectSeat('253','J16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J16') }" d="m347.39,30.59c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="J17" @click="onSelectSeat('253','J17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J17') }" d="m348.59,30.61c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J18" @click="onSelectSeat('253','J18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J18') }" d="m349.79,30.64c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="J19" @click="onSelectSeat('253','J19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J19') }" d="m351,30.67c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J20" @click="onSelectSeat('253','J20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J20') }" d="m352.2,30.7c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J21" @click="onSelectSeat('253','J21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J21') }" d="m353.4,30.73c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="J22" @click="onSelectSeat('253','J22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J22') }" d="m354.6,30.76c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J23" @click="onSelectSeat('253','J23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J23') }" d="m355.8,30.78c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="J24" @click="onSelectSeat('253','J24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J24') }" d="m357.01,30.81c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J25" @click="onSelectSeat('253','J25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J25') }" d="m358.21,30.84c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J26" @click="onSelectSeat('253','J26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J26') }" d="m359.41,30.87c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="J27" @click="onSelectSeat('253','J27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J27') }" d="m360.59,30.9c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>  
                                  <path id="J28" @click="onSelectSeat('253','J28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J28') }" d="m361.79,30.93c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J29" @click="onSelectSeat('253','J29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J29') }" d="m362.99,30.96c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="J30" @click="onSelectSeat('253','J30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J30') }" d="m364.2,30.98c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J31" @click="onSelectSeat('253','J31')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J31') }" d="m365.4,31.01c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="J32" @click="onSelectSeat('253','J32')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J32') }" d="m366.6,31.04c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="J33" @click="onSelectSeat('253','J33')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J33') }" d="m367.8,31.07c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="J34" @click="onSelectSeat('253','J34')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('J34') }" d="m369,31.1c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>

                                  <path id="I1" @click="onSelectSeat('253','I1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I1') }" d="m329.33,31.41c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I2" @click="onSelectSeat('253','I2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I2') }" d="m330.53,31.44c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I3" @click="onSelectSeat('253','I3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I3') }" d="m331.74,31.47c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I4" @click="onSelectSeat('253','I4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I4') }" d="m332.94,31.5c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I5" @click="onSelectSeat('253','I5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I5') }" d="m334.14,31.52c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I6" @click="onSelectSeat('253','I6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I6') }" d="m335.34,31.55c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I7" @click="onSelectSeat('253','I7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I7') }" d="m336.54,31.58c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="I8" @click="onSelectSeat('253','I8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I8') }" d="m337.75,31.61c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I9" @click="onSelectSeat('253','I9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I9') }" d="m338.95,31.64c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I10" @click="onSelectSeat('253','I10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I10') }" d="m340.15,31.67c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="I11" @click="onSelectSeat('253','I11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I11') }" d="m341.35,31.7c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I12" @click="onSelectSeat('253','I12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I12') }" d="m342.55,31.72c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I13" @click="onSelectSeat('253','I13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I13') }" d="m343.76,31.75c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I14" @click="onSelectSeat('253','I14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I14') }" d="m344.96,31.78c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I15" @click="onSelectSeat('253','I15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I15') }" d="m346.16,31.81c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="I16" @click="onSelectSeat('253','I16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I16') }" d="m347.36,31.84c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I17" @click="onSelectSeat('253','I17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I17') }" d="m348.56,31.87c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I18" @click="onSelectSeat('253','I18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I18') }" d="m349.77,31.9c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="I19" @click="onSelectSeat('253','I19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I19') }" d="m350.97,31.92c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I20" @click="onSelectSeat('253','I20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I20') }" d="m352.17,31.95c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I21" @click="onSelectSeat('253','I21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I21') }" d="m353.37,31.98c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I22" @click="onSelectSeat('253','I22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I22') }" d="m354.57,32.01c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I23" @click="onSelectSeat('253','I23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I23') }" d="m355.77,32.04c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="I24" @click="onSelectSeat('253','I24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I24') }" d="m356.98,32.07c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I25" @click="onSelectSeat('253','I25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I25') }" d="m358.18,32.09c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I26" @click="onSelectSeat('253','I26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I26') }" d="m359.38,32.12c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="I27" @click="onSelectSeat('253','I27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I27') }" d="m360.56,32.15c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>                              
                                  <path id="I28" @click="onSelectSeat('253','I28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I28') }" d="m361.76,32.18c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I29" @click="onSelectSeat('253','I29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I29') }" d="m362.96,32.21c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I30" @click="onSelectSeat('253','I30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I30') }" d="m364.17,32.24c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="I31" @click="onSelectSeat('253','I31')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I31') }" d="m365.37,32.27c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I32" @click="onSelectSeat('253','I32')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I32') }" d="m366.57,32.29c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="I33" @click="onSelectSeat('253','I33')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I33') }" d="m367.77,32.32c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="I34" @click="onSelectSeat('253','I34')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('I34') }" d="m368.97,32.35c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  

                                  <path id="H1" @click="onSelectSeat('253','H1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H1') }" d="m329.3,32.66c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H2" @click="onSelectSeat('253','H2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H2') }" d="m330.5,32.69c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H3" @click="onSelectSeat('253','H3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H3') }" d="m331.71,32.72c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H4" @click="onSelectSeat('253','H4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H4') }" d="m332.91,32.75c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H5" @click="onSelectSeat('253','H5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H5') }" d="m334.11,32.78c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="H6" @click="onSelectSeat('253','H6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H6') }" d="m335.31,32.81c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H7" @click="onSelectSeat('253','H7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H7') }" d="m336.51,32.83c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H8" @click="onSelectSeat('253','H8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H8') }" d="m337.72,32.86c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H9" @click="onSelectSeat('253','H9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H9') }" d="m338.92,32.89c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H10" @click="onSelectSeat('253','H10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H10') }" d="m340.12,32.92c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="H11" @click="onSelectSeat('253','H11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H11') }" d="m341.32,32.95c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H12" @click="onSelectSeat('253','H12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H12') }" d="m342.52,32.98c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H13" @click="onSelectSeat('253','H13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H13') }" d="m343.73,33.01c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="H14" @click="onSelectSeat('253','H14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H14') }" d="m344.93,33.03c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H15" @click="onSelectSeat('253','H15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H15') }" d="m346.13,33.06c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H16" @click="onSelectSeat('253','H16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H16') }" d="m347.33,33.09c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="H17" @click="onSelectSeat('253','H17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H17') }" d="m348.53,33.12c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H18" @click="onSelectSeat('253','H18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H18') }" d="m349.74,33.15c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H19" @click="onSelectSeat('253','H19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H19') }" d="m350.94,33.18c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="H20" @click="onSelectSeat('253','H20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H20') }" d="m352.14,33.2c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H21" @click="onSelectSeat('253','H21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H21') }" d="m353.34,33.23c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H22" @click="onSelectSeat('253','H22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H22') }" d="m354.54,33.26c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H23" @click="onSelectSeat('253','H23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H23') }" d="m355.74,33.29c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H24" @click="onSelectSeat('253','H24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H24') }" d="m356.95,33.32c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="H25" @click="onSelectSeat('253','H25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H25') }" d="m358.15,33.35c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H26" @click="onSelectSeat('253','H26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H26') }" d="m359.35,33.38c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H27" @click="onSelectSeat('253','H27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H27') }" d="m360.53,33.4c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H28" @click="onSelectSeat('253','H28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H28') }" d="m361.73,33.43c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H29" @click="onSelectSeat('253','H29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H29') }" d="m362.93,33.46c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H30" @click="onSelectSeat('253','H30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H30') }" d="m364.14,33.49c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="H31" @click="onSelectSeat('253','H31')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H31') }" d="m365.34,33.52c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H32" @click="onSelectSeat('253','H32')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H32') }" d="m366.54,33.55c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="H33" @click="onSelectSeat('253','H33')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H33') }" d="m367.74,33.57c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="H34" @click="onSelectSeat('253','H34')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('H34') }" d="m368.94,33.6c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  
                                  <path id="G1" @click="onSelectSeat('253','G1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G1') }" d="m329.27,33.92c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G2" @click="onSelectSeat('253','G2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G2') }" d="m330.47,33.94c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="G3" @click="onSelectSeat('253','G3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G3') }" d="m331.68,33.97c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G4" @click="onSelectSeat('253','G4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G4') }" d="m332.88,34c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G5" @click="onSelectSeat('253','G5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G5') }" d="m334.08,34.03c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G6" @click="onSelectSeat('253','G6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G6') }" d="m335.28,34.06c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G7" @click="onSelectSeat('253','G7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G7') }" d="m336.48,34.09c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G8" @click="onSelectSeat('253','G8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G8') }" d="m337.69,34.12c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G9" @click="onSelectSeat('253','G9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G9') }" d="m338.89,34.14c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G10" @click="onSelectSeat('253','G10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G10') }" d="m340.09,34.17c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="G11" @click="onSelectSeat('253','G11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G11') }" d="m341.29,34.2c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G12" @click="onSelectSeat('253','G12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G12') }" d="m342.49,34.23c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G13" @click="onSelectSeat('253','G13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G13') }" d="m343.7,34.26c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G14" @click="onSelectSeat('253','G14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G14') }" d="m344.9,34.29c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G15" @click="onSelectSeat('253','G15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G15') }" d="m346.1,34.32c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="G16" @click="onSelectSeat('253','G16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G16') }" d="m352.11,34.46c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G17" @click="onSelectSeat('253','G17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G17') }" d="m353.31,34.49c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G18" @click="onSelectSeat('253','G18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G18') }" d="m354.51,34.51c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G19" @click="onSelectSeat('253','G19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G19') }" d="m355.72,34.54c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G20" @click="onSelectSeat('253','G20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G20') }" d="m356.92,34.57c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G21" @click="onSelectSeat('253','G21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G21') }" d="m358.12,34.6c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G22" @click="onSelectSeat('253','G22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G22') }" d="m359.32,34.63c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="G23" @click="onSelectSeat('253','G23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G23') }" d="m360.5,34.66c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G24" @click="onSelectSeat('253','G24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G24') }" d="m361.7,34.69c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G25" @click="onSelectSeat('253','G25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G25') }" d="m362.9,34.71c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="G26" @click="onSelectSeat('253','G26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G26') }" d="m364.11,34.74c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G27" @click="onSelectSeat('253','G27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G27') }" d="m365.31,34.77c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="G28" @click="onSelectSeat('253','G28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G28') }" d="m366.51,34.8c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="G29" @click="onSelectSeat('253','G29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G29') }" d="m367.71,34.83c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="G30" @click="onSelectSeat('253','G30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('G30') }" d="m368.91,34.86c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                

                                  <path id="F1" @click="onSelectSeat('253','F1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F1') }" d="m329.24,35.17c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F2" @click="onSelectSeat('253','F2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F2') }" d="m330.45,35.2c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="F3" @click="onSelectSeat('253','F3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F3') }" d="m331.65,35.23c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F4" @click="onSelectSeat('253','F4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F4') }" d="m332.85,35.25c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F5" @click="onSelectSeat('253','F5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F5') }" d="m334.05,35.28c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F6" @click="onSelectSeat('253','F6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F6') }" d="m335.25,35.31c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/> 
                                  <path id="F7" @click="onSelectSeat('253','F7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F7') }" d="m336.45,35.34c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="F8" @click="onSelectSeat('253','F8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F8') }" d="m337.66,35.37c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="F9" @click="onSelectSeat('253','F9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F9') }" d="m338.86,35.4c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F10" @click="onSelectSeat('253','F10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F10') }" d="m340.06,35.43c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="F11" @click="onSelectSeat('253','F11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F11') }" d="m341.26,35.45c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F12" @click="onSelectSeat('253','F12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F12') }" d="m342.46,35.48c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F13" @click="onSelectSeat('253','F13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F13') }" d="m343.67,35.51c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="F14" @click="onSelectSeat('253','F14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F14') }" d="m344.87,35.54c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F15" @click="onSelectSeat('253','F15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F15') }" d="m346.07,35.57c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F16" @click="onSelectSeat('253','F16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F16') }" d="m352.08,35.71c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F17" @click="onSelectSeat('253','F17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F17') }" d="m353.28,35.74c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="F18" @click="onSelectSeat('253','F18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F18') }" d="m354.48,35.77c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F19" @click="onSelectSeat('253','F19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F19') }" d="m355.69,35.8c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F20" @click="onSelectSeat('253','F20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F20') }" d="m356.89,35.82c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F21" @click="onSelectSeat('253','F21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F21') }" d="m358.09,35.85c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F22" @click="onSelectSeat('253','F22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F22') }" d="m359.29,35.88c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="F23" @click="onSelectSeat('253','F23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F23') }" d="m360.47,35.91c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="F24" @click="onSelectSeat('253','F24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F24') }" d="m361.67,35.94c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F25" @click="onSelectSeat('253','F25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F25') }" d="m362.88,35.97c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="F26" @click="onSelectSeat('253','F26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F26') }" d="m364.08,35.99c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F27" @click="onSelectSeat('253','F27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F27') }" d="m365.28,36.02c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="F28" @click="onSelectSeat('253','F28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F28') }" d="m366.48,36.05c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="F29" @click="onSelectSeat('253','F29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F29') }" d="m367.68,36.08c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="F30" @click="onSelectSeat('253','F30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('F30') }" d="m368.88,36.11c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  

                                  <path id="E1" @click="onSelectSeat('253','E1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E1') }" d="m329.21,36.42c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E2" @click="onSelectSeat('253','E2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E2') }" d="m330.42,36.45c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="E3" @click="onSelectSeat('253','E3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E3') }" d="m331.62,36.48c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E4" @click="onSelectSeat('253','E4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E4') }" d="m332.82,36.51c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E5" @click="onSelectSeat('253','E5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E5') }" d="m334.02,36.54c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E6" @click="onSelectSeat('253','E6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E6') }" d="m335.22,36.56c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E7" @click="onSelectSeat('253','E7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E7') }" d="m336.43,36.59c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="E8" @click="onSelectSeat('253','E8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E8') }" d="m337.63,36.62c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E9" @click="onSelectSeat('253','E9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E9') }" d="m338.83,36.65c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E10" @click="onSelectSeat('253','E10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E10') }" d="m340.03,36.68c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E11" @click="onSelectSeat('253','E11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E11') }" d="m341.23,36.71c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E12" @click="onSelectSeat('253','E12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E12') }" d="m342.43,36.74c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E13" @click="onSelectSeat('253','E13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E13') }" d="m343.64,36.76c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E14" @click="onSelectSeat('253','E14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E14') }" d="m344.84,36.79c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E15" @click="onSelectSeat('253','E15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E15') }" d="m346.04,36.82c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="E16" @click="onSelectSeat('253','E16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E16') }" d="m352.05,36.96c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E17" @click="onSelectSeat('253','E17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E17') }" d="m353.25,36.99c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E18" @click="onSelectSeat('253','E18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E18') }" d="m354.45,37.02c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E19" @click="onSelectSeat('253','E19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E19') }" d="m355.66,37.05c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E20" @click="onSelectSeat('253','E20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E20') }" d="m356.86,37.08c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E21" @click="onSelectSeat('253','E21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E21') }" d="m358.06,37.11c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E22" @click="onSelectSeat('253','E22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E22') }" d="m359.26,37.13c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="E23" @click="onSelectSeat('253','E23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E23') }" d="m360.44,37.16c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E24" @click="onSelectSeat('253','E24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E24') }" d="m361.64,37.19c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E25" @click="onSelectSeat('253','E25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E25') }" d="m362.85,37.22c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E26" @click="onSelectSeat('253','E26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E26') }" d="m364.05,37.25c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="E27" @click="onSelectSeat('253','E27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E27') }" d="m365.25,37.28c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E28" @click="onSelectSeat('253','E28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E28') }" d="m366.45,37.3c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="E29" @click="onSelectSeat('253','E29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E29') }" d="m367.65,37.33c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="E30" @click="onSelectSeat('253','E30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('E30') }" d="m368.85,37.36c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  

                                  <path id="D1" @click="onSelectSeat('253','D1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D1') }" d="m329.18,37.67c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D2" @click="onSelectSeat('253','D2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D2') }" d="m330.39,37.7c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="D3" @click="onSelectSeat('253','D3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D3') }" d="m331.59,37.73c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D4" @click="onSelectSeat('253','D4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D4') }" d="m332.79,37.76c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D5" @click="onSelectSeat('253','D5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D5') }" d="m333.99,37.79c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="D7" @click="onSelectSeat('253','D7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D7') }" d="m336.4,37.85c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="D8" @click="onSelectSeat('253','D8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D8') }" d="m337.6,37.87c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D9" @click="onSelectSeat('253','D9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D9') }" d="m338.8,37.9c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D10" @click="onSelectSeat('253','D10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D10') }" d="m340,37.93c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="D11" @click="onSelectSeat('253','D11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D11') }" d="m341.2,37.96c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="D12" @click="onSelectSeat('253','D12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D12') }" d="m342.41,37.99c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D13" @click="onSelectSeat('253','D13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D13') }" d="m343.61,38.02c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="D14" @click="onSelectSeat('253','D14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D14') }" d="m344.81,38.04c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D15" @click="onSelectSeat('253','D15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D15') }" d="m346.01,38.07c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="D16" @click="onSelectSeat('253','D16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D16') }" d="m352.02,38.22c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D17" @click="onSelectSeat('253','D17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D17') }" d="m353.22,38.24c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D18" @click="onSelectSeat('253','D18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D18') }" d="m354.42,38.27c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D19" @click="onSelectSeat('253','D19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D19') }" d="m355.63,38.3c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D20" @click="onSelectSeat('253','D20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D20') }" d="m356.83,38.33c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="D21" @click="onSelectSeat('253','D21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D21') }" d="m358.03,38.36c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D22" @click="onSelectSeat('253','D22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D22') }" d="m359.23,38.39c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="D23" @click="onSelectSeat('253','D23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D23') }" d="m360.41,38.41c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D24" @click="onSelectSeat('253','D24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D24') }" d="m361.61,38.44c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D25" @click="onSelectSeat('253','D25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D25') }" d="m362.82,38.47c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="D26" @click="onSelectSeat('253','D26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D26') }" d="m364.02,38.5c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="D27" @click="onSelectSeat('253','D27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D27') }" d="m365.22,38.53c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D28" @click="onSelectSeat('253','D28')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D28') }" d="m366.42,38.56c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="D29" @click="onSelectSeat('253','D29')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D29') }" d="m367.62,38.59c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="D30" @click="onSelectSeat('253','D30')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('D30') }" d="m368.83,38.61c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  
                                  <path id="C1" @click="onSelectSeat('253','C1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C1') }" d="m329.15,38.93c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C2" @click="onSelectSeat('253','C2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C2') }" d="m330.36,38.96c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="C3" @click="onSelectSeat('253','C3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C3') }" d="m331.56,38.98c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C4" @click="onSelectSeat('253','C4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C4') }" d="m332.76,39.01c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C5" @click="onSelectSeat('253','C5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C5') }" d="m333.96,39.04c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="C6" @click="onSelectSeat('253','C6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C6') }" d="m335.16,39.07c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C7" @click="onSelectSeat('253','C7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C7') }" d="m336.37,39.1c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C8" @click="onSelectSeat('253','C8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C8') }" d="m337.57,39.13c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="C9" @click="onSelectSeat('253','C9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C9') }" d="m338.77,39.16c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C10" @click="onSelectSeat('253','C10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C10') }" d="m339.97,39.18c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="C11" @click="onSelectSeat('253','C11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C11') }" d="m341.17,39.21c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="C12" @click="onSelectSeat('253','C12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C12') }" d="m356.8,39.58c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C13" @click="onSelectSeat('253','C13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C13') }" d="m358,39.61c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C14" @click="onSelectSeat('253','C14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C14') }" d="m359.2,39.64c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="C15" @click="onSelectSeat('253','C15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C15') }" d="m360.38,39.67c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="C16" @click="onSelectSeat('253','C16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C16') }" d="m361.58,39.7c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C17" @click="onSelectSeat('253','C17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C17') }" d="m362.79,39.72c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="C18" @click="onSelectSeat('253','C18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C18') }" d="m363.99,39.75c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="C19" @click="onSelectSeat('253','C19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C19') }" d="m365.19,39.78c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="C20" @click="onSelectSeat('253','C20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C20') }" d="m366.39,39.81c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="C21" @click="onSelectSeat('253','C21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C21') }" d="m367.59,39.84c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="C22" @click="onSelectSeat('253','C22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('C22') }" d="m368.8,39.87c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  
                                  <path id="B1" @click="onSelectSeat('253','B1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B1') }" d="m329.12,40.18c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B2" @click="onSelectSeat('253','B2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B2') }" d="m330.33,40.21c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="B3" @click="onSelectSeat('253','B3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B3') }" d="m331.53,40.24c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B4" @click="onSelectSeat('253','B4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B4') }" d="m332.73,40.27c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B5" @click="onSelectSeat('253','B5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B5') }" d="m333.93,40.29c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B6" @click="onSelectSeat('253','B6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B6') }" d="m335.13,40.32c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B7" @click="onSelectSeat('253','B7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B7') }" d="m336.34,40.35c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="B8" @click="onSelectSeat('253','B8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B8') }" d="m337.54,40.38c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="B9" @click="onSelectSeat('253','B9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B9') }" d="m338.74,40.41c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B10" @click="onSelectSeat('253','B10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B10') }" d="m339.94,40.44c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="B11" @click="onSelectSeat('253','B11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B11') }" d="m341.14,40.46c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B12" @click="onSelectSeat('253','B12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B12') }" d="m356.77,40.84c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B13" @click="onSelectSeat('253','B13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B13') }" d="m357.97,40.86c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B14" @click="onSelectSeat('253','B14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B14') }" d="m359.17,40.89c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="B15" @click="onSelectSeat('253','B15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B15') }" d="m360.35,40.92c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="B16" @click="onSelectSeat('253','B16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B16') }" d="m361.55,40.95c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B17" @click="onSelectSeat('253','B17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B17') }" d="m362.76,40.98c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="B18" @click="onSelectSeat('253','B18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B18') }" d="m363.96,41.01c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B19" @click="onSelectSeat('253','B19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B19') }" d="m365.16,41.03c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="B20" @click="onSelectSeat('253','B20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B20') }" d="m366.36,41.06c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="B21" @click="onSelectSeat('253','B21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B21') }" d="m367.56,41.09c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="B22" @click="onSelectSeat('253','B22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('B22') }" d="m368.77,41.12c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  
                                  <!-- <circle class="cls-19" cx="328.69" cy="41.33" r=".44"/> -->
                                  <path id="A1" @click="onSelectSeat('253','A1')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A1') }" d="m329.09,41.43c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A2" @click="onSelectSeat('253','A2')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A2') }" d="m330.3,41.46c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57s.35-.31.57-.21.31.35.21.57Z"/>
                                  <path id="A3" @click="onSelectSeat('253','A3')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A3') }" d="m331.5,41.49c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A4" @click="onSelectSeat('253','A4')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A4') }" d="m332.7,41.52c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A5" @click="onSelectSeat('253','A5')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A5') }" d="m333.9,41.55c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="A6" @click="onSelectSeat('253','A6')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A6') }" d="m335.1,41.58c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A7" @click="onSelectSeat('253','A7')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A7') }" d="m336.31,41.6c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="A8" @click="onSelectSeat('253','A8')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A8') }" d="m337.51,41.63c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="A9" @click="onSelectSeat('253','A9')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A9') }" d="m338.71,41.66c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A10" @click="onSelectSeat('253','A10')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A10') }" d="m339.91,41.69c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A11" @click="onSelectSeat('253','A11')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A11') }" d="m341.11,41.72c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  
                                  <circle id="A12" @click="onSelectSeat('253','A12')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A12') }" cx="345.57" cy="42.69" r=".44"/>
                                  <circle id="A13" @click="onSelectSeat('253','A13')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A13') }" cx="347.29" cy="42.72" r=".44"/>
                                  <circle id="A14" @click="onSelectSeat('253','A14')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A14') }" cx="348.92" cy="42.75" r=".44"/>
                                  <circle id="A15" @click="onSelectSeat('253','A15')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A15') }" cx="350.55" cy="42.79" r=".44"/>
                                  <circle id="A16" @click="onSelectSeat('253','A16')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A16') }" cx="352.18" cy="42.83" r=".44"/>
                                  <path id="A17" @click="onSelectSeat('253','A17')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A17') }" d="m356.74,42.09c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="A18" @click="onSelectSeat('253','A18')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A18') }" d="m357.94,42.12c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A19" @click="onSelectSeat('253','A19')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A19') }" d="m359.14,42.15c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="A20" @click="onSelectSeat('253','A20')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A20') }" d="m360.32,42.17c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="A21" @click="onSelectSeat('253','A21')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A21') }" d="m361.52,42.2c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A22" @click="onSelectSeat('253','A22')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A22') }" d="m362.73,42.23c-.1.21-.35.31-.57.21s-.31-.35-.21-.57c.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A23" @click="onSelectSeat('253','A23')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A23') }" d="m363.93,42.26c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="A24" @click="onSelectSeat('253','A24')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A24') }" d="m365.13,42.29c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                  <path id="A25" @click="onSelectSeat('253','A25')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A25') }" d="m366.33,42.32c-.1.21-.35.31-.57.21s-.31-.35-.21-.57.35-.31.57-.21c.21.1.31.35.21.57Z"/>
                                  <path id="A26" @click="onSelectSeat('253','A26')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A26') }" d="m367.53,42.34c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21s.31.35.21.57Z"/>
                                  <path id="A27" @click="onSelectSeat('253','A27')" v-show="isSeatVisible === 'section253'" :class="{ 'selected': isSelected('A27') }" d="m368.74,42.37c-.1.21-.35.31-.57.21-.21-.1-.31-.35-.21-.57.1-.21.35-.31.57-.21.21.1.31.35.21.57Z"/>
                                 
                              </g>
                            <g id="stadiumsquare">  

                              <polygon class="cls-8" points="309.19 16.04 309.18 27.25 390.17 29.29 390.14 18 387.34 17.95 309.19 16.04"/>
                              <polygon class="cls-23" points="34.82 276.81 34.52 276.86 40.96 315.44 51.4 354.28 69.59 389.05 95.27 418.15 128.92 440.65 168.46 453.61 206.62 458.38 236.81 458.38 263.58 458.38 286.95 458.38 309.86 458.38 340.72 458.38 370.66 458.38 393.8 458.38 415.36 458.38 437.6 458.38 467.56 456.33 500.94 453.83 537.31 441.56 573.21 420.89 602.76 394.06 623.22 358.14 633.44 324.06 638.67 287.04 640.48 252.77 640.48 216.91 638.89 181.49 633.22 146.74 622.08 112.66 604.13 79.93 577.08 53.11 545.72 32.2 512.08 19.02 474.37 13.11 436.92 10.84 408.93 9.7 408.93 14.46 389.87 14.01 389.87 28.98 309.48 26.96 309.48 11.3 290.65 11.07 290.65 5.62 261.53 5.62 226.13 6.76 187.07 10.62 148 22.21 113.9 39.03 85.25 66.08 65.03 98.12 50.72 130.37 40.95 164.9 36.41 201.69 34.52 276.83 34.52 276.86 34.82 276.81 35.12 276.81 37.02 201.74 41.55 165.02 51.29 130.58 65.57 98.4 85.73 66.47 114.25 39.53 148.22 22.77 187.19 11.22 226.17 7.36 261.54 6.23 290.05 6.23 290.05 11.67 308.88 11.9 308.88 27.55 390.48 29.6 390.48 14.63 409.53 15.08 409.53 10.33 436.89 11.45 474.31 13.71 511.92 19.61 545.44 32.74 576.69 53.58 603.64 80.3 621.53 112.9 632.63 146.88 638.29 181.55 639.88 216.92 639.88 252.75 638.06 286.99 632.85 323.93 622.66 357.9 602.28 393.68 572.85 420.39 537.06 441.01 500.82 453.24 467.51 455.73 467.51 455.73 437.58 457.77 415.36 457.77 393.8 457.77 370.66 457.77 340.72 457.77 309.86 457.77 286.95 457.77 263.58 457.77 236.81 457.77 206.66 457.77 168.59 453.01 129.18 440.1 95.67 417.69 70.09 388.71 51.97 354.06 41.55 315.31 35.12 276.76 34.82 276.81 35.12 276.81 34.82 276.81"/>
                            </g>
                            <g id="group140">

                            <!-- <path class="cls-19" d="m204.44,55.5c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m204.29,54.28c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m205.61,55.35c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.78,55.2c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.95,55.05c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.11,54.9c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.28,54.75c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.45,54.6c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.62,54.45c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.79,54.31c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.96,54.16c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.12,54.01c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.29,53.86c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.46,53.71c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.63,53.56c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.8,53.41c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.97,53.26c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.13,53.11c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.3,52.96c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.47,52.82c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.64,52.67c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.81,52.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.98,52.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.14,52.22c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.31,52.07c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.48,51.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.65,51.77c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.16,50.85c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.33,50.7c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.49,50.55c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m205.45,54.13c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.62,53.98c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.79,53.83c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.96,53.68c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.13,53.54c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.3,53.39c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.46,53.24c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.63,53.09c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.8,52.94c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.97,52.79c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.14,52.64c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.31,52.49c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.47,52.34c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.64,52.19c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.81,52.04c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.98,51.9c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.15,51.75c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.32,51.6c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.48,51.45c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.65,51.3c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.82,51.15c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.99,51c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m205.06,51.07c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m203.89,51.22c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.23,50.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.4,50.77c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.57,50.62c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.74,50.47c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.91,50.32c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.07,50.17c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.24,50.02c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.41,49.87c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.58,49.73c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.75,49.58c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.92,49.43c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.08,49.28c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.25,49.13c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.42,48.98c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.59,48.83c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.76,48.68c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.93,48.53c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.09,48.38c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.26,48.23c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.43,48.09c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.6,47.94c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.77,47.79c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.94,47.64c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.1,47.49c-.06.22-.3.35-.52.28s-.35-.3-.28-.52c.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m204.6,56.72c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m205.77,56.57c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.93,56.42c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.1,56.27c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.27,56.12c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.44,55.97c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.61,55.82c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.78,55.67c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.94,55.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.11,55.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.28,55.23c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.45,55.08c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.62,54.93c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.79,54.78c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.95,54.63c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.12,54.48c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.29,54.33c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.46,54.18c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.63,54.03c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.8,53.88c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.96,53.74c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.13,53.59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.3,53.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.47,53.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.64,53.14c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m205.92,57.78c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.09,57.64c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.26,57.49c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.43,57.34c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.59,57.19c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.76,57.04c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.93,56.89c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.1,56.74c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.27,56.59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.44,56.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.6,56.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.77,56.15c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.94,56c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.11,55.85c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.28,55.7c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.45,55.55c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.61,55.4c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.78,55.25c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.95,55.1c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.12,54.95c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.29,54.8c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.46,54.65c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.62,54.51c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.79,54.36c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.08,59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.24,58.85c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.41,58.7c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.58,58.56c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.75,58.41c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.92,58.26c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.09,58.11c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.25,57.96c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.42,57.81c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.59,57.66c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.76,57.51c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.93,57.36c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>  
                            <path class="cls-19" d="m220.1,57.21c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.26,57.06c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.43,56.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.6,56.77c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.77,56.62c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.94,56.47c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.11,56.32c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.27,56.17c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.44,56.02c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.61,55.87c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.78,55.72c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.95,55.57c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.23,60.22c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.4,60.07c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.57,59.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.74,59.77c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m210.9,59.62c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.07,59.48c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.24,59.33c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.41,59.18c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.58,59.03c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.75,58.88c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.91,58.73c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.08,58.58c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.25,58.43c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.42,58.28c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.59,58.13c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.76,57.98c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.92,57.84c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.09,57.69c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.26,57.54c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.43,57.39c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.6,57.24c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.77,57.09c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.93,56.94c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m206.39,61.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m207.55,61.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m208.72,61.14c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m209.89,60.99c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.06,60.84c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.23,60.69c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.4,60.54c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.56,60.39c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.73,60.25c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m216.9,60.1c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.07,59.95c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.24,59.8c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.41,59.65c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.57,59.5c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.74,59.35c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m223.91,59.2c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.08,59.05c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.25,58.9c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.42,58.76c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.59,58.61c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.75,58.46c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m230.92,58.31c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.09,58.16c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m211.22,62.06c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m212.38,61.91c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m213.55,61.76c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m214.72,61.61c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m215.89,61.46c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m217.06,61.31c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m218.23,61.17c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m219.39,61.02c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m220.56,60.87c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m221.73,60.72c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m222.9,60.57c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m224.07,60.42c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m225.24,60.27c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m226.4,60.12c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m227.57,59.97c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m228.74,59.82c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m229.91,59.67c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.71,62.98c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.54,63.13c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.88,62.83c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.04,62.68c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.21,62.53c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.38,62.38c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.55,62.23c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.72,62.09c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.89,61.94c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.05,61.79c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.22,61.64c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.39,61.49c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.56,61.34c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.73,61.19c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.9,61.04c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.06,60.89c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.86,64.2c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.03,64.05c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.2,63.9c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.37,63.75c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.54,63.6c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.7,63.45c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.87,63.3c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.04,63.15c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.21,63c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.38,62.86c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.55,62.71c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.71,62.56c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.88,62.41c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.05,62.26c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.22,62.11c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.02,65.41c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.19,65.27c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.35,65.12c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.52,64.97c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.69,64.82c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.86,64.67c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.03,64.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.2,64.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.36,64.22c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.53,64.07c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.7,63.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.87,63.78c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.04,63.63c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.21,63.48c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.37,63.33c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.17,66.63c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.34,66.48c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.51,66.33c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.68,66.19c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.85,66.04c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.02,65.89c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.18,65.74c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.35,65.59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.52,65.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.69,65.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.86,65.14c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.03,64.99c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.19,64.84c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.36,64.69c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.53,64.55c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.33,67.85c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.5,67.7c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.67,67.55c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.83,67.4c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219,67.25c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.17,67.11c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.34,66.96c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.51,66.81c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.68,66.66c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.84,66.51c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.01,66.36c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.18,66.21c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.35,66.06c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.52,65.91c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.69,65.76c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m209.81,69.66c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m210.98,69.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.15,69.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.32,69.22c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.48,69.07c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.65,68.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.82,68.77c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.99,68.62c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.16,68.47c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.33,68.32c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.49,68.17c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.66,68.02c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.83,67.88c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225,67.73c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.17,67.58c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.34,67.43c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.5,67.28c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.67,67.13c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.84,66.98c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m209.97,70.88c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m211.13,70.73c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.3,70.58c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.47,70.43c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.64,70.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.81,70.14c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.98,69.99c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.14,69.84c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.31,69.69c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.48,69.54c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.65,69.39c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.82,69.24c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.99,69.09c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.15,68.94c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.32,68.8c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.49,68.65c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.66,68.5c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.83,68.35c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231,68.2c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m210.12,72.1c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m211.29,71.95c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.46,71.8c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.63,71.65c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.79,71.5c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.96,71.35c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.13,71.21c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.3,71.06c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.47,70.91c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.64,70.76c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.8,70.61c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.97,70.46c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.14,70.31c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.31,70.16c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.48,70.01c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.65,69.86c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.81,69.72c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.98,69.57c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.15,69.42c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m210.28,73.32c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m210.43,74.54c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m211.45,73.17c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.61,73.02c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.78,72.87c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.95,72.72c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.12,72.57c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.29,72.42c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.46,72.27c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.62,72.13c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.79,71.98c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.96,71.83c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.13,71.68c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.3,71.53c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.47,71.38c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.63,71.23c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.8,71.08c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.97,70.93c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.14,70.78c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.31,70.63c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m231.08,59.53c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.23,60.74c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.39,61.96c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.54,63.18c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.7,64.4c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.85,65.61c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.01,66.83c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.16,68.05c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.32,69.27c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.48,70.49c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m211.6,74.39c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.77,74.24c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.94,74.09c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.11,73.94c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.27,73.79c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.44,73.64c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.61,73.49c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.78,73.34c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.95,73.19c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.12,73.04c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.28,72.9c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.45,72.75c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.62,72.6c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.79,72.45c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.96,72.3c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.13,72.15c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.29,72c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.46,71.85c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.63,71.7c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m211.76,75.6c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.92,75.46c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.09,75.31c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.26,75.16c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.43,75.01c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.6,74.86c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.77,74.71c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.93,74.56c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.1,74.41c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.27,74.26c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.44,74.11c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.61,73.96c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.78,73.82c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.94,73.67c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.11,73.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.28,73.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m211.91,76.82c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.08,76.67c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.25,76.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.42,76.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.58,76.23c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.75,76.08c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m218.92,75.93c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.09,75.78c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.26,75.63c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.43,75.48c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.59,75.33c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.76,75.18c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m225.93,75.03c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.1,74.88c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.27,74.74c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.44,74.59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m203.07,54.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m202.68,51.37c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m201.52,51.52c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m212.07,78.04c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m213.23,77.89c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m214.4,77.74c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m215.57,77.59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m216.74,77.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m217.91,77.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m219.08,77.15c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m220.24,77c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m221.41,76.85c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m222.58,76.7c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m223.75,76.55c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m224.92,76.4c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m226.09,76.25c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m227.25,76.1c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m228.42,75.95c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m229.59,75.8c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.45,73.22c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.6,74.44c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m230.76,75.65c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.62,73.07c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.77,74.29c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m231.93,75.51c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.79,72.92c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.94,74.14c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.1,75.36c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m232.25,59.38c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.4,60.59c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.56,61.81c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28c.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.71,63.03c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m232.87,64.25c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52s.3-.35.52-.28.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.02,65.47c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.18,66.68c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.33,67.9c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.49,69.12c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.64,70.34c-.06.22-.3.35-.52.28s-.35-.3-.28-.52c.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.8,71.55c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.95,72.77c-.06.22-.3.35-.52.28s-.35-.3-.28-.52c.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.11,73.99c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.27,75.21c-.06.22-.3.35-.52.28s-.35-.3-.28-.52c.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.1,56.79c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.26,58.01c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.41,59.23c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.57,60.45c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.72,61.66c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m233.88,62.88c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.04,64.1c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.19,65.32c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.35,66.53c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.5,67.75c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.66,68.97c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.81,70.19c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28s.35.3.28.52Z"/>
                            <path class="cls-15" d="m234.97,71.41c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m235.12,72.62c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m235.28,73.84c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-15" d="m235.43,75.06c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.81,52.99c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m233.96,54.21c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m234.12,55.43c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m234.27,56.64c-.06.22-.3.35-.52.28s-.35-.3-.28-.52c.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/>
                            <path class="cls-19" d="m234.43,57.86c-.06.22-.3.35-.52.28-.22-.06-.35-.3-.28-.52.06-.22.3-.35.52-.28.22.06.35.3.28.52Z"/> -->
                            
                            </g>
                            <g id="group139">  
                              <polygon class="cls-9" points="197.9 48.4 207.57 80.51 176.46 90.32 159.56 62.08 169.54 57.09 197.9 48.4"/>
                              <polygon class="cls-22" points="197.9 48.4 197.32 48.58 206.82 80.11 176.74 89.6 160.41 62.33 169.77 57.65 198.07 48.98 197.9 48.4 197.32 48.58 197.9 48.4 197.72 47.82 169.32 56.52 158.7 61.83 176.19 91.04 208.32 80.91 198.3 47.65 197.72 47.82 197.9 48.4"/>
                              <text class="cls-5" transform="translate(175.46 70.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">139</tspan></text>
<!--                            
                            <path class="cls-19" d="m194.73,55.82c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.56,56.22c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.38,56.63c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.21,57.03c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.04,57.44c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.86,57.85c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.69,58.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.51,58.66c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.34,59.06c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.17,59.47c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.99,59.88c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.82,60.28c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.64,60.69c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.47,61.09c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.3,61.5c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.12,61.91c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.95,62.31c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.77,62.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.6,63.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.43,63.53c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.25,63.94c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.08,64.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m168.91,64.75c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m167.73,65.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m166.56,65.56c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m167.31,63.93c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m166.13,64.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.13,55c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.96,55.4c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.79,55.81c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.61,56.22c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.44,56.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.26,57.03c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.09,57.43c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.92,57.84c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.74,58.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.57,58.65c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.39,59.06c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.22,59.46c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.05,59.87c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.87,60.28c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.7,60.68c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.53,61.09c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.35,61.49c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.18,61.9c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172,62.31c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.83,62.71c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169.66,63.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m168.48,63.53c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.29,52.55c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.46,52.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.11,52.96c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.94,53.36c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.77,53.77c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.59,54.18c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.42,54.58c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.24,54.99c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.07,55.39c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.9,55.8c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.72,56.21c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.55,56.61c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.37,57.02c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.2,57.42c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.03,57.83c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.85,58.24c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.68,58.64c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.51,59.05c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.33,59.45c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.16,59.86c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169.98,60.27c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m168.81,60.67c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m167.64,61.08c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m166.46,61.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m165.29,61.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m164.11,62.3c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.15,57.04c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.98,57.45c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.81,57.85c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.63,58.26c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.46,58.66c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.28,59.07c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.11,59.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.94,59.88c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.76,60.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.59,60.69c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.42,61.1c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.24,61.51c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.07,61.91c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.89,62.32c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.72,62.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.55,63.13c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.37,63.54c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.2,63.94c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.02,64.35c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.85,64.75c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.68,65.16c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.5,65.57c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169.33,65.97c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m168.15,66.38c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m166.98,66.78c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m194.4,58.67c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.23,59.08c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.06,59.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.88,59.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.71,60.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.53,60.7c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.36,61.11c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.19,61.51c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.01,61.92c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.84,62.32c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.66,62.73c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.49,63.14c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.32,63.54c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.14,63.95c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.97,64.35c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.8,64.76c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.62,65.17c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.45,65.57c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.27,65.98c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.1,66.38c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.93,66.79c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169.75,67.2c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m168.58,67.6c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m167.4,68.01c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m194.83,59.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.65,60.3c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.48,60.7c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.3,61.11c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.13,61.52c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.96,61.92c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.78,62.33c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.61,62.73c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.44,63.14c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.26,63.55c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.09,63.95c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.91,64.36c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.74,64.76c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.57,65.17c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.39,65.58c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.22,65.98c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.04,66.39c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.87,66.8c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.7,67.2c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.52,67.61c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.35,68.01c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.18,68.42c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169,68.83c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.25,61.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m194.08,61.52c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.9,61.93c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.73,62.33c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.55,62.74c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.38,63.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.21,63.55c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.03,63.96c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.86,64.36c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.68,64.77c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.51,65.18c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.34,65.58c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.16,65.99c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.99,66.39c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.82,66.8c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.64,67.21c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.47,67.61c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.29,68.02c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.12,68.42c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.95,68.83c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.77,69.24c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.6,69.64c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169.42,70.05c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.67,62.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.58,58.26c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m196,59.49c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m196.42,60.71c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m196.85,61.93c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.95,55.4c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m196.37,56.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m196.79,57.84c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m197.22,59.07c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03s-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m197.64,60.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m198.06,61.51c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03s-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m194.5,62.75c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.33,63.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.15,63.56c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.98,63.96c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.8,64.37c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m188.63,64.78c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.46,65.18c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.28,65.59c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.11,65.99c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.93,66.4c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.76,66.81c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m181.59,67.21c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.41,67.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.24,68.02c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.06,68.43c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.89,68.84c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m175.72,69.24c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.54,69.65c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.37,70.05c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.2,70.46c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.02,70.87c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m169.85,71.27c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m189.05,66c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m187.88,66.41c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m186.71,66.81c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m185.53,67.22c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m184.36,67.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m183.18,68.03c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m182.01,68.44c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m180.84,68.84c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m179.66,69.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m178.49,69.65c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m177.31,70.06c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m176.14,70.47c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m174.97,70.87c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m173.79,71.28c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m172.62,71.68c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.48,67.22c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.3,67.63c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.13,68.03c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.95,68.44c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.78,68.85c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.61,69.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.43,69.66c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.26,70.07c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.09,70.47c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.91,70.88c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.74,71.28c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.56,71.69c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.39,72.1c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m174.22,72.5c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m173.04,72.91c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.9,68.45c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.73,68.85c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.55,69.26c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.38,69.66c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.2,70.07c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.03,70.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.86,70.88c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.68,71.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.51,71.69c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.33,72.1c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.16,72.51c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.99,72.91c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.81,73.32c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m174.64,73.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m173.47,74.13c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.32,69.67c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.15,70.08c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.97,70.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.8,70.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.63,71.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.45,71.7c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.28,72.11c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.11,72.51c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.93,72.92c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.76,73.32c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.58,73.73c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.41,74.14c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.24,74.54c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.06,74.95c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m173.89,75.35c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.75,70.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.57,71.3c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.4,71.71c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.22,72.11c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.05,72.52c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.88,72.92c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.7,73.33c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.53,73.74c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.35,74.14c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.18,74.55c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.01,74.95c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.83,75.36c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.66,75.77c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.49,76.17c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m174.31,76.58c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.17,72.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m190.23,65.59c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.65,66.82c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.07,68.04c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.5,69.26c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.92,70.49c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.34,71.71c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m191.4,65.19c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.82,66.41c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.25,67.63c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.67,68.86c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.09,70.08c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.52,71.31c.16.18.44.19.62.03s.19-.44.03-.62c-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m192.57,64.78c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193,66c.16.18.44.19.62.03s.19-.44.03-.62c-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.42,67.23c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.84,68.45c.16.18.44.19.62.03s.19-.44.03-.62c-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.27,69.68c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.69,70.9c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m193.75,64.38c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.17,65.6c.16.18.44.19.62.03s.19-.44.03-.62c-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190,72.52c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.82,72.93c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.65,73.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.47,73.74c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.3,74.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.13,74.55c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.95,74.96c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.78,75.37c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.6,75.77c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.43,76.18c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.26,76.58c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.08,76.99c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.91,77.4c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m174.73,77.8c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.29,71.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.11,72.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.94,72.53c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.77,72.93c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.59,73.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.42,73.75c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.24,74.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.07,74.56c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.9,74.96c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.72,75.37c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.55,75.78c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.37,76.18c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.2,76.59c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.03,76.99c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.85,77.4c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.68,77.81c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.51,78.21c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.33,78.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.16,79.02c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.71,72.94c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.54,73.35c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.36,73.75c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.19,74.16c.16.18.44.19.62.03s.19-.44.03-.62c-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.02,74.56c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.84,74.97c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.67,75.38c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.49,75.78c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.32,76.19c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.15,76.59c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.97,77c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.8,77.41c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.62,77.81c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.45,78.22c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.28,78.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.1,79.03c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.93,79.44c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.75,79.84c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m175.58,80.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.13,74.16c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.96,74.57c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.79,74.98c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.61,75.38c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.44,75.79c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.26,76.19c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.09,76.6c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.92,77.01c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.74,77.41c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.57,77.82c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.4,78.22c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.22,78.63c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.05,79.04c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.87,79.44c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.7,79.85c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.53,80.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.35,80.66c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.18,81.07c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176,81.47c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.56,75.39c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.98,76.61c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.46,71.31c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.88,72.53c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m198.31,73.76c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m198.73,74.98c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.15,76.2c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.38,75.79c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.21,76.2c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.04,76.61c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.86,77.01c.16.18.44.19.62.03s.19-.44.03-.62c-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.69,77.42c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.51,77.82c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.34,78.23c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.17,78.64c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.99,79.04c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.82,79.45c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.64,79.85c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.47,80.26c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.3,80.67c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.12,81.07c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.95,81.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.78,81.88c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m177.6,82.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.43,82.7c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m171.44,72.09c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m171.87,73.31c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.81,77.02c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.63,77.42c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.46,77.83c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.28,78.23c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.11,78.64c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.94,79.05c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.76,79.45c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.59,79.86c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.42,80.26c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.24,80.67c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.07,81.08c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.89,81.48c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.72,81.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.55,82.29c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.37,82.7c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.2,83.11c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.02,83.51c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m176.85,83.92c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.23,78.24c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.06,78.65c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.88,79.05c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.71,79.46c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.53,79.86c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.36,80.27c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.19,80.68c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.01,81.08c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.84,81.49c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.66,81.89c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.49,82.3c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.32,82.71c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.14,83.11c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.97,83.52c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.8,83.92c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m179.62,84.33c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m197.65,79.46c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.48,79.87c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.31,80.28c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.13,80.68c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.96,81.09c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.78,81.49c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m190.61,81.9c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.44,82.31c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.26,82.71c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.09,83.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.91,83.52c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m184.74,83.93c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.57,84.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.39,84.74c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.22,85.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.04,85.55c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.52,54.17c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m194.68,51.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m195.85,51.32c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m198.08,80.69c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m198.4,77.83c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m198.83,79.06c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.25,80.28c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.58,77.43c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m200,78.65c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m200.42,79.88c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m198.64,70.9c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.06,72.13c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.48,73.35c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.9,74.58c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m200.33,75.8c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m200.75,77.02c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m201.17,78.25c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m201.6,79.47c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m199.81,70.5c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m200.23,71.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m200.66,72.95c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m201.08,74.17c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m201.5,75.39c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m201.93,76.62c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m202.35,77.84c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m202.77,79.06c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m196.9,81.09c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m195.73,81.5c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m194.55,81.91c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m193.38,82.31c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m192.21,82.72c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m191.03,83.12c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m189.86,83.53c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m188.69,83.94c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m187.51,84.34c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m186.34,84.75c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m185.16,85.15c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m183.99,85.56c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m182.82,85.97c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m181.64,86.37c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m180.47,86.78c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.45,84.74c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-15" d="m178.87,85.96c.16.18.44.19.62.03.18-.16.19-.44.03-.62-.16-.18-.44-.19-.62-.03-.18.16-.19.44-.03.62Z"/>
                            <path class="cls-19" d="m170.27,72.5c.16.18.44.19.62.03.18-.16.19-.44.03-.62s-.44-.19-.62-.03c-.18.16-.19.44-.03.62Z"/> -->
                            
                            </g>
                            <g id="group138" >  
                              <polygon class="cls-9" points="159.56 62.08 175.1 88.28 152.87 105.98 124.58 86.12 141.98 70.36 159.56 62.08"/>
                              <polygon class="cls-22" points="159.56 62.08 159.04 62.38 174.31 88.13 152.85 105.22 125.55 86.06 142.32 70.87 159.81 62.62 159.56 62.08 159.04 62.38 159.56 62.08 159.3 61.53 141.64 69.85 123.61 86.18 152.89 106.73 175.89 88.42 159.8 61.29 159.3 61.53 159.56 62.08"/>
                              <text class="cls-5" transform="translate(140.46 88.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">138</tspan></text>
                           
                            <!-- <path class="cls-19" d="m155.54,68.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.68,67.79c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.56,69.63c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.59,70.46c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.61,71.29c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.63,72.11c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.65,72.94c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>                  
                            <path class="cls-19" d="m149.67,73.76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.69,74.59c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.71,75.42c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.73,76.24c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.75,77.07c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.77,77.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.79,78.72c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.81,79.55c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.83,80.37c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.86,81.2c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.88,82.02c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.9,82.85c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.92,83.68c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m136.94,84.5c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m135.96,85.33c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m134.98,86.15c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m134,86.98c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m133.02,87.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m132.16,86.79c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m131.18,87.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.7,68.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.72,69.44c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.75,70.27c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.77,71.09c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.79,71.92c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.81,72.74c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.83,73.57c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.85,74.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.87,75.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.89,76.05c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.91,76.87c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.93,77.7c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.95,78.53c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.97,79.35c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.99,80.18c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.02,81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.04,81.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.06,82.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m136.08,83.48c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m135.1,84.31c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m134.12,85.13c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m133.14,85.96c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.98,66.57c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.96,65.75c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151,67.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.02,68.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.04,69.05c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.06,69.88c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.09,70.7c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.11,71.53c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.13,72.35c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.15,73.18c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.17,74.01c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.19,74.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.21,75.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.23,76.48c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.25,77.31c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.27,78.14c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.29,78.96c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.4,69.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.43,70.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.45,71.48c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.47,72.31c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.49,73.13c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.51,73.96c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.53,74.78c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.55,75.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.57,76.44c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.59,77.26c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.61,78.09c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.63,78.91c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.65,79.74c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.67,80.57c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.7,81.39c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.72,82.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.74,83.04c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.76,83.87c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.78,84.7c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.8,85.52c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m136.82,86.35c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m135.84,87.17c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m134.86,88c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m133.88,88.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.29,71.68c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.31,72.5c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.33,73.33c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.35,74.15c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.37,74.98c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.39,75.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.41,76.63c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.43,77.46c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.45,78.28c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.47,79.11c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.49,79.94c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.51,80.76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.54,81.59c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.56,82.41c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.58,83.24c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.6,84.07c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.62,84.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.64,85.72c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.66,86.54c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.68,87.37c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m136.7,88.2c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m135.72,89.02c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m134.74,89.85c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m157.15,72.7c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.17,73.52c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.19,74.35c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.21,75.17c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.23,76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.25,76.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.27,77.65c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.29,78.48c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.31,79.3c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.33,80.13c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.35,80.96c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.38,81.78c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.4,82.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.42,83.43c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.44,84.26c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.46,85.09c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.48,85.91c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.5,86.74c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.52,87.56c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.54,88.39c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.56,89.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m136.58,90.04c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m135.6,90.87c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m158.01,73.72c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m157.03,74.54c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.05,75.37c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.07,76.19c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.09,77.02c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.11,77.85c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.13,78.67c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.15,79.5c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.17,80.32c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.2,81.15c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.22,81.98c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.24,82.8c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.26,83.63c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.28,84.45c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.3,85.28c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.32,86.11c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.34,86.93c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.36,87.76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.38,88.58c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.4,89.41c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.42,90.24c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.44,91.06c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m136.46,91.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m158.87,74.74c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m157.27,70.85c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m158.13,71.87c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m158.99,72.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m159.85,73.91c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.56,67.95c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m157.42,68.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m158.28,69.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m159.14,71.01c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m157.89,75.56c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.91,76.39c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.93,77.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.95,78.04c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.97,78.87c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.99,79.69c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.01,80.52c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.04,81.35c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.06,82.17c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.08,83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.1,83.82c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.12,84.65c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.14,85.48c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.16,86.3c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.18,87.13c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.2,87.95c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.22,88.78c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m141.24,89.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m140.26,90.43c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m139.28,91.26c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m138.31,92.08c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m137.33,92.91c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.85,80.71c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m152.88,81.54c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m151.9,82.37c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m150.92,83.19c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m149.94,84.02c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m148.96,84.84c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147.98,85.67c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m147,86.5c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m146.02,87.32c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m145.04,88.15c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m144.06,88.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m143.08,89.8c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m142.1,90.63c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.72,81.73c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.74,82.56c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.76,83.39c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.78,84.21c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.8,85.04c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.82,85.86c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.84,86.69c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.86,87.52c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m146.88,88.34c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m145.9,89.17c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m144.92,89.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m143.94,90.82c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.58,82.76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.6,83.58c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.62,84.41c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.64,85.23c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.66,86.06c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.68,86.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.7,87.71c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.72,88.54c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.74,89.36c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m146.76,90.19c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m145.78,91.02c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m144.8,91.84c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.44,83.78c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.46,84.6c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.48,85.43c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.5,86.25c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.52,87.08c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.54,87.91c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.56,88.73c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.58,89.56c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.6,90.38c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.62,91.21c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m146.64,92.04c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m145.67,92.86c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.3,84.8c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.32,85.62c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.34,86.45c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.36,87.27c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.38,88.1c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.4,88.93c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.42,89.75c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.44,90.58c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.46,91.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.49,92.23c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.51,93.06c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m146.53,93.88c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.16,85.82c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m154.83,79.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.69,80.91c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.56,81.93c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.42,82.95c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.28,83.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.14,84.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.81,79.06c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.67,80.08c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.53,81.1c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.4,82.12c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.26,83.14c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.12,84.17c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m156.79,78.24c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.65,79.26c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.51,80.28c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.38,81.3c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.24,82.32c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.1,83.34c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.49,79.45c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.35,80.47c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.22,81.49c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.08,82.51c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m157.77,77.41c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.63,78.43c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.47,78.63c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.33,79.65c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.19,80.67c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.06,81.69c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m158.75,76.58c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.61,77.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.45,77.8c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.31,78.82c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.17,79.84c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.03,80.86c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m159.73,75.76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.59,76.78c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.43,76.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.29,77.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.15,79.02c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.01,80.04c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m160.71,74.93c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.57,75.95c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.18,86.64c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.2,87.47c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.22,88.3c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.24,89.12c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.26,89.95c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.28,90.77c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.3,91.6c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.33,92.43c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.35,93.25c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.37,94.08c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.39,94.9c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.94,83.53c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.96,84.36c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.98,85.19c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160,86.01c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.02,86.84c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.04,87.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.06,88.49c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.08,89.32c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.1,90.14c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.12,90.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.14,91.79c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.17,92.62c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.19,93.45c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.21,94.27c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.23,95.1c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.25,95.92c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.27,96.75c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m146.29,97.58c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m145.31,98.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.8,84.56c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.82,85.38c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.84,86.21c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.86,87.03c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.88,87.86c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.9,88.68c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.92,89.51c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.94,90.34c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.96,91.16c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.98,91.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.01,92.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.03,93.64c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.05,94.47c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.07,95.29c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.09,96.12c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.11,96.94c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.13,97.77c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.15,98.6c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m146.17,99.42c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.66,85.58c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.68,86.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.7,87.23c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.72,88.05c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.74,88.88c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.76,89.71c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.78,90.53c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.8,91.36c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.83,92.18c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.85,93.01c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.87,93.84c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.89,94.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.91,95.49c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.93,96.31c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.95,97.14c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.97,97.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.99,98.79c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.01,99.62c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m147.03,100.44c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.52,86.6c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.38,87.62c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.92,82.71c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.78,83.73c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.64,84.75c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.5,85.77c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.36,86.79c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.54,87.42c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.56,88.25c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.58,89.07c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.6,89.9c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.62,90.73c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.64,91.55c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.67,92.38c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.69,93.2c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.71,94.03c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.73,94.86c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.75,95.68c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.77,96.51c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.79,97.33c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.81,98.16c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.83,98.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.85,99.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m148.87,100.64c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.4,88.44c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.42,89.27c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.44,90.09c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.46,90.92c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.48,91.75c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.51,92.57c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.53,93.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.55,94.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.57,95.05c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.59,95.88c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.61,96.7c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.63,97.53c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.65,98.35c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.67,99.18c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.69,100.01c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m150.71,100.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m149.73,101.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.26,89.46c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.28,90.29c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.3,91.12c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.32,91.94c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.35,92.77c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.37,93.59c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.39,94.42c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.41,95.25c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.43,96.07c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.45,96.9c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.47,97.72c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.49,98.55c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.51,99.38c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.53,100.2c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.55,101.03c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m151.57,101.85c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.12,90.48c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.14,91.31c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.16,92.14c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.19,92.96c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.21,93.79c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.23,94.61c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.25,95.44c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.27,96.27c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.29,97.09c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.31,97.92c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.33,98.74c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.35,99.57c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.37,100.4c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.39,101.22c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m153.41,102.05c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m152.43,102.87c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m155.7,66.93c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-19" d="m153.98,64.89c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.98,91.51c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.24,88.64c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m168.1,89.66c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m168.96,90.68c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m168.22,87.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m169.08,88.83c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m169.94,89.85c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.9,81.88c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.76,82.9c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.62,83.92c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.48,84.94c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m168.34,85.97c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m169.2,86.99c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m170.06,88.01c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m170.92,89.03c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.87,81.06c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.74,82.08c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.6,83.1c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m168.46,84.12c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m169.32,85.14c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m170.18,86.16c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m171.04,87.18c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m171.9,88.2c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m167.01,92.33c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m166.03,93.16c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m165.05,93.98c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61s-.5-.02-.61.21c-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m164.07,94.81c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m163.09,95.63c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m162.11,96.46c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m161.13,97.29c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m160.15,98.11c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m159.17,98.94c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m158.19,99.76c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m157.21,100.59c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m156.23,101.42c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m155.25,102.24c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/>
                            <path class="cls-15" d="m154.27,103.07c.22.11.5.02.61-.21.11-.22.02-.5-.21-.61-.22-.11-.5-.02-.61.21-.11.22-.02.5.21.61Z"/> -->
                          
                            </g>
                            <g id="group137" v-once>  
                              <polygon id="section137" class="cls-9"  @click="handleZoom('section137')" points="103.04 120.24 103.04 120.24 105.13 115.2 120.2 90.09 124.58 86.12 154.92 107.34 139.49 132.51 103.04 120.24"/>
                              <polygon id="section137" class="cls-22"  @click="handleZoom('section137')" points="103.04 120.24 103.6 120.47 105.68 115.48 120.67 90.48 124.63 86.9 154.1 107.51 139.22 131.78 103.24 119.67 103.04 120.24 103.6 120.47 103.04 120.24 102.85 120.81 139.75 133.24 155.73 107.17 124.53 85.35 119.73 89.71 104.59 114.93 102.24 120.61 102.85 120.81 103.04 120.24"/>
                              <text class="cls-5" transform="translate(120.46 115.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">137</tspan></text>
<!--
                              <path  d="m126.18,94.3c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <circle class="cls-19" cx="126.18" cy="94.3" r="0.45" />
                              
                      
                              <circle id="T24" @click="onSelectSeat('137','T24')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T24') }" cx="127.31" cy="94.68" r="0.45" />
                              <circle id="T23" @click="onSelectSeat('137','T23')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T23') }" cx="126.64" cy="95.67" r="0.45" />
                              <circle id="T22" @click="onSelectSeat('137','T22')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T22') }" cx="125.95" cy="96.68" r="0.45" />
                              <circle id="T21" @click="onSelectSeat('137','T21')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T21') }" cx="125.32" cy="97.83" r="0.45" />
                              <circle id="T20" @click="onSelectSeat('137','T20')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T20') }" cx="124.65" cy="98.95" r="0.45" />
                              <circle id="T19" @click="onSelectSeat('137','T19')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T19') }" cx="123.95" cy="99.97" r="0.45" />
                              <circle id="T18" @click="onSelectSeat('137','T18')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T18') }" cx="123.28" cy="101.11" r="0.45" />
                              <circle id="T17" @click="onSelectSeat('137','T17')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T17') }" cx="122.65" cy="102.15" r="0.45" />
                              <circle id="T16" @click="onSelectSeat('137','T16')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T16') }" cx="121.96" cy="103.3" r="0.45" />
                              <circle id="T15" @click="onSelectSeat('137','T15')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T15') }" cx="121.25" cy="104.36" r="0.45" />
                              <circle id="T14" @click="onSelectSeat('137','T14')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T14') }" cx="120.56" cy="105.42" r="0.45" />
                              <circle id="T13" @click="onSelectSeat('137','T13')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T13') }" cx="119.89" cy="106.54" r="0.45" />
                              <circle id="T12" @click="onSelectSeat('137','T12')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T12') }" cx="119.25" cy="107.59" r="0.45" />
                              <circle id="T11" @click="onSelectSeat('137','T11')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T11') }" cx="118.55" cy="108.69" r="0.45" />
                              <circle id="T10" @click="onSelectSeat('137','T10')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T10') }" cx="117.86" cy="109.8" r="0.45" />
                              <circle id="T9" @click="onSelectSeat('137','T9')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T9') }" cx="117.17" cy="110.85" r="0.45" />
                              <circle id="T8" @click="onSelectSeat('137','T8')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T8') }" cx="116.49" cy="111.94" r="0.45" />
                              <circle id="T7" @click="onSelectSeat('137','T7')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T7') }" cx="115.79" cy="113.02" r="0.45" />
                              <circle id="T6" @click="onSelectSeat('137','T6')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T6') }" cx="115.15" cy="114.1" r="0.45" />
                              <circle id="T5" @click="onSelectSeat('137','T5')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T5') }" cx="114.49" cy="115.24" r="0.45" />
                              <circle id="T4" @click="onSelectSeat('137','T4')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T4') }" cx="113.7" cy="116.35" r="0.45" />
                              <circle id="T3" @click="onSelectSeat('137','T3')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T3') }" cx="113.03" cy="117.35" r="0.45" />
                              <circle id="T2" @click="onSelectSeat('137','T2')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T2') }" cx="112.35" cy="118.5" r="0.45" />
                              <circle id="T1" @click="onSelectSeat('137','T1')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('T1') }" cx="111.67" cy="119.64" r="0.45" />
                              
                              
                              <path  d="m110.54,119.27c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m125.5,95.39c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m124.82,96.48c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m124.14,97.56c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m123.46,98.65c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path  d="m122.78,99.73c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m122.1,100.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m121.42,101.9c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m120.74,102.99c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>

                              <circle class="cls-19" cx="108.83" cy="116.11" r="0.47"/>
                              <circle class="cls-19" cx="109.89" cy="114.42" r="0.47"/>
                              <circle class="cls-19" cx="110.95" cy="112.73" r="0.47"/>
                              <circle class="cls-19" cx="112.01" cy="111.03" r="0.47"/>
                              <circle class="cls-19" cx="113.08" cy="109.34" r="0.47"/>
                              <circle class="cls-19" cx="114.14" cy="107.64" r="0.47"/>
                              <circle class="cls-19" cx="115.2" cy="105.95" r="0.47"/>
                              <circle class="cls-19" cx="116.26" cy="104.25" r="0.47"/>
                              <circle class="cls-19" cx="117.32" cy="102.56" r="0.47"/>
                              <circle class="cls-19" cx="118.38" cy="100.86" r="0.47"/>
                              <circle class="cls-19" cx="119.44" cy="99.17" r="0.47"/>
                              <circle class="cls-19" cx="120.5" cy="97.48" r="0.47"/>
                              <circle class="cls-19" cx="121.57" cy="95.78" r="0.47"/>
                              <circle class="cls-19" cx="122.63" cy="94.09" r="0.47"/>
                              <circle class="cls-19" cx="128.44" cy="95.72" r="0.47"/>
                              <circle class="cls-19" cx="127.76" cy="96.81" r="0.47"/>
                              <circle class="cls-19" cx="127.08" cy="97.89" r="0.47"/>
                              <circle class="cls-19" cx="126.4" cy="98.98" r="0.47"/>

                              <path  d="m120.06,104.07c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m119.38,105.16c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m118.7,106.25c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m118.02,107.33c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path  d="m117.34,108.42c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m116.66,109.5c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m115.98,110.59c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m115.3,111.67c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m114.62,112.76c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m113.94,113.85c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m113.25,114.95c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m112.57,116.03c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m111.9,117.1c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m111.22,118.19c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m128.44,95.72c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m127.76,96.81c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m127.08,97.89c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m126.4,98.98c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              
                              
                              <path id="S20" @click="onSelectSeat('137','S20')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S20') }" cx="122.39" cy="105.1" d="m125.72,100.06c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path id="S19" @click="onSelectSeat('137','S19')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S19') }" cx="122.39" cy="105.1" d="m125.04,101.15c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path id="S18" @click="onSelectSeat('137','S18')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S18') }" cx="122.39" cy="105.1" d="m124.36,102.24c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path id="S17" @click="onSelectSeat('137','S17')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S17') }" cx="122.39" cy="105.1" d="m123.68,103.32c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path id="S16" @click="onSelectSeat('137','S16')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S16') }" cx="122.39" cy="105.1" d="m123,104.41c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <circle id="S15" @click="onSelectSeat('137','S15')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S15') }" cx="122.39" cy="105.1" r="0.46"/>
                              <circle id="S14" @click="onSelectSeat('137','S14')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S14') }" cx="121.69" cy="106.18" r="0.46"/>
                              <circle id="S13" @click="onSelectSeat('137','S13')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S13') }" cx="120.99" cy="107.26" r="0.46"/>
                              <circle id="S12" @click="onSelectSeat('137','S12')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S12') }" cx="120.36" cy="108.35" r="0.46"/>
                              <circle id="S11" @click="onSelectSeat('137','S11')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S11') }" cx="119.65" cy="109.46" r="0.46"/> 
                              <circle id="S10" @click="onSelectSeat('137','S10')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S10') }" cx="118.98" cy="110.52" r="0.46"/>
                              <circle id="S9" @click="onSelectSeat('137','S9')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S9') }" cx="118.34" cy="111.6" r="0.46"/>
                              <circle id="S8" @click="onSelectSeat('137','S8')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S8') }" cx="117.59" cy="112.65" r="0.46"/>
                              <circle id="S7" @click="onSelectSeat('137','S7')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S7') }" cx="116.98" cy="113.68" r="0.46"/>
                              <circle id="S6" @click="onSelectSeat('137','S6')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S6') }" cx="116.3" cy="114.76" r="0.46"/>
                              <circle id="S5" @click="onSelectSeat('137','S5')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S5') }" cx="115.59" cy="115.94" r="0.46"/>
                              <circle id="S4" @click="onSelectSeat('137','S4')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S4') }" cx="114.9" cy="117.04" r="0.46"/>
                              <circle id="S3" @click="onSelectSeat('137','S3')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S3') }" cx="114.25" cy="118.12" r="0.46"/>
                              <circle id="S2" @click="onSelectSeat('137','S2')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S2') }" cx="113.56" cy="119.21" r="0.46"/>
                              <circle id="S1" @click="onSelectSeat('137','S1')" v-show="isSeatVisible === 'section137'" :class="{ 'selected': isSelected('S1') }" cx="112.8" cy="120.32" r="0.46"/>
                              
                              <path d="m128.89,97.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m128.21,98.6c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m127.53,99.69c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path d="m126.85,100.77c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m126.17,101.86c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m125.49,102.94c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m124.81,104.03c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m124.13,105.12c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m123.45,106.2c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m122.77,107.29c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m122.09,108.37c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path d="m121.41,109.46c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m120.73,110.54c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m120.05,111.63c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m119.37,112.72c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m118.69,113.8c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m118.01,114.89c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m117.33,115.97c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m116.64,117.08c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m115.97,118.15c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path d="m115.29,119.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m114.61,120.32c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path d="m113.93,121.4c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m130.02,98.22c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m129.34,99.31c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m128.66,100.4c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m127.98,101.48c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m127.3,102.57c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path d="m126.62,103.65c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m125.94,104.74c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m125.26,105.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m124.58,106.91c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path d="m123.9,108c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              

                              <path  d="m123.22,109.08c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m122.54,110.17c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m121.86,111.25c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m121.18,112.34c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m120.5,113.42c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m119.82,114.51c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m119.14,115.6c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m118.46,116.68c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m117.77,117.78c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m117.1,118.86c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m116.42,119.94c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m115.74,121.02c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m115.06,122.11c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38s.13.48.38.52Z"/>
                              <path  d="m131.16,98.93c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m130.48,100.02c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m129.8,101.1c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m129.12,102.19c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m128.44,103.28c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m127.76,104.36c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m127.08,105.45c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m126.4,106.53c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m125.72,107.62c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m125.04,108.7c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m124.36,109.79c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m123.68,110.88c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m123,111.96c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m122.32,113.05c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m121.64,114.13c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m120.96,115.22c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m120.28,116.3c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m119.6,117.39c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m118.9,118.49c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m118.23,119.57c.25.04.48-.13.52-.38s-.13-.48-.38-.52-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path  d="m117.56,120.65c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m116.87,121.73c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m116.19,122.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m132.29,99.64c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m129.57,96.43c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m130.7,97.14c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m131.84,97.85c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m132.97,98.56c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m128.01,93.89c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m129.15,94.6c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path  d="m130.28,95.3c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              
                              
                              <path class="cls-19" d="m131.41,96.01c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m131.61,100.73c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m130.93,101.81c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m130.25,102.9c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m129.57,103.98c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m128.89,105.07c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m128.21,106.16c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m127.53,107.24c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m126.85,108.33c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m126.17,109.41c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m125.49,110.5c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m124.81,111.58c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m124.13,112.67c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m123.45,113.76c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m122.77,114.84c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m122.09,115.93c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m121.41,117.01c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m120.73,118.1c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m120.04,119.2c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m119.36,120.28c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m118.69,121.36c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m118.01,122.44c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m117.33,123.53c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m129.34,106.87c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m128.66,107.95c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m127.98,109.04c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m127.3,110.12c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m126.62,111.21c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m125.94,112.29c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m125.26,113.38c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m124.58,114.46c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m123.9,115.55c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m123.22,116.64c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m122.54,117.72c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m121.86,118.81c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m121.17,119.91c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.47,107.57c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.79,108.66c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.11,109.75c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.43,110.83c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.75,111.92c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.07,113c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.39,114.09c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.71,115.17c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.03,116.26c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m124.35,117.35c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m123.67,118.43c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m122.99,119.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.6,108.28c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.92,109.37c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.24,110.45c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.56,111.54c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.88,112.63c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.2,113.71c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.52,114.8c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.84,115.88c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.16,116.97c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.48,118.05c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m124.8,119.14c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m124.12,120.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.73,108.99c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.05,110.08c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.37,111.16c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.69,112.25c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.01,113.33c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.33,114.42c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.65,115.51c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.97,116.59c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.29,117.68c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.61,118.76c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.93,119.85c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.25,120.93c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.87,109.7c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.19,110.79c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.51,111.87c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.83,112.96c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.15,114.04c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.46,115.13c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.78,116.21c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.1,117.3c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.42,118.39c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.74,119.47c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.06,120.56c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.38,121.64c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135,110.41c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m130.02,105.78c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.15,106.49c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.28,107.2c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.41,107.91c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.55,108.61c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.68,109.32c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m130.7,104.69c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.83,105.4c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.96,106.11c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.09,106.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.23,107.53c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.36,108.24c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path class="cls-19" d="m131.38,103.61c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.51,104.32c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.64,105.03c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.77,105.73c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.91,106.44c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.04,107.15c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.32,103.94c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.45,104.65c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.59,105.36c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.72,106.07c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m132.06,102.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              
                              <path class="cls-15" d="m133.19,103.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135,102.85c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.13,103.56c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.27,104.27c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.4,104.98c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m132.74,101.44c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.87,102.15c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.68,101.77c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m133.42,100.35c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.55,101.06c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.32,111.49c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.64,112.58c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.96,113.67c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.28,114.75c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.6,115.84c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.92,116.92c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.24,118.01c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.56,119.09c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.88,120.18c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.2,121.27c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.52,122.35c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m122.3,120.62c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m123.43,121.33c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m124.56,122.04c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.69,122.75c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.83,123.45c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m120.49,120.99c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m121.63,121.7c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m122.76,122.4c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m123.89,123.11c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m125.02,123.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m126.15,124.53c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.85,106.78c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.17,107.86c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.49,108.95c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.81,110.03c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.13,111.12c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.45,112.2c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.77,113.29c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.09,114.37c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.41,115.46c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.73,116.55c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.05,117.63c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.37,118.72c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.69,119.8c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.01,120.89c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.33,121.97c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.65,123.06c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.96,124.16c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m127.28,125.24c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.98,107.48c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.3,108.57c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.62,109.66c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.94,110.74c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.26,111.83c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.58,112.91c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.9,114c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.22,115.08c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.54,116.17c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.86,117.26c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.18,118.34c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.5,119.43c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.82,120.51c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.14,121.6c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.46,122.68c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.78,123.77c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.09,124.87c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m128.41,125.95c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.11,108.19c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.43,109.28c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.75,110.36c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.07,111.45c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.39,112.54c.25.04.48-.13.52-.38s-.13-.48-.38-.52c-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.71,113.62c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.03,114.71c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.35,115.79c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.67,116.88c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.99,117.96c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.31,119.05c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.63,120.14c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.95,121.22c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.27,122.31c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.59,123.39c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.91,124.48c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.22,125.58c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m129.55,126.66c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.24,108.9c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.37,109.61c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.53,105.69c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.66,106.4c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.79,107.11c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.92,107.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.05,108.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.56,109.99c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.88,111.07c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.2,112.16c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.52,113.24c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.84,114.33c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.16,115.42c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.48,116.5c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.8,117.59c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.12,118.67c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.44,119.76c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.76,120.84c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.08,121.93c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.4,123.02c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.72,124.1c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.04,125.19c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.35,126.29c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m130.68,127.37c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.69,110.7c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.01,111.78c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.33,112.87c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.65,113.95c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.97,115.04c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.29,116.12c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.61,117.21c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.93,118.3c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.25,119.38c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.57,120.47c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.89,121.55c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.21,122.64c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.53,123.72c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.85,124.81c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m133.17,125.9c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m132.48,127c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m131.81,128.07c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m119.82,122.06c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.83,111.4c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.15,112.49c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.47,113.58c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.79,114.66c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.11,115.75c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.43,116.83c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.75,117.92c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.07,119c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.39,120.09c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.71,121.18c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.03,122.26c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.35,123.35c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.67,124.43c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.99,125.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m134.31,126.6c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.96,112.11c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.28,113.2c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.6,114.28c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.92,115.37c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.24,116.46c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.56,117.54c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.88,118.63c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.2,119.71c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.52,120.8c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.84,121.88c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.16,122.97c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.48,124.06c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.8,125.14c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.12,126.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m135.44,127.31c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m126.88,93.18c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m146.09,112.82c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.51,110.32c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m145.64,111.03c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m146.77,111.74c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m145.19,109.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m146.32,109.94c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m147.45,110.65c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.21,104.6c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.34,105.31c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.47,106.02c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.6,106.73c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.74,107.44c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m145.87,108.15c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m147,108.86c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m148.13,109.57c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.89,103.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.02,104.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.15,104.94c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.28,105.64c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m145.42,106.35c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m146.55,107.06c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m147.68,107.77c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m148.81,108.48c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m132.54,96.72c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-19" d="m133.67,97.43c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.59,102.39c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.73,103.1c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.86,103.81c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.99,104.52c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m146.12,105.23c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m147.25,105.94c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m148.38,106.65c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m149.51,107.35c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m145.41,113.91c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.73,114.99c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m144.05,116.08c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m143.37,117.17c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.69,118.25c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m142.01,119.34c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m141.33,120.42c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m140.65,121.51c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.97,122.59c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m139.29,123.68c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m138.61,124.77c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m137.25,126.94c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52s-.48.13-.52.38c-.04.25.13.48.38.52Z"/>
                              <path class="cls-15" d="m136.57,128.02c.25.04.48-.13.52-.38.04-.25-.13-.48-.38-.52-.25-.04-.48.13-.52.38-.04.25.13.48.38.52Z"/> 
                             -->   
                            </g>  
                            <g id="group135">  
                              <polygon id="section135" @click="isSectionEnabled('135') ? handleZoom('section135') : null" :class="{ 'enabled': isSectionEnabled('135') , 'disabled': !isSectionEnabled('135') }" points="84.5 188.44 126.1 192.41 131.54 159.52 90.47 154.13 85.81 172.11 84.5 188.44"/>
                              <polygon class="cls-22" points="84.5 188.44 84.44 189.05 126.6 193.07 132.24 159 90.02 153.46 85.21 172.01 83.85 188.99 84.44 189.05 84.5 188.44 85.11 188.49 86.41 172.21 90.92 154.8 130.85 160.03 125.59 191.76 84.56 187.84 84.5 188.44 85.11 188.49 84.5 188.44"/>
                              <text class="cls-5" transform="translate(98.46 175.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">135</tspan></text>
                            
                              <circle id="V22" @click="onSelectSeat('135','V22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V22') }" cx="95.2" cy="159.39" r=".44"/>
                              <path id="V21" @click="onSelectSeat('135','V21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V21') }" d="m95.12,161.04c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V20" @click="onSelectSeat('135','V20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V20') }" d="m94.92,162.26c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V19" @click="onSelectSeat('135','V19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V19') }" d="m94.72,163.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="V18" @click="onSelectSeat('135','V18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V18') }" d="m94.53,164.7c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V17" @click="onSelectSeat('135','V17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V17') }" d="m94.33,165.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="V16" @click="onSelectSeat('135','V16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V16') }" d="m94.14,167.14c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="V15" @click="onSelectSeat('135','V15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V15') }" d="m93.94,168.37c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V14" @click="onSelectSeat('135','V14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V14') }" d="m93.74,169.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V13" @click="onSelectSeat('135','V13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V13') }" d="m93.55,170.81c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="V12" @click="onSelectSeat('135','V12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V12') }" d="m93.35,172.03c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V11" @click="onSelectSeat('135','V11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V11') }" d="m93.16,173.25c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V10" @click="onSelectSeat('135','V10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V10') }" d="m92.96,174.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="V9" @click="onSelectSeat('135','V9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V9') }" d="m92.76,175.7c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V8" @click="onSelectSeat('135','V8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V8') }" d="m92.57,176.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V7" @click="onSelectSeat('135','V7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V7') }" d="m92.37,178.14c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="V6" @click="onSelectSeat('135','V6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V6') }" d="m92.18,179.36c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V5" @click="onSelectSeat('135','V5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V5') }" d="m91.98,180.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="V4" @click="onSelectSeat('135','V4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V4') }" d="m91.78,181.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="V3" @click="onSelectSeat('135','V3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V3') }" d="m91.59,183.04c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V2" @click="onSelectSeat('135','V2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V2') }" d="m91.39,184.25c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="V1" @click="onSelectSeat('135','V1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('V1') }" d="m91.2,185.47c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="U23" @click="onSelectSeat('135','U23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U23') }" d="m99.4,157.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U22" @click="onSelectSeat('135','U22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U22') }" d="m97.16,160.11c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U21" @click="onSelectSeat('135','U21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U21') }" d="m96.96,161.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U20" @click="onSelectSeat('135','U20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U20') }" d="m96.77,162.55c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U19" @click="onSelectSeat('135','U19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U19') }" d="m96.57,163.77c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U18" @click="onSelectSeat('135','U18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U18') }" d="m96.37,165c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U17" @click="onSelectSeat('135','U17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U17') }" d="m96.18,166.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U16" @click="onSelectSeat('135','U16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U16') }" d="m95.98,167.44c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U15" @click="onSelectSeat('135','U15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U15') }" d="m95.79,168.66c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U14" @click="onSelectSeat('135','U14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U14') }" d="m95.59,169.88c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U13" @click="onSelectSeat('135','U13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U13') }" d="m95.39,171.11c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U12" @click="onSelectSeat('135','U12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U12') }" d="m95.2,172.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U11" @click="onSelectSeat('135','U11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U11') }" d="m95,173.55c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U10" @click="onSelectSeat('135','U10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U10') }" d="m94.81,174.77c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U9" @click="onSelectSeat('135','U9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U9') }" d="m94.61,175.99c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="U8" @click="onSelectSeat('135','U8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U8') }" d="m94.41,177.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U7" @click="onSelectSeat('135','U7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U7') }" d="m94.22,178.44c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U6" @click="onSelectSeat('135','U6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U6') }" d="m94.02,179.66c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U5" @click="onSelectSeat('135','U5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U5') }" d="m93.83,180.88c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U4" @click="onSelectSeat('135','U4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U4') }" d="m93.63,182.12c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="U3" @click="onSelectSeat('135','U3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U3') }" d="m93.43,183.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U2" @click="onSelectSeat('135','U2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U2') }" d="m93.24,184.55c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="U1" @click="onSelectSeat('135','U1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('U1') }" d="m93.04,185.77c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="T25" @click="onSelectSeat('135','T25')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T25') }" d="m99,160.4c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T24" @click="onSelectSeat('135','S24')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T24') }" d="m99.2,159.18c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T23" @click="onSelectSeat('135','T23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T23') }" d="m98.81,161.63c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T22" @click="onSelectSeat('135','T22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T22') }" d="m98.61,162.85c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T21" @click="onSelectSeat('135','T21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T21') }" d="m98.41,164.07c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T20" @click="onSelectSeat('135','T20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T20') }" d="m98.22,165.29c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T19" @click="onSelectSeat('135','T19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T19') }" d="m98.02,166.51c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T18" @click="onSelectSeat('135','T18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T18') }" d="m97.83,167.74c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T17" @click="onSelectSeat('135','T17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T17') }" d="m97.63,168.96c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="T16" @click="onSelectSeat('135','T16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T16') }" d="m97.43,170.18c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T15" @click="onSelectSeat('135','T15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T15') }" d="m97.24,171.4c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T14" @click="onSelectSeat('135','T14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T14') }" d="m97.04,172.62c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T13" @click="onSelectSeat('135','T13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T13') }" d="m96.85,173.85c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T12" @click="onSelectSeat('135','T12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T12') }" d="m96.65,175.07c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="T11" @click="onSelectSeat('135','T11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T11') }" d="m96.46,176.29c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T10" @click="onSelectSeat('135','T10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T10') }" d="m96.26,177.51c.23-.06.38-.3.32-.53s-.3-.38-.53-.32c-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T9" @click="onSelectSeat('135','T9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T9') }" d="m96.06,178.73c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T8" @click="onSelectSeat('135','T8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T8') }" d="m95.87,179.95c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T7" @click="onSelectSeat('135','T7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T7') }" d="m95.67,181.18c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T6" @click="onSelectSeat('135','T6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T6') }" d="m95.47,182.42c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T5" @click="onSelectSeat('135','T5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T5') }" d="m95.28,183.63c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T4" @click="onSelectSeat('135','T4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T4') }" d="m95.08,184.84c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T3" @click="onSelectSeat('135','T3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T3') }" d="m94.89,186.06c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="T2" @click="onSelectSeat('135','T2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T2') }" d="m94.69,187.29c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="T1" @click="onSelectSeat('135','T1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('T1') }" d="m94.5,188.51c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                       
                              <circle id="S26" @click="onSelectSeat('135','S26')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S26') }" cx="100.57" cy="157.7" r=".44" transform="translate(-81.58 115.2) rotate(-44.34)"/>
                              <path id="S25" @click="onSelectSeat('135','S25')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S25') }" d="m100.47,159.39c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>                     
                              <path id="S24" @click="onSelectSeat('135','S24')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S24') }" d="m100.28,160.61c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <circle id="S23" @click="onSelectSeat('135','S23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S23') }" cx="99.97" cy="161.41" r=".44"/>
                              <path id="S22" @click="onSelectSeat('135','S22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S22') }" d="m99.88,163.05c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S21" @click="onSelectSeat('135','S21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S21') }" d="m99.69,164.27c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S20" @click="onSelectSeat('135','S20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S20') }" d="m99.49,165.5c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S19" @click="onSelectSeat('135','S19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S19') }" d="m99.3,166.72c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S18" @click="onSelectSeat('135','S18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S18') }" d="m99.1,167.94c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S17" @click="onSelectSeat('135','S17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S17') }" d="m98.9,169.16c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S16" @click="onSelectSeat('135','S16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S16') }" d="m98.71,170.38c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="S15" @click="onSelectSeat('135','S15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S15') }" d="m98.51,171.61c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S14" @click="onSelectSeat('135','S14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S14') }" d="m98.32,172.83c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S13" @click="onSelectSeat('135','S13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S13') }" d="m98.12,174.05c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="S12" @click="onSelectSeat('135','S12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S12') }" d="m97.92,175.27c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S11" @click="onSelectSeat('135','S11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S11') }" d="m97.73,176.49c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="S10" @click="onSelectSeat('135','S10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S10') }" d="m97.53,177.72c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="S9" @click="onSelectSeat('135','S9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S9') }" d="m97.34,178.94c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S8" @click="onSelectSeat('135','S8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S8') }" d="m97.14,180.16c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S7" @click="onSelectSeat('135','S7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S7') }" d="m96.94,181.38c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="S6" @click="onSelectSeat('135','S6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S6') }" d="m96.75,182.62c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S5" @click="onSelectSeat('135','S5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S5') }" d="m96.55,183.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="S4" @click="onSelectSeat('135','S4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S4') }" d="m96.36,185.05c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="S3" @click="onSelectSeat('135','S3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S3') }" d="m96.16,186.27c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="S2" @click="onSelectSeat('135','S2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S2') }" d="m95.97,187.49c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="S1" @click="onSelectSeat('135','S1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('S1') }" d="m95.77,188.72c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              
                              <path id="R26" @click="onSelectSeat('135','R26')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R26') }" d="m101.95,158.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R25" @click="onSelectSeat('135','R25')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R25') }" d="m101.75,159.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="R24" @click="onSelectSeat('135','R24')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R24') }" d="m101.55,160.81c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R23" @click="onSelectSeat('135','R23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R23') }" d="m101.35,162.04c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R22" @click="onSelectSeat('135','R22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R22') }" d="m101.16,163.26c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R21" @click="onSelectSeat('135','R21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R21') }" d="m100.96,164.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R20" @click="onSelectSeat('135','R20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R20') }" d="m100.77,165.7c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="R19" @click="onSelectSeat('135','R19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R19') }" d="m100.57,166.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R18" @click="onSelectSeat('135','R18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R18') }" d="m100.37,168.14c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="R17" @click="onSelectSeat('135','R17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R17') }" d="m100.18,169.37c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R16" @click="onSelectSeat('135','R16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R16') }" d="m99.98,170.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R15" @click="onSelectSeat('135','R15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R15') }" d="m99.79,171.81c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="R14" @click="onSelectSeat('135','R14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R14') }" d="m99.59,173.03c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R13" @click="onSelectSeat('135','R13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R13') }" d="m99.39,174.25c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R12" @click="onSelectSeat('135','R12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R12') }" d="m99.2,175.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R11" @click="onSelectSeat('135','R11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R11') }" d="m99,176.7c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R10" @click="onSelectSeat('135','R10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R10') }" d="m98.81,177.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R9" @click="onSelectSeat('135','R9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R9') }" d="m98.61,179.14c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R8" @click="onSelectSeat('135','R8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R8') }" d="m98.41,180.36c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R7" @click="onSelectSeat('135','R7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R7') }" d="m98.22,181.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R6" @click="onSelectSeat('135','R6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R6') }" d="m98.02,182.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="R5" @click="onSelectSeat('135','R5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R5') }" d="m97.83,184.04c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R4" @click="onSelectSeat('135','R4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R4') }" d="m97.63,185.25c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R3" @click="onSelectSeat('135','R3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R3') }" d="m97.43,186.47c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R2" @click="onSelectSeat('135','R2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R2') }" d="m97.24,187.69c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="R1" @click="onSelectSeat('135','R1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('R1') }" d="m97.04,188.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="Q25" @click="onSelectSeat('135','Q25')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q25') }" d="m103.22,158.53c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q24" @click="onSelectSeat('135','Q24')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q24') }" d="m103.02,159.8c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <circle id="Q23" @click="onSelectSeat('135','Q23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q23') }" cx="102.71" cy="160.59" r=".44"/>
                              <path id="Q22" @click="onSelectSeat('135','Q22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q22') }" d="m102.63,162.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q21" @click="onSelectSeat('135','Q21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q21') }" d="m102.43,163.46c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="Q20" @click="onSelectSeat('135','Q20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q20') }" d="m102.24,164.68c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q19" @click="onSelectSeat('135','Q19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q19') }" d="m102.04,165.91c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q18" @click="onSelectSeat('135','Q18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q18') }" d="m101.84,167.13c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q17" @click="onSelectSeat('135','Q17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q17') }" d="m101.65,168.35c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q16" @click="onSelectSeat('135','Q16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q16') }" d="m101.45,169.57c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="Q15" @click="onSelectSeat('135','Q15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q15') }" d="m101.26,170.79c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q14" @click="onSelectSeat('135','Q14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q14') }" d="m101.06,172.01c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q13" @click="onSelectSeat('135','Q13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q13') }" d="m100.86,173.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q12" @click="onSelectSeat('135','Q12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q12') }" d="m100.67,174.46c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q11" @click="onSelectSeat('135','Q11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q11') }" d="m100.47,175.68c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q10" @click="onSelectSeat('135','Q10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q10') }" d="m100.28,176.9c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q9" @click="onSelectSeat('135','Q9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q9') }" d="m100.08,178.12c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q8" @click="onSelectSeat('135','Q8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q8') }" d="m99.88,179.35c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q7" @click="onSelectSeat('135','Q7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q7') }" d="m99.69,180.57c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q6" @click="onSelectSeat('135','Q6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q6') }" d="m99.49,181.79c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q5" @click="onSelectSeat('135','Q5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q5') }" d="m99.29,183.03c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q4" @click="onSelectSeat('135','Q4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q4') }" d="m99.1,184.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="Q3" @click="onSelectSeat('135','Q3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q3') }" d="m98.9,185.46c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q2" @click="onSelectSeat('135','Q2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q2') }" d="m98.71,186.68c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="Q1" @click="onSelectSeat('135','Q1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('Q1') }" d="m98.51,187.9c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <circle id="P24" @click="onSelectSeat('135','P24')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P24') }" cx="104.39" cy="158.31" r=".44"/>
                              <path id="P23" @click="onSelectSeat('135','P23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P23') }" d="m104.29,160c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P22" @click="onSelectSeat('135','P22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P22') }" d="m104.1,161.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P21" @click="onSelectSeat('135','P21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P21') }" d="m103.9,162.44c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P20" @click="onSelectSeat('135','P20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P20') }" d="m103.7,163.67c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P19" @click="onSelectSeat('135','P19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P19') }" d="m103.51,164.89c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="P18" @click="onSelectSeat('135','P18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P18') }" d="m103.31,166.11c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P17" @click="onSelectSeat('135','P17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P17') }" d="m103.12,167.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P16" @click="onSelectSeat('135','P16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P16') }" d="m102.92,168.55c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="P15" @click="onSelectSeat('135','P15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P15') }" d="m102.72,169.77c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P14" @click="onSelectSeat('135','P14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P14') }" d="m102.53,171c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="P13" @click="onSelectSeat('135','P13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P13') }" d="m102.33,172.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="P12" @click="onSelectSeat('135','P12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P12') }" d="m102.14,173.44c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P11" @click="onSelectSeat('135','P11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P11') }" d="m101.94,174.66c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P10" @click="onSelectSeat('135','P10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P10') }" d="m101.75,175.88c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="P9" @click="onSelectSeat('135','P9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P9') }" d="m101.55,177.11c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P8" @click="onSelectSeat('135','P8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P8') }" d="m101.35,178.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P7" @click="onSelectSeat('135','P7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P7') }" d="m101.16,179.55c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="P6" @click="onSelectSeat('135','P6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P6') }" d="m100.96,180.77c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P5" @click="onSelectSeat('135','P5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P5') }" d="m100.77,181.99c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P4" @click="onSelectSeat('135','P4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P4') }" d="m100.57,183.23c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="P3" @click="onSelectSeat('135','P3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P3') }" d="m100.37,184.45c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="P2" @click="onSelectSeat('135','P2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P2') }" d="m100.18,185.66c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="P1" @click="onSelectSeat('135','P1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('P1') }" d="m99.98,186.88c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              
                              <path id="O24" @click="onSelectSeat('135','O24')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O24') }" d="m105.77,158.94c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <circle id="O23" @click="onSelectSeat('135','O23')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O23') }" cx="105.46" cy="159.78" r=".44"/>
                              <path id="O22" @click="onSelectSeat('135','O22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O22') }" d="m105.37,161.43c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="O21" @click="onSelectSeat('135','O21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O21') }" d="m105.17,162.65c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O20" @click="onSelectSeat('135','O20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O20') }" d="m104.98,163.87c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O19" @click="onSelectSeat('135','O19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O19') }" d="m104.78,165.09c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O18" @click="onSelectSeat('135','O18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O18') }" d="m104.59,166.31c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="O17" @click="onSelectSeat('135','O17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O17') }" d="m104.39,167.54c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O16" @click="onSelectSeat('135','O16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O16') }" d="m104.19,168.76c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O15" @click="onSelectSeat('135','O15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O15') }" d="m104,169.98c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O14" @click="onSelectSeat('135','O14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O14') }" d="m103.8,171.2c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O13" @click="onSelectSeat('135','O13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O13') }" d="m103.61,172.42c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O12" @click="onSelectSeat('135','O12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O12') }" d="m103.41,173.64c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O11" @click="onSelectSeat('135','O11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O11') }" d="m103.21,174.87c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O10" @click="onSelectSeat('135','O10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O10') }" d="m103.02,176.09c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O9" @click="onSelectSeat('135','O9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O9') }" d="m102.82,177.31c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="O8" @click="onSelectSeat('135','O8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O8') }" d="m102.63,178.53c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O7" @click="onSelectSeat('135','O7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O7') }" d="m102.43,179.75c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O6" @click="onSelectSeat('135','O6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O6') }" d="m102.24,180.98c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="O5" @click="onSelectSeat('135','O5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O5') }" d="m102.04,182.2c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O4" @click="onSelectSeat('135','O4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O4') }" d="m101.84,183.44c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O3" @click="onSelectSeat('135','O3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O3') }" d="m101.65,184.65c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O2" @click="onSelectSeat('135','O2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O2') }" d="m101.45,185.86c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="O1" @click="onSelectSeat('135','O1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('O1') }" d="m101.26,187.09c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                             
                              <path id="N20" @click="onSelectSeat('135','N20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N20') }" d="m106.64,161.63c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N19" @click="onSelectSeat('135','N19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N19') }" d="m106.45,162.85c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N18" @click="onSelectSeat('135','N18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N18') }" d="m106.25,164.07c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N17" @click="onSelectSeat('135','N17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N17') }" d="m106.06,165.3c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="N16" @click="onSelectSeat('135','N16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N16') }" d="m105.86,166.52c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N15" @click="onSelectSeat('135','N15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N15') }" d="m105.66,167.74c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="N14" @click="onSelectSeat('135','N14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N14') }" d="m105.47,168.96c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="N13" @click="onSelectSeat('135','N13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N13') }" d="m105.27,170.18c.23-.06.38-.3.32-.53s-.3-.38-.53-.32c-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="N12" @click="onSelectSeat('135','N12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N12') }" d="m105.08,171.41c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N11" @click="onSelectSeat('135','N11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N11') }" d="m104.88,172.63c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N10" @click="onSelectSeat('135','N10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N10') }" d="m104.68,173.85c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N9" @click="onSelectSeat('135','N9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N9') }" d="m104.49,175.07c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N8" @click="onSelectSeat('135','N8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N8') }" d="m104.29,176.29c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N7" @click="onSelectSeat('135','N7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N7') }" d="m104.1,177.51c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N6" @click="onSelectSeat('135','N6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N6') }" d="m103.9,178.74c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="N5" @click="onSelectSeat('135','N5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N5') }" d="m103.7,179.96c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N4" @click="onSelectSeat('135','N4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N4') }" d="m103.51,181.18c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N3" @click="onSelectSeat('135','N3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N3') }" d="m103.31,182.4c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="N2" @click="onSelectSeat('135','N2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N2') }" d="m103.11,183.64c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="N1" @click="onSelectSeat('135','N1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('N1') }" d="m102.91,184.88c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="M20" @click="onSelectSeat('135','M20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M20') }" d="m107.92,161.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M19" @click="onSelectSeat('135','M19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M19') }" d="m107.72,163.06c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="M18" @click="onSelectSeat('135','M18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M18') }" d="m107.53,164.28c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M17" @click="onSelectSeat('135','M17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M17') }" d="m107.33,165.5c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="M16" @click="onSelectSeat('135','M16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M16') }" d="m107.13,166.72c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="M15" @click="onSelectSeat('135','M15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M15') }" d="m106.94,167.94c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M14" @click="onSelectSeat('135','M14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M14') }" d="m106.74,169.17c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M13" @click="onSelectSeat('135','M13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M13') }" d="m106.55,170.39c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="M12" @click="onSelectSeat('135','M12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M12') }" d="m106.35,171.61c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M11" @click="onSelectSeat('135','M11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M11') }" d="m106.15,172.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M10" @click="onSelectSeat('135','M10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M10') }" d="m105.96,174.05c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="M9" @click="onSelectSeat('135','M9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M9') }" d="m105.76,175.28c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M8" @click="onSelectSeat('135','M8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M8') }" d="m105.57,176.5c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M7" @click="onSelectSeat('135','M7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M7') }" d="m105.37,177.72c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="M6" @click="onSelectSeat('135','M6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M6') }" d="m105.17,178.94c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M5" @click="onSelectSeat('135','M5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M5') }" d="m104.98,180.16c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="M4" @click="onSelectSeat('135','M4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M4') }" d="m104.78,181.38c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="M3" @click="onSelectSeat('135','M3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M3') }" d="m104.59,182.61c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M2" @click="onSelectSeat('135','M2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M2') }" d="m104.39,183.85c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="M1" @click="onSelectSeat('135','M1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('M1') }" d="m104.19,185.09c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                
                              <path id="L19" @click="onSelectSeat('135','L19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L19') }"  d="m108.99,163.26c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L18" @click="onSelectSeat('135','L18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L18') }"  d="m108.8,164.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L17" @click="onSelectSeat('135','L17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L17') }"  d="m108.6,165.7c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L16" @click="onSelectSeat('135','L16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L16') }"  d="m108.41,166.93c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L15" @click="onSelectSeat('135','L15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L15') }"  d="m108.21,168.15c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>  
                              <path id="L14" @click="onSelectSeat('135','L14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L14') }" d="m108.02,169.37c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L13" @click="onSelectSeat('135','L13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L13') }" d="m107.82,170.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L12" @click="onSelectSeat('135','L12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L12') }" d="m107.62,171.81c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="L11" @click="onSelectSeat('135','L11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L11') }" d="m107.43,173.04c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L10" @click="onSelectSeat('135','L10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L10') }" d="m107.23,174.26c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L9" @click="onSelectSeat('135','L9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L9') }" d="m107.04,175.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="L8" @click="onSelectSeat('135','L8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L8') }" d="m106.84,176.7c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L7" @click="onSelectSeat('135','L7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L7') }" d="m106.64,177.92c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L6" @click="onSelectSeat('135','L6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L6') }" d="m106.45,179.14c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="L5" @click="onSelectSeat('135','L5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L5') }" d="m106.25,180.37c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L4" @click="onSelectSeat('135','L4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L4') }" d="m106.06,181.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="L3" @click="onSelectSeat('135','L3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L3') }" d="m105.86,182.81c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="L2" @click="onSelectSeat('135','L2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L2') }" d="m105.66,184.05c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="L1" @click="onSelectSeat('135','L1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('L1') }" d="m105.46,185.29c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="K19" @click="onSelectSeat('135','K19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K19') }" d="m110.27,163.46c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K18" @click="onSelectSeat('135','K18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K18') }" d="m110.07,164.69c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K17" @click="onSelectSeat('135','K17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K17') }" d="m109.88,165.91c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K16" @click="onSelectSeat('135','K16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K16') }" d="m109.68,167.13c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K15" @click="onSelectSeat('135','K15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K15') }" d="m109.48,168.35c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K14" @click="onSelectSeat('135','K14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K14') }" d="m109.29,169.57c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K13" @click="onSelectSeat('135','K13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K13') }" d="m109.09,170.8c.23-.06.38-.3.32-.53s-.3-.38-.53-.32c-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="K12" @click="onSelectSeat('135','K12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K12') }" d="m108.9,172.02c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K11" @click="onSelectSeat('135','K11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K11') }" d="m108.7,173.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K10" @click="onSelectSeat('135','K10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K10') }" d="m108.5,174.46c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K9" @click="onSelectSeat('135','K9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K9') }" d="m108.31,175.68c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K8" @click="onSelectSeat('135','K8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K8') }" d="m108.11,176.91c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="K7" @click="onSelectSeat('135','K7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K7') }" d="m107.92,178.13c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K6" @click="onSelectSeat('135','K6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K6') }" d="m107.72,179.35c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K5" @click="onSelectSeat('135','K5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K5') }" d="m107.53,180.57c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K4" @click="onSelectSeat('135','K4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K4') }" d="m107.33,181.79c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K3" @click="onSelectSeat('135','K3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K3') }" d="m107.13,183.01c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="K2" @click="onSelectSeat('135','K2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K2') }" d="m106.94,184.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="K1" @click="onSelectSeat('135','K1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('K1') }" d="m106.74,185.46c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="J19" @click="onSelectSeat('135','J19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J19') }" d="m111.54,163.67c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="J18" @click="onSelectSeat('135','J18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J18') }" d="m111.35,164.89c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="J17" @click="onSelectSeat('135','J17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J17') }" d="m111.15,166.11c.23-.06.38-.3.32-.53s-.3-.38-.53-.32c-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="J16" @click="onSelectSeat('135','J16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J16') }" d="m110.95,167.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J15" @click="onSelectSeat('135','J15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J15') }" d="m110.76,168.56c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="J14" @click="onSelectSeat('135','J14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J14') }" d="m110.56,169.78c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J13" @click="onSelectSeat('135','J13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J13') }" d="m110.37,171c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J12" @click="onSelectSeat('135','J12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J12') }" d="m110.17,172.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="J11" @click="onSelectSeat('135','J11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J11') }" d="m109.97,173.44c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J10" @click="onSelectSeat('135','J10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J10') }" d="m109.78,174.67c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="J9" @click="onSelectSeat('135','J9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J9') }" d="m109.58,175.89c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="J8" @click="onSelectSeat('135','J8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J8') }" d="m109.39,177.11c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J7" @click="onSelectSeat('135','J7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J7') }" d="m109.19,178.33c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="J6" @click="onSelectSeat('135','J6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J6') }" d="m108.99,179.55c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J5" @click="onSelectSeat('135','J5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J5') }" d="m108.8,180.78c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J4" @click="onSelectSeat('135','J4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J4') }" d="m108.6,182c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J3" @click="onSelectSeat('135','J3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J3') }" d="m108.41,183.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J2" @click="onSelectSeat('135','J2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J2') }" d="m108.21,184.44c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="J1" @click="onSelectSeat('135','J1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('J1') }" d="m108.02,185.66c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="I19" @click="onSelectSeat('135','I19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I19') }" d="m112.82,163.87c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I18" @click="onSelectSeat('135','I18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I18') }" d="m112.62,165.1c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I17" @click="onSelectSeat('135','I17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I17') }" d="m112.42,166.32c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="I16" @click="onSelectSeat('135','I16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I16') }" d="m112.23,167.54c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I15" @click="onSelectSeat('135','I15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I15') }" d="m112.03,168.76c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I14" @click="onSelectSeat('135','I14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I14') }" d="m111.84,169.98c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="I13" @click="onSelectSeat('135','I13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I13') }" d="m111.64,171.2c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I12" @click="onSelectSeat('135','I12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I12') }" d="m111.44,172.43c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I11" @click="onSelectSeat('135','I11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I11') }" d="m111.25,173.65c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="I10" @click="onSelectSeat('135','I10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I10') }" d="m111.05,174.87c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I9" @click="onSelectSeat('135','I9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I9') }" d="m110.86,176.09c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="I8" @click="onSelectSeat('135','I8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I8') }" d="m110.66,177.31c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="I7" @click="onSelectSeat('135','I7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I7') }" d="m110.46,178.54c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I6" @click="onSelectSeat('135','I6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I6') }" d="m110.27,179.76c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I5" @click="onSelectSeat('135','I5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I5') }" d="m110.07,180.98c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="I4" @click="onSelectSeat('135','I4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I4') }" d="m109.88,182.2c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I3" @click="onSelectSeat('135','I3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I3') }" d="m109.68,183.42c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="I2" @click="onSelectSeat('135','I2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I2') }" d="m109.48,184.65c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="I1" @click="onSelectSeat('135','I1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('I1') }" d="m109.29,185.87c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="H22" @click="onSelectSeat('135','H22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H22') }" d="m114.68,160.37c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="H21" @click="onSelectSeat('135','H21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H21') }" d="m114.48,161.63c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H20" @click="onSelectSeat('135','H20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H20') }" d="m114.28,162.86c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H19" @click="onSelectSeat('135','H19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H19') }" d="m114.09,164.08c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H18" @click="onSelectSeat('135','H18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H18') }" d="m113.89,165.3c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H17" @click="onSelectSeat('135','H17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H17') }" d="m113.7,166.52c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H16" @click="onSelectSeat('135','H16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H16') }" d="m113.5,167.74c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H15" @click="onSelectSeat('135','H15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H15') }" d="m113.31,168.97c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H14" @click="onSelectSeat('135','H14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H14') }" d="m113.11,170.19c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H13" @click="onSelectSeat('135','H13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H13') }" d="m112.91,171.41c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="H12" @click="onSelectSeat('135','H12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H12') }" d="m112.72,172.63c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H11" @click="onSelectSeat('135','H11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H11') }" d="m112.52,173.85c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H10" @click="onSelectSeat('135','H10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H10') }" d="m112.33,175.07c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="H9" @click="onSelectSeat('135','H9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H9') }" d="m112.13,176.3c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H8" @click="onSelectSeat('135','H8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H8') }" d="m111.93,177.52c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="H7" @click="onSelectSeat('135','H7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H7') }" d="m111.74,178.74c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="H6" @click="onSelectSeat('135','H6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H6') }" d="m111.54,179.96c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H5" @click="onSelectSeat('135','H5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H5') }" d="m111.35,181.18c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H4" @click="onSelectSeat('135','H4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H4') }" d="m111.15,182.41c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="H3" @click="onSelectSeat('135','H3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H3') }" d="m110.95,183.63c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H2" @click="onSelectSeat('135','H2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H2') }" d="m110.76,184.87c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="H1" @click="onSelectSeat('135','H1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('H1') }" d="m110.56,186.08c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                                                            
                              <path id="G22" @click="onSelectSeat('135','G22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G22') }" d="m115.96,160.57c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="G21" @click="onSelectSeat('135','G21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G21') }" d="m115.75,161.84c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G20" @click="onSelectSeat('135','G20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G20') }" d="m115.56,163.06c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="G19" @click="onSelectSeat('135','G19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G19') }" d="m115.36,164.28c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G18" @click="onSelectSeat('135','G18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G18') }" d="m115.17,165.5c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G17" @click="onSelectSeat('135','G17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G17') }" d="m114.97,166.73c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G16" @click="onSelectSeat('135','G16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G16') }" d="m114.77,167.95c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G15" @click="onSelectSeat('135','G15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G15') }" d="m114.58,169.17c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G14" @click="onSelectSeat('135','G14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G14') }" d="m114.38,170.39c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G13" @click="onSelectSeat('135','G13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G13') }" d="m114.19,171.61c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G12" @click="onSelectSeat('135','G12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G12') }" d="m113.99,172.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="G11" @click="onSelectSeat('135','G11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G11') }" d="m113.8,174.06c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G10" @click="onSelectSeat('135','G10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G10') }" d="m113.6,175.28c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G9" @click="onSelectSeat('135','G9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G9') }" d="m113.4,176.5c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G8" @click="onSelectSeat('135','G8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G8') }" d="m113.21,177.72c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G7" @click="onSelectSeat('135','G7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G7') }" d="m113.01,178.94c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G6" @click="onSelectSeat('135','G6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G6') }" d="m112.82,180.17c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G5" @click="onSelectSeat('135','G5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G5') }" d="m112.62,181.39c.23-.06.38-.3.32-.53s-.3-.38-.53-.32c-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="G4" @click="onSelectSeat('135','G4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G4') }" d="m112.42,182.61c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G3" @click="onSelectSeat('135','G3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G3') }" d="m112.23,183.83c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G2" @click="onSelectSeat('135','G2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G2') }" d="m112.03,185.07c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="G1" @click="onSelectSeat('135','G1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('G1') }" d="m111.83,186.28c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <circle id="F22" @click="onSelectSeat('135','F22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F22') }" cx="116.92" cy="161.62" r=".44"/>
                              <path id="F21" @click="onSelectSeat('135','F21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F21') }" d="m116.83,163.26c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F20" @click="onSelectSeat('135','F20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F20') }" d="m117.23,160.78c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F19" @click="onSelectSeat('135','F19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F19') }" d="m116.64,164.49c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F18" @click="onSelectSeat('135','F18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F18') }" d="m116.44,165.71c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F17" @click="onSelectSeat('135','F17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F17') }" d="m116.24,166.93c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F16" @click="onSelectSeat('135','F16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F16') }" d="m116.05,168.15c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <circle id="F15" @click="onSelectSeat('135','F15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F15') }" cx="115.74" cy="168.95" r=".44"/>
                              <path id="F14" @click="onSelectSeat('135','F14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F14') }" d="m115.66,170.6c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F13" @click="onSelectSeat('135','F13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F13') }" d="m115.46,171.82c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F12" @click="onSelectSeat('135','F12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F12') }" d="m115.26,173.04c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F11" @click="onSelectSeat('135','F11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F11') }" d="m115.07,174.26c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="F10" @click="onSelectSeat('135','F10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F10') }" d="m114.87,175.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F9" @click="onSelectSeat('135','F9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F9') }" d="m114.68,176.7c.23-.06.38-.3.32-.53s-.3-.38-.53-.32c-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F8" @click="onSelectSeat('135','F8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F8') }" d="m114.48,177.93c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F7" @click="onSelectSeat('135','F7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F7') }" d="m114.28,179.15c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F6" @click="onSelectSeat('135','F6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F6') }" d="m114.09,180.37c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F5" @click="onSelectSeat('135','F5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F5') }" d="m113.89,181.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F4" @click="onSelectSeat('135','F4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F4') }" d="m113.7,182.81c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="F3" @click="onSelectSeat('135','F3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F3') }" d="m113.5,184.04c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="F2" @click="onSelectSeat('135','F2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F2') }" d="m113.3,185.28c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="F1" @click="onSelectSeat('135','F1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('F1') }" d="m113.11,186.49c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <circle id="E22" @click="onSelectSeat('135','E22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E22') }" cx="118.4" cy="160.56" r=".44"/>
                              <path id="E21" @click="onSelectSeat('135','E21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E21') }" d="m118.3,162.25c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E20" @click="onSelectSeat('135','E20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E20') }" d="m118.11,163.47c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E19" @click="onSelectSeat('135','E19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E19') }" d="m117.91,164.69c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E18" @click="onSelectSeat('135','E18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E18') }" d="m117.71,165.91c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E17" @click="onSelectSeat('135','E17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E17') }" d="m117.52,167.13c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E16" @click="onSelectSeat('135','E16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E16') }" d="m117.32,168.36c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E15" @click="onSelectSeat('135','E15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E15') }" d="m117.13,169.58c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E14" @click="onSelectSeat('135','E14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E14') }" d="m116.93,170.8c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E13" @click="onSelectSeat('135','E13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E13') }" d="m116.73,172.02c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="E12" @click="onSelectSeat('135','E12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E12') }" d="m116.54,173.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E11" @click="onSelectSeat('135','E11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E11') }" d="m116.34,174.47c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E10" @click="onSelectSeat('135','E10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E10') }" d="m116.15,175.69c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E9" @click="onSelectSeat('135','E9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E9') }" d="m115.95,176.91c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E8" @click="onSelectSeat('135','E8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E8') }" d="m115.75,178.13c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E7" @click="onSelectSeat('135','E7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E7') }" d="m115.56,179.35c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E6" @click="onSelectSeat('135','E6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E6') }" d="m115.36,180.57c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E5" @click="onSelectSeat('135','E5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E5') }" d="m115.17,181.8c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E4" @click="onSelectSeat('135','E4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E4') }" d="m114.97,183.02c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="E3" @click="onSelectSeat('135','E3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E3') }" d="m114.77,184.24c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="E2" @click="onSelectSeat('135','E2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E2') }" d="m114.58,185.48c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32ZE"/>
                              <path id="E1" @click="onSelectSeat('135','E1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('E1') }" d="m114.38,186.69c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              
                              <path id="D22" @click="onSelectSeat('135','D22')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D22') }" d="m119.78,161.18c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D21" @click="onSelectSeat('135','D21')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D21') }" d="m119.58,162.45c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D20" @click="onSelectSeat('135','D20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D20') }" d="m119.38,163.67c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D19" @click="onSelectSeat('135','D19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D19') }" d="m119.18,164.89c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D18" @click="onSelectSeat('135','D18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D18') }" d="m118.99,166.12c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D17" @click="onSelectSeat('135','D17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D17') }" d="m118.79,167.34c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D16" @click="onSelectSeat('135','D16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D16') }" d="m118.6,168.56c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D15" @click="onSelectSeat('135','D15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D15') }" d="m118.4,169.78c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D14" @click="onSelectSeat('135','D14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D14') }" d="m118.2,171c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D13" @click="onSelectSeat('135','D13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D13') }" d="m118.01,172.23c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D12" @click="onSelectSeat('135','D12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D12') }" d="m117.81,173.45c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D11" @click="onSelectSeat('135','D11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D11') }" d="m117.62,174.67c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="D10" @click="onSelectSeat('135','D10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D10') }" d="m117.42,175.89c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D9" @click="onSelectSeat('135','D9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D9') }" d="m117.22,177.11c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D8" @click="onSelectSeat('135','D8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D8') }" d="m117.03,178.34c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="D7" @click="onSelectSeat('135','D7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D7') }" d="m116.83,179.56c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D6" @click="onSelectSeat('135','D6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D6') }" d="m116.64,180.78c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D5" @click="onSelectSeat('135','D5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D5') }" d="m116.44,182c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="D4" @click="onSelectSeat('135','D4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D4') }" d="m116.24,183.22c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="D3" @click="onSelectSeat('135','D3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D3') }" d="m116.05,184.44c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="D2" @click="onSelectSeat('135','D2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D2') }" d="m115.85,185.69c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="D1" @click="onSelectSeat('135','D1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('D1') }" d="m115.66,186.9c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>

                              <path id="C20" @click="onSelectSeat('135','C20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C20') }" d="m121.05,161.39c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="C19" @click="onSelectSeat('135','C19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C19') }" d="m120.85,162.65c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="C18" @click="onSelectSeat('135','C18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C18') }" d="m120.65,163.88c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C17" @click="onSelectSeat('135','C17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C17') }" d="m120.46,165.1c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C16" @click="onSelectSeat('135','C16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C16') }" d="m120.26,166.32c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="C15" @click="onSelectSeat('135','C15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C15') }" d="m120.06,167.54c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C14" @click="onSelectSeat('135','C14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C14') }" d="m119.87,168.76c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="C13" @click="onSelectSeat('135','C13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C13') }" d="m119.67,169.99c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="C12" @click="onSelectSeat('135','C12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C12') }" d="m119.48,171.21c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C11" @click="onSelectSeat('135','C11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C11') }" d="m119.28,172.43c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C10" @click="onSelectSeat('135','C10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C10') }" d="m119.09,173.65c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C9" @click="onSelectSeat('135','C9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C9') }" d="m118.89,174.87c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C8" @click="onSelectSeat('135','C8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C8') }" d="m118.69,176.1c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="C7" @click="onSelectSeat('135','C7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C7') }" d="m118.5,177.32c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C6" @click="onSelectSeat('135','C6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C6') }" d="m118.3,178.54c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C5" @click="onSelectSeat('135','C5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C5') }" d="m118.11,179.76c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C4" @click="onSelectSeat('135','C4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C4') }" d="m117.91,180.98c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="C3" @click="onSelectSeat('135','C3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C3') }" d="m117.71,182.2c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="C2" @click="onSelectSeat('135','C2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C2') }" d="m117.52,183.43c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="C1" @click="onSelectSeat('135','C1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('C1') }" d="m117.32,184.65c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="B20" @click="onSelectSeat('135','B20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B20') }" d="m122.33,161.59c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B19" @click="onSelectSeat('135','B19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B19') }" d="m122.12,162.86c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="B18" @click="onSelectSeat('135','B18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B18') }" d="m121.93,164.08c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <circle id="B17" @click="onSelectSeat('135','B17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B17') }" cx="121.62" cy="164.88" r=".44"/>
                              <path id="B16" @click="onSelectSeat('135','B16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B16') }" d="m121.53,166.52c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B15" @click="onSelectSeat('135','B15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B15') }" d="m121.34,167.75c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <circle id="B14" @click="onSelectSeat('135','B14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B14') }" cx="121.03" cy="168.54" r=".44"/>
                              <path id="B13" @click="onSelectSeat('135','B13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B13') }" d="m120.95,170.19c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B12" @click="onSelectSeat('135','B12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B12') }" d="m120.75,171.41c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <circle id="B11" @click="onSelectSeat('135','B11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B11') }" cx="120.45" cy="172.21" r=".44"/>
                              <path id="B10" @click="onSelectSeat('135','B10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B10') }" d="m120.36,173.86c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B9" @click="onSelectSeat('135','B9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B9') }" d="m120.16,175.08c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <circle id="B8" @click="onSelectSeat('135','B8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B8') }" cx="119.86" cy="175.88" r=".44"/>
                              <path id="B7" @click="onSelectSeat('135','B7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B7') }" d="m119.77,177.52c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B6" @click="onSelectSeat('135','B6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B6') }" d="m119.58,178.74c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="B5" @click="onSelectSeat('135','B5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B5') }" d="m119.38,179.97c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B4" @click="onSelectSeat('135','B4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B4') }" d="m119.18,181.19c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="B3" @click="onSelectSeat('135','B3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B3') }" d="m118.99,182.41c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <circle id="B2" @click="onSelectSeat('135','B2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B2') }" cx="118.68" cy="183.21" r=".44"/>
                              <path id="B1" @click="onSelectSeat('135','B1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('B1') }" d="m118.6,184.85c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              
                              <path id="A20" @click="onSelectSeat('135','A20')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A20') }" d="m123.6,161.8c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="A19" @click="onSelectSeat('135','A19')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A19') }" d="m123.4,163.06c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <circle id="A18" @click="onSelectSeat('135','A18')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A18') }" cx="123.09" cy="163.86" r=".44"/>
                              <path id="A17" @click="onSelectSeat('135','A17')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A17') }" d="m123,165.51c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53s.3.38.53.32Z"/>
                              <path id="A16" @click="onSelectSeat('135','A16')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A16') }" d="m122.81,166.73c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <circle id="A15" @click="onSelectSeat('135','A15')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A15') }" cx="122.5" cy="167.53" r=".44"/>
                              <path id="A14" @click="onSelectSeat('135','A14')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A14') }" d="m122.42,169.17c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="A13" @click="onSelectSeat('135','A13')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A13') }" d="m122.22,170.39c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="A12" @click="onSelectSeat('135','A12')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A12') }" d="m122.02,171.62c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="A11" @click="onSelectSeat('135','A11')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A11') }" d="m121.83,172.84c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="A10" @click="onSelectSeat('135','A10')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A10') }" d="m121.63,174.06c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="A9" @click="onSelectSeat('135','A9')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A9') }" d="m121.44,175.28c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="A8" @click="onSelectSeat('135','A8')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A8') }" d="m121.24,176.5c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="A7" @click="onSelectSeat('135','A7')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A7') }" d="m121.04,177.73c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="A6" @click="onSelectSeat('135','A6')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A6') }" d="m120.85,178.95c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="A5" @click="onSelectSeat('135','A5')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A5') }" d="m120.65,180.17c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="A4" @click="onSelectSeat('135','A4')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A4') }" d="m120.46,181.39c.23-.06.38-.3.32-.53s-.3-.38-.53-.32-.38.3-.32.53c.06.23.3.38.53.32Z"/>
                              <path id="A3" @click="onSelectSeat('135','A3')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A3') }" d="m120.26,182.61c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53.3.38.53.32Z"/>
                              <path id="A2" @click="onSelectSeat('135','A2')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A2') }" d="m120.06,183.84c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32-.23.06-.38.3-.32.53.06.23.3.38.53.32Z"/>
                              <path id="A1" @click="onSelectSeat('135','A1')" v-show="isSeatVisible === 'section135'" :class="{ 'selected': isSelected('A1') }" d="m119.87,185.06c.23-.06.38-.3.32-.53-.06-.23-.3-.38-.53-.32s-.38.3-.32.53c.06.23.3.38.53.32Z"/>

                            </g>
                            <g id="group133">  
                              <polygon id="section133" @click="isSectionEnabled('133') ? handleZoom('section133') : null" :class="{ 'enabled': isSectionEnabled('133') , 'disabled': !isSectionEnabled('133') }" points="101.51 216.52 100.24 233.25 81.17 232.72 82.55 215.32 101.51 216.52"/>
                              <polygon id="section133" @click="isSectionEnabled('133') ? handleZoom('section133') : null" class="cls-22" points="101.51 216.52 100.91 216.48 99.68 232.63 81.82 232.14 83.1 215.96 101.47 217.13 101.51 216.52 100.91 216.48 101.51 216.52 101.55 215.92 81.99 214.68 80.52 233.31 100.8 233.87 102.16 215.96 101.55 215.92 101.51 216.52"/>
                              <text class="cls-5" transform="translate(82.46 225.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">133</tspan></text>

                              <path id="E1" @click="onSelectSeat('133','E1')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E1') }" d="m86.81,231.39c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="E2" @click="onSelectSeat('133','E2')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E2') }" d="m86.89,230.12c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="E3" @click="onSelectSeat('133','E3')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E3') }" d="m86.96,228.84c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="E4" @click="onSelectSeat('133','E4')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E4') }" d="m87.04,227.56c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="E5" @click="onSelectSeat('133','E5')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E5') }" d="m87.12,226.28c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="E6" @click="onSelectSeat('133','E6')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E6') }" d="m87.19,225c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="E7" @click="onSelectSeat('133','E7')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E7') }" d="m87.27,223.72c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="E8" @click="onSelectSeat('133','E8')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E8') }" d="m87.35,222.44c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="E9" @click="onSelectSeat('133','E9')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E9') }" d="m87.42,221.16c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="E10" @click="onSelectSeat('133','E10')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E10') }" d="m87.5,219.89c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="E11" @click="onSelectSeat('133','E11')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E11') }" d="m87.57,218.61c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="E12" @click="onSelectSeat('133','E12')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('E12') }" d="m87.65,217.33c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                            
                              <path id="F1" @click="onSelectSeat('133','F1')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F1') }" d="m85.48,231.32c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="F2" @click="onSelectSeat('133','F2')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F2') }" d="m85.56,230.04c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <circle id="F3" @click="onSelectSeat('133','F3')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F3') }" cx="85.43" cy="229.16" r=".45" transform="translate(-139.83 133.92) rotate(-46.64)"/>
                              <path id="F4" @click="onSelectSeat('133','F4')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F4') }" d="m85.71,227.48c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <circle id="F5" @click="onSelectSeat('133','F5')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F5') }" cx="85.58" cy="226.6" r=".45" transform="translate(-137.93 133.23) rotate(-46.64)"/>
                              <circle id="F6" @click="onSelectSeat('133','F6')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F6') }" cx="85.65" cy="225.33" r=".45" transform="translate(-136.97 132.88) rotate(-46.64)"/>
                              <path id="F7" @click="onSelectSeat('133','F7')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F7') }" d="m85.94,223.64c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <circle id="F8" @click="onSelectSeat('133','F8')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F8') }" cx="85.81" cy="222.77" r=".45" transform="translate(-135.07 132.19) rotate(-46.64)"/>
                              <path id="F9" @click="onSelectSeat('133','F9')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F9') }" d="m86.09,221.09c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="F10" @click="onSelectSeat('133','F10')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F10') }" d="m86.16,219.81c.22.11.31.39.2.61s-.39.31-.61.2c-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <circle id="F11" @click="onSelectSeat('133','F11')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F11') }" cx="86.04" cy="218.93" r=".45" transform="translate(-132.2 131.16) rotate(-46.64)"/>
                              <path id="F12" @click="onSelectSeat('133','F12')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('F12') }" d="m86.32,217.25c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              
                              <path id="D1" @click="onSelectSeat('133','D1')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D1') }" d="m88.15,231.47c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <circle id="D2" @click="onSelectSeat('133','D2')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D2') }" cx="88.02" cy="230.6" r=".45" transform="translate(-140.07 136.25) rotate(-46.64)"/>
                              <path id="D3" @click="onSelectSeat('133','D3')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D3') }" d="m88.3,228.92c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="D4" @click="onSelectSeat('133','D4')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D4') }" d="m88.37,227.64c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="D5" @click="onSelectSeat('133','D5')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D5') }" d="m88.45,226.36c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="D6" @click="onSelectSeat('133','D6')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D6') }" d="m88.53,225.08c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="D7" @click="onSelectSeat('133','D7')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D7') }" d="m88.6,223.8c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="D8" @click="onSelectSeat('133','D8')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D8') }" d="m88.68,222.52c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="D9" @click="onSelectSeat('133','D9')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D9') }" d="m88.75,221.24c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="D10" @click="onSelectSeat('133','D10')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D10') }" d="m88.83,219.96c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="D11" @click="onSelectSeat('133','D11')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D11') }" d="m88.91,218.69c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="D12" @click="onSelectSeat('133','D12')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('D12') }" d="m88.98,217.41c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              
                              <path id="C1" @click="onSelectSeat('133','C1')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C1') }" d="m89.48,231.55c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="C2" @click="onSelectSeat('133','C2')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C2') }" d="m89.55,230.27c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="C3" @click="onSelectSeat('133','C3')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C3') }" d="m89.63,229c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="C4" @click="onSelectSeat('133','C4')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C4') }" d="m89.71,227.72c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="C5" @click="onSelectSeat('133','C5')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C5') }" d="m89.78,226.44c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="C6" @click="onSelectSeat('133','C6')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C6') }" d="m89.86,225.16c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61.39-.31.61-.2Z"/>
                              <path id="C7" @click="onSelectSeat('133','C7')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C7') }" d="m89.94,223.88c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="C8" @click="onSelectSeat('133','C8')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C8') }" d="m90.01,222.6c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="C9" @click="onSelectSeat('133','C9')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C9') }" d="m90.09,221.32c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="C10" @click="onSelectSeat('133','C10')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C10') }" d="m90.16,220.04c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="C11" @click="onSelectSeat('133','C11')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C11') }" d="m90.24,218.77c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="C12" @click="onSelectSeat('133','C12')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('C12') }" d="m90.32,217.49c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              
                              <path id="B1" @click="onSelectSeat('133','B1')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B1') }" d="m90.81,231.63c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B2" @click="onSelectSeat('133','B2')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B2') }" d="m90.89,230.35c.22.11.31.39.2.61s-.39.31-.61.2c-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="B3" @click="onSelectSeat('133','B3')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B3') }" d="m90.96,229.08c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B4" @click="onSelectSeat('133','B4')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B4') }" d="m91.04,227.8c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B5" @click="onSelectSeat('133','B5')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B5') }" d="m91.12,226.52c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="B6" @click="onSelectSeat('133','B6')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B6') }" d="m91.19,225.24c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B7" @click="onSelectSeat('133','B7')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B7') }" d="m91.27,223.96c.22.11.31.39.2.61s-.39.31-.61.2c-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="B8" @click="onSelectSeat('133','B8')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B8') }" d="m91.34,222.68c.22.11.31.39.2.61-.11.22-.39.31-.61.2s-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B9" @click="onSelectSeat('133','B9')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B9') }" d="m91.42,221.4c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B10" @click="onSelectSeat('133','B10')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B10') }" d="m91.5,220.12c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="B11" @click="onSelectSeat('133','B11')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B11') }" d="m91.57,218.84c.22.11.31.39.2.61s-.39.31-.61.2-.31-.39-.2-.61c.11-.22.39-.31.61-.2Z"/>
                              <path id="B12" @click="onSelectSeat('133','B12')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('B12') }" d="m91.65,217.57c.22.11.31.39.2.61s-.39.31-.61.2c-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              
                              <path id="A1" @click="onSelectSeat('133','A1')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A1') }" d="m92.14,231.71c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="A2" @click="onSelectSeat('133','A2')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A2') }" d="m92.22,230.43c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="A3" @click="onSelectSeat('133','A3')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A3') }" d="m92.3,229.15c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <path id="A4" @click="onSelectSeat('133','A4')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A4') }" d="m92.37,227.88c.22.11.31.39.2.61s-.39.31-.61.2c-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <circle id="A5" @click="onSelectSeat('133','A5')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A5') }" cx="92.24" cy="227" r=".45"/>
                              <path id="A6" @click="onSelectSeat('133','A6')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A6') }" d="m92.52,225.32c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <circle id="A7" @click="onSelectSeat('133','A7')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A7') }" cx="92.4" cy="224.44" r=".45"/>
                              <path id="A8" @click="onSelectSeat('133','A8')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A8') }" d="m92.68,222.76c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <path id="A9" @click="onSelectSeat('133','A9')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A9') }" d="m92.75,221.48c.22.11.31.39.2.61s-.39.31-.61.2c-.22-.11-.31-.39-.2-.61s.39-.31.61-.2Z"/>
                              <circle id="A10" @click="onSelectSeat('133','A10')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A10') }" cx="92.62" cy="220.61" r=".45"/>
                              <path id="A11" @click="onSelectSeat('133','A11')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A11') }" d="m92.91,218.92c.22.11.31.39.2.61-.11.22-.39.31-.61.2-.22-.11-.31-.39-.2-.61.11-.22.39-.31.61-.2Z"/>
                              <circle id="A12" @click="onSelectSeat('133','A12')" v-show="isSeatVisible === 'section133'" :class="{ 'selected': isSelected('A12') }" cx="92.78" cy="218.05" r=".45"/>
                              
                            </g>
                            <g id="group132">  
                              <polygon id="section132" @click="isSectionEnabled('132') ? handleZoom('section132') :  null" :class="{ 'enabled': isSectionEnabled('132') , 'disabled': !isSectionEnabled('132') }" points="81.17 232.72 80.8 236.87 80.73 258.88 114.98 258.88 116.12 233.93 81.17 232.72"/>
                              <polygon class="cls-22" points="81.17 232.72 80.57 232.67 80.19 236.85 80.12 259.49 115.56 259.49 116.75 233.34 80.62 232.1 80.57 232.67 81.17 232.72 81.15 233.33 115.49 234.51 114.4 258.28 81.33 258.28 81.4 236.9 81.77 232.78 81.17 232.72 81.15 233.33 81.17 232.72"/>
                              <text class="cls-5" transform="translate(90.46 250.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">132</tspan></text>

                              <circle id="U1" @click="onSelectSeat('132','U1')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U1') }" cx="84.39" cy="257.46" r=".45"/>
                              <circle id="U2" @click="onSelectSeat('132','U2')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U2') }" cx="84.38" cy="256.25" r=".45"/>
                              <circle id="U3" @click="onSelectSeat('132','U3')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U3') }" cx="84.39" cy="254.83" r=".45"/>
                              <circle id="U4" @click="onSelectSeat('132','U4')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U4') }" cx="84.39" cy="253.59" r=".45"/>
                              <circle id="U5" @click="onSelectSeat('132','U5')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U5') }" cx="84.39" cy="252.44" r=".45"/>
                              <circle id="U6" @click="onSelectSeat('132','U6')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U6') }" cx="84.39" cy="251.16" r=".45"/>
                              <circle id="U7" @click="onSelectSeat('132','U7')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U7') }" cx="84.39" cy="249.75" r=".45"/>
                              <circle id="U8" @click="onSelectSeat('132','U8')" v-show="isSeatVisible === 'section132'" :class="{ 'selected': isSelected('U8') }" cx="84.39" cy="248.57" r=".45"/>

                              <path v-for="seat in seats.section132" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section132'" @click="onSelectSeat('132', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>

                            </g> 
                            <g id="group131"> 
                              <polygon id="section131" @click="isSectionEnabled('131') ? handleZoom('section131') :  null" :class="{ 'enabled': isSectionEnabled('131') , 'disabled': !isSectionEnabled('131') }" points="84.64 296.16 124.39 289.47 122.92 259.56 114.98 258.88 80.73 258.88 80.98 271.49 84.64 296.16"/>
                              <polygon class="cls-22" points="84.64 296.16 84.74 296.75 125.02 289.98 123.5 259.01 115.01 258.28 80.11 258.28 80.38 271.54 84.13 296.86 84.74 296.75 84.64 296.16 85.24 296.07 81.59 271.44 81.34 259.49 114.96 259.49 122.35 260.12 123.76 288.96 84.54 295.56 84.64 296.16 85.24 296.07 84.64 296.16"/>
                              <text class="cls-5" transform="translate(90.46 280.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">131</tspan></text>

                              <path v-for="seat in seats.section131" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section131'" @click="onSelectSeat('131', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            </g> 
                            <g id="group130"> 
                              <polygon id="section130" @click="isSectionEnabled('130') ? handleZoom('section130') : null" :class="{ 'enabled': isSectionEnabled('130') , 'disabled': !isSectionEnabled('130') }" points="129.96 321.73 93.74 336.19 85.72 303.23 84.64 296.16 124.39 289.47 124.51 292.01 129.96 321.73"/>
                              <polygon class="cls-22" points="129.96 321.73 129.73 321.16 94.16 335.37 86.31 303.12 85.33 296.65 123.82 290.18 123.91 292.08 129.36 321.84 129.96 321.73 129.73 321.16 129.96 321.73 130.55 321.62 125.11 291.94 124.96 288.76 83.95 295.66 85.12 303.35 93.31 337.01 130.64 322.1 130.55 321.62 129.96 321.73"/>
                              <text class="cls-5" transform="translate(100.46 315.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">130</tspan></text>
                              
                              <path v-for="seat in seats.section130" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section130'" @click="onSelectSeat('130', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            </g> 
                            <g id="group129"> 
                              <polygon id="section129" @click="isSectionEnabled('129') ? handleZoom('section129') : null" :class="{ 'enabled': isSectionEnabled('129') , 'disabled': !isSectionEnabled('129') }" points="93.74 336.19 129.96 321.73 145.16 346.68 144.48 347.82 142.43 349.4 113.97 368.06 107.95 360.97 93.74 336.19"/>
                              <polygon class="cls-22" points="93.74 336.19 93.96 336.75 129.71 322.48 144.45 346.68 144.02 347.41 142.08 348.91 114.08 367.27 108.45 360.62 94.26 335.89 93.74 336.19 93.96 336.75 93.74 336.19 93.21 336.49 107.45 361.32 113.85 368.86 142.79 349.9 144.94 348.22 145.86 346.68 130.21 320.97 92.86 335.89 93.21 336.49 93.74 336.19"/>
                              <text class="cls-5" transform="translate(110.46 345.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">129</tspan></text>
                              
                              <circle id="T1" @click="onSelectSeat('129','T1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T1') }" cx="114.82" cy="364.38" r=".47"/>
                              <circle id="T2" @click="onSelectSeat('129','T2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T2') }" cx="114.29" cy="363.16" r=".47" transform="translate(-256.06 451.42) rotate(-85.93)"/>
                              <path id="T3" @click="onSelectSeat('129','T3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T3') }" d="m113.76,361.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T4" @click="onSelectSeat('129','T4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T4') }" d="m113.23,360.24c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="T5" @click="onSelectSeat('129','T5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T5') }" cx="112.69" cy="359.49" r=".47"/>
                              <path id="T6" @click="onSelectSeat('129','T6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T6') }" d="m112.16,357.8c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="T7" @click="onSelectSeat('129','T7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T7') }" d="m111.63,356.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T8" @click="onSelectSeat('129','T8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T8') }" d="m111.1,355.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T9" @click="onSelectSeat('129','T9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T9') }" d="m110.57,354.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="T10" @click="onSelectSeat('129','T10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T10') }" d="m110.03,352.91c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T11" @click="onSelectSeat('129','T11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T11') }" d="m109.5,351.69c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="T12" @click="onSelectSeat('129','T12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T12') }" cx="108.97" cy="350.94" r=".47"/>
                              <path id="T13" @click="onSelectSeat('129','T13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T13') }" d="m108.44,349.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="T14" @click="onSelectSeat('129','T14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T14') }" d="m107.91,348.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T15" @click="onSelectSeat('129','T15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T15') }" d="m107.37,346.8c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="T16" @click="onSelectSeat('129','T16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T16') }" cx="106.84" cy="346.05" r=".47"/>
                              <path id="T17" @click="onSelectSeat('129','T17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T17') }" d="m106.31,344.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="T18" @click="onSelectSeat('129','T18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T18') }" d="m105.78,343.14c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T19" @click="onSelectSeat('129','T19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T19') }" d="m105.24,341.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="T20" @click="onSelectSeat('129','T20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T20') }" cx="104.71" cy="341.17" r=".47"/>
                              <path id="T21" @click="onSelectSeat('129','T21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T21') }" d="m104.18,339.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T22" @click="onSelectSeat('129','T22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T22') }" d="m103.65,338.25c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T23" @click="onSelectSeat('129','T23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T23') }" d="m103.12,337.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="T24" @click="onSelectSeat('129','T24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T24') }" cx="102.58" cy="336.28" r=".47"/>
                              <path id="T25" @click="onSelectSeat('129','T25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T25') }" d="m102.05,334.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="T26" @click="onSelectSeat('129','T26')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('T26') }" d="m101.49,333.29c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              
                              <path id="U1" @click="onSelectSeat('129','U1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U1') }" d="m114.08,365.69c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U2" @click="onSelectSeat('129','U2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U2') }" d="m113.55,364.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U3" @click="onSelectSeat('129','U3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U3') }" d="m113.02,363.24c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="U4" @click="onSelectSeat('129','U4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U4') }" cx="112.49" cy="362.49" r=".47"/>
                              <path id="U5" @click="onSelectSeat('129','U5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U5') }" d="m111.95,360.8c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U6" @click="onSelectSeat('129','U6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U6') }" d="m111.42,359.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="U7" @click="onSelectSeat('129','U7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U7') }" cx="110.89" cy="358.83" r=".47"/>
                              <circle id="U8" @click="onSelectSeat('129','U8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U8') }" cx="110.36" cy="357.61" r=".47"/>
                              <path id="U9" @click="onSelectSeat('129','U9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U9') }" d="m109.82,355.91c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U10" @click="onSelectSeat('129','U10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U10') }" d="m109.29,354.69c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="U11" @click="onSelectSeat('129','U11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U11') }" cx="108.76" cy="353.94" r=".47"/>
                              <path id="U12" @click="onSelectSeat('129','U12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U12') }" d="m108.23,352.25c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="U13" @click="onSelectSeat('129','U13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U13') }" d="m107.7,351.02c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U14" @click="onSelectSeat('129','U14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U14') }" d="m107.16,349.8c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="U15" @click="onSelectSeat('129','U15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U15') }" cx="106.63" cy="349.05" r=".47"/>
                              <path id="U16" @click="onSelectSeat('129','U16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U16') }" d="m106.1,347.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U17" @click="onSelectSeat('129','U17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U17') }" d="m105.57,346.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="U18" @click="onSelectSeat('129','U18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U18') }" cx="105.04" cy="345.39" r=".47"/>
                              <circle id="U19" @click="onSelectSeat('129','U19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U19') }" cx="104.5" cy="344.16" r=".47" transform="translate(-246.2 424.01) rotate(-85.93)"/>
                              <path id="U20" @click="onSelectSeat('129','U20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U20') }" d="m103.97,342.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U21" @click="onSelectSeat('129','U21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U21') }" d="m103.44,341.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="U22" @click="onSelectSeat('129','U22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U22') }" cx="102.91" cy="340.5" r=".47"/>
                              <circle id="U23" @click="onSelectSeat('129','U23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U23') }" cx="102.37" cy="339.28" r=".47" transform="translate(-243.31 417.34) rotate(-85.93)"/>
                              <path id="U24" @click="onSelectSeat('129','U24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U24') }" d="m101.84,337.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U25" @click="onSelectSeat('129','U25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U25') }" d="m101.31,336.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="U26" @click="onSelectSeat('129','U26')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U26') }" d="m100.78,335.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="U27" @click="onSelectSeat('129','U27')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('U27') }" cx="100.22" cy="334.32" r=".47"/>
                          
                              <path id="S1" @click="onSelectSeat('129','S1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S1') }" d="m116.1,363.36c.26,0,.47.21.47.47,0,.26-.21.47-.47.47-.26,0-.47-.21-.47-.47,0-.26.21-.47.47-.47Z"/>
                              <path id="S2" @click="onSelectSeat('129','S2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S2') }" d="m115.56,362.13c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S3" @click="onSelectSeat('129','S3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S3') }" d="m115.03,360.91c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="S4" @click="onSelectSeat('129','S4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S4') }" cx="114.5" cy="360.16" r=".47"/>
                              <path id="S5" @click="onSelectSeat('129','S5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S5') }" d="m113.97,358.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S6" @click="onSelectSeat('129','S6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S6') }" d="m113.44,357.25c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="S7" @click="onSelectSeat('129','S7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S7') }" cx="112.9" cy="356.5" r=".47"/>
                              <circle id="S8" @click="onSelectSeat('129','S8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S8') }" cx="112.37" cy="355.27" r=".47"/>
                              <path id="S9" @click="onSelectSeat('129','S9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S9') }" d="m111.84,353.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S10" @click="onSelectSeat('129','S10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S10') }" d="m111.31,352.36c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="S11" @click="onSelectSeat('129','S11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S11') }" cx="110.78" cy="351.61" r=".47"/>
                              <circle id="S12" @click="onSelectSeat('129','S12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S12') }" cx="110.24" cy="350.39" r=".47"/>
                              <path id="S13" @click="onSelectSeat('129','S13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S13') }" d="m109.71,348.69c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S14" @click="onSelectSeat('129','S14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S14') }" d="m109.18,347.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="S15" @click="onSelectSeat('129','S15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S15') }" cx="108.65" cy="346.72" r=".47"/>
                              <path id="S16" @click="onSelectSeat('129','S16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S16') }" d="m108.11,345.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S17" @click="onSelectSeat('129','S17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S17') }" d="m107.58,343.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S18" @click="onSelectSeat('129','S18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S18') }" d="m107.05,342.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="S19" @click="onSelectSeat('129','S19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S19') }" cx="106.52" cy="341.83" r=".47"/>
                              <path id="S20" @click="onSelectSeat('129','S20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S20') }" d="m105.99,340.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S21" @click="onSelectSeat('129','S21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S21') }" d="m105.45,338.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="S22" @click="onSelectSeat('129','S22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S22') }" cx="104.92" cy="338.17" r=".47"/>
                              <circle id="S23" @click="onSelectSeat('129','S23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S23') }" cx="104.39" cy="336.95" r=".47"/>
                              <path id="S24" @click="onSelectSeat('129','S24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S24') }" d="m103.86,335.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S25" @click="onSelectSeat('129','S25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S25') }" d="m103.33,334.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="S26" @click="onSelectSeat('129','S26')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('S26') }" d="m102.76,332.74c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>

                              <path id="R1" @click="onSelectSeat('129','R1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R1') }" d="m117.37,362.8c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R2" @click="onSelectSeat('129','R2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R2') }" cx="116.84" cy="362.05" r=".47"/>
                              <circle id="R3" @click="onSelectSeat('129','R3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R3') }" cx="116.31" cy="360.83" r=".47"/>
                              <path id="R4" @click="onSelectSeat('129','R4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R4') }" d="m115.77,359.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R5" @click="onSelectSeat('129','R5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R5') }" d="m115.24,357.91c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R6" @click="onSelectSeat('129','R6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R6') }" cx="114.71" cy="357.16" r=".47"/>
                              <circle id="R7" @click="onSelectSeat('129','R7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R7') }" cx="114.18" cy="355.94" r=".47"/>
                              <path id="R8" @click="onSelectSeat('129','R8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R8') }" d="m113.65,354.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R9" @click="onSelectSeat('129','R9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R9') }" d="m113.11,353.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R10" @click="onSelectSeat('129','R10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R10') }" cx="112.58" cy="352.28" r=".47"/>
                              <path id="R11" @click="onSelectSeat('129','R11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R11') }" d="m112.05,350.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R12" @click="onSelectSeat('129','R12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R12') }" d="m111.52,349.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R13" @click="onSelectSeat('129','R13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R13') }" d="m110.98,348.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R14" @click="onSelectSeat('129','R14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R14') }" cx="110.45" cy="347.39" r=".47"/>
                              <path id="R15" @click="onSelectSeat('129','R15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R15') }" d="m109.92,345.69c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R16" @click="onSelectSeat('129','R16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R16') }" d="m109.39,344.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R17" @click="onSelectSeat('129','R17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R17') }" cx="108.86" cy="343.72" r=".47"/>
                              <circle id="R18" @click="onSelectSeat('129','R18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R18') }" cx="108.32" cy="342.5" r=".47"/>
                              <path id="R19" @click="onSelectSeat('129','R19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R19') }" d="m107.79,340.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R20" @click="onSelectSeat('129','R20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R20') }" d="m107.26,339.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R21" @click="onSelectSeat('129','R21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R21') }" cx="106.73" cy="338.84" r=".47"/>
                              <path id="R22" @click="onSelectSeat('129','R22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R22') }" d="m106.2,337.14c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="R23" @click="onSelectSeat('129','R23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R23') }" d="m105.66,335.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="R24" @click="onSelectSeat('129','R24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R24') }" d="m105.13,334.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="R25" @click="onSelectSeat('129','R25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R25') }" cx="104.6" cy="333.95" r=".47"/>
                              <path id="R26" @click="onSelectSeat('129','R26')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('R26') }" d="m104.04,332.18c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>

                              <circle id="Q1" @click="onSelectSeat('129','Q1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q1') }" cx="118.11" cy="361.5" r=".47"/>
                              <path id="Q2" @click="onSelectSeat('129','Q2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q2') }" d="m117.58,359.8c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q3" @click="onSelectSeat('129','Q3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q3') }" d="m117.05,358.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="Q4" @click="onSelectSeat('129','Q4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q4') }" cx="116.52" cy="357.83" r=".47"/>
                              <path id="Q5" @click="onSelectSeat('129','Q5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q5') }" d="m115.98,356.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q6" @click="onSelectSeat('129','Q6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q6') }" d="m115.45,354.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q7" @click="onSelectSeat('129','Q7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q7') }" d="m114.92,353.69c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="Q8" @click="onSelectSeat('129','Q8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q8') }" cx="114.39" cy="352.94" r=".47"/>
                              <path id="Q9" @click="onSelectSeat('129','Q9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q9') }" d="m113.85,351.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q10" @click="onSelectSeat('129','Q10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q10') }" d="m113.32,350.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="Q11" @click="onSelectSeat('129','Q11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q11') }" cx="112.79" cy="349.28" r=".47"/>
                              <circle id="Q12" @click="onSelectSeat('129','Q12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q12') }" cx="112.26" cy="348.06" r=".47"/>
                              <path id="Q13" @click="onSelectSeat('129','Q13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q13') }" d="m111.73,346.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q14" @click="onSelectSeat('129','Q14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q14') }" d="m111.19,345.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="Q15" @click="onSelectSeat('129','Q15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q15') }" cx="110.66" cy="344.39" r=".47"/>
                              <path id="Q16" @click="onSelectSeat('129','Q16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q16') }" d="m110.13,342.7c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="Q17" @click="onSelectSeat('129','Q17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q17') }" d="m109.6,341.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q18" @click="onSelectSeat('129','Q18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q18') }" d="m109.07,340.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="Q19" @click="onSelectSeat('129','Q19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q19') }" cx="108.53" cy="339.5" r=".47"/>
                              <path id="Q20" @click="onSelectSeat('129','Q20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q20') }" d="m108,337.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q21" @click="onSelectSeat('129','Q21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q21') }" d="m107.47,336.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="Q22" @click="onSelectSeat('129','Q22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q22') }" cx="106.94" cy="335.84" r=".47"/>
                              <path id="Q23" @click="onSelectSeat('129','Q23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q23') }" d="m106.4,334.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="Q24" @click="onSelectSeat('129','Q24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q24') }" d="m105.87,332.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="Q25" @click="onSelectSeat('129','Q25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('Q25') }" d="m105.31,331.63c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                        
                              <path id="P1" @click="onSelectSeat('129','P1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P1') }" d="m119.39,360.47c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P2" @click="onSelectSeat('129','P2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P2') }" d="m118.85,359.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="P3" @click="onSelectSeat('129','P3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P3') }" cx="118.32" cy="358.5" r=".47"/>
                              <path id="P4" @click="onSelectSeat('129','P4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P4') }" d="m117.79,356.8c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P5" @click="onSelectSeat('129','P5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P5') }" d="m117.26,355.58c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="P6" @click="onSelectSeat('129','P6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P6') }" cx="116.72" cy="354.83" r=".47"/>
                              <path id="P7" @click="onSelectSeat('129','P7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P7') }" d="m116.19,353.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="P8" @click="onSelectSeat('129','P8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P8') }" d="m115.66,351.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P9" @click="onSelectSeat('129','P9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P9') }" d="m115.13,350.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="P10" @click="onSelectSeat('129','P10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P10') }" cx="114.6" cy="349.94" r=".47"/>
                              <path id="P11" @click="onSelectSeat('129','P11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P11') }" d="m114.06,348.25c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47,0-.26.21-.47.47-.47Z"/>
                              <path id="P12" @click="onSelectSeat('129','P12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P12') }" d="m113.53,347.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P13" @click="onSelectSeat('129','P13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P13') }" d="m113,345.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="P14" @click="onSelectSeat('129','P14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P14') }" cx="112.47" cy="345.06" r=".47"/>
                              <path id="P15" @click="onSelectSeat('129','P15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P15') }" d="m111.94,343.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="P16" @click="onSelectSeat('129','P16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P16') }" d="m111.4,342.14c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="P17" @click="onSelectSeat('129','P17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P17') }" cx="110.87" cy="341.39" r=".47"/>
                              <circle id="P18" @click="onSelectSeat('129','P18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P18') }" cx="110.34" cy="340.17" r=".47"/>
                              <path id="P19" @click="onSelectSeat('129','P19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P19') }" d="m109.81,338.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P20" @click="onSelectSeat('129','P20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P20') }" d="m109.27,337.25c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="P21" @click="onSelectSeat('129','P21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P21') }" cx="108.74" cy="336.5" r=".47"/>
                              <circle id="P22" @click="onSelectSeat('129','P22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P22') }" cx="108.21" cy="335.28" r=".47"/>
                              <path id="P23" @click="onSelectSeat('129','P23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P23') }" d="m107.68,333.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P24" @click="onSelectSeat('129','P24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P24') }" d="m107.15,332.37c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="P25" @click="onSelectSeat('129','P25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('P25') }" d="m106.58,331.07c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                            
                              <circle id="O1" @click="onSelectSeat('129','O1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O1') }" cx="120.66" cy="360.39" r=".47"/>
                              <circle id="O2" @click="onSelectSeat('129','O2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O2') }" cx="120.13" cy="359.17" r=".47"/>
                              <path id="O3" @click="onSelectSeat('129','O3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O3') }" d="m119.59,357.47c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="O4" @click="onSelectSeat('129','O4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O4') }" d="m119.06,356.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="O5" @click="onSelectSeat('129','O5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O5') }" cx="118.53" cy="355.5" r=".47"/>
                              <path id="O6" @click="onSelectSeat('129','O6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O6') }" d="m118,353.81c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="O7" @click="onSelectSeat('129','O7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O7') }" d="m117.47,352.58c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="O8" @click="onSelectSeat('129','O8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O8') }" d="m116.93,351.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="O9" @click="onSelectSeat('129','O9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O9') }" cx="116.4" cy="350.61" r=".47"/>
                              <path id="O10" @click="onSelectSeat('129','O10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O10') }" d="m115.87,348.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="O11" @click="onSelectSeat('129','O11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O11') }" d="m115.34,347.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="O12" @click="onSelectSeat('129','O12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O12') }" cx="114.81" cy="346.95" r=".47"/>
                              <circle id="O13" @click="onSelectSeat('129','O13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O13') }" cx="114.27" cy="345.72" r=".47"/>
                              <path id="O14" @click="onSelectSeat('129','O14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O14') }" d="m113.74,344.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="O15" @click="onSelectSeat('129','O15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O15') }" d="m113.21,342.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="O16" @click="onSelectSeat('129','O16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O16') }" cx="112.68" cy="342.06" r=".47"/>
                              <circle id="O17" @click="onSelectSeat('129','O17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O17') }" cx="112.14" cy="340.84" r=".47"/>
                              <path id="O18" @click="onSelectSeat('129','O18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O18') }" d="m111.61,339.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="O19" @click="onSelectSeat('129','O19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O19') }" d="m111.08,337.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="O20" @click="onSelectSeat('129','O20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O20') }" cx="110.55" cy="337.17" r=".47"/>
                              <path id="O21" @click="onSelectSeat('129','O21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O21') }" d="m110.02,335.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="O22" @click="onSelectSeat('129','O22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O22') }" d="m109.48,334.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="O23" @click="onSelectSeat('129','O23')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O23') }" cx="108.95" cy="333.51" r=".47"/>
                              <circle id="O24" @click="onSelectSeat('129','O24')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O24') }" cx="108.42" cy="332.28" r=".47"/>
                              <circle id="O25" @click="onSelectSeat('129','O25')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('O25') }" cx="107.86" cy="330.99" r=".47"/>
                              
                              <path id="N1" @click="onSelectSeat('129','N1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N1') }" d="m119.27,353.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="N2" @click="onSelectSeat('129','N2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N2') }" cx="118.74" cy="352.5" r=".47"/>
                              <circle id="N3" @click="onSelectSeat('129','N3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N3') }" cx="118.21" cy="351.28" r=".47"/>
                              <path id="N4" @click="onSelectSeat('129','N4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N4') }" d="m117.68,349.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="N5" @click="onSelectSeat('129','N5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N5') }" d="m117.14,348.36c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="N6" @click="onSelectSeat('129','N6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N6') }" cx="116.61" cy="347.61" r=".47"/>
                              <circle id="N7" @click="onSelectSeat('129','N7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N7') }" cx="116.08" cy="346.39" r=".47"/>
                              <path id="N8" @click="onSelectSeat('129','N8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N8') }" d="m115.55,344.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="N9" @click="onSelectSeat('129','N9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N9') }" d="m115.01,343.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="N10" @click="onSelectSeat('129','N10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N10') }" cx="114.48" cy="342.73" r=".47"/>
                              <path id="N11" @click="onSelectSeat('129','N11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N11') }" d="m113.95,341.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="N12" @click="onSelectSeat('129','N12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N12') }" d="m113.42,339.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="N13" @click="onSelectSeat('129','N13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N13') }" cx="112.89" cy="339.06" r=".47"/>
                              <circle id="N14" @click="onSelectSeat('129','N14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N14') }" cx="112.35" cy="337.84" r=".47"/>
                              <path id="N15" @click="onSelectSeat('129','N15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N15') }" d="m111.82,336.14c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="N16" @click="onSelectSeat('129','N16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N16') }" d="m111.29,334.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="N17" @click="onSelectSeat('129','N17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N17') }" cx="110.76" cy="334.17" r=".47"/>
                              <circle id="N18" @click="onSelectSeat('129','N18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N18') }" cx="110.23" cy="332.95" r=".47"/>
                              <path id="N19" @click="onSelectSeat('129','N19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('N19') }" d="m109.69,331.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                            
                              <circle id="M1" @click="onSelectSeat('129','M1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M1') }" cx="120.55" cy="353.17" r=".47"/>
                              <circle id="M2" @click="onSelectSeat('129','M2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M2') }" cx="120.01" cy="351.95" r=".47"/>
                              <path id="M3" @click="onSelectSeat('129','M3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M3') }" d="m119.48,350.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="M4" @click="onSelectSeat('129','M4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M4') }" d="m118.95,349.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="M5" @click="onSelectSeat('129','M5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M5') }" cx="118.42" cy="348.28" r=".47"/>
                              <path id="M6" @click="onSelectSeat('129','M6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M6') }" d="m117.88,346.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="M7" @click="onSelectSeat('129','M7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M7') }" d="m117.35,345.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="M8" @click="onSelectSeat('129','M8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M8') }" cx="116.82" cy="344.62" r=".47"/>
                              <circle id="M9" @click="onSelectSeat('129','M9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M9') }" cx="116.29" cy="343.39" r=".47"/>
                              <path id="M10" @click="onSelectSeat('129','M10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M10') }" d="m115.76,341.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="M11" @click="onSelectSeat('129','M11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M11') }" d="m115.22,340.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="M12" @click="onSelectSeat('129','M12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M12') }" cx="114.69" cy="339.73" r=".47"/>
                              <circle id="M13" @click="onSelectSeat('129','M13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M13') }" cx="114.16" cy="338.51" r=".47"/>
                              <path id="M14" @click="onSelectSeat('129','M14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M14') }" d="m113.63,336.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="M15" @click="onSelectSeat('129','M15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M15') }" d="m113.1,335.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="M16" @click="onSelectSeat('129','M16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M16') }" cx="112.56" cy="334.84" r=".47"/>
                              <path id="M17" @click="onSelectSeat('129','M17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M17') }" d="m112.03,333.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="M18" @click="onSelectSeat('129','M18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M18') }" d="m111.5,331.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="M19" @click="onSelectSeat('129','M19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('M19') }" d="m110.97,330.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                            
                              <path id="L1" @click="onSelectSeat('129','L1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L1') }" d="m121.29,350.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="L2" @click="onSelectSeat('129','L2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L2') }" cx="120.75" cy="350.17" r=".47"/>
                              <circle id="L3" @click="onSelectSeat('129','L3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L3') }" cx="120.22" cy="348.95" r=".47"/>
                              <path id="L4" @click="onSelectSeat('129','L4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L4') }" d="m119.69,347.25c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="L5" @click="onSelectSeat('129','L5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L5') }" d="m119.16,346.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="L6" @click="onSelectSeat('129','L6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L6') }" cx="118.63" cy="345.28" r=".47"/>
                              <circle id="L7" @click="onSelectSeat('129','L7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L7') }" cx="118.09" cy="344.06" r=".47"/>
                              <path id="L8" @click="onSelectSeat('129','L8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L8') }" d="m117.56,342.37c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="L9" @click="onSelectSeat('129','L9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L9') }" d="m117.03,341.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="L10" @click="onSelectSeat('129','L10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L10') }" cx="116.5" cy="340.39" r=".47"/>
                              <path id="L11" @click="onSelectSeat('129','L11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L11') }" d="m115.97,338.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="L12" @click="onSelectSeat('129','L12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L12') }" d="m115.43,337.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="L13" @click="onSelectSeat('129','L13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L13') }" d="m114.9,336.26c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="L14" @click="onSelectSeat('129','L14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L14') }" cx="114.37" cy="335.51" r=".47"/>
                              <path id="L15" @click="onSelectSeat('129','L15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L15') }"  d="m113.84,333.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="L16" @click="onSelectSeat('129','L16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L16') }"  d="m113.3,332.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="L17" @click="onSelectSeat('129','L17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L17') }"  d="m112.77,331.37c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="L18" @click="onSelectSeat('129','L18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('L18') }"  cx="112.24" cy="330.62" r=".47"/>
                            
                              <circle id="K1" @click="onSelectSeat('129','K1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K1') }" cx="122.56" cy="350.84" r=".47"/>
                              <circle id="K2" @click="onSelectSeat('129','K2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K2') }" cx="122.03" cy="349.62" r=".47"/>
                              <path id="K3" @click="onSelectSeat('129','K3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K3') }" d="m121.5,347.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="K4" @click="onSelectSeat('129','K4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K4') }" d="m120.96,346.7c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="K5" @click="onSelectSeat('129','K5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K5') }" cx="120.43" cy="345.95" r=".47"/>
                              <path id="K6" @click="onSelectSeat('129','K6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K6') }" d="m119.9,344.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="K7" @click="onSelectSeat('129','K7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K7') }" d="m119.37,343.03c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="K8" @click="onSelectSeat('129','K8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K8') }" d="m118.84,341.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="K9" @click="onSelectSeat('129','K9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K9') }" cx="118.3" cy="341.06" r=".47"/>
                              <path id="K10" @click="onSelectSeat('129','K10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K10') }" d="m117.77,339.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="K11" @click="onSelectSeat('129','K11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K11') }" d="m117.24,338.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="K12" @click="onSelectSeat('129','K12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K12') }" cx="116.71" cy="337.4" r=".47"/>
                              <circle id="K13" @click="onSelectSeat('129','K13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K13') }" cx="116.17" cy="336.17" r=".47"/>
                              <path id="K14" @click="onSelectSeat('129','K14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K14') }" d="m115.64,334.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="K15" @click="onSelectSeat('129','K15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K15') }" d="m115.11,333.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="K16" @click="onSelectSeat('129','K16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K16') }" cx="114.58" cy="332.51" r=".47"/>
                              <path id="K17" @click="onSelectSeat('129','K17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K17') }" d="m114.05,330.82c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="K18" @click="onSelectSeat('129','K18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('K18') }" d="m113.51,329.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              
                              <path id="J1" @click="onSelectSeat('129','J1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J1') }" d="m123.3,348.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="J2" @click="onSelectSeat('129','J2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J2') }" d="m122.77,347.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="J3" @click="onSelectSeat('129','J3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J3') }" cx="122.24" cy="346.62" r=".47"/>
                              <path id="J4" @click="onSelectSeat('129','J4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J4') }" d="m121.71,344.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="J5" @click="onSelectSeat('129','J5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J5') }" d="m121.17,343.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="J6" @click="onSelectSeat('129','J6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J6') }" cx="120.64" cy="342.95" r=".47"/>
                              <circle id="J7" @click="onSelectSeat('129','J7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J7') }" cx="120.11" cy="341.73" r=".47"/>
                              <path id="J8" @click="onSelectSeat('129','J8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J8') }" d="m119.58,340.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="J9" @click="onSelectSeat('129','J9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J9') }" d="m119.04,338.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="J10" @click="onSelectSeat('129','J10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J10') }" cx="118.51" cy="338.06" r=".47"/>
                              <path id="J11" @click="onSelectSeat('129','J11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J11') }" d="m117.98,336.37c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="J12" @click="onSelectSeat('129','J12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J12') }" d="m117.45,335.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="J13" @click="onSelectSeat('129','J13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J13') }" d="m116.92,333.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="J14" @click="onSelectSeat('129','J14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J14') }" cx="116.38" cy="333.18" r=".47"/>
                              <path id="J15" @click="onSelectSeat('129','J15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J15') }" d="m115.85,331.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="J16" @click="onSelectSeat('129','J16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J16') }" d="m115.32,330.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="J17" @click="onSelectSeat('129','J17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('J17') }" cx="114.79" cy="329.51" r=".47"/>
                            
                              <circle id="I1" @click="onSelectSeat('129','I1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I1') }" cx="124.58" cy="348.51" r=".47"/>
                              <circle id="I2" @click="onSelectSeat('129','I2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I2') }" cx="124.04" cy="347.28" r=".47"/>
                              <path id="I3" @click="onSelectSeat('129','I3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I3') }" d="m123.51,345.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="I4" @click="onSelectSeat('129','I4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I4') }" d="m122.98,344.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="I5" @click="onSelectSeat('129','I5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I5') }" cx="122.45" cy="343.62" r=".47"/>
                              <path id="I6" @click="onSelectSeat('129','I6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I6') }" d="m121.91,341.92c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="I7" @click="onSelectSeat('129','I7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I7') }" d="m121.38,340.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="I8" @click="onSelectSeat('129','I8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I8') }" d="m120.85,339.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="I9" @click="onSelectSeat('129','I9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I9') }" cx="120.32" cy="338.73" r=".47"/>
                              <path id="I10" @click="onSelectSeat('129','I10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I10') }" d="m119.79,337.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="I11" @click="onSelectSeat('129','I11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I11') }" d="m119.25,335.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="I12" @click="onSelectSeat('129','I12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I12') }" cx="118.72" cy="335.07" r=".47"/>
                              <circle id="I13" @click="onSelectSeat('129','I13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I13') }" cx="118.19" cy="333.84" r=".47"/>
                              <path id="I14" @click="onSelectSeat('129','I14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I14') }" d="m117.66,332.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="I15" @click="onSelectSeat('129','I15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I15') }" d="m117.13,330.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="I16" @click="onSelectSeat('129','I16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I16') }" cx="116.59" cy="330.18" r=".47"/>
                              <circle id="I17" @click="onSelectSeat('129','I17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('I17') }" cx="116.06" cy="328.96" r=".47"/>

                              <circle id="H1" @click="onSelectSeat('129','H1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H1') }" cx="128.51" cy="354.06" r=".47"/>
                              <circle id="H2" @click="onSelectSeat('129','H2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H2') }" cx="127.98" cy="352.84" r=".47"/>     
                              <path id="H3" @click="onSelectSeat('129','H3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H3') }" d="m127.45,351.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="H4" @click="onSelectSeat('129','H4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H4') }" d="m126.91,349.92c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="H5" @click="onSelectSeat('129','H5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H5') }" cx="126.38" cy="349.17" r=".47"/>
                              <path id="H6" @click="onSelectSeat('129','H6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H6') }" d="m125.85,347.48c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47,0-.26.21-.47.47-.47Z"/>
                              <path id="H7" @click="onSelectSeat('129','H7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H7') }" d="m125.32,346.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="H8" @click="onSelectSeat('129','H8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H8') }" d="m124.78,345.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="H9" @click="onSelectSeat('129','H9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H9') }" cx="124.25" cy="344.29" r=".47"/>
                              <path id="H10" @click="onSelectSeat('129','H10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H10') }" d="m123.72,342.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="H11" @click="onSelectSeat('129','H11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H11') }" d="m123.19,341.37c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="H12" @click="onSelectSeat('129','H12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H12') }" cx="122.66" cy="340.62" r=".47"/>
                              <circle id="H13" @click="onSelectSeat('129','H13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H13') }" cx="122.12" cy="339.4" r=".47"/>
                              <path id="H14" @click="onSelectSeat('129','H14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H14') }" d="m121.59,337.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="H15" @click="onSelectSeat('129','H15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H15') }" d="m121.06,336.48c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="H16" @click="onSelectSeat('129','H16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H16') }" cx="120.53" cy="335.73" r=".47"/>
                              <circle id="H17" @click="onSelectSeat('129','H17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H17') }" cx="120" cy="334.51" r=".47"/>
                              <path id="H18" @click="onSelectSeat('129','H18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H18') }" d="m119.46,332.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="H19" @click="onSelectSeat('129','H19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H19') }" d="m118.93,331.6c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="H20" @click="onSelectSeat('129','H20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H20') }" cx="118.4" cy="330.84" r=".47"/>
                              <path id="H21" @click="onSelectSeat('129','H21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H21') }" d="m117.87,329.15c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="H22" @click="onSelectSeat('129','H22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('H22') }" d="m117.33,327.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>

                              <path id="G1" @click="onSelectSeat('129','G1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G1') }" d="m129.78,353.03c.26,0,.47.21.47.47,0,.26-.21.47-.47.47-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="G2" @click="onSelectSeat('129','G2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G2') }" d="m129.25,351.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G3" @click="onSelectSeat('129','G3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G3') }" d="m128.72,350.59c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="G4" @click="onSelectSeat('129','G4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G4') }" cx="128.19" cy="349.84" r=".47"/>
                              <path id="G5" @click="onSelectSeat('129','G5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G5') }" d="m127.65,348.15c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G6" @click="onSelectSeat('129','G6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G6') }" d="m127.12,346.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="G7" @click="onSelectSeat('129','G7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G7') }" cx="126.59" cy="346.17" r=".47"/>
                              <circle id="G8" @click="onSelectSeat('129','G8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G8') }" cx="126.06" cy="344.95" r=".47"/>
                              <path id="G9" @click="onSelectSeat('129','G9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G9') }" d="m125.53,343.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G10" @click="onSelectSeat('129','G10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G10') }" d="m124.99,342.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="G11" @click="onSelectSeat('129','G11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G11') }" cx="124.46" cy="341.29" r=".47"/>
                              <circle id="G12" @click="onSelectSeat('129','G12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G12') }" cx="123.93" cy="340.07" r=".47"/>
                              <path id="G13" @click="onSelectSeat('129','G13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G13') }" d="m123.4,338.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G14" @click="onSelectSeat('129','G14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G14') }" d="m122.87,337.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="G15" @click="onSelectSeat('129','G15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G15') }" cx="122.33" cy="336.4" r=".47"/>
                              <path id="G16" @click="onSelectSeat('129','G16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G16') }" d="m121.8,334.71c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G17" @click="onSelectSeat('129','G17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G17') }" d="m121.27,333.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G18" @click="onSelectSeat('129','G18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G18') }" d="m120.74,332.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="G19" @click="onSelectSeat('129','G19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G19') }" cx="120.2" cy="331.51" r=".47"/>
                              <path id="G20" @click="onSelectSeat('129','G20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G20') }" d="m119.67,329.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="G21" @click="onSelectSeat('129','G21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G21') }" d="m119.14,328.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="G22" @click="onSelectSeat('129','G22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('G22') }" cx="118.61" cy="327.85" r=".47"/>
                            
                              <path id="F1" @click="onSelectSeat('129','F1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F1') }" d="m131.06,352.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="F2" @click="onSelectSeat('129','F2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F2') }" cx="130.52" cy="351.73" r=".47"/>
                              <circle id="F3" @click="onSelectSeat('129','F3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F3') }" cx="129.99" cy="350.51" r=".47"/>
                              <path id="F4" @click="onSelectSeat('129','F4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F4') }" d="m129.46,348.81c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="F5" @click="onSelectSeat('129','F5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F5') }" d="m128.93,347.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="F6" @click="onSelectSeat('129','F6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F6') }" cx="128.4" cy="346.84" r=".47"/>
                              <circle id="F7" @click="onSelectSeat('129','F7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F7') }" cx="127.86" cy="345.62" r=".47"/>
                              <path id="F8" @click="onSelectSeat('129','F8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F8') }" d="m127.33,343.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="F9" @click="onSelectSeat('129','F9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F9') }" d="m126.8,342.7c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="F10" @click="onSelectSeat('129','F10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F10') }" cx="126.27" cy="341.95" r=".47"/>
                              <path id="F11" @click="onSelectSeat('129','F11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F11') }" d="m125.74,340.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="F12" @click="onSelectSeat('129','F12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F12') }" d="m125.2,339.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="F13" @click="onSelectSeat('129','F13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F13') }" d="m124.67,337.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="F14" @click="onSelectSeat('129','F14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F14') }" cx="124.14" cy="337.07" r=".47"/>
                              <path id="F15" @click="onSelectSeat('129','F15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F15') }" d="m123.61,335.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="F16" @click="onSelectSeat('129','F16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F16') }" d="m123.07,334.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="F17" @click="onSelectSeat('129','F17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F17') }" cx="122.54" cy="333.4" r=".47"/>
                              <circle id="F18" @click="onSelectSeat('129','F18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F18') }" cx="122.01" cy="332.18" r=".47"/>
                              <path id="F19" @click="onSelectSeat('129','F19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F19') }"  d="m121.48,330.49c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="F20" @click="onSelectSeat('129','F20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F20') }"  d="m120.95,329.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="F21" @click="onSelectSeat('129','F21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F21') }"  cx="120.41" cy="328.51" r=".47"/>
                              <path id="F22" @click="onSelectSeat('129','F22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('F22') }"  d="m119.88,326.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                            
                              <circle id="E1" @click="onSelectSeat('129','E1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E1') }" cx="132.33" cy="352.4" r=".47"/>
                              <circle id="E2" @click="onSelectSeat('129','E2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E2') }" cx="131.8" cy="351.18" r=".47"/>
                              <path id="E3" @click="onSelectSeat('129','E3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E3') }" d="m131.27,349.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="E4" @click="onSelectSeat('129','E4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E4') }" d="m130.73,348.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="E5" @click="onSelectSeat('129','E5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E5') }" cx="130.2" cy="347.51" r=".47"/>
                              <path id="E6" @click="onSelectSeat('129','E6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E6') }" d="m129.67,345.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="E7" @click="onSelectSeat('129','E7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E7') }" d="m129.14,344.59c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="E8" @click="onSelectSeat('129','E8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E8') }" d="m128.61,343.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="E9" @click="onSelectSeat('129','E9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E9') }" cx="128.07" cy="342.62" r=".47"/>
                              <path id="E10" @click="onSelectSeat('129','E10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E10') }" d="m127.54,340.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="E11" @click="onSelectSeat('129','E11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E11') }" d="m127.01,339.71c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="E12" @click="onSelectSeat('129','E12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E12') }" cx="126.48" cy="338.96" r=".47"/>
                              <circle id="E13" @click="onSelectSeat('129','E13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E13') }" cx="125.94" cy="337.73" r=".47"/>
                              <path id="E14" @click="onSelectSeat('129','E14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E14') }" d="m125.41,336.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="E15" @click="onSelectSeat('129','E15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E15') }" d="m124.88,334.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="E16" @click="onSelectSeat('129','E16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E16') }" cx="124.35" cy="334.07" r=".47"/>
                              <path id="E17" @click="onSelectSeat('129','E17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E17') }" d="m123.82,332.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="E18" @click="onSelectSeat('129','E18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E18') }" d="m123.28,331.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="E19" @click="onSelectSeat('129','E19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E19') }" d="m122.75,329.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="E20" @click="onSelectSeat('129','E20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E20') }" cx="122.22" cy="329.18" r=".47"/>
                              <path id="E21" @click="onSelectSeat('129','E21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E21') }" d="m121.69,327.49c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="E22" @click="onSelectSeat('129','E22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('E22') }" d="m121.16,326.27c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>

                              <path id="D1" @click="onSelectSeat('129','D1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D1') }" d="m133.6,351.37c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="D2" @click="onSelectSeat('129','D2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D2') }" d="m133.07,350.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="D3" @click="onSelectSeat('129','D3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D3') }" d="m132.54,348.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <circle id="D4" @click="onSelectSeat('129','D4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D4') }" cx="132.01" cy="348.18" r=".47"/>
                              <path id="D5" @click="onSelectSeat('129','D5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D5') }" d="m131.48,346.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="D6" @click="onSelectSeat('129','D6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D6') }" d="m130.94,345.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="D7" @click="onSelectSeat('129','D7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D7') }" cx="130.41" cy="344.51" r=".47"/>
                              <circle id="D8" @click="onSelectSeat('129','D8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D8') }" cx="129.88" cy="343.29" r=".47"/>
                              <path id="D9" @click="onSelectSeat('129','D9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D9') }" d="m129.35,341.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="D10" @click="onSelectSeat('129','D10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D10') }" d="m128.81,340.37c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="D11" @click="onSelectSeat('129','D11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D11') }" cx="128.28" cy="339.62" r=".47"/>
                              <path id="D12" @click="onSelectSeat('129','D12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D12') }" d="m127.75,337.93c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="D13" @click="onSelectSeat('129','D13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D13') }" d="m127.22,336.71c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="D14" @click="onSelectSeat('129','D14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D14') }" d="m126.69,335.49c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="D15" @click="onSelectSeat('129','D15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D15') }" cx="126.15" cy="334.74" r=".47"/>
                              <path id="D16" @click="onSelectSeat('129','D16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D16') }" d="m125.62,333.04c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="D17" @click="onSelectSeat('129','D17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D17') }" d="m125.09,331.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="D18" @click="onSelectSeat('129','D18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D18') }" cx="124.56" cy="331.07" r=".47"/>
                              <circle id="D19" @click="onSelectSeat('129','D19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D19') }" cx="124.03" cy="329.85" r=".47"/>
                              <path id="D20" @click="onSelectSeat('129','D20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D20') }" d="m123.49,328.15c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="D21" @click="onSelectSeat('129','D21')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D21') }" d="m122.96,326.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="D22" @click="onSelectSeat('129','D22')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('D22') }" cx="122.43" cy="326.18" r=".47"/>
                            
                              <circle id="C1" @click="onSelectSeat('129','C1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C1') }" cx="133.81" cy="348.84" r=".47"/>
                              <path id="C2" @click="onSelectSeat('129','C2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C2') }" d="m133.28,347.15c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="C3" @click="onSelectSeat('129','C3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C3') }" d="m132.75,345.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="C4" @click="onSelectSeat('129','C4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C4') }" cx="132.22" cy="345.18" r=".47"/>
                              <path id="C5" @click="onSelectSeat('129','C5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C5') }" d="m131.68,343.48c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="C6" @click="onSelectSeat('129','C6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C6') }" d="m131.15,342.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="C7" @click="onSelectSeat('129','C7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C7') }" d="m130.62,341.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="C8" @click="onSelectSeat('129','C8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C8') }" cx="130.09" cy="340.29" r=".47"/>
                              <path id="C9" @click="onSelectSeat('129','C9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C9') }" d="m129.56,338.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="C10" @click="onSelectSeat('129','C10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C10') }" d="m129.02,337.38c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="C11" @click="onSelectSeat('129','C11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C11') }" cx="128.49" cy="336.62" r=".47"/>
                              <circle id="C12" @click="onSelectSeat('129','C12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C12') }" cx="127.96" cy="335.4" r=".47"/>
                              <path id="C13" @click="onSelectSeat('129','C13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C13') }" d="m127.43,333.71c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="C14" @click="onSelectSeat('129','C14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C14') }" d="m126.9,332.49c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="C15" @click="onSelectSeat('129','C15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C15') }" cx="126.36" cy="331.74" r=".47"/>
                              <path id="C16" @click="onSelectSeat('129','C16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C16') }" d="m125.83,330.04c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="C17" @click="onSelectSeat('129','C17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C17') }" d="m125.3,328.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="C18" @click="onSelectSeat('129','C18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C18') }" d="m124.77,327.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="C19" @click="onSelectSeat('129','C19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C19') }" cx="124.23" cy="326.85" r=".47"/>
                              <path id="C20" @click="onSelectSeat('129','C20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('C20') }" d="m123.7,325.16c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              
                              <path id="B1" @click="onSelectSeat('129','B1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B1') }" d="m135.09,347.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="B2" @click="onSelectSeat('129','B2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B2') }" d="m134.55,346.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="B3" @click="onSelectSeat('129','B3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B3') }" cx="134.02" cy="345.85" r=".47"/>
                              <path id="B4" @click="onSelectSeat('129','B4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B4') }" d="m133.49,344.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="B5" @click="onSelectSeat('129','B5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B5') }" d="m132.96,342.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="B6" @click="onSelectSeat('129','B6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B6') }" cx="132.43" cy="342.18" r=".47"/>
                              <circle id="B7" @click="onSelectSeat('129','B7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B7') }" cx="131.89" cy="340.96" r=".47"/>
                              <path id="B8" @click="onSelectSeat('129','B8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B8') }" d="m131.36,339.26c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="B9" @click="onSelectSeat('129','B9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B9') }" d="m130.83,338.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="B10" @click="onSelectSeat('129','B10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B10') }" cx="130.3" cy="337.29" r=".47"/>
                              <path id="B11" @click="onSelectSeat('129','B11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B11') }" d="m129.77,335.6c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="B12" @click="onSelectSeat('129','B12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B12') }" d="m129.23,334.38c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="B13" @click="onSelectSeat('129','B13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B13') }" d="m128.7,333.15c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="B14" @click="onSelectSeat('129','B14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B14') }" cx="128.17" cy="332.4" r=".47"/>
                              <path id="B15" @click="onSelectSeat('129','B15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B15') }" d="m127.64,330.71c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="B16" @click="onSelectSeat('129','B16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B16') }" d="m127.1,329.49c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="B17" @click="onSelectSeat('129','B17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B17') }" cx="126.57" cy="328.74" r=".47"/>
                              <circle id="B18" @click="onSelectSeat('129','B18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B18') }" cx="126.04" cy="327.52" r=".47"/>
                              <path id="B19" @click="onSelectSeat('129','B19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B19') }" d="m125.51,325.82c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="B20" @click="onSelectSeat('129','B20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('B20') }" d="m124.98,324.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>

                              <circle id="A1" @click="onSelectSeat('129','A1')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A1') }" cx="136.36" cy="347.73" r=".47"/>
                              <circle id="A2" @click="onSelectSeat('129','A2')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A2') }" cx="135.83" cy="346.51" r=".47"/>
                              <path id="A3" @click="onSelectSeat('129','A3')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A3') }" d="m135.3,344.82c.26,0,.47.21.47.47,0,.26-.21.47-.47.47s-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="A4" @click="onSelectSeat('129','A4')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A4') }" d="m134.76,343.6c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="A5" @click="onSelectSeat('129','A5')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A5') }" cx="134.23" cy="342.85" r=".47"/>
                              <path id="A6" @click="onSelectSeat('129','A6')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A6') }" d="m133.7,341.15c.26,0,.47.21.47.47s-.21.47-.47.47c-.26,0-.47-.21-.47-.47s.21-.47.47-.47Z"/>
                              <path id="A7" @click="onSelectSeat('129','A7')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A7') }" d="m133.17,339.93c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="A8" @click="onSelectSeat('129','A8')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A8') }" d="m132.64,338.71c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              
                              <circle id="A9" @click="onSelectSeat('129','A9')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A9') }" cx="132.1" cy="337.96" r=".47"/>
                              <path id="A10" @click="onSelectSeat('129','A10')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A10') }" d="m131.57,336.27c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <path id="A11" @click="onSelectSeat('129','A11')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A11') }" d="m131.04,335.04c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="A12" @click="onSelectSeat('129','A12')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A12') }" cx="130.51" cy="334.29" r=".47"/>
                              <circle id="A13" @click="onSelectSeat('129','A13')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A13') }" cx="129.97" cy="333.07" r=".47"/>
                              <path id="A14" @click="onSelectSeat('129','A14')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A14') }" d="m129.44,331.38c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="A15" @click="onSelectSeat('129','A15')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A15') }" d="m128.91,330.16c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="A16" @click="onSelectSeat('129','A16')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A16') }" cx="128.38" cy="329.41" r=".47"/>
                              <circle id="A17" @click="onSelectSeat('129','A17')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A17') }" cx="127.85" cy="328.18" r=".47"/>
                              <path id="A18" @click="onSelectSeat('129','A18')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A18') }" d="m127.31,326.49c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47c0-.26.21-.47.47-.47Z"/>
                              <path id="A19" @click="onSelectSeat('129','A19')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A19') }" d="m126.78,325.27c.26,0,.47.21.47.47s-.21.47-.47.47-.47-.21-.47-.47.21-.47.47-.47Z"/>
                              <circle id="A20" @click="onSelectSeat('129','A20')" v-show="isSeatVisible === 'section129'" :class="{ 'selected': isSelected('A20') }" cx="126.25" cy="324.52" r=".47"/>

                              </g>
                              <g id="group128">  
                                <polygon id="section128" @click="isSectionEnabled('128') ? handleZoom('section128') : null" :class="{ 'enabled': isSectionEnabled('128') , 'disabled': !isSectionEnabled('128') }" points="161.94 367.55 148.11 399.09 127.35 384.11 113.97 368.06 142.43 349.4 161.94 367.55"/>
                                <polygon class="cls-22" points="161.94 367.55 161.39 367.31 147.85 398.16 127.76 383.67 114.87 368.2 142.37 350.17 161.53 368 161.94 367.55 161.39 367.31 161.94 367.55 162.36 367.11 142.5 348.64 113.07 367.93 126.93 384.56 148.36 400.02 162.67 367.4 162.36 367.11 161.94 367.55"/>
                                <text class="cls-5" transform="translate(130.46 375.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">128</tspan></text>

                                <path v-for="seat in seats.section128" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section128'" @click="onSelectSeat('128', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            
                            </g> 
                            <g id="group127"> 
                              <polygon id="section127" @click="isSectionEnabled('127') ? handleZoom('section127') : null" :class="{ 'enabled': isSectionEnabled('127') , 'disabled': !isSectionEnabled('127') }" points="163.99 364.83 193.03 377.99 181.8 411.68 148.11 399.09 161.94 367.55 163.99 364.83"/>
                              <polygon class="cls-22" points="163.99 364.83 163.74 365.38 192.28 378.32 181.42 410.89 148.92 398.74 162.47 367.86 164.47 365.19 163.99 364.83 163.74 365.38 163.99 364.83 163.5 364.47 161.42 367.25 147.29 399.43 182.17 412.47 193.77 377.66 163.79 364.08 163.5 364.47 163.99 364.83"/>
                              <text class="cls-5" transform="translate(165.46 395.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">127</tspan></text>

                              <path id="T1" @click="onSelectSeat('127','T1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T1') }" d="m181.61,405.55c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T2" @click="onSelectSeat('127','T2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T2') }" d="m180.43,405.05c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T3" @click="onSelectSeat('127','T3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T3') }" d="m179.25,404.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T4" @click="onSelectSeat('127','T4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T4') }" d="m178.08,404.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T5" @click="onSelectSeat('127','T5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T5') }" d="m176.9,403.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="T6" @click="onSelectSeat('127','T6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T6') }" cx="176.03" cy="403.37" r=".45"/>
                              <circle id="T7" @click="onSelectSeat('127','T7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T7') }" cx="174.85" cy="402.87" r=".45"/>
                              <path id="T8" @click="onSelectSeat('127','T8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T8') }" d="m173.36,402.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T9" @click="onSelectSeat('127','T9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T9') }" d="m172.18,401.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T10" @click="onSelectSeat('127','T10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T10') }" d="m171,401.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T11" @click="onSelectSeat('127','T11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T11') }" d="m169.82,400.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T12" @click="onSelectSeat('127','T12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T12') }" d="m168.64,400.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="T13" @click="onSelectSeat('127','T13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T13') }" cx="167.77" cy="399.87" r=".45"/>
                              <path id="T14" @click="onSelectSeat('127','T14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T14') }" d="m166.28,399.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T15" @click="onSelectSeat('127','T15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T15') }" d="m165.1,398.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T16" @click="onSelectSeat('127','T16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T16') }" d="m163.92,398.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T17" @click="onSelectSeat('127','T17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T17') }" d="m162.74,397.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T18" @click="onSelectSeat('127','T18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T18') }" d="m161.56,397.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="T19" @click="onSelectSeat('127','T19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T19') }" cx="160.7" cy="396.87" r=".45"/>
                              <path id="T20" @click="onSelectSeat('127','T20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T20') }" d="m159.21,396.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T21" @click="onSelectSeat('127','T21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T21') }" d="m158.03,395.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T22" @click="onSelectSeat('127','T22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T22') }" d="m156.85,395.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T23" @click="onSelectSeat('127','T23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T23') }" d="m155.67,394.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T24" @click="onSelectSeat('127','T24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T24') }" d="m154.49,394.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T25" @click="onSelectSeat('127','T25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T25') }" d="m153.31,393.54c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="T26" @click="onSelectSeat('127','T26')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('T26') }" d="m152.13,393.04c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                             
                              <path id="U1" @click="onSelectSeat('127','U1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U1') }" d="m181.09,406.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U2" @click="onSelectSeat('127','U2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U2') }" d="m179.91,406.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U3" @click="onSelectSeat('127','U3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U3') }" d="m178.73,405.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U4" @click="onSelectSeat('127','U4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U4') }" d="m177.55,405.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="U5" @click="onSelectSeat('127','U5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U5') }" cx="176.69" cy="405.1" r=".45"/>
                              <path id="U6" @click="onSelectSeat('127','U6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U6') }" d="m175.2,404.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U7" @click="onSelectSeat('127','U7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U7') }" d="m174.02,403.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U8" @click="onSelectSeat('127','U8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U8') }" d="m172.84,403.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U9" @click="onSelectSeat('127','U9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U9') }" d="m171.66,402.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U10" @click="onSelectSeat('127','U10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U10') }" d="m170.48,402.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U11" @click="onSelectSeat('127','U11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U11') }" d="m169.3,401.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="U12" @click="onSelectSeat('127','U12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U12') }" cx="168.43" cy="401.6" r=".45"/>
                              <path id="U13" @click="onSelectSeat('127','U13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U13') }" d="m166.94,400.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U14" @click="onSelectSeat('127','U14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U14') }" d="m165.76,400.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U15" @click="onSelectSeat('127','U15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U15') }" d="m164.58,399.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U16" @click="onSelectSeat('127','U16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U16') }" d="m163.4,399.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U17" @click="onSelectSeat('127','U17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U17') }" d="m162.22,398.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U18" @click="onSelectSeat('127','U18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U18') }" d="m161.04,398.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="U19" @click="onSelectSeat('127','U19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U19') }" cx="160.18" cy="398.1" r=".45"/>
                              <path id="U20" @click="onSelectSeat('127','U20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U20') }" d="m158.68,397.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U21" @click="onSelectSeat('127','U21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U21') }" d="m157.51,396.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U22" @click="onSelectSeat('127','U22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U22') }" d="m156.33,396.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U23" @click="onSelectSeat('127','U23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U23') }" d="m155.15,395.77c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="U24" @click="onSelectSeat('127','U24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U24') }" d="m153.97,395.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="U25" @click="onSelectSeat('127','U25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U25') }" cx="153.1" cy="395.1" r=".45"/>
                              <path id="U26" @click="onSelectSeat('127','U26')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('U26') }" d="m151.61,394.27c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              
                              <circle id="S1" @click="onSelectSeat('127','S1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S1') }" cx="182.45" cy="404.65" r=".45"/>
                              <path id="S2" @click="onSelectSeat('127','S2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S2') }" d="m180.96,403.82c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S3" @click="onSelectSeat('127','S3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S3') }" d="m179.78,403.32c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S4" @click="onSelectSeat('127','S4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S4') }" d="m178.6,402.82c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="S5" @click="onSelectSeat('127','S5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S5') }" cx="177.73" cy="402.64" r=".45"/>
                              <circle id="S6" @click="onSelectSeat('127','S6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S6') }" cx="176.55" cy="402.14" r=".45"/>
                              <circle id="S7" @click="onSelectSeat('127','S7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S7') }" cx="175.37" cy="401.64" r=".45"/>
                              <path id="S8" @click="onSelectSeat('127','S8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S8') }" d="m173.88,400.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S9" @click="onSelectSeat('127','S9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S9') }" d="m172.7,400.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S10" @click="onSelectSeat('127','S10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S10') }" d="m171.52,399.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S11" @click="onSelectSeat('127','S11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S11') }" d="m170.34,399.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="S12" @click="onSelectSeat('127','S12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S12') }" cx="169.47" cy="399.14" r=".45"/>
                              <circle id="S13" @click="onSelectSeat('127','S13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S13') }" cx="168.29" cy="398.64" r=".45"/>
                              <path id="S14" @click="onSelectSeat('127','S14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S14') }" d="m166.8,397.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S15" @click="onSelectSeat('127','S15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S15') }" d="m165.62,397.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S16" @click="onSelectSeat('127','S16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S16') }" d="m164.44,396.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S17" @click="onSelectSeat('127','S17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S17') }" d="m163.27,396.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S18" @click="onSelectSeat('127','S18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S18') }" d="m162.09,395.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S19" @click="onSelectSeat('127','S19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S19') }" d="m160.91,395.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="S20" @click="onSelectSeat('127','S20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S20') }" cx="160.04" cy="395.14" r=".45"/>
                              <path id="S21" @click="onSelectSeat('127','S21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S21') }" d="m158.55,394.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S22" @click="onSelectSeat('127','S22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S22') }" d="m157.37,393.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S23" @click="onSelectSeat('127','S23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S23') }" d="m156.19,393.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S24" @click="onSelectSeat('127','S24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S24') }" d="m155.01,392.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S25" @click="onSelectSeat('127','S25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S25') }" d="m153.83,392.31c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="S26" @click="onSelectSeat('127','S26')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('S26') }" d="m152.65,391.81c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              
                              <path id="R1" @click="onSelectSeat('127','R1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R1') }" d="m182.66,403.09c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R2" @click="onSelectSeat('127','R2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R2') }" d="m181.48,402.59c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R3" @click="onSelectSeat('127','R3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R3') }" d="m180.3,402.09c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R4" @click="onSelectSeat('127','R4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R4') }" d="m179.12,401.59c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R5" @click="onSelectSeat('127','R5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R5') }" d="m177.94,401.09c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R6" @click="onSelectSeat('127','R6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R6') }" d="m176.76,400.59c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="R7" @click="onSelectSeat('127','R7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R7') }" cx="175.89" cy="400.41" r=".45"/>
                              <circle id="R8" @click="onSelectSeat('127','R8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R8') }" cx="174.71" cy="399.91" r=".45"/>
                              <path id="R9" @click="onSelectSeat('127','R9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R9') }" d="m173.22,399.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R10" @click="onSelectSeat('127','R10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R10') }" d="m172.04,398.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R11" @click="onSelectSeat('127','R11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R11') }" d="m170.86,398.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R12" @click="onSelectSeat('127','R12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R12') }" d="m169.68,397.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R13" @click="onSelectSeat('127','R13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R13') }" d="m168.5,397.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="R14" @click="onSelectSeat('127','R14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R14') }" cx="167.64" cy="396.91" r=".45"/>
                              <path id="R15" @click="onSelectSeat('127','R15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R15') }" d="m166.15,396.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R16" @click="onSelectSeat('127','R16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R16') }" d="m164.97,395.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R17" @click="onSelectSeat('127','R17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R17') }" d="m163.79,395.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R18" @click="onSelectSeat('127','R18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R18') }" d="m162.61,394.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R19" @click="onSelectSeat('127','R19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R19') }" d="m161.43,394.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R20" @click="onSelectSeat('127','R20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R20') }" d="m160.25,393.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R21" @click="onSelectSeat('127','R21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R21') }" d="m159.07,393.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R22" @click="onSelectSeat('127','R22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R22') }" d="m157.89,392.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R23" @click="onSelectSeat('127','R23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R23') }" d="m156.71,392.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R24" @click="onSelectSeat('127','R24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R24') }" d="m155.53,391.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R25" @click="onSelectSeat('127','R25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R25') }" d="m154.35,391.08c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="R26" @click="onSelectSeat('127','R26')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('R26') }"  d="m153.17,390.58c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                          
                              <circle id="Q1" @click="onSelectSeat('127','Q1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q1') }" cx="182.31" cy="401.69" r=".45"/>
                              <path id="Q2" @click="onSelectSeat('127','Q2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q2') }" d="m180.82,400.86c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q3" @click="onSelectSeat('127','Q3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q3') }" d="m179.64,400.36c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q4" @click="onSelectSeat('127','Q4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q4') }" d="m178.46,399.86c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="Q5" @click="onSelectSeat('127','Q5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q5') }" cx="177.59" cy="399.69" r=".45"/>
                              <path id="Q6" @click="onSelectSeat('127','Q6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q6') }" d="m176.1,398.86c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="Q7" @click="onSelectSeat('127','Q7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q7') }" cx="175.23" cy="398.68" r=".45"/>
                              <path id="Q8" @click="onSelectSeat('127','Q8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q8') }" d="m173.74,397.86c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q9" @click="onSelectSeat('127','Q9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q9') }" d="m172.56,397.36c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q10" @click="onSelectSeat('127','Q10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q10') }" d="m171.38,396.86c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q11" @click="onSelectSeat('127','Q11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q11') }" d="m170.2,396.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q12" @click="onSelectSeat('127','Q12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q12') }" d="m169.03,395.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="Q13" @click="onSelectSeat('127','Q13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q13') }" cx="168.16" cy="395.68" r=".45"/>
                              <path id="Q14" @click="onSelectSeat('127','Q14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q14') }" d="m166.67,394.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q15" @click="onSelectSeat('127','Q15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q15') }" d="m165.49,394.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q16" @click="onSelectSeat('127','Q16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q16') }" d="m164.31,393.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q17" @click="onSelectSeat('127','Q17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q17') }" d="m163.13,393.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q18" @click="onSelectSeat('127','Q18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q18') }" d="m161.95,392.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q19" @click="onSelectSeat('127','Q19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q19') }" d="m160.77,392.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q20" @click="onSelectSeat('127','Q20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q20') }" d="m159.59,391.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q21" @click="onSelectSeat('127','Q21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q21') }" d="m158.41,391.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q22" @click="onSelectSeat('127','Q22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q22') }" d="m157.23,390.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q23" @click="onSelectSeat('127','Q23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q23') }" d="m156.05,390.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q24" @click="onSelectSeat('127','Q24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q24') }" d="m154.87,389.85c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="Q25" @click="onSelectSeat('127','Q25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('Q25') }" d="m153.69,389.35c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                            
                              <circle id="P1" @click="onSelectSeat('127','P1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P1') }" cx="182.83" cy="400.46" r=".45"/>
                              <path id="P2" @click="onSelectSeat('127','P2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P2') }" d="m181.34,399.63c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P3" @click="onSelectSeat('127','P3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P3') }" d="m180.16,399.13c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P4" @click="onSelectSeat('127','P4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P4') }" d="m178.98,398.63c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P5" @click="onSelectSeat('127','P5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P5') }" d="m177.8,398.13c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P6" @click="onSelectSeat('127','P6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P6') }" d="m176.62,397.63c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="P7" @click="onSelectSeat('127','P7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P7') }" cx="175.76" cy="397.46" r=".45"/>
                              <path id="P8" @click="onSelectSeat('127','P8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P8') }" d="m174.26,396.63c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P9" @click="onSelectSeat('127','P9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P9') }" d="m173.09,396.13c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P10" @click="onSelectSeat('127','P10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P10') }" d="m171.91,395.63c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P11" @click="onSelectSeat('127','P11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P11') }" d="m170.73,395.13c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P12" @click="onSelectSeat('127','P12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P12') }" d="m169.55,394.63c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P13" @click="onSelectSeat('127','P13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P13') }" d="m168.37,394.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P14" @click="onSelectSeat('127','P14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P14') }" d="m167.19,393.62c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P15" @click="onSelectSeat('127','P15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P15') }" d="m166.01,393.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P16" @click="onSelectSeat('127','P16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P16') }" d="m164.83,392.62c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P17" @click="onSelectSeat('127','P17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P17') }" d="m163.65,392.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P18" @click="onSelectSeat('127','P18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P18') }" d="m162.47,391.62c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P19" @click="onSelectSeat('127','P19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P19') }" d="m161.29,391.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P20" @click="onSelectSeat('127','P20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P20') }" d="m160.11,390.62c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P21" @click="onSelectSeat('127','P21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P21') }" d="m158.93,390.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P22" @click="onSelectSeat('127','P22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P22') }" d="m157.75,389.62c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P23" @click="onSelectSeat('127','P23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P23') }" d="m156.57,389.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P24" @click="onSelectSeat('127','P24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P24') }" d="m155.39,388.62c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="P25" @click="onSelectSeat('127','P25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('P25') }" d="m154.22,388.12c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                            
                              <circle id="O1"  @click="onSelectSeat('127','O1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O1') }" cx="183.35" cy="399.23" r=".45"/>
                              <path id="O2"  @click="onSelectSeat('127','O2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O2') }" d="m181.86,398.4c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O3"  @click="onSelectSeat('127','O3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O3') }" d="m180.68,397.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O4"  @click="onSelectSeat('127','O4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O4') }" d="m179.5,397.4c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O5"  @click="onSelectSeat('127','O5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O5') }" d="m178.32,396.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O6"  @click="onSelectSeat('127','O6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O6') }" d="m177.14,396.4c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O7"  @click="onSelectSeat('127','O7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O7') }" d="m175.97,395.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="O8"  @click="onSelectSeat('127','O8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O8') }" cx="175.1" cy="395.73" r=".45"/>
                              <path id="O9"  @click="onSelectSeat('127','O9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O9') }" d="m173.61,394.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O10"  @click="onSelectSeat('127','O10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O10') }" d="m172.43,394.4c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O11"  @click="onSelectSeat('127','O11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O11') }" d="m171.25,393.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O12"  @click="onSelectSeat('127','O12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O12') }" d="m170.07,393.4c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O13"  @click="onSelectSeat('127','O13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O13') }" d="m168.89,392.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O14"  @click="onSelectSeat('127','O14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O14') }" d="m167.71,392.4c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O15"  @click="onSelectSeat('127','O15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O15') }" d="m166.53,391.9c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O16"  @click="onSelectSeat('127','O16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O16') }" d="m165.35,391.39c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O17"  @click="onSelectSeat('127','O17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O17') }" d="m164.17,390.89c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O18"  @click="onSelectSeat('127','O18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O18') }" d="m162.99,390.39c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O19"  @click="onSelectSeat('127','O19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O19') }" d="m161.81,389.89c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O20"  @click="onSelectSeat('127','O20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O20') }" d="m160.63,389.39c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O21"  @click="onSelectSeat('127','O21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O21') }" d="m159.45,388.89c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O22"  @click="onSelectSeat('127','O22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O22') }" d="m158.27,388.39c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O23"  @click="onSelectSeat('127','O23')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O23') }" d="m157.1,387.89c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O24"  @click="onSelectSeat('127','O24')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O24') }" d="m155.92,387.39c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="O25"  @click="onSelectSeat('127','O25')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('O25') }" d="m154.74,386.89c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                         
                              <path id="N1" @click="onSelectSeat('127','N1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N1') }"  d="m177.67,395.17c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <circle id="N2" @click="onSelectSeat('127','N2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N2') }"  cx="176.8" cy="395" r=".45"/>
                              <circle id="N3" @click="onSelectSeat('127','N3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N3') }"  cx="175.62" cy="394.5" r=".45"/>
                              <path id="N4" @click="onSelectSeat('127','N4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N4') }"  d="m174.13,393.67c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N5" @click="onSelectSeat('127','N5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N5') }"  d="m172.95,393.17c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N6" @click="onSelectSeat('127','N6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N6') }"  d="m171.77,392.67c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N7" @click="onSelectSeat('127','N7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N7') }"  d="m170.59,392.17c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N8" @click="onSelectSeat('127','N8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N8') }"  d="m169.41,391.67c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N9" @click="onSelectSeat('127','N9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N9') }"  d="m168.23,391.17c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N10" @click="onSelectSeat('127','N10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N10') }"  d="m167.05,390.67c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N11" @click="onSelectSeat('127','N11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N11') }"  d="m165.87,390.17c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N12" @click="onSelectSeat('127','N12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N12') }"  d="m164.69,389.67c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N13" @click="onSelectSeat('127','N13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N13') }"  d="m163.51,389.17c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N14" @click="onSelectSeat('127','N14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N14') }"  d="m162.33,388.66c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N15" @click="onSelectSeat('127','N15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N15') }"  d="m161.16,388.16c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N16" @click="onSelectSeat('127','N16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N16') }"  d="m159.98,387.66c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N17" @click="onSelectSeat('127','N17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N17') }" d="m158.8,387.16c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N18" @click="onSelectSeat('127','N18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N18') }" d="m157.62,386.66c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N19" @click="onSelectSeat('127','N19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N19') }" d="m156.44,386.16c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="N20" @click="onSelectSeat('127','N20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('N20') }" d="m155.26,385.66c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                 
                              <path id="M1" @click="onSelectSeat('127','M1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M1') }" d="m175.83,392.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M2" @click="onSelectSeat('127','M2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M2') }" d="m174.65,392.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M3" @click="onSelectSeat('127','M3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M3') }" d="m173.47,391.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M4" @click="onSelectSeat('127','M4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M4') }" d="m172.29,391.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M5" @click="onSelectSeat('127','M5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M5') }" d="m171.11,390.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M6" @click="onSelectSeat('127','M6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M6') }" d="m169.93,390.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M7" @click="onSelectSeat('127','M7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M7') }" d="m168.75,389.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M8" @click="onSelectSeat('127','M8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M8') }" d="m167.57,389.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M9" @click="onSelectSeat('127','M9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M9') }" d="m166.39,388.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M10" @click="onSelectSeat('127','M10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M10') }" d="m165.21,388.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M11" @click="onSelectSeat('127','M11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M11') }" d="m164.04,387.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M12" @click="onSelectSeat('127','M12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M12') }" d="m162.86,387.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M13" @click="onSelectSeat('127','M13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M13') }" d="m161.68,386.94c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M14" @click="onSelectSeat('127','M14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M14') }" d="m160.5,386.44c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M15" @click="onSelectSeat('127','M15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M15') }" d="m159.32,385.93c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M16" @click="onSelectSeat('127','M16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M16') }" d="m158.14,385.43c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M17" @click="onSelectSeat('127','M17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M17') }" d="m156.96,384.93c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="M18" @click="onSelectSeat('127','M18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('M18') }" d="m155.78,384.43c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>

                              <path id="L1" @click="onSelectSeat('127','L1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L1') }" d="m176.35,391.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L2" @click="onSelectSeat('127','L2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L2') }" d="m175.17,391.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L3" @click="onSelectSeat('127','L3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L3') }" d="m173.99,390.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L4" @click="onSelectSeat('127','L4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L4') }" d="m172.81,390.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L5" @click="onSelectSeat('127','L5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L5') }" d="m171.63,389.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L6" @click="onSelectSeat('127','L6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L6') }" d="m170.45,389.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L7" @click="onSelectSeat('127','L7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L7') }" d="m169.27,388.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L8" @click="onSelectSeat('127','L8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L8') }" d="m168.09,388.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L9" @click="onSelectSeat('127','L9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L9') }" d="m166.92,387.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L10" @click="onSelectSeat('127','L10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L10') }" d="m165.74,387.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L11" @click="onSelectSeat('127','L11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L11') }" d="m164.56,386.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L12" @click="onSelectSeat('127','L12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L12') }" d="m163.38,386.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L13" @click="onSelectSeat('127','L13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L13') }" d="m162.2,385.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L14" @click="onSelectSeat('127','L14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L14') }" d="m161.02,385.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L15" @click="onSelectSeat('127','L15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L15') }" d="m159.84,384.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L16" @click="onSelectSeat('127','L16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L16') }" d="m158.66,384.21c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L17" @click="onSelectSeat('127','L17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L17') }" d="m157.48,383.71c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="L16" @click="onSelectSeat('127','L18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('L18') }" d="m156.3,383.2c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>

                              <path id="K1" @click="onSelectSeat('127','K1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K1') }" d="m176.87,390.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K2" @click="onSelectSeat('127','K2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K2') }" d="m175.69,389.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K3" @click="onSelectSeat('127','K3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K3') }" d="m174.51,389.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K4" @click="onSelectSeat('127','K4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K4') }" d="m173.33,388.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K5" @click="onSelectSeat('127','K5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K5') }" d="m172.15,388.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K6" @click="onSelectSeat('127','K6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K6') }" d="m170.97,387.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K7" @click="onSelectSeat('127','K7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K7') }" d="m169.8,387.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K8" @click="onSelectSeat('127','K8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K8') }" d="m168.62,386.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K9" @click="onSelectSeat('127','K9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K9') }" d="m167.44,386.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K10" @click="onSelectSeat('127','K10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K10') }" d="m166.26,385.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K11" @click="onSelectSeat('127','K11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K11') }" d="m165.08,385.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K12" @click="onSelectSeat('127','K12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K12') }" d="m163.9,384.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K13" @click="onSelectSeat('127','K13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K13') }" d="m162.72,384.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K14" @click="onSelectSeat('127','K14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K14') }" d="m161.54,383.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K15" @click="onSelectSeat('127','K15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K15') }" d="m160.36,383.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K16" @click="onSelectSeat('127','K16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K16') }" d="m159.18,382.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K17" @click="onSelectSeat('127','K17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K17') }" d="m158,382.48c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="K18" @click="onSelectSeat('127','K18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('K18') }" d="m156.82,381.98c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>

                              <path id="J1" @click="onSelectSeat('127','J1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J1') }" d="m177.39,389.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J2" @click="onSelectSeat('127','J2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J2') }" d="m176.21,388.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J3" @click="onSelectSeat('127','J3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J3') }" d="m175.03,388.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J4" @click="onSelectSeat('127','J4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J4') }" d="m173.86,387.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J5" @click="onSelectSeat('127','J5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J5') }" d="m172.68,387.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J6" @click="onSelectSeat('127','J6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J6') }" d="m171.5,386.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J7" @click="onSelectSeat('127','J7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J7') }" d="m170.32,386.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J8" @click="onSelectSeat('127','J8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J8') }" d="m169.14,385.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J9" @click="onSelectSeat('127','J9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J9') }" d="m167.96,385.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J10" @click="onSelectSeat('127','J10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J10') }" d="m166.78,384.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J11" @click="onSelectSeat('127','J11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J11') }" d="m165.6,384.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J12" @click="onSelectSeat('127','J12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J12') }" d="m164.42,383.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J13" @click="onSelectSeat('127','J13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J13') }" d="m163.24,383.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J14" @click="onSelectSeat('127','J14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J14') }" d="m162.06,382.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J15" @click="onSelectSeat('127','J15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J15') }" d="m160.88,382.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J16" @click="onSelectSeat('127','J16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J16') }" d="m159.7,381.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J17" @click="onSelectSeat('127','J17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J17') }" d="m158.52,381.25c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="J18" @click="onSelectSeat('127','J18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('J18') }" d="m157.34,380.75c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                            
                              <path id="I1" @click="onSelectSeat('127','I1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I1') }" d="m177.91,388.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I2" @click="onSelectSeat('127','I2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I2') }" d="m176.74,387.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I3" @click="onSelectSeat('127','I3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I3') }" d="m175.56,387.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I4" @click="onSelectSeat('127','I4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I4') }" d="m174.38,386.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I5" @click="onSelectSeat('127','I5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I5') }" d="m173.2,386.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I6" @click="onSelectSeat('127','I6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I6') }" d="m172.02,385.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I7" @click="onSelectSeat('127','I7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I7') }" d="m170.84,385.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I8" @click="onSelectSeat('127','I8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I8') }" d="m169.66,384.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I9" @click="onSelectSeat('127','I9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I9') }" d="m168.48,384.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I10" @click="onSelectSeat('127','I10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I10') }" d="m167.3,383.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I11" @click="onSelectSeat('127','I11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I11') }" d="m166.12,383.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I12" @click="onSelectSeat('127','I12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I12') }" d="m164.94,382.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I13" @click="onSelectSeat('127','I13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I13') }" d="m163.76,382.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I14" @click="onSelectSeat('127','I14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I14') }" d="m162.58,381.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I15" @click="onSelectSeat('127','I15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I15') }" d="m161.4,381.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I16" @click="onSelectSeat('127','I16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I16') }" d="m160.22,380.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I17" @click="onSelectSeat('127','I17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I17') }" d="m159.04,380.02c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="I18" @click="onSelectSeat('127','I18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('I18') }" d="m157.87,379.52c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                            
                              <path id="H1" @click="onSelectSeat('127','H1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H1') }" d="m183.15,388.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H2" @click="onSelectSeat('127','H2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H2') }" d="m181.97,388.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H3" @click="onSelectSeat('127','H3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H3') }" d="m180.79,387.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H4" @click="onSelectSeat('127','H4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H4') }" d="m179.62,387.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H5" @click="onSelectSeat('127','H5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H5') }" d="m178.44,386.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H6" @click="onSelectSeat('127','H6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H6') }" d="m177.26,386.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H7" @click="onSelectSeat('127','H7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H7') }" d="m176.08,385.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H8" @click="onSelectSeat('127','H8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H8') }" d="m174.9,385.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H9" @click="onSelectSeat('127','H9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H9') }" d="m173.72,384.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H10" @click="onSelectSeat('127','H10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H10') }" d="m172.54,384.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H11" @click="onSelectSeat('127','H11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H11') }" d="m171.36,383.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H12" @click="onSelectSeat('127','H12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H12') }" d="m170.18,383.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H13" @click="onSelectSeat('127','H13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H13') }" d="m169,382.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H14" @click="onSelectSeat('127','H14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H14') }" d="m167.82,382.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H15" @click="onSelectSeat('127','H15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H15') }" d="m166.64,381.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H16" @click="onSelectSeat('127','H16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H16') }" d="m165.46,381.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H17" @click="onSelectSeat('127','H17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H17') }" d="m164.28,380.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H18" @click="onSelectSeat('127','H18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H18') }" d="m163.1,380.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H19" @click="onSelectSeat('127','H19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H19') }" d="m161.92,379.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H20" @click="onSelectSeat('127','H20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H20') }" d="m160.75,379.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H21" @click="onSelectSeat('127','H21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H21') }" d="m159.57,378.79c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="H22" @click="onSelectSeat('127','H22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('H22') }" d="m158.39,378.29c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                             
                              <path id="G1" @click="onSelectSeat('127','G1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G1') }"  d="m183.67,387.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G2" @click="onSelectSeat('127','G2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G2') }"  d="m182.5,387.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G3" @click="onSelectSeat('127','G3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G3') }"  d="m181.32,386.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G4" @click="onSelectSeat('127','G4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G4') }"  d="m180.14,386.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G5" @click="onSelectSeat('127','G5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G5') }"  d="m178.96,385.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G6" @click="onSelectSeat('127','G6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G6') }"  d="m177.78,385.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G7" @click="onSelectSeat('127','G7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G7') }"  d="m176.6,384.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G8" @click="onSelectSeat('127','G8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G8') }"  d="m175.42,384.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G9" @click="onSelectSeat('127','G9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G9') }"  d="m174.24,383.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G10" @click="onSelectSeat('127','G10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G10') }"  d="m173.06,383.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G11" @click="onSelectSeat('127','G11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G11') }"  d="m171.88,382.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G12" @click="onSelectSeat('127','G12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G12') }"  d="m170.7,382.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G13" @click="onSelectSeat('127','G13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G13') }"  d="m169.52,381.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G14" @click="onSelectSeat('127','G14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G14') }"  d="m168.34,381.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G15" @click="onSelectSeat('127','G15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G15') }"  d="m167.16,380.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G16" @click="onSelectSeat('127','G16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G16') }"  d="m165.98,380.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G17" @click="onSelectSeat('127','G17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G17') }"  d="m164.81,379.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G18" @click="onSelectSeat('127','G18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G18') }"  d="m163.63,379.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G19" @click="onSelectSeat('127','G19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G19') }" d="m162.45,378.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G20" @click="onSelectSeat('127','G20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G20') }" d="m161.27,378.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G21" @click="onSelectSeat('127','G21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G21') }" d="m160.09,377.56c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="G22" @click="onSelectSeat('127','G22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('G22') }" d="m158.91,377.06c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>

                              <path id="F1" @click="onSelectSeat('127','F1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F1') }"  d="m184.2,386.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F2" @click="onSelectSeat('127','F2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F2') }"  d="m183.02,385.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F3" @click="onSelectSeat('127','F3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F3') }"  d="m181.84,385.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F4" @click="onSelectSeat('127','F4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F4') }"  d="m180.66,384.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F5" @click="onSelectSeat('127','F5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F5') }"  d="m179.48,384.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F6" @click="onSelectSeat('127','F6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F6') }"  d="m178.3,383.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F7" @click="onSelectSeat('127','F7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F7') }"  d="m177.12,383.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F8" @click="onSelectSeat('127','F8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F8') }"  d="m175.94,382.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F9" @click="onSelectSeat('127','F9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F9') }"  d="m174.76,382.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F10" @click="onSelectSeat('127','F10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F10') }"  d="m173.58,381.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F11" @click="onSelectSeat('127','F11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F11') }"  d="m172.4,381.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F12" @click="onSelectSeat('127','F12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F12') }"  d="m171.22,380.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F13" @click="onSelectSeat('127','F13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F13') }"  d="m170.04,380.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F14" @click="onSelectSeat('127','F14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F14') }"  d="m168.86,379.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F15" @click="onSelectSeat('127','F15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F15') }"  d="m167.69,379.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F16" @click="onSelectSeat('127','F16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F16') }"  d="m166.51,378.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F17" @click="onSelectSeat('127','F17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F17') }"  d="m165.33,378.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F18" @click="onSelectSeat('127','F18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F18') }"  d="m164.15,377.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F19" @click="onSelectSeat('127','F19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F19') }" d="m162.97,377.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F20" @click="onSelectSeat('127','F20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F20') }" d="m161.79,376.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F21" @click="onSelectSeat('127','F21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F21') }" d="m160.61,376.33c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="F22" @click="onSelectSeat('127','F22')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('F22') }" d="m159.43,375.83c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                          
                              <path id="E1" @click="onSelectSeat('127','E1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E1') }" d="m183.54,384.61c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E2" @click="onSelectSeat('127','E2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E2') }" d="m182.36,384.11c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E3" @click="onSelectSeat('127','E3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E3') }" d="m181.18,383.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E4" @click="onSelectSeat('127','E4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E4') }" d="m180,383.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E5" @click="onSelectSeat('127','E5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E5') }" d="m178.82,382.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E6" @click="onSelectSeat('127','E6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E6') }" d="m177.64,382.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E7" @click="onSelectSeat('127','E7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E7') }" d="m176.46,381.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E8" @click="onSelectSeat('127','E8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E8') }" d="m175.28,381.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E9" @click="onSelectSeat('127','E9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E9') }" d="m174.1,380.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E10" @click="onSelectSeat('127','E10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E10') }" d="m172.92,380.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E11" @click="onSelectSeat('127','E11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E11') }" d="m171.74,379.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E12" @click="onSelectSeat('127','E12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E12') }" d="m170.57,379.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E13" @click="onSelectSeat('127','E13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E13') }" d="m169.39,378.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E14" @click="onSelectSeat('127','E14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E14') }" d="m168.21,378.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E15" @click="onSelectSeat('127','E15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E15') }" d="m167.03,377.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E16" @click="onSelectSeat('127','E16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E16') }" d="m165.85,377.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E17" @click="onSelectSeat('127','E17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E17') }" d="m164.67,376.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E18" @click="onSelectSeat('127','E18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E18') }" d="m163.49,376.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E19" @click="onSelectSeat('127','E19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E19') }" d="m162.31,375.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E20" @click="onSelectSeat('127','E20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E20') }" d="m161.13,375.1c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="E21" @click="onSelectSeat('127','E21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('E21') }" d="m159.95,374.6c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>

                              <path id="D1" @click="onSelectSeat('127','D1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D1') }" d="m184.06,383.38c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D2" @click="onSelectSeat('127','D2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D2') }" d="m182.88,382.88c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D3" @click="onSelectSeat('127','D3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D3') }" d="m181.7,382.38c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D4" @click="onSelectSeat('127','D4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D4') }" d="m180.52,381.88c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D5" @click="onSelectSeat('127','D5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D5') }" d="m179.34,381.38c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D6" @click="onSelectSeat('127','D6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D6') }" d="m178.16,380.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D7" @click="onSelectSeat('127','D7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D7') }" d="m176.98,380.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D8" @click="onSelectSeat('127','D8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D8') }" d="m175.8,379.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D9" @click="onSelectSeat('127','D9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D9') }" d="m174.62,379.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D10" @click="onSelectSeat('127','D10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D10') }" d="m173.45,378.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D11" @click="onSelectSeat('127','D11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D11') }" d="m172.27,378.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D12" @click="onSelectSeat('127','D12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D12') }" d="m171.09,377.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D13" @click="onSelectSeat('127','D13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D13') }" d="m169.91,377.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D14" @click="onSelectSeat('127','D14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D14') }" d="m168.73,376.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D15" @click="onSelectSeat('127','D15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D15') }" d="m167.55,376.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D16" @click="onSelectSeat('127','D16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D16') }" d="m166.37,375.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D17" @click="onSelectSeat('127','D17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D17') }" d="m165.19,375.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D18" @click="onSelectSeat('127','D18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D18') }" d="m164.01,374.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D19" @click="onSelectSeat('127','D19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D19') }" d="m162.83,374.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D20" @click="onSelectSeat('127','D20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D20') }" d="m161.65,373.87c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="D21" @click="onSelectSeat('127','D21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('D21') }" d="m160.47,373.37c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                         
                              <path id="C1" @click="onSelectSeat('127','C1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C1') }" d="m184.58,382.15c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C2" @click="onSelectSeat('127','C2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C2') }" d="m183.4,381.65c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C3" @click="onSelectSeat('127','C3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C3') }" d="m182.22,381.15c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C4" @click="onSelectSeat('127','C4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C4') }" d="m181.04,380.65c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C5" @click="onSelectSeat('127','C5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C5') }" d="m179.86,380.15c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C6" @click="onSelectSeat('127','C6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C6') }" d="m178.68,379.65c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C7" @click="onSelectSeat('127','C7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C7') }" d="m177.51,379.15c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C8" @click="onSelectSeat('127','C8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C8') }" d="m176.33,378.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C9" @click="onSelectSeat('127','C9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C9') }" d="m175.15,378.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C10" @click="onSelectSeat('127','C10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C10') }" d="m173.97,377.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C11" @click="onSelectSeat('127','C11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C11') }" d="m172.79,377.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C12" @click="onSelectSeat('127','C12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C12') }" d="m171.61,376.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C13" @click="onSelectSeat('127','C13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C13') }" d="m170.43,376.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C14" @click="onSelectSeat('127','C14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C14') }" d="m169.25,375.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C15" @click="onSelectSeat('127','C15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C15') }" d="m168.07,375.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C16" @click="onSelectSeat('127','C16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C16') }" d="m166.89,374.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C17" @click="onSelectSeat('127','C17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C17') }" d="m165.71,374.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C18" @click="onSelectSeat('127','C18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C18') }" d="m164.53,373.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C19" @click="onSelectSeat('127','C19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C19') }" d="m163.35,373.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C20" @click="onSelectSeat('127','B20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C20') }" d="m162.17,372.64c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="C21" @click="onSelectSeat('127','C21')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('C21') }" d="m160.99,372.14c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              
                              <path id="B1" @click="onSelectSeat('127','B1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B1') }" d="m183.92,380.42c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B2" @click="onSelectSeat('127','B2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B2') }" d="m182.74,379.92c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B3" @click="onSelectSeat('127','B3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B3') }" d="m181.56,379.42c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B4" @click="onSelectSeat('127','B4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B4') }" d="m180.39,378.92c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B5" @click="onSelectSeat('127','B5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B5') }" d="m179.21,378.42c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B6" @click="onSelectSeat('127','B6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B6') }" d="m178.03,377.92c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B7" @click="onSelectSeat('127','B7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B7') }" d="m176.85,377.42c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B8" @click="onSelectSeat('127','B8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B8') }" d="m175.67,376.92c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B9" @click="onSelectSeat('127','B9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B9') }" d="m174.49,376.42c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B10" @click="onSelectSeat('127','B10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B10') }" d="m173.31,375.91c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B11" @click="onSelectSeat('127','B11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B11') }" d="m172.13,375.41c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B12" @click="onSelectSeat('127','B12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B12') }" d="m170.95,374.91c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B13" @click="onSelectSeat('127','B13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B13') }" d="m169.77,374.41c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B14" @click="onSelectSeat('127','B14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B14') }" d="m168.59,373.91c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B15" @click="onSelectSeat('127','B15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B15') }" d="m167.41,373.41c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B16" @click="onSelectSeat('127','B16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B16') }" d="m166.23,372.91c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B17" @click="onSelectSeat('127','B17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B17') }" d="m165.05,372.41c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B18" @click="onSelectSeat('127','B18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B18') }" d="m163.87,371.91c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B19" @click="onSelectSeat('127','B19')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B19') }" d="m162.69,371.41c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="B20" @click="onSelectSeat('127','B20')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('B20') }" d="m161.52,370.91c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              
                              <path id="A1" @click="onSelectSeat('127','A1')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A1') }" d="m182.09,378.19c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A2" @click="onSelectSeat('127','A2')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A2') }" d="m180.91,377.69c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A3" @click="onSelectSeat('127','A3')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A3') }" d="m179.73,377.19c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A4" @click="onSelectSeat('127','A4')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A4') }" d="m178.55,376.69c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A5" @click="onSelectSeat('127','A5')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A5') }" d="m177.37,376.19c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A6" @click="onSelectSeat('127','A6')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A6') }" d="m176.19,375.69c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A7" @click="onSelectSeat('127','A7')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A7') }" d="m175.01,375.19c.18-.17.47-.16.64.02.17.18.16.47-.02.64s-.47.16-.64-.02c-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A8" @click="onSelectSeat('127','A8')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A8') }" d="m173.83,374.69c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A9" @click="onSelectSeat('127','A9')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A9') }" d="m172.65,374.19c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A10" @click="onSelectSeat('127','A10')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A10') }" d="m171.47,373.69c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A11" @click="onSelectSeat('127','A11')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A11') }" d="m170.29,373.18c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A12" @click="onSelectSeat('127','A12')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A12') }" d="m169.11,372.68c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A13" @click="onSelectSeat('127','A13')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A13') }" d="m167.93,372.18c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A14" @click="onSelectSeat('127','A14')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A14') }" d="m166.75,371.68c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A15" @click="onSelectSeat('127','A15')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A15') }" d="m165.57,371.18c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A16" @click="onSelectSeat('127','A16')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A16') }" d="m164.4,370.68c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A17" @click="onSelectSeat('127','A17')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A17') }" d="m163.22,370.18c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              <path id="A18" @click="onSelectSeat('127','A18')" v-show="isSeatVisible === 'section127'" :class="{ 'selected': isSelected('A18') }" d="m162.04,369.68c.18-.17.47-.16.64.02.17.18.16.47-.02.64-.18.17-.47.16-.64-.02-.17-.18-.16-.47.02-.64Z"/>
                              
                            </g>  
                            <g id="group125">  
                              <polygon id="section125" @click="isSectionEnabled('125') ? handleZoom('section125') : null" :class="{ 'enabled': isSectionEnabled('125') , 'disabled': !isSectionEnabled('125') }" points="262.64 414.68 223.99 413.04 227.51 384.8 263.58 385.93 262.64 414.68"/>
                              <polygon class="cls-22" points="262.67 414.08 224.67 412.46 228.04 385.42 262.96 386.52 262.04 414.66 263.25 414.7 264.21 385.34 226.98 384.17 223.31 413.62 262.62 415.28 262.67 414.08 262.67 414.08"/>
                              <text class="cls-5" transform="translate(235.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">125</tspan></text>

                              <path v-for="seat in seats.section125" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section125'" @click="onSelectSeat('125', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            
                            </g>
                            <g id="group124">  
                              <polygon id="section124" @click="isSectionEnabled('124') ? handleZoom('section124') : null" :class="{ 'enabled': isSectionEnabled('124') , 'disabled': !isSectionEnabled('124') }" points="302.3 387.12 301.82 414.74 262.96 414.68 263.87 385.94 302.3 387.12"/>
                              <polygon class="cls-22" points="302.3 387.12 301.69 387.11 301.23 414.14 263.58 414.08 264.45 386.56 302.28 387.73 302.3 387.12 301.69 387.11 302.3 387.12 302.32 386.52 263.28 385.31 262.33 415.28 302.42 415.35 302.91 386.54 302.32 386.52 302.3 387.12"/>
                              <text class="cls-5" transform="translate(275.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">124</tspan></text>

                              <circle id="O6" @click="onSelectSeat('124','O6')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('O6') }" cx="292.7" cy="405.12" r=".41"/>
                              <circle id="N10" @click="onSelectSeat('124','N10')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('N10') }" cx="288.07" cy="403.94" r=".41"/>
                              <circle id="N22" @click="onSelectSeat('124','N22')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('N22') }" cx="274.2" cy="404.03" r=".41"/>
                              <circle id="N17" @click="onSelectSeat('124','N17')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('N17') }" cx="279.98" cy="403.99" r=".41"/>

                              <circle id="T6" @click="onSelectSeat('124','T6')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('T6') }" cx="292.73" cy="411.14" r=".41"/>
                              <circle id="S17" @click="onSelectSeat('124','S17')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('R17') }" cx="280.01" cy="410.02" r=".41"/>
                              <circle id="Q20" @click="onSelectSeat('124','Q20')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('Q20') }" cx="276.53" cy="407.63" r=".41"/>
                              <circle id="Q8" @click="onSelectSeat('124','Q8')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('Q8') }" cx="290.4" cy="407.54" r=".41"/>

                              <circle id="L8" @click="onSelectSeat('124','L8')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('L8') }" cx="290.36" cy="401.52" r=".41"/>
                              <circle id="K19" @click="onSelectSeat('124','K19')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('K19') }" cx="277.64" cy="400.39" r=".41"/>
                              <circle id="L1" @click="onSelectSeat('124','L1')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('L1') }" cx="298.55" cy="401.47" r=".41"/>
                              <circle id="I10" @click="onSelectSeat('124','I10')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('I10') }" cx="288.03" cy="397.92" r=".41"/>
                              <circle id="H18" @click="onSelectSeat('124','H18')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('H18') }" cx="278.78" cy="396.77" r=".41"/>
                              <circle id="G1" @click="onSelectSeat('124','G1')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('G1') }" cx="298.51" cy="395.45" r=".41"/>
                              <circle id="F19" @click="onSelectSeat('124','F19')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('F19') }" cx="277.61" cy="394.37" r=".41"/>
                              <circle id="G8" @click="onSelectSeat('124','G8')" v-show="isSeatVisible === 'section124'" :class="{ 'selected': isSelected('G8') }" cx="290.33" cy="395.5" r=".41"/>
                              
                              <path v-for="seat in seats.section124" :key="seat.id" :id="seat.id" v-show="isSeatVisible === 'section124'" @click="onSelectSeat('124', seat.id)" :class="{ 'selected': isSelected(seat.id) }" :d="seat.d"/>
                            
                            </g> 
                            <g id="group123"> 
                              <polygon id="section123" @click="isSectionEnabled('123') ? handleZoom('section123') : null" class="cls-9" points="342.08 387.06 342.08 414.74 301.82 415.03 302.3 387.12 342.08 387.06"/>
                              <polygon id="section123" @click="isSectionEnabled('123') ? handleZoom('section123') : null" class="cls-22" points="342.08 387.06 341.47 387.06 341.47 414.14 302.44 414.42 302.89 387.73 342.08 387.67 342.08 387.06 341.47 387.06 342.08 387.06 342.08 386.46 301.7 386.52 301.21 415.63 342.68 415.34 342.68 386.46 342.08 386.46 342.08 387.06"/>
                              <text class="cls-5" transform="translate(315.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">123</tspan></text>

                            </g> 
                            
                            <g id="group122">

                              <rect class="cls-9" x="342.08" y="387.06" width="40.42" height="27.68"/>
                              <polygon class="cls-22" points="382.5 414.74 383.1 414.74 383.1 386.46 341.47 386.46 341.47 415.35 383.1 415.35 383.1 414.74 382.5 414.74 382.5 414.14 342.68 414.14 342.68 387.67 381.89 387.67 381.89 414.74 382.5 414.74 382.5 414.14 382.5 414.74"/>
                              <text class="cls-5" transform="translate(355.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">122</tspan></text>

                            </g>

                            <g id="group121">  
                              <polygon class="cls-9" points="422.84 387.06 421.68 414.74 382.5 414.74 382.5 387.06 422.84 387.06"/>
                              <polygon class="cls-22" points="422.84 387.06 422.24 387.04 421.1 414.14 383.1 414.14 383.1 387.67 422.84 387.67 422.84 387.06 422.24 387.04 422.84 387.06 422.84 386.46 381.89 386.46 381.89 415.35 422.26 415.35 423.47 386.46 422.84 386.46 422.84 387.06"/>
                              <text class="cls-5" transform="translate(395.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">121</tspan></text>

                            </g> 
                            <g id="group120"> 
                              <polygon class="cls-9" points="422.84 387.06 456.19 385.02 460.32 414.74 421.68 414.74 422.84 387.06"/>
                              <polygon class="cls-22" points="422.84 387.06 422.88 387.67 455.67 385.66 459.63 414.14 422.31 414.14 423.45 387.09 422.84 387.06 422.88 387.67 422.84 387.06 422.24 387.04 421.04 415.35 461.02 415.35 456.71 384.38 422.26 386.49 422.24 387.04 422.84 387.06"/>
                              <text class="cls-5" transform="translate(430.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">120</tspan></text>

                            </g>
                            <g id="group119"> 
                              <polygon class="cls-9" points="488.41 378.22 498.14 409.23 487.81 412.71 487.05 413.04 460.32 414.74 456.19 385.02 488.41 378.22"/>
                              <polygon class="cls-22" points="488.41 378.22 487.83 378.4 497.39 408.85 487.59 412.14 486.9 412.44 460.84 414.1 456.87 385.5 488.53 378.81 488.41 378.22 487.83 378.4 488.41 378.22 488.28 377.62 455.51 384.55 459.8 415.38 487.19 413.64 488.02 413.27 498.9 409.61 488.82 377.51 488.28 377.62 488.41 378.22"/>
                              <text class="cls-5" transform="translate(470.46 405.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">119</tspan></text>

                            </g> 
                            <g id="group118">  
                              <polygon class="cls-9" points="517.67 366.65 534.53 392.51 517.51 402.52 498.14 409.23 488.41 378.22 517.67 366.65"/>
                              <polygon class="cls-22" points="517.67 366.65 517.17 366.98 533.68 392.31 517.26 401.97 498.54 408.45 489.15 378.57 517.89 367.21 517.67 366.65 517.17 366.98 517.67 366.65 517.45 366.08 487.66 377.86 497.75 410.01 517.77 403.07 535.38 392.71 517.91 365.9 517.45 366.08 517.67 366.65"/>
                              <text class="cls-5" transform="translate(500.46 395.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">118</tspan></text>

                            </g>
                            <g id="group117">    
                              <polygon class="cls-9" points="537.86 351.73 538.09 351.45 566.68 368.8 544.1 386.6 534.53 392.51 519.03 368.91 537.86 351.73"/>
                              <polygon class="cls-22" points="537.86 351.73 538.33 352.11 538.56 351.82 538.09 351.45 537.78 351.96 565.62 368.87 543.75 386.11 534.71 391.69 519.82 369.01 538.3 352.15 538.33 352.11 537.86 351.73 537.45 351.28 518.24 368.82 534.34 393.34 544.45 387.1 567.73 368.74 537.95 350.65 537.39 351.35 537.86 351.73 537.45 351.28 537.86 351.73"/>
                              <text class="cls-5" transform="translate(530.46 372.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">117</tspan></text>
                            </g>
                            <g id="group116">   
                              <polygon class="cls-9" points="553.52 325.81 583.68 336.37 581.65 341.92 566.68 368.8 538.09 351.45 536.5 349.4 553.52 325.81"/>
                              <polygon class="cls-22" points="553.52 325.81 553.32 326.38 582.9 336.74 581.1 341.66 566.45 367.96 538.5 350.99 537.26 349.39 554.01 326.16 553.52 325.81 553.32 326.38 553.52 325.81 553.03 325.46 535.75 349.42 537.68 351.9 566.9 369.64 582.2 342.17 584.46 336 553.29 325.09 553.03 325.46 553.52 325.81"/>
                              <text class="cls-5" transform="translate(550.46 350.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">116</tspan></text>
                            </g>
                            <g id="group115">   
                              <polygon class="cls-9" points="559.42 297 592.31 301.65 590.16 314.69 583.41 336.24 553.52 325.81 559.42 297"/>
                              <polygon class="cls-22" points="559.42 297 559.33 297.6 591.61 302.16 589.57 314.55 583.02 335.46 554.22 325.41 560.01 297.12 559.42 297 559.33 297.6 559.42 297 558.82 296.88 552.82 326.21 583.8 337.02 590.75 314.83 593 301.14 558.94 296.32 558.82 296.88 559.42 297"/>
                               <text class="cls-5" transform="translate(550.46 350.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">115</tspan></text>
                            </g>
                            <g id="group114"> 
                              <polygon class="cls-9" points="562.37 264.78 596.71 265.19 595.49 282.02 592.31 301.65 559.42 297 562.37 264.78"/>
                              <polygon class="cls-22" points="562.37 264.78 562.36 265.39 596.06 265.79 594.89 281.95 591.8 300.97 560.07 296.48 562.97 264.84 562.37 264.78 562.36 265.39 562.37 264.78 561.76 264.73 558.76 297.52 592.81 302.33 596.09 282.09 597.36 264.59 561.81 264.17 561.76 264.73 562.37 264.78"/>
                            </g>
                              <g id="group110"> 
                              <polygon class="cls-9" points="592.59 165.19 556.47 168.14 560.03 197.86 596.72 197.86 595.95 185.38 592.59 165.19"/>
                              <polygon class="cls-22" points="592.59 165.19 592.54 164.58 555.79 167.59 559.49 198.47 597.37 198.47 596.55 185.31 593.1 164.54 592.54 164.58 592.59 165.19 591.99 165.29 595.35 185.45 596.08 197.26 560.56 197.26 557.14 168.69 592.64 165.79 592.59 165.19 591.99 165.29 592.59 165.19"/>
                              <text class="cls-5" transform="translate(565.46 183.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">110</tspan></text>
                            </g>
                            <g id="group109">  
                              <polygon class="cls-9" points="549.89 140.69 583.18 131.61 591.18 156.68 592.59 165.19 556.47 168.14 549.89 140.69"/>
                              <polygon class="cls-22" points="549.89 140.69 550.05 141.27 582.78 132.34 590.59 156.82 591.89 164.64 556.93 167.49 550.48 140.54 549.89 140.69 550.05 141.27 549.89 140.69 549.3 140.83 556 168.78 593.29 165.74 591.77 156.53 583.58 130.87 549.16 140.26 549.3 140.83 549.89 140.69"/>
                              <text class="cls-5" transform="translate(560.46 153.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">109</tspan></text>
                            </g>

                            <g id="group111"> 
                              <polygon class="cls-9" points="569.4 239.93 597.87 240.34 597.87 249.24 596.71 265.19 569.4 264.78 569.4 239.93"/>
                              <polygon class="cls-22" points="569.4 239.93 569.39 240.54 597.27 240.94 597.27 249.22 596.15 264.58 570 264.19 570 239.93 569.4 239.93 569.39 240.54 569.4 239.93 568.79 239.93 568.79 265.38 597.27 265.8 598.48 249.26 598.48 239.75 568.79 239.32 568.79 239.93 569.4 239.93"/>
                            </g>
                            <g id="group112"> 
                              <polygon class="cls-9" points="583.97 222.98 597.97 222.7 597.87 240.34 583.92 240.05 583.97 222.98"/>
                              <polygon class="cls-22" points="583.97 222.98 583.98 223.28 597.67 223.01 597.57 240.03 584.22 239.76 584.28 222.98 583.97 222.98 583.98 223.28 583.97 222.98 583.67 222.98 583.61 240.35 598.17 240.65 598.28 222.39 583.67 222.69 583.67 222.98 583.97 222.98"/>
                            </g>
                            <g id="group113"> 
                              <polygon class="cls-9" points="597.97 222.7 567.64 222.98 567.58 200.35 560.32 200.35 560.03 197.86 596.72 197.86 597.99 218.05 597.97 222.7"/>
                              <polygon class="cls-22" points="597.97 222.7 597.97 222.1 568.24 222.37 568.19 199.75 560.86 199.75 560.71 198.47 596.15 198.47 597.39 218.06 597.37 222.7 597.97 222.7 597.97 222.1 597.97 222.7 598.58 222.7 598.6 218.03 597.29 197.26 559.34 197.26 559.79 200.96 566.98 200.96 567.04 223.59 598.58 223.3 598.58 222.7 597.97 222.7"/>
                              
                            </g>
                            <g id="group108">  
                              <polygon class="cls-9" points="562.71 98.39 567.36 103.71 581.99 128.55 583.18 131.61 549.89 140.69 534.46 118 562.71 98.39"/>
                              <polygon class="cls-22" points="562.25 98.78 566.86 104.06 581.44 128.81 582.37 131.2 550.14 139.99 535.3 118.15 563.06 98.88 562.37 97.89 533.62 117.84 549.63 141.38 583.98 132.01 582.54 128.28 567.85 103.35 563.17 97.99 562.25 98.78 562.25 98.78"/>
                              <text class="cls-5" transform="translate(550.46 123.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">108</tspan></text>
                            </g>
                            <g id="group107">  
                              <polygon class="cls-9" points="517.9 97.81 536.4 73.19 548.64 82.27 562.71 98.39 537.18 116.18 517.9 97.81"/>
                              <polygon class="cls-22" points="517.9 97.81 518.38 98.17 536.52 74.04 548.23 82.71 561.81 98.27 537.24 115.41 518.32 97.37 517.9 97.81 518.38 98.17 517.9 97.81 517.48 98.25 537.12 116.96 563.61 98.5 549.05 81.82 536.28 72.35 517.09 97.87 517.48 98.25 517.9 97.81"/>
                              <text class="cls-5" transform="translate(530.46 98.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">107</tspan></text>
                            </g>
                            <g id="group106">  
                              <polygon class="cls-9" points="488.41 87.6 497.59 56.06 526.96 66.33 536.4 73.19 516.54 99.62 488.41 87.6"/>
                              <polygon class="cls-22" points="488.41 87.6 488.99 87.77 498 56.85 526.68 66.87 535.55 73.32 516.34 98.88 488.64 87.04 488.41 87.6 488.99 87.77 488.41 87.6 488.17 88.16 516.74 100.37 537.25 73.06 527.24 65.79 497.19 55.28 487.68 87.94 488.17 88.16 488.41 87.6"/>
                              <text class="cls-5" transform="translate(500.46 78.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">106</tspan></text>
                            </g>
                            <g id="group105">    
                              <polygon class="cls-9" points="458.23 82.61 461.65 50.98 469.69 51.3 497.59 56.06 488.41 87.6 458.23 82.61"/>
                              <polygon class="cls-22" points="458.23 82.61 458.83 82.67 462.19 51.61 469.63 51.9 496.82 56.55 487.98 86.91 458.33 82.01 458.23 82.61 458.83 82.67 458.23 82.61 458.13 83.2 488.84 88.28 498.37 55.58 469.75 50.7 461.11 50.36 457.57 83.11 458.13 83.2 458.23 82.61"/>
                              <text class="cls-5" transform="translate(465.46 72.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">105</tspan></text>
                            </g>
                            <g id="group104">   
                              <polygon class="cls-9" points="424.43 81.25 427.25 56.51 433.61 56.92 434.19 49.89 461.65 50.98 458.23 82.61 424.43 81.25"/>
                              <polygon class="cls-22" points="424.43 81.25 425.03 81.32 427.79 57.15 434.17 57.56 434.75 50.52 460.98 51.56 457.69 81.98 424.45 80.64 424.43 81.25 425.03 81.32 424.43 81.25 424.41 81.85 458.77 83.24 462.32 50.41 433.64 49.27 433.06 56.28 426.72 55.87 423.76 81.82 424.41 81.85 424.43 81.25"/>
                               <text class="cls-5" transform="translate(435.46 72.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">104</tspan></text>
                            </g>
                            <g id="group103">  
                              <polygon class="cls-9" points="403.56 80.79 404.6 55.11 427.25 56.51 424.43 81.25 403.56 80.79"/>
                              <polygon class="cls-22" points="403.56 80.79 404.16 80.82 405.18 55.75 426.58 57.07 423.89 80.63 403.57 80.19 403.56 80.79 404.16 80.82 403.56 80.79 403.55 81.4 424.97 81.86 427.92 55.95 404.03 54.46 402.93 81.38 403.55 81.4 403.56 80.79"/>
                              <text class="cls-5" transform="translate(407.46 72.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">103</tspan></text>
                            </g>
                            <g id="group102">  
                              <polygon id="section102" @click="handleZoom('section102')"  :class="{ 'enabled': isSectionEnabled('102') , 'disabled': !isSectionEnabled('102') }" points="377.14 60.8 404.36 61.07 403.56 80.79 351.83 80.57 351.83 72.4 376.78 72.4 376.78 60.74 377.14 60.8"/>

                              <text class="cls-5" transform="translate(380.46 72.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">102</tspan></text>

                            <!-- <path id="M13" @click="onSelectSeat('102','M13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M13') }" d="m399.94,62.71c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M12" @click="onSelectSeat('102','M12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M12') }" d="m398.17,62.68c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M11" @click="onSelectSeat('102','M11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M11') }" d="m396.57,62.64c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="M10" @click="onSelectSeat('102','M10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M10') }" d="m394.79,62.61c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M9" @click="onSelectSeat('102','M9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M9') }" d="m392.96,62.57c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M8" @click="onSelectSeat('102','M8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M8') }" d="m391.17,62.53c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="M7" @click="onSelectSeat('102','M7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M7') }" d="m389.35,62.5c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M6" @click="onSelectSeat('102','M6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M6') }" d="m387.57,62.46c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M5" @click="onSelectSeat('102','M5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M5') }" d="m385.84,62.42c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26-.35.37-.26.61Z"/>
                            <path id="M4" @click="onSelectSeat('102','M4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M4') }" d="m384.06,62.39c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M3" @click="onSelectSeat('102','M3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M3') }" d="m382.28,62.35c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M2" @click="onSelectSeat('102','M2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M2') }" d="m380.49,62.31c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="M1" @click="onSelectSeat('102','M1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('M1') }" d="m378.7,62.28c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                             -->
                            <path id="L13" @click="onSelectSeat('102','L13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L13') }" d="m399.92,64.09c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L12" @click="onSelectSeat('102','L12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L12') }" d="m398.14,64.05c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L11" @click="onSelectSeat('102','L11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L11') }" d="m396.54,64.02c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="L10" @click="onSelectSeat('102','L10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L10') }" d="m394.76,63.99c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L9" @click="onSelectSeat('102','L9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L9') }" d="m392.93,63.95c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L8" @click="onSelectSeat('102','L8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L8') }" d="m391.15,63.91c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L7" @click="onSelectSeat('102','L7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L7') }" d="m389.33,63.87c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="L6" @click="onSelectSeat('102','L6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L6') }" d="m387.54,63.84c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L5" @click="onSelectSeat('102','L5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L5') }" d="m385.81,63.8c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="L4" @click="onSelectSeat('102','L4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L4') }" d="m384.03,63.77c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L3" @click="onSelectSeat('102','L3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L3') }" d="m382.25,63.73c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="L2" @click="onSelectSeat('102','L2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L2') }" d="m380.46,63.69c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="L1" @click="onSelectSeat('102','L1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('L1') }" d="m378.67,63.66c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            
                            <path id="K13" @click="onSelectSeat('102','K13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K13') }" d="m399.89,65.47c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="K12" @click="onSelectSeat('102','K12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K12') }" d="m398.11,65.43c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K11" @click="onSelectSeat('102','K11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K11') }" d="m396.52,65.4c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K10" @click="onSelectSeat('102','K10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K10') }" d="m394.73,65.37c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="K9" @click="onSelectSeat('102','K9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K9') }" d="m392.9,65.33c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K8" @click="onSelectSeat('102','K8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K8') }" d="m391.12,65.29c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K7" @click="onSelectSeat('102','K7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K7') }" d="m389.3,65.25c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26-.35.37-.26.61Z"/>
                            <path id="K6" @click="onSelectSeat('102','K6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K6') }" d="m387.51,65.22c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K5" @click="onSelectSeat('102','K5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K5') }" d="m385.78,65.18c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="K4" @click="onSelectSeat('102','K4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K4') }" d="m384,65.15c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K3" @click="onSelectSeat('102','K3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K3') }" d="m382.22,65.11c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="K2" @click="onSelectSeat('102','K2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K2') }" d="m380.43,65.07c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="K1" @click="onSelectSeat('102','K1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('K1') }" d="m378.64,65.04c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            
                            <path id="J13" @click="onSelectSeat('102','J13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J13') }" d="m399.86,66.85c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="J12" @click="onSelectSeat('102','J12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J12') }" d="m398.08,66.81c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J11" @click="onSelectSeat('102','J11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J11') }" d="m396.49,66.78c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J10" @click="onSelectSeat('102','J10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J10') }" d="m394.7,66.74c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="J9" @click="onSelectSeat('102','J9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J9') }" d="m392.88,66.71c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="J8" @click="onSelectSeat('102','J8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J8') }" d="m391.09,66.67c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J7" @click="onSelectSeat('102','J7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J7') }" d="m389.27,66.63c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J6" @click="onSelectSeat('102','J6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J6') }" d="m387.49,66.6c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J5" @click="onSelectSeat('102','J5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J5') }" d="m385.76,66.56c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J4" @click="onSelectSeat('102','J4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J4') }" d="m383.97,66.53c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="J3" @click="onSelectSeat('102','J3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J3') }" d="m382.19,66.49c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="J2" @click="onSelectSeat('102','J2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J2') }" d="m380.41,66.45c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="J1" @click="onSelectSeat('102','J1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('J1') }" d="m378.61,66.42c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            
                            <path id="I13" @click="onSelectSeat('102','I13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I13') }" d="m399.83,68.23c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="I12" @click="onSelectSeat('102','I12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I12') }" d="m398.05,68.19c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="I11" @click="onSelectSeat('102','I11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I11') }" d="m396.46,68.16c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="I10" @click="onSelectSeat('102','I10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I10') }" d="m394.67,68.12c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="I9" @click="onSelectSeat('102','I9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I9') }" d="m392.85,68.09c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="I8" @click="onSelectSeat('102','I8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I8') }" d="m391.06,68.05c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="I7" @click="onSelectSeat('102','I7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I7') }" d="m389.24,68.01c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="I6" @click="onSelectSeat('102','I6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I6') }" d="m387.46,67.98c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="I5" @click="onSelectSeat('102','I5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I5') }" d="m385.73,67.94c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="I4" @click="onSelectSeat('102','I4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I4') }" d="m383.94,67.91c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="I3" @click="onSelectSeat('102','I3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I3') }" d="m382.16,67.87c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="I2" @click="onSelectSeat('102','I2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I2') }" d="m380.38,67.83c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="I1" @click="onSelectSeat('102','I1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('I1') }" d="m378.59,67.8c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            
                            <path id="H13" @click="onSelectSeat('102','H13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H13') }" d="m399.8,69.61c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="H12" @click="onSelectSeat('102','H12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H12') }" d="m398.02,69.57c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="H11" @click="onSelectSeat('102','H11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H11') }" d="m396.43,69.54c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="H10" @click="onSelectSeat('102','H10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H10') }" d="m394.65,69.5c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="H9" @click="onSelectSeat('102','H9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H9') }" d="m392.82,69.47c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="H8" @click="onSelectSeat('102','H8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H8') }" d="m391.03,69.43c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="H7" @click="onSelectSeat('102','H7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H7') }" d="m389.21,69.39c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="H6" @click="onSelectSeat('102','H6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H6') }" d="m387.43,69.36c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="H5" @click="onSelectSeat('102','H5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H5') }" d="m385.7,69.32c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="H4" @click="onSelectSeat('102','H4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H4') }" d="m383.92,69.28c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="H3" @click="onSelectSeat('102','H3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H3') }" d="m382.13,69.25c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="H2" @click="onSelectSeat('102','H2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H2') }" d="m380.35,69.21c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="H1" @click="onSelectSeat('102','H1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('H1') }" d="m378.56,69.18c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            
                            <path id="G13" @click="onSelectSeat('102','G13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G13') }" d="m399.77,70.99c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G12" @click="onSelectSeat('102','G12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G12') }" d="m398,70.95c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="G11" @click="onSelectSeat('102','G11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G11') }" d="m396.4,70.92c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G10" @click="onSelectSeat('102','G10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G10') }" d="m394.62,70.88c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G9" @click="onSelectSeat('102','G9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G9') }" d="m392.79,70.85c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G8" @click="onSelectSeat('102','G8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G8') }" d="m391.01,70.81c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="G7" @click="onSelectSeat('102','G7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G7') }" d="m389.19,70.77c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G6" @click="onSelectSeat('102','G6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G6') }" d="m387.4,70.74c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26-.35.37-.26.61Z"/>
                            <path id="G5" @click="onSelectSeat('102','G5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G5') }" d="m385.67,70.7c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G4" @click="onSelectSeat('102','G4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G4') }" d="m383.89,70.66c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="G3" @click="onSelectSeat('102','G3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G3') }" d="m382.11,70.63c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="G2" @click="onSelectSeat('102','G2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G2') }" d="m380.32,70.59c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="G1" @click="onSelectSeat('102','G1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('G1') }" d="m378.53,70.56c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            
                            <path id="F13" @click="onSelectSeat('102','F13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F13') }" d="m399.75,72.37c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F12" @click="onSelectSeat('102','F12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F12') }" d="m397.97,72.33c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="F11" @click="onSelectSeat('102','F11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F11') }" d="m396.38,72.3c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path id="F10" @click="onSelectSeat('102','F10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F10') }" d="m394.59,72.26c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F9" @click="onSelectSeat('102','F9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F9') }" d="m392.76,72.23c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F8" @click="onSelectSeat('102','F8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F8') }" d="m390.98,72.19c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="F7" @click="onSelectSeat('102','F7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F7') }" d="m389.16,72.15c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F6" @click="onSelectSeat('102','F6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F6') }" d="m387.37,72.12c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="F5" @click="onSelectSeat('102','F5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F5') }" d="m385.64,72.08c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F4" @click="onSelectSeat('102','F4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F4') }" d="m383.86,72.04c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F3" @click="onSelectSeat('102','F3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F3') }" d="m382.08,72.01c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path id="F2" @click="onSelectSeat('102','F2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F2') }" d="m380.29,71.97c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path id="F1" @click="onSelectSeat('102','F1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('F1') }" d="m378.5,71.93c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                                 <!--
                            <path class="cls-15" d="m399.72,73.75c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m397.94,73.71c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m396.35,73.68c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m394.56,73.64c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m392.73,73.61c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m390.95,73.57c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m389.13,73.53c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m387.34,73.5c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m385.62,73.46c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m383.83,73.42c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m382.05,73.39c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m380.26,73.35c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m378.47,73.31c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            
                            
                            <path class="cls-15" d="m399.69,75.13c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m397.91,75.09c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m396.32,75.06c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m394.53,75.02c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m392.71,74.99c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m390.92,74.95c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m389.1,74.91c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m387.32,74.87c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m385.59,74.84c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m383.8,74.8c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m382.02,74.77c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m380.24,74.73c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m378.44,74.69c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            
                            <path class="cls-15" d="m399.66,76.51c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m397.88,76.47c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m396.29,76.44c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m394.51,76.4c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m392.68,76.36c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m390.89,76.33c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m389.07,76.29c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m387.29,76.25c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m385.56,76.22c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m383.77,76.18c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m381.99,76.15c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m380.21,76.11c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m399.63,77.89c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m397.86,77.85c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m396.26,77.82c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m394.48,77.78c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m392.65,77.74c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m390.87,77.71c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m389.04,77.67c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m387.26,77.63c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m385.53,77.6c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m383.75,77.56c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m381.97,77.53c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m380.18,77.49c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m378.39,77.45c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m376.63,77.42c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m374.97,77.38c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m373.33,77.35c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m371.54,77.31c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m369.76,77.28c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                            <path class="cls-15" d="m367.83,77.1c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>

                              <path id="B13" @click="onSelectSeat('102','B13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B13') }" d="m383.72,78.94c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B12" @click="onSelectSeat('102','B12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B12') }" d="m380.15,78.87c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B11" @click="onSelectSeat('102','B11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B11') }" d="m376.6,78.8c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B10" @click="onSelectSeat('102','B10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B10') }" d="m373.3,78.73c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B8" @click="onSelectSeat('102','B8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B8') }" d="m366.14,77.07c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B7" @click="onSelectSeat('102','B7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B7') }" d="m364.48,77.03c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B6" @click="onSelectSeat('102','B6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B6') }" d="m362.68,76.99c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B5" @click="onSelectSeat('102','B5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B5') }" d="m360.9,76.96c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B4" @click="onSelectSeat('102','B4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B4') }" d="m359.13,76.92c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                              <path id="B3" @click="onSelectSeat('102','B3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B3') }" d="m357.39,76.89c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B2" @click="onSelectSeat('102','B2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B2') }" d="m355.6,76.85c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="B1" @click="onSelectSeat('102','B1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('B1') }" d="m385.5,78.98c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              
                              <path id="A20" @click="onSelectSeat('102','A20')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m399.61,79.27c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                              <path id="A19" @click="onSelectSeat('102','A19')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m397.83,79.23c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A18" @click="onSelectSeat('102','A18')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m396.23,79.2c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A17" @click="onSelectSeat('102','A17')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m394.45,79.16c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26-.35.37-.26.61Z"/>
                              <path id="A16" @click="onSelectSeat('102','A16')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m392.62,79.12c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                              <path id="A15" @click="onSelectSeat('102','A15')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m390.84,79.09c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A14" @click="onSelectSeat('102','A14')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m389.02,79.05c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                              <path id="A13" @click="onSelectSeat('102','A13')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m387.23,79.01c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A12" @click="onSelectSeat('102','A12')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A12') }" d="m381.94,78.91c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                              <path id="A11" @click="onSelectSeat('102','A11')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A11') }" d="m378.36,78.83c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A10" @click="onSelectSeat('102','A10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A10') }" d="m374.94,78.76c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A10" @click="onSelectSeat('102','A10')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A10') }" d="m371.51,78.69c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26c-.24.1-.35.37-.26.61Z"/>
                              <path id="A9" @click="onSelectSeat('102','A9')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A9') }" d="m369.73,78.66c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A8" @click="onSelectSeat('102','A8')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A8') }" d="m367.8,78.48c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                              <path id="A7" @click="onSelectSeat('102','A7')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A7') }" d="m366.12,78.45c.1.24.37.35.61.26.24-.1.35-.37.26-.61s-.37-.35-.61-.26-.35.37-.26.61Z"/>
                              <path id="A6" @click="onSelectSeat('102','A6')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A6') }" d="m364.45,78.41c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A5" @click="onSelectSeat('102','A5')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A5') }" d="m362.65,78.37c.1.24.37.35.61.26s.35-.37.26-.61c-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A4" @click="onSelectSeat('102','A4')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A4') }" d="m360.87,78.34c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                              <path id="A3" @click="onSelectSeat('102','A3')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A3') }" d="m359.1,78.3c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A2" @click="onSelectSeat('102','A2')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A2') }" d="m357.36,78.27c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26-.24.1-.35.37-.26.61Z"/>
                              <path id="A1" @click="onSelectSeat('102','A1')" v-show="isSeatVisible === 'section102'" :class="{ 'selected': isSelected('A1') }" d="m355.57,78.23c.1.24.37.35.61.26.24-.1.35-.37.26-.61-.1-.24-.37-.35-.61-.26s-.35.37-.26.61Z"/>
                            -->
                            </g>
                            <g id="group143">  
                              
                              <polygon :class="{ 'enabled': isSectionEnabled('143') , 'disabled': !isSectionEnabled('143') }" points="295.63 58.49 295.4 77.39 347.3 79.66 347.3 72.4 320.48 72.23 320.55 59.09 295.63 58.49"/>
                              <text class="cls-5" transform="translate(300.46 72.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">143</tspan></text>

                            </g>
                            <g id="group142">  
                              <polygon id="section142" @click="isSectionEnabled('142') ? handleZoom('section142') : null" :class="{ 'enabled': isSectionEnabled('142') , 'disabled': !isSectionEnabled('142') }" points="274.45 52.03 272.9 77.28 293.76 77.45 294.21 52.03 274.45 52.03"/>
                              <polygon id="section142" @click="isSectionEnabled('142') ? handleZoom('section142') : null" class="cls-22" points="274.45 52.03 273.85 51.99 272.26 77.88 294.36 78.06 294.82 51.43 273.88 51.43 273.85 51.99 274.45 52.03 274.45 52.64 293.59 52.64 293.17 76.84 273.54 76.68 275.06 52.07 274.45 52.03 274.45 52.64 274.45 52.03"/>
                              <text class="cls-5" transform="translate(275.46 70.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">142</tspan></text>

                              <path id="R1" @click="onSelectSeat('142','R1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R1') }" d="m276.13,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>              
                              <path id="R2" @click="onSelectSeat('142','R2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R2') }" d="m277.41,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R3" @click="onSelectSeat('142','R3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R3') }" d="m278.69,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R4" @click="onSelectSeat('142','R4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R4') }" d="m279.97,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R5" @click="onSelectSeat('142','R5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R5') }" d="m281.25,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R6" @click="onSelectSeat('142','R6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R6') }" d="m282.53,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R7" @click="onSelectSeat('142','R7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R7') }" d="m283.82,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R8" @click="onSelectSeat('142','R8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R8') }" d="m285.1,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R9" @click="onSelectSeat('142','R9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R9') }" d="m286.38,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R10" @click="onSelectSeat('142','R10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R10') }" d="m287.66,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R11" @click="onSelectSeat('142','R11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R11') }" d="m288.94,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R12" @click="onSelectSeat('142','R12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R12') }" d="m290.22,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R13" @click="onSelectSeat('142','R13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R13') }" d="m291.5,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="R14" @click="onSelectSeat('142','R14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('R14') }" d="m292.78,53.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="Q1" @click="onSelectSeat('142','Q1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q1') }" d="m276.13,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q2" @click="onSelectSeat('142','Q2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q2') }" d="m277.41,54.51c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="Q3" @click="onSelectSeat('142','Q3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q3') }" d="m278.69,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q4" @click="onSelectSeat('142','Q4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q4') }" d="m279.97,54.51c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q5" @click="onSelectSeat('142','Q5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q5') }" d="m281.25,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q6" @click="onSelectSeat('142','Q6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q6') }" d="m282.53,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q7" @click="onSelectSeat('142','Q7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q7') }" d="m283.82,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="Q8" @click="onSelectSeat('142','Q8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q8') }" d="m285.1,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q9" @click="onSelectSeat('142','Q9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q9') }" d="m286.38,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="Q10" @click="onSelectSeat('142','Q10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q10') }" d="m287.66,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q11" @click="onSelectSeat('142','Q11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q11') }" d="m288.94,54.51c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q12" @click="onSelectSeat('142','Q12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q12') }" d="m290.22,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q13" @click="onSelectSeat('142','Q13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q13') }" d="m291.5,54.51c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="Q14" @click="onSelectSeat('142','Q14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('Q14') }" d="m292.78,54.51c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="P1" @click="onSelectSeat('142','P1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P1') }" d="m276.13,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P2" @click="onSelectSeat('142','P2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P2') }" d="m277.41,55.85c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="P3" @click="onSelectSeat('142','P3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P3') }" d="m278.69,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P4" @click="onSelectSeat('142','P4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P4') }" d="m279.97,55.85c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P5" @click="onSelectSeat('142','P5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P5') }" d="m281.25,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P6" @click="onSelectSeat('142','P6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P6') }" d="m282.53,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P7" @click="onSelectSeat('142','P7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P7') }" d="m283.82,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="P8" @click="onSelectSeat('142','P8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P8') }" d="m285.1,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P9" @click="onSelectSeat('142','P9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P9') }" d="m286.38,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="P10" @click="onSelectSeat('142','P10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P10') }" d="m287.66,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P11" @click="onSelectSeat('142','P11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P11') }" d="m288.94,55.85c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P12" @click="onSelectSeat('142','P12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P12') }" d="m290.22,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P13" @click="onSelectSeat('142','P13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P13') }" d="m291.5,55.85c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="P14" @click="onSelectSeat('142','P14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('P14') }" d="m292.78,55.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="O1" @click="onSelectSeat('142','O1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O1') }" d="m276.13,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O2" @click="onSelectSeat('142','O2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O2') }" d="m277.41,57.18c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="O3" @click="onSelectSeat('142','O3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O3') }" d="m278.69,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O4" @click="onSelectSeat('142','O4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O4') }" d="m279.97,57.18c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O5" @click="onSelectSeat('142','O5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O5') }" d="m281.25,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O6" @click="onSelectSeat('142','O6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O6') }" d="m282.53,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O7" @click="onSelectSeat('142','O7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O7') }" d="m283.82,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="O8" @click="onSelectSeat('142','O8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O8') }" d="m285.1,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O9" @click="onSelectSeat('142','O9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O9') }" d="m286.38,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="O10" @click="onSelectSeat('142','O10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O10') }" d="m287.66,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O11" @click="onSelectSeat('142','O11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O11') }" d="m288.94,57.18c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O12" @click="onSelectSeat('142','O12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O12') }" d="m290.22,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O13" @click="onSelectSeat('142','O13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O13') }" d="m291.5,57.18c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="O14" @click="onSelectSeat('142','O14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('O14') }" d="m292.78,57.18c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="N1" @click="onSelectSeat('142','N1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N1') }" d="m281.25,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N2" @click="onSelectSeat('142','N2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N2') }" d="m282.53,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N3" @click="onSelectSeat('142','N3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N3') }" d="m283.82,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N4" @click="onSelectSeat('142','N4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N4') }" d="m285.1,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N5" @click="onSelectSeat('142','N5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N5') }" d="m286.38,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N6" @click="onSelectSeat('142','N6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N6') }" d="m287.66,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N7" @click="onSelectSeat('142','N7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N7') }" d="m288.94,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N8" @click="onSelectSeat('142','N8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N8') }" d="m290.22,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N9" @click="onSelectSeat('142','N9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N9') }" d="m291.5,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="N10" @click="onSelectSeat('142','N10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('N10') }" d="m292.78,58.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="M1" @click="onSelectSeat('142','M1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M1') }" d="m281.25,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M2" @click="onSelectSeat('142','M2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M2') }" d="m282.53,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M3" @click="onSelectSeat('142','M3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M3') }" d="m283.82,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="M4" @click="onSelectSeat('142','M4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M4') }" d="m285.1,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M5" @click="onSelectSeat('142','M5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M5') }" d="m286.38,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="M6" @click="onSelectSeat('142','M6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M6') }" d="m287.66,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M7" @click="onSelectSeat('142','M7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M7') }" d="m288.94,59.85c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M8" @click="onSelectSeat('142','M8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M8') }" d="m290.22,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M9" @click="onSelectSeat('142','M9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M9') }" d="m291.5,59.85c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="M10" @click="onSelectSeat('142','M10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('M10') }" d="m292.78,59.85c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="L1" @click="onSelectSeat('142','L1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L1') }" d="m281.25,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L2" @click="onSelectSeat('142','L2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L2') }" d="m282.53,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L3" @click="onSelectSeat('142','L3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L3') }" d="m283.82,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="L4" @click="onSelectSeat('142','L4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L4') }" d="m285.1,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L5" @click="onSelectSeat('142','L5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L5') }" d="m286.38,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="L6" @click="onSelectSeat('142','L6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L6') }" d="m287.66,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L7" @click="onSelectSeat('142','L7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L7') }" d="m288.94,61.19c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L8" @click="onSelectSeat('142','L8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L8') }" d="m290.22,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L9" @click="onSelectSeat('142','L9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L9') }" d="m291.5,61.19c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="L10" @click="onSelectSeat('142','L10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('L10') }" d="m292.78,61.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="K1" @click="onSelectSeat('142','K1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K1') }" d="m281.25,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K2" @click="onSelectSeat('142','K2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K2') }" d="m282.53,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K3" @click="onSelectSeat('142','K3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K3') }" d="m283.82,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="K4" @click="onSelectSeat('142','K4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K4') }" d="m285.1,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K5" @click="onSelectSeat('142','K5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K5') }" d="m286.38,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="K6" @click="onSelectSeat('142','K6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K6') }" d="m287.66,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K7" @click="onSelectSeat('142','K7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K7') }" d="m288.94,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K8" @click="onSelectSeat('142','K8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K8') }" d="m290.22,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K9" @click="onSelectSeat('142','K9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K9') }" d="m291.5,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="K10" @click="onSelectSeat('142','K10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('K10') }" d="m292.78,62.52c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="J1" @click="onSelectSeat('142','J1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J1') }" d="m281.25,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J2" @click="onSelectSeat('142','J2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J2') }" d="m282.53,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J3" @click="onSelectSeat('142','J3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J3') }" d="m283.82,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J4" @click="onSelectSeat('142','J4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J4') }" d="m285.1,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J5" @click="onSelectSeat('142','J5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J5') }" d="m286.38,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J6" @click="onSelectSeat('142','J6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J6') }" d="m287.66,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J7" @click="onSelectSeat('142','J7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J7') }" d="m288.94,63.86c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J8" @click="onSelectSeat('142','J8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J8') }" d="m290.22,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J9" @click="onSelectSeat('142','J9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J9') }" d="m291.5,63.86c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="J10" @click="onSelectSeat('142','J10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('J10') }" d="m292.78,63.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="I1" @click="onSelectSeat('142','I1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I1') }" d="m281.25,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="I2" @click="onSelectSeat('142','I2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I2') }" d="m282.53,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                              <path id="I3" @click="onSelectSeat('142','I3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I3') }" d="m283.82,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                              <path id="I4" @click="onSelectSeat('142','I4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I4') }" d="m285.1,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="I5" @click="onSelectSeat('142','I5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I5') }" d="m286.38,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6s.37-.33.6-.23.33.37.23.6Z"/>
                              <path id="I6" @click="onSelectSeat('142','I6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I6') }" d="m287.66,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="I7" @click="onSelectSeat('142','I7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I7') }" d="m288.94,65.19c-.1.23-.37.33-.6.23s-.33-.37-.23-.6.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                              <path id="I8" @click="onSelectSeat('142','I8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I8') }" d="m290.22,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="I9" @click="onSelectSeat('142','I9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I9') }" d="m291.5,65.19c-.1.23-.37.33-.6.23s-.33-.37-.23-.6.37-.33.6-.23c.23.1.33.37.23.6Z"/>
                              <path id="I10" @click="onSelectSeat('142','I10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('I10') }" d="m292.78,65.19c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="H1" @click="onSelectSeat('142','H1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H1') }" d="m276.13,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H2" @click="onSelectSeat('142','H2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H2') }" d="m277.41,66.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="H3" @click="onSelectSeat('142','H3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H3') }" d="m278.69,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H4" @click="onSelectSeat('142','H4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H4') }" d="m279.97,66.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H5" @click="onSelectSeat('142','H5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H5') }" d="m281.25,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H6" @click="onSelectSeat('142','H6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H6') }" d="m282.53,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H7" @click="onSelectSeat('142','H7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H7') }" d="m283.82,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="H8" @click="onSelectSeat('142','H8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H8') }" d="m285.1,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H9" @click="onSelectSeat('142','H9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H9') }" d="m286.38,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="H10" @click="onSelectSeat('142','H10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H10') }" d="m287.66,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H11" @click="onSelectSeat('142','H11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H11') }" d="m288.94,66.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H12" @click="onSelectSeat('142','H12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H12') }" d="m290.22,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H13" @click="onSelectSeat('142','H13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H13') }" d="m291.5,66.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="H14" @click="onSelectSeat('142','H14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('H14') }" d="m292.78,66.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="G1" @click="onSelectSeat('142','G1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G1') }" d="m276.13,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G2" @click="onSelectSeat('142','G2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G2') }" d="m277.41,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G3" @click="onSelectSeat('142','G3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G3') }" d="m278.69,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G4" @click="onSelectSeat('142','G4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G4') }" d="m279.97,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G5" @click="onSelectSeat('142','G5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G5') }" d="m281.25,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G6" @click="onSelectSeat('142','G6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G6') }" d="m282.53,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G7" @click="onSelectSeat('142','G7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G7') }" d="m283.82,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G8" @click="onSelectSeat('142','G8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G8') }" d="m285.1,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G9" @click="onSelectSeat('142','G9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G9') }" d="m286.38,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G10" @click="onSelectSeat('142','G10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G10') }" d="m287.66,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G11" @click="onSelectSeat('142','G11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G11') }" d="m288.94,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G12" @click="onSelectSeat('142','G12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G12') }" d="m290.22,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G13" @click="onSelectSeat('142','G13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G13') }" d="m291.5,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="G14" @click="onSelectSeat('142','G14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('G14') }" d="m292.78,67.86c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="F1" @click="onSelectSeat('142','F1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F1') }" d="m276.13,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F2" @click="onSelectSeat('142','F2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F2') }" d="m277.41,69.2c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="F3" @click="onSelectSeat('142','F3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F3') }" d="m278.69,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F4" @click="onSelectSeat('142','F4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F4') }" d="m279.97,69.2c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F5" @click="onSelectSeat('142','F5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F5') }" d="m281.25,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F6" @click="onSelectSeat('142','F6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F6') }" d="m282.53,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F7" @click="onSelectSeat('142','F7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F7') }" d="m283.82,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="F8" @click="onSelectSeat('142','F8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F8') }" d="m285.1,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F9" @click="onSelectSeat('142','F9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F9') }" d="m286.38,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="F10" @click="onSelectSeat('142','F10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F10') }" d="m287.66,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F11" @click="onSelectSeat('142','F11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F11') }" d="m288.94,69.2c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F12" @click="onSelectSeat('142','F12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F12') }" d="m290.22,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F13" @click="onSelectSeat('142','F13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F13') }" d="m291.5,69.2c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="F14" @click="onSelectSeat('142','F14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('F14') }" d="m292.78,69.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="E1" @click="onSelectSeat('142','E1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E1') }" d="m276.13,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E2" @click="onSelectSeat('142','E2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E2') }" d="m277.41,70.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="E3" @click="onSelectSeat('142','E3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E3') }" d="m278.69,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E4" @click="onSelectSeat('142','E4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E4') }" d="m279.97,70.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E5" @click="onSelectSeat('142','E5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E5') }" d="m281.25,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E6" @click="onSelectSeat('142','E6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E6') }" d="m282.53,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E7" @click="onSelectSeat('142','E7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E7') }" d="m283.82,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="E8" @click="onSelectSeat('142','E8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E8') }" d="m285.1,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E9" @click="onSelectSeat('142','E9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E9') }" d="m286.38,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="E10" @click="onSelectSeat('142','E10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E10') }" d="m287.66,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E11" @click="onSelectSeat('142','E11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E11') }" d="m288.94,70.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E12" @click="onSelectSeat('142','E12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E12') }" d="m290.22,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E13" @click="onSelectSeat('142','E13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E13') }" d="m291.5,70.53c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="E14" @click="onSelectSeat('142','E14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('E14') }" d="m292.78,70.53c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                                                          
                              <path id="D1" @click="onSelectSeat('142','D1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D1') }"  d="m276.13,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D2" @click="onSelectSeat('142','D2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D2') }"  d="m277.41,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="D3" @click="onSelectSeat('142','D3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D3') }"  d="m278.69,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D4" @click="onSelectSeat('142','D4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D4') }"  d="m279.97,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D5" @click="onSelectSeat('142','D5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D5') }"  d="m281.25,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D6" @click="onSelectSeat('142','D6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D6') }"  d="m282.53,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D7" @click="onSelectSeat('142','D7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D7') }"  d="m283.82,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="D8" @click="onSelectSeat('142','D8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D8') }"  d="m285.1,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D9" @click="onSelectSeat('142','D9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D9') }"  d="m286.38,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="D10" @click="onSelectSeat('142','D10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D10') }"  d="m287.66,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D11" @click="onSelectSeat('142','D11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D11') }"  d="m288.94,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D12" @click="onSelectSeat('142','D12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D12') }"  d="m290.22,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D13" @click="onSelectSeat('142','D13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D13') }"  d="m291.5,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="D14" @click="onSelectSeat('142','D14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('D14') }"  d="m292.78,71.87c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>

                              <path id="C1" @click="onSelectSeat('142','C1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C1') }" d="m276.13,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C2" @click="onSelectSeat('142','C2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C2') }" d="m277.41,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C3" @click="onSelectSeat('142','C3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C3') }" d="m278.69,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C4" @click="onSelectSeat('142','C4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C4') }" d="m279.97,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C5" @click="onSelectSeat('142','C5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C5') }" d="m281.25,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C6" @click="onSelectSeat('142','C6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C6') }" d="m282.53,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C7" @click="onSelectSeat('142','C7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C7') }" d="m283.82,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C8" @click="onSelectSeat('142','C8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C8') }" d="m285.1,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C9" @click="onSelectSeat('142','C9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C9') }" d="m286.38,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C10" @click="onSelectSeat('142','C10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C10') }" d="m287.66,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C11" @click="onSelectSeat('142','C11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C11') }" d="m288.94,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C12" @click="onSelectSeat('142','C12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C12') }" d="m290.22,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C13" @click="onSelectSeat('142','C13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C13') }" d="m291.5,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="C14" @click="onSelectSeat('142','C14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('C14') }" d="m292.78,73.2c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="B1" @click="onSelectSeat('142','B1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B1') }" d="m276.13,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B2" @click="onSelectSeat('142','B2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B2') }" d="m277.41,74.54c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="B3" @click="onSelectSeat('142','B3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B3') }" d="m278.69,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B4" @click="onSelectSeat('142','B4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B4') }" d="m279.97,74.54c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B5" @click="onSelectSeat('142','B5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B5') }" d="m281.25,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B6" @click="onSelectSeat('142','B6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B6') }" d="m282.53,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B7" @click="onSelectSeat('142','B7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B7') }" d="m283.82,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="B8" @click="onSelectSeat('142','B8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B8') }" d="m285.1,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B9" @click="onSelectSeat('142','B9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B9') }" d="m286.38,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="B10" @click="onSelectSeat('142','B10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B10') }" d="m287.66,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B11" @click="onSelectSeat('142','B11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B11') }" d="m288.94,74.54c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B12" @click="onSelectSeat('142','B12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B12') }" d="m290.22,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B13" @click="onSelectSeat('142','B13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B13') }" d="m291.5,74.54c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="B14" @click="onSelectSeat('142','B14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('B14') }" d="m292.78,74.54c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              
                              <path id="A1" @click="onSelectSeat('142','A1')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A1') }" d="m276.13,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A2" @click="onSelectSeat('142','A2')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A2') }" d="m277.41,75.88c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="A3" @click="onSelectSeat('142','A3')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A3') }" d="m278.69,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A4" @click="onSelectSeat('142','A4')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A4') }" d="m279.97,75.88c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A5" @click="onSelectSeat('142','A5')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A5') }" d="m281.25,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A6" @click="onSelectSeat('142','A6')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A6') }" d="m282.53,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A7" @click="onSelectSeat('142','A7')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A7') }" d="m283.82,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="A8" @click="onSelectSeat('142','A8')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A8') }" d="m285.1,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A9" @click="onSelectSeat('142','A9')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A9') }" d="m286.38,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23s.33.37.23.6Z"/>
                              <path id="A10" @click="onSelectSeat('142','A10')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A10') }" d="m287.66,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A11" @click="onSelectSeat('142','A11')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A11') }" d="m288.94,75.88c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A12" @click="onSelectSeat('142','A12')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A12') }" d="m290.22,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A13" @click="onSelectSeat('142','A13')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A13') }" d="m291.5,75.88c-.1.23-.37.33-.6.23s-.33-.37-.23-.6c.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/>
                              <path id="A14" @click="onSelectSeat('142','A14')" v-show="isSeatVisible === 'section142'" :class="{ 'selected': isSelected('A14') }" d="m292.78,75.88c-.1.23-.37.33-.6.23-.23-.1-.33-.37-.23-.6.1-.23.37-.33.6-.23.23.1.33.37.23.6Z"/> 

                            </g>
                            <g id="group141">  
                              <polygon id="section141" @click="isSectionEnabled('141') ? handleZoom('section141') : null" :class="{ 'enabled': isSectionEnabled('141') , 'disabled': !isSectionEnabled('141') }" points="236.58 45.59 238.63 76.26 272.9 77.28 274.45 52.03 265.54 52.03 265.54 45.59 236.58 45.59"/>
                              <polygon id="section141" @click="isSectionEnabled('141') ? handleZoom('section141') : null" class="cls-22" points="236.58 45.59 235.97 45.63 238.06 76.84 273.47 77.9 275.1 51.43 266.15 51.43 266.15 44.99 235.93 44.99 235.97 45.63 236.58 45.59 236.58 46.2 264.94 46.2 264.94 52.64 273.81 52.64 272.33 76.65 239.19 75.67 237.18 45.55 236.58 45.59 236.58 46.2 236.58 45.59"/>
                              <text class="cls-5" transform="translate(245.46 65.32) scale(5 5)"><tspan x="0" y="0" xml:space="preserve">141</tspan></text>
                
                              <path id="W1" @click="onSelectSeat('141','W1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('W1') }" d="m239.2,46.84c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="W2" @click="onSelectSeat('141','W2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('W2') }" d="m240.46,46.84c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="V1" @click="onSelectSeat('141','V1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('V1') }" d="m239.2,48.15c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="V2" @click="onSelectSeat('141','V2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('V2') }" d="m240.46,48.15c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="U1" @click="onSelectSeat('141','U1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('U1') }" d="m239.2,49.47c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="U2" @click="onSelectSeat('141','U2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('U2') }" d="m240.46,49.47c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="T1" @click="onSelectSeat('141','T1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('T1') }" d="m239.2,50.78c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="T2" @click="onSelectSeat('141','T2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('T2') }" d="m240.46,50.78c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="S1" @click="onSelectSeat('141','S1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S1') }" d="m239.2,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S2" @click="onSelectSeat('141','S2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S2') }" d="m240.46,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S3" @click="onSelectSeat('141','S3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S3') }" d="m255.6,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S4" @click="onSelectSeat('141','S4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S4') }" d="m256.86,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S5" @click="onSelectSeat('141','S5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S5') }" d="m258.13,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>              
                              <path id="S6" @click="onSelectSeat('141','S6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S6') }" d="m259.39,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S7" @click="onSelectSeat('141','S7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S7') }" d="m260.65,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S8" @click="onSelectSeat('141','S8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S8') }" d="m261.91,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S9" @click="onSelectSeat('141','S9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S9') }" d="m263.17,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="S10" @click="onSelectSeat('141','S10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('S10') }" d="m264.43,52.1c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="R1" @click="onSelectSeat('141','R1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R1') }" d="m239.2,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R2" @click="onSelectSeat('141','R2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R2') }" d="m240.46,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R3" @click="onSelectSeat('141','R3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R3') }" d="m241.72,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R4" @click="onSelectSeat('141','R4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R4') }" d="m242.99,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R5" @click="onSelectSeat('141','R5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R5') }" d="m244.25,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R6" @click="onSelectSeat('141','R6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R6') }" d="m245.51,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R7" @click="onSelectSeat('141','R7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R7') }" d="m246.77,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R8" @click="onSelectSeat('141','R8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R8') }" d="m248.03,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R9" @click="onSelectSeat('141','R9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R9') }" d="m249.29,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R10" @click="onSelectSeat('141','R10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R10') }" d="m250.56,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="R11" @click="onSelectSeat('141','R11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R11') }" d="m251.82,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R12" @click="onSelectSeat('141','R12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R12') }" d="m253.08,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R13" @click="onSelectSeat('141','R13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R13') }" d="m254.34,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R14" @click="onSelectSeat('141','R14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R14') }" d="m255.6,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R15" @click="onSelectSeat('141','R15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R15') }" d="m256.86,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R16" @click="onSelectSeat('141','R16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R16') }" d="m258.13,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R17" @click="onSelectSeat('141','R17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R17') }" d="m259.39,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R18" @click="onSelectSeat('141','R18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R18') }" d="m260.65,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R19" @click="onSelectSeat('141','R19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R19') }" d="m261.91,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R20" @click="onSelectSeat('141','R20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R20') }" d="m263.17,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R21" @click="onSelectSeat('141','R21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R21') }" d="m264.43,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R22" @click="onSelectSeat('141','R22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R22') }" d="m265.7,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R23" @click="onSelectSeat('141','R23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R23') }" d="m266.96,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R24" @click="onSelectSeat('141','R24')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R24') }" d="m268.22,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R25" @click="onSelectSeat('141','R25')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R25') }" d="m269.48,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="R26" @click="onSelectSeat('141','R26')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('R26') }" d="m270.74,53.41c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="Q1" @click="onSelectSeat('141','Q1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q1') }" d="m239.2,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q2" @click="onSelectSeat('141','Q2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q2') }" d="m240.46,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q3" @click="onSelectSeat('141','Q3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q3') }" d="m241.72,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q4" @click="onSelectSeat('141','Q4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q4') }" d="m242.99,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q5" @click="onSelectSeat('141','Q5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q5') }" d="m244.25,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q6" @click="onSelectSeat('141','Q6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q6') }" d="m245.51,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q7" @click="onSelectSeat('141','Q7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q7') }" d="m246.77,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q8" @click="onSelectSeat('141','Q8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q8') }" d="m248.03,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q9" @click="onSelectSeat('141','Q9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q9') }" d="m249.29,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q10" @click="onSelectSeat('141','Q10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q10') }" d="m250.56,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="Q11" @click="onSelectSeat('141','Q11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q11') }" d="m251.82,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q12" @click="onSelectSeat('141','Q12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q12') }" d="m253.08,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q13" @click="onSelectSeat('141','Q13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q13') }" d="m254.34,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q14" @click="onSelectSeat('141','Q14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q14') }" d="m255.6,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q15" @click="onSelectSeat('141','Q15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q15') }" d="m256.86,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q16" @click="onSelectSeat('141','Q16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q16') }" d="m258.13,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q17" @click="onSelectSeat('141','Q17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q17') }" d="m259.39,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q18" @click="onSelectSeat('141','Q18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q18') }" d="m260.65,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q19" @click="onSelectSeat('141','Q19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q19') }" d="m261.91,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q20" @click="onSelectSeat('141','Q20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q20') }" d="m263.17,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q21" @click="onSelectSeat('141','Q21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q21') }" d="m264.43,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q22" @click="onSelectSeat('141','Q22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q22') }" d="m265.7,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q23" @click="onSelectSeat('141','Q23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q23') }" d="m266.96,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q24" @click="onSelectSeat('141','Q24')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q24') }" d="m268.22,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q25" @click="onSelectSeat('141','Q25')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q25') }" d="m269.48,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="Q26" @click="onSelectSeat('141','Q26')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('Q26') }" d="m270.74,54.73c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="P1" @click="onSelectSeat('141','P1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P1') }" d="m239.2,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P2" @click="onSelectSeat('141','P2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P2') }" d="m240.46,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P3" @click="onSelectSeat('141','P3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P3') }" d="m241.72,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P4" @click="onSelectSeat('141','P4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P4') }" d="m242.99,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P5" @click="onSelectSeat('141','P5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P5') }" d="m244.25,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P6" @click="onSelectSeat('141','P6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P6') }" d="m245.51,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P7" @click="onSelectSeat('141','P7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P7') }" d="m246.77,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P8" @click="onSelectSeat('141','P8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P8') }" d="m248.03,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P9" @click="onSelectSeat('141','P9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P9') }" d="m249.29,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P10" @click="onSelectSeat('141','P10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P10') }" d="m250.56,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="P11" @click="onSelectSeat('141','P11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P11') }" d="m251.82,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P12" @click="onSelectSeat('141','P12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P12') }" d="m253.08,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P13" @click="onSelectSeat('141','P13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P13') }" d="m254.34,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P14" @click="onSelectSeat('141','P14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P14') }" d="m255.6,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P15" @click="onSelectSeat('141','P15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P15') }" d="m256.86,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P16" @click="onSelectSeat('141','P16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P16') }" d="m258.13,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P17" @click="onSelectSeat('141','P17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P17') }" d="m259.39,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P18" @click="onSelectSeat('141','P18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P18') }" d="m260.65,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P19" @click="onSelectSeat('141','P19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P19') }" d="m261.91,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P20" @click="onSelectSeat('141','P20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P20') }" d="m263.17,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P21" @click="onSelectSeat('141','P21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P21') }" d="m264.43,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P22" @click="onSelectSeat('141','P22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P22') }" d="m265.7,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P23" @click="onSelectSeat('141','P23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P23') }" d="m266.96,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P24" @click="onSelectSeat('141','P24')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P24') }" d="m268.22,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P25" @click="onSelectSeat('141','P25')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P25') }" d="m269.48,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="P26" @click="onSelectSeat('141','P26')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('P26') }" d="m270.74,56.04c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="O1" @click="onSelectSeat('141','O1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O1') }" d="m239.2,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O2" @click="onSelectSeat('141','O2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O2') }" d="m240.46,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O3" @click="onSelectSeat('141','O3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O3') }" d="m241.72,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O4" @click="onSelectSeat('141','O4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O4') }" d="m242.99,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O5" @click="onSelectSeat('141','O5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O5') }" d="m244.25,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O6" @click="onSelectSeat('141','O6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O6') }" d="m245.51,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O7" @click="onSelectSeat('141','O7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O7') }" d="m246.77,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O8" @click="onSelectSeat('141','O8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O8') }" d="m248.03,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O9" @click="onSelectSeat('141','O9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O9') }" d="m249.29,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O10" @click="onSelectSeat('141','O10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O10') }" d="m250.56,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="O11" @click="onSelectSeat('141','O11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O11') }" d="m251.82,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O12" @click="onSelectSeat('141','O12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O12') }" d="m253.08,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O13" @click="onSelectSeat('141','O13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O13') }" d="m254.34,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O14" @click="onSelectSeat('141','O14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O14') }" d="m255.6,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O15" @click="onSelectSeat('141','O15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O15') }" d="m256.86,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O16" @click="onSelectSeat('141','O16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O16') }" d="m258.13,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O17" @click="onSelectSeat('141','O17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O17') }" d="m259.39,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O18" @click="onSelectSeat('141','O18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O18') }" d="m260.65,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O19" @click="onSelectSeat('141','O19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O19') }" d="m261.91,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O20" @click="onSelectSeat('141','O20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O20') }" d="m263.17,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O21" @click="onSelectSeat('141','O21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O21') }" d="m264.43,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O22" @click="onSelectSeat('141','O22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O22') }" d="m265.7,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O23" @click="onSelectSeat('141','O23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O23') }" d="m266.96,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="O24" @click="onSelectSeat('141','O24')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('O24') }" d="m268.22,57.36c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="N1" @click="onSelectSeat('141','N1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N1') }" d="m240.46,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N2" @click="onSelectSeat('141','N2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N2') }" d="m241.72,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N3" @click="onSelectSeat('141','N3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N3') }" d="m242.99,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N4" @click="onSelectSeat('141','N4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N4') }" d="m244.25,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N5" @click="onSelectSeat('141','N5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N5') }" d="m245.51,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N6" @click="onSelectSeat('141','N6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N6') }" d="m246.77,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N7" @click="onSelectSeat('141','N7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N7') }" d="m248.03,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N8" @click="onSelectSeat('141','N8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N8') }" d="m249.29,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N9" @click="onSelectSeat('141','N9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N9') }" d="m250.56,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="N10" @click="onSelectSeat('141','N10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N10') }" d="m251.82,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N11" @click="onSelectSeat('141','N11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N11') }" d="m253.08,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N12" @click="onSelectSeat('141','N12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N12') }" d="m254.34,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N13" @click="onSelectSeat('141','N13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N13') }" d="m255.6,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N14" @click="onSelectSeat('141','N14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N14') }" d="m256.86,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N15" @click="onSelectSeat('141','N15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N15') }" d="m258.13,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N16" @click="onSelectSeat('141','N16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N16') }" d="m259.39,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N17" @click="onSelectSeat('141','N17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N17') }" d="m260.65,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N18" @click="onSelectSeat('141','N18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N18') }" d="m261.91,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N19" @click="onSelectSeat('141','N19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N19') }" d="m263.17,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N20" @click="onSelectSeat('141','N20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N20') }" d="m264.43,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="N21" @click="onSelectSeat('141','N21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('N21') }" d="m265.7,58.67c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="M1" @click="onSelectSeat('141','M1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M1') }" d="m242.99,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M2" @click="onSelectSeat('141','M2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M2') }" d="m244.25,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M3" @click="onSelectSeat('141','M3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M3') }" d="m245.51,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M4" @click="onSelectSeat('141','M4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M4') }" d="m246.77,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M5" @click="onSelectSeat('141','M5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M5') }" d="m248.03,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M6" @click="onSelectSeat('141','M6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M6') }" d="m249.29,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M7" @click="onSelectSeat('141','M7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M7') }" d="m250.56,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="M8" @click="onSelectSeat('141','M8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M8') }" d="m251.82,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M9" @click="onSelectSeat('141','M9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M9') }" d="m253.08,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M10" @click="onSelectSeat('141','M10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M10') }" d="m254.34,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M11" @click="onSelectSeat('141','M11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M11') }" d="m255.6,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M12" @click="onSelectSeat('141','M12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M12') }" d="m256.86,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M13" @click="onSelectSeat('141','M13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M13') }" d="m258.13,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M14" @click="onSelectSeat('141','M14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M14') }" d="m259.39,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M15" @click="onSelectSeat('141','M15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M15') }" d="m260.65,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M16" @click="onSelectSeat('141','M16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M16') }" d="m261.91,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M17" @click="onSelectSeat('141','M17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M17') }" d="m263.17,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M18" @click="onSelectSeat('141','M18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M18') }" d="m264.43,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="M19" @click="onSelectSeat('141','M19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('M19') }" d="m265.7,59.99c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>

                              <path id="L1" @click="onSelectSeat('141','L1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L1') }" d="m242.99,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L2" @click="onSelectSeat('141','L2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L2') }" d="m244.25,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L3" @click="onSelectSeat('141','L3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L3') }" d="m245.51,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L4" @click="onSelectSeat('141','L4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L4') }" d="m246.77,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L5" @click="onSelectSeat('141','L5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L5') }" d="m248.03,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L6" @click="onSelectSeat('141','L6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L6') }" d="m249.29,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L7" @click="onSelectSeat('141','L7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L7') }" d="m250.56,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L8" @click="onSelectSeat('141','L8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L8') }" d="m251.82,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L9" @click="onSelectSeat('141','L9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L9') }" d="m253.08,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L10" @click="onSelectSeat('141','L10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L10') }" d="m254.34,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L11" @click="onSelectSeat('141','L11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L11') }" d="m255.6,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L12" @click="onSelectSeat('141','L12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L12') }" d="m256.86,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L13" @click="onSelectSeat('141','L13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L13') }" d="m258.13,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L14" @click="onSelectSeat('141','L14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L14') }" d="m259.39,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L15" @click="onSelectSeat('141','L15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L15') }" d="m260.65,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L16" @click="onSelectSeat('141','L16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L16') }" d="m261.91,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L17" @click="onSelectSeat('141','L17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L17') }" d="m263.17,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L18" @click="onSelectSeat('141','L18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L18') }" d="m264.43,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="L19" @click="onSelectSeat('141','L19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('L19') }" d="m265.7,61.3c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="K1" @click="onSelectSeat('141','K1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K1') }" d="m242.99,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K2" @click="onSelectSeat('141','K2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K2') }" d="m244.25,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K3" @click="onSelectSeat('141','K3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K3') }" d="m245.51,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K4" @click="onSelectSeat('141','K4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K4') }" d="m246.77,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K5" @click="onSelectSeat('141','K5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K5') }" d="m248.03,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K6" @click="onSelectSeat('141','K6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K6') }" d="m249.29,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K7" @click="onSelectSeat('141','K7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K7') }" d="m250.56,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K8" @click="onSelectSeat('141','K8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K8') }" d="m251.82,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K9" @click="onSelectSeat('141','K9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K9') }" d="m253.08,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K10" @click="onSelectSeat('141','K10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K10') }" d="m254.34,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K11" @click="onSelectSeat('141','K11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K11') }" d="m255.6,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K12" @click="onSelectSeat('141','K12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K12') }" d="m256.86,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K13" @click="onSelectSeat('141','K13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K13') }" d="m258.13,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K14" @click="onSelectSeat('141','K14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K14') }" d="m259.39,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K15" @click="onSelectSeat('141','K15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K15') }" d="m260.65,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K16" @click="onSelectSeat('141','K16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K16') }" d="m261.91,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K17" @click="onSelectSeat('141','K17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K17') }" d="m263.17,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K18" @click="onSelectSeat('141','K18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K18') }" d="m264.43,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="K19" @click="onSelectSeat('141','K19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('K19') }" d="m265.7,62.62c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="J1" @click="onSelectSeat('141','J1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J1') }" d="m242.99,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J2" @click="onSelectSeat('141','J2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J2') }" d="m244.25,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J3" @click="onSelectSeat('141','J3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J3') }" d="m245.51,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J4" @click="onSelectSeat('141','J4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J4') }" d="m246.77,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J5" @click="onSelectSeat('141','J5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J5') }" d="m248.03,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J6" @click="onSelectSeat('141','J6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J6') }" d="m249.29,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J7" @click="onSelectSeat('141','J7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J7') }" d="m250.56,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="J8" @click="onSelectSeat('141','J8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J8') }" d="m251.82,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J9" @click="onSelectSeat('141','J9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J9') }" d="m253.08,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J10" @click="onSelectSeat('141','J10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J10') }" d="m254.34,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J11" @click="onSelectSeat('141','J11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J11') }" d="m255.6,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J12" @click="onSelectSeat('141','J12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J12') }" d="m256.86,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J13" @click="onSelectSeat('141','J13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J13') }" d="m258.13,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J14" @click="onSelectSeat('141','J14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J14') }" d="m259.39,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J15" @click="onSelectSeat('141','J15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J15') }" d="m260.65,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J16" @click="onSelectSeat('141','J16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J16') }" d="m261.91,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J17" @click="onSelectSeat('141','J17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J17') }" d="m263.17,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J18" @click="onSelectSeat('141','J18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J18') }" d="m264.43,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="J19" @click="onSelectSeat('141','J19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('J19') }" d="m265.7,63.93c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                        
                              <path id="I1" @click="onSelectSeat('141','I1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I1') }" d="m242.99,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I2" @click="onSelectSeat('141','I2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I2') }" d="m244.25,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I3" @click="onSelectSeat('141','I3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I3') }" d="m245.51,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I4" @click="onSelectSeat('141','I4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I4') }" d="m246.77,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I5" @click="onSelectSeat('141','I5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I5') }" d="m248.03,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I6" @click="onSelectSeat('141','I6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I6') }" d="m249.29,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I7" @click="onSelectSeat('141','I7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I7') }" d="m250.56,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="I8" @click="onSelectSeat('141','I8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I8') }" d="m251.82,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I9" @click="onSelectSeat('141','I9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I9') }" d="m253.08,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I10" @click="onSelectSeat('141','I10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I10') }" d="m254.34,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I11" @click="onSelectSeat('141','I11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I11') }" d="m255.6,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I12" @click="onSelectSeat('141','I12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I12') }" d="m256.86,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I13" @click="onSelectSeat('141','I13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I13') }" d="m258.13,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I14" @click="onSelectSeat('141','I14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I14') }" d="m259.39,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I15" @click="onSelectSeat('141','I15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I15') }" d="m260.65,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I16" @click="onSelectSeat('141','I16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I16') }" d="m261.91,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I17" @click="onSelectSeat('141','I17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I17') }" d="m263.17,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I18" @click="onSelectSeat('141','I18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I18') }" d="m264.43,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="I19" @click="onSelectSeat('141','I19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('I19') }" d="m265.7,65.25c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="H1" @click="onSelectSeat('141','H1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H1') }" d="m242.99,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H2" @click="onSelectSeat('141','H2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H2') }" d="m244.25,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H3" @click="onSelectSeat('141','H3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H3') }" d="m245.51,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H4" @click="onSelectSeat('141','H4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H4') }" d="m246.77,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H5" @click="onSelectSeat('141','H5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H5') }" d="m248.03,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H6" @click="onSelectSeat('141','H6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H6') }" d="m249.29,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H7" @click="onSelectSeat('141','H7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H7') }" d="m250.56,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="H8" @click="onSelectSeat('141','H8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H8') }" d="m251.82,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H9" @click="onSelectSeat('141','H9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H9') }" d="m253.08,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H10" @click="onSelectSeat('141','H10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H10') }" d="m254.34,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H11" @click="onSelectSeat('141','H11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H11') }" d="m255.6,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H12" @click="onSelectSeat('141','H12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H12') }" d="m256.86,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H13" @click="onSelectSeat('141','H13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H13') }" d="m258.13,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H14" @click="onSelectSeat('141','H14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H14') }" d="m259.39,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H15" @click="onSelectSeat('141','H15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H15') }" d="m260.65,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H16" @click="onSelectSeat('141','H16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H16') }" d="m261.91,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H17" @click="onSelectSeat('141','H17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H17') }" d="m263.17,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H18" @click="onSelectSeat('141','H18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H18') }" d="m264.43,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H19" @click="onSelectSeat('141','H19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H19') }" d="m265.7,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H20" @click="onSelectSeat('141','H20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H20') }" d="m266.96,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>             
                              <path id="H21" @click="onSelectSeat('141','H21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H21') }" d="m268.22,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H22" @click="onSelectSeat('141','H22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H22') }" d="m269.48,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="H23" @click="onSelectSeat('141','H23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('H23') }" d="m270.74,66.56c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="G1" @click="onSelectSeat('141','G1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G1') }" d="m242.99,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G2" @click="onSelectSeat('141','G2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G2') }" d="m244.25,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G3" @click="onSelectSeat('141','G3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G3') }" d="m245.51,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G4" @click="onSelectSeat('141','G4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G4') }" d="m246.77,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G5" @click="onSelectSeat('141','G5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G5') }" d="m248.03,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G6" @click="onSelectSeat('141','G6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G6') }" d="m249.29,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G7" @click="onSelectSeat('141','G7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G7') }" d="m250.56,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="G8" @click="onSelectSeat('141','G8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G8') }" d="m251.82,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G9" @click="onSelectSeat('141','G9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G9') }" d="m253.08,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G10" @click="onSelectSeat('141','G10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G10') }" d="m254.34,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G11" @click="onSelectSeat('141','G11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G11') }" d="m255.6,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G12" @click="onSelectSeat('141','G12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G12') }" d="m256.86,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G13" @click="onSelectSeat('141','G13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G13') }" d="m258.13,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G14" @click="onSelectSeat('141','G14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G14') }" d="m259.39,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G15" @click="onSelectSeat('141','G15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G15') }" d="m260.65,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G16" @click="onSelectSeat('141','G16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G16') }" d="m261.91,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G17" @click="onSelectSeat('141','G17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G17') }" d="m263.17,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G18" @click="onSelectSeat('141','G18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G18') }" d="m264.43,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G19" @click="onSelectSeat('141','G19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G19') }" d="m265.7,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G20" @click="onSelectSeat('141','G20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G20') }" d="m266.96,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G21" @click="onSelectSeat('141','G21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G21') }" d="m268.22,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G22" @click="onSelectSeat('141','G22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G22') }" d="m269.48,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="G23" @click="onSelectSeat('141','G23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('G23') }" d="m270.74,67.88c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                            
                              <path id="F1" @click="onSelectSeat('141','F1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F1') }" d="m242.99,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F2" @click="onSelectSeat('141','F2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F2') }" d="m244.25,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F3" @click="onSelectSeat('141','F3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F3') }" d="m245.51,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F4" @click="onSelectSeat('141','F4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F4') }" d="m246.77,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F5" @click="onSelectSeat('141','F5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F5') }" d="m248.03,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F6" @click="onSelectSeat('141','F6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F6') }" d="m249.29,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F7" @click="onSelectSeat('141','F7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F7') }" d="m250.56,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="F8" @click="onSelectSeat('141','F8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F8') }" d="m251.82,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F9" @click="onSelectSeat('141','F9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F9') }" d="m253.08,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F10" @click="onSelectSeat('141','F10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F10') }" d="m254.34,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F11" @click="onSelectSeat('141','F11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F11') }" d="m255.6,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F12" @click="onSelectSeat('141','F12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F12') }" d="m256.86,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F13" @click="onSelectSeat('141','F13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F13') }" d="m258.13,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F14" @click="onSelectSeat('141','F14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F14') }" d="m259.39,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F15" @click="onSelectSeat('141','F15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F15') }" d="m260.65,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F16" @click="onSelectSeat('141','F16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F16') }" d="m261.91,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F17" @click="onSelectSeat('141','F17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F17') }" d="m263.17,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F18" @click="onSelectSeat('141','F18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F18') }" d="m264.43,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F19" @click="onSelectSeat('141','F19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F19') }" d="m265.7,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F20" @click="onSelectSeat('141','F20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F20') }" d="m266.96,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F21" @click="onSelectSeat('141','F21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F21') }" d="m268.22,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F22" @click="onSelectSeat('141','F22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F22') }" d="m269.48,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="F23" @click="onSelectSeat('141','F23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('F23') }" d="m270.74,69.19c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="E1" @click="onSelectSeat('141','E1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E1') }" d="m242.99,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E2" @click="onSelectSeat('141','E2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E2') }" d="m244.25,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E3" @click="onSelectSeat('141','E3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E3') }" d="m245.51,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E4" @click="onSelectSeat('141','E4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E4') }" d="m246.77,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E5" @click="onSelectSeat('141','E5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E5') }" d="m248.03,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E6" @click="onSelectSeat('141','E6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E6') }" d="m249.29,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E7" @click="onSelectSeat('141','E7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E7') }" d="m250.56,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="E8" @click="onSelectSeat('141','E8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E8') }" d="m251.82,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E9" @click="onSelectSeat('141','E9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E9') }" d="m253.08,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E10" @click="onSelectSeat('141','E10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E10') }" d="m254.34,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E11" @click="onSelectSeat('141','E11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E11') }" d="m255.6,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E12" @click="onSelectSeat('141','E12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E12') }" d="m256.86,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E13" @click="onSelectSeat('141','E13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E13') }" d="m258.13,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E14" @click="onSelectSeat('141','E14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E14') }" d="m259.39,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E15" @click="onSelectSeat('141','E15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E15') }" d="m260.65,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E16" @click="onSelectSeat('141','E16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E16') }" d="m261.91,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E17" @click="onSelectSeat('141','E17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E17') }" d="m263.17,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E18" @click="onSelectSeat('141','E18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E18') }" d="m264.43,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E19" @click="onSelectSeat('141','E19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E19') }" d="m265.7,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E20" @click="onSelectSeat('141','E20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E20') }" d="m266.96,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E21" @click="onSelectSeat('141','E21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E21') }" d="m268.22,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E22" @click="onSelectSeat('141','E22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E22') }" d="m269.48,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="E23" @click="onSelectSeat('141','E23')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('E23') }" d="m270.74,70.51c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="D1" @click="onSelectSeat('141','D1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D1') }" d="m244.25,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D2" @click="onSelectSeat('141','D2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D2') }" d="m245.51,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D3" @click="onSelectSeat('141','D3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D3') }" d="m246.77,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D4" @click="onSelectSeat('141','D4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D4') }" d="m248.03,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D5" @click="onSelectSeat('141','D5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D5') }" d="m249.29,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D6" @click="onSelectSeat('141','D6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D6') }" d="m250.56,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D7" @click="onSelectSeat('141','D7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D7') }" d="m251.82,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D8" @click="onSelectSeat('141','D8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D8') }" d="m253.08,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D9" @click="onSelectSeat('141','D9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D9') }" d="m254.34,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D10" @click="onSelectSeat('141','D10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D10') }" d="m255.6,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D11" @click="onSelectSeat('141','D11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D11') }" d="m256.86,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D12" @click="onSelectSeat('141','D12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D12') }" d="m258.13,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D13" @click="onSelectSeat('141','D13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D13') }" d="m259.39,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D14" @click="onSelectSeat('141','D14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D14') }" d="m260.65,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D15" @click="onSelectSeat('141','D15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D15') }" d="m261.91,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D16" @click="onSelectSeat('141','D16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D16') }" d="m263.17,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D17" @click="onSelectSeat('141','D17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D17') }" d="m264.43,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D18" @click="onSelectSeat('141','D18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D18') }" d="m265.7,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D19" @click="onSelectSeat('141','D19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D19') }" d="m266.96,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D20" @click="onSelectSeat('141','D20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D20') }" d="m268.22,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D21" @click="onSelectSeat('141','D21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D21') }" d="m269.48,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="D22" @click="onSelectSeat('141','D22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('D22') }" d="m270.74,71.82c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                              <path id="C1" @click="onSelectSeat('141','C1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C1') }" d="m244.25,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C2" @click="onSelectSeat('141','C2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C2') }" d="m245.51,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C3" @click="onSelectSeat('141','C3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C3') }" d="m246.77,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C4" @click="onSelectSeat('141','C4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C4') }" d="m248.03,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C5" @click="onSelectSeat('141','C5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C5') }" d="m249.29,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C6" @click="onSelectSeat('141','C6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C6') }" d="m250.56,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C7" @click="onSelectSeat('141','C7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C7') }" d="m251.82,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C8" @click="onSelectSeat('141','C8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C8') }" d="m253.08,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C9" @click="onSelectSeat('141','C9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C9') }" d="m254.34,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C10" @click="onSelectSeat('141','C10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C10') }" d="m255.6,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C11" @click="onSelectSeat('141','C11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C11') }" d="m256.86,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C12" @click="onSelectSeat('141','C12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C12') }" d="m258.13,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C13" @click="onSelectSeat('141','C13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C13') }" d="m259.39,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C14" @click="onSelectSeat('141','C14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C14') }" d="m260.65,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C15" @click="onSelectSeat('141','C15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C15') }" d="m261.91,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C16" @click="onSelectSeat('141','C16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C16') }" d="m263.17,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C17" @click="onSelectSeat('141','C17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C17') }" d="m264.43,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C18" @click="onSelectSeat('141','C18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C18') }" d="m265.7,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C19" @click="onSelectSeat('141','C19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C19') }" d="m266.96,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C20" @click="onSelectSeat('141','C20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C20') }" d="m268.22,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C21" @click="onSelectSeat('141','C21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C21') }" d="m269.48,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="C22" @click="onSelectSeat('141','C22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('C22') }" d="m270.74,73.14c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                            
                              <path id="B1" @click="onSelectSeat('141','B1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B1') }" d="m244.25,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B2" @click="onSelectSeat('141','B2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B2') }" d="m245.51,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B3" @click="onSelectSeat('141','B3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B3') }" d="m246.77,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B4" @click="onSelectSeat('141','B4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B4') }" d="m248.03,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B5" @click="onSelectSeat('141','B5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B5') }" d="m249.29,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B6" @click="onSelectSeat('141','B6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B6') }" d="m250.56,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="B7" @click="onSelectSeat('141','B7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B7') }" d="m251.82,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B8" @click="onSelectSeat('141','B8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B8') }" d="m253.08,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B9" @click="onSelectSeat('141','B9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B9') }" d="m254.34,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B10" @click="onSelectSeat('141','B10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B10') }" d="m255.6,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B11" @click="onSelectSeat('141','B11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B11') }" d="m256.86,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B12" @click="onSelectSeat('141','B12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B12') }" d="m258.13,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B13" @click="onSelectSeat('141','B13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B13') }" d="m259.39,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B14" @click="onSelectSeat('141','B14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B14') }" d="m260.65,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B15" @click="onSelectSeat('141','B15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B15') }" d="m261.91,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B16" @click="onSelectSeat('141','B16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B16') }" d="m263.17,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B17" @click="onSelectSeat('141','B17')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B17') }" d="m264.43,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B18" @click="onSelectSeat('141','B18')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B18') }" d="m265.7,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B19" @click="onSelectSeat('141','B19')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B19') }" d="m266.96,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B20" @click="onSelectSeat('141','B20')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B20') }" d="m268.22,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B21" @click="onSelectSeat('141','B21')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B21') }" d="m269.48,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="B22" @click="onSelectSeat('141','B22')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('B22') }" d="m270.74,74.45c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                        
                              <path id="A1" @click="onSelectSeat('141','A1')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A1') }" d="m244.25,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A2" @click="onSelectSeat('141','A2')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A2') }" d="m245.51,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A3" @click="onSelectSeat('141','A3')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A3') }" d="m246.77,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A4" @click="onSelectSeat('141','A4')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A4') }" d="m248.03,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A5" @click="onSelectSeat('141','A5')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A5') }" d="m249.29,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A6" @click="onSelectSeat('141','A6')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A6') }" d="m250.56,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23s.33.36.23.59Z"/>
                              <path id="A7" @click="onSelectSeat('141','A7')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A7') }" d="m251.82,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A8" @click="onSelectSeat('141','A8')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A8') }" d="m253.08,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A9" @click="onSelectSeat('141','A9')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A9') }" d="m254.34,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A10" @click="onSelectSeat('141','A10')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A10') }" d="m255.6,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A11" @click="onSelectSeat('141','A11')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A11') }" d="m256.86,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A12" @click="onSelectSeat('141','A12')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A12') }" d="m258.13,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A13" @click="onSelectSeat('141','A13')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A13') }" d="m259.39,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A14" @click="onSelectSeat('141','A14')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A14') }" d="m260.65,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A15" @click="onSelectSeat('141','A15')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A15') }" d="m261.91,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              <path id="A16" @click="onSelectSeat('141','A16')" v-show="isSeatVisible === 'section141'" :class="{ 'selected': isSelected('A16') }" d="m263.17,75.77c-.1.23-.36.33-.59.23-.23-.1-.33-.36-.23-.59.1-.23.36-.33.59-.23.23.1.33.36.23.59Z"/>
                              
                            </g>  
                          
                          </svg>
                    </div> 
                  </div>
                </div>
              </div>
              <DemoModal v-if="showModal" @close="closeModal">
                   <h5 class="text-center">LOGGIA.</h5>
                   <svg id="LOGGIA" xmlns="http://www.w3.org/2000/svg" style="width: 250px; height: 200;" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <circle id="B1" @click="onSelectSeat('LOGGIA_01','B1')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('B1'), 'unselected': !isSelected('B1') }" cx="30.25" cy="35.14" r="15"/>
                      <circle id="B2" @click="onSelectSeat('LOGGIA_01','B2')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('B2'), 'unselected': !isSelected('B2') }" cx="65.25" cy="35.14" r="15"/>
                      <circle id="B3" @click="onSelectSeat('LOGGIA_01','B3')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('B3'), 'unselected': !isSelected('B3') }" cx="180.25" cy="35.14" r="15"/>
                      <circle id="B4" @click="onSelectSeat('LOGGIA_01','B4')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('B4'), 'unselected': !isSelected('B4') }" cx="220.25" cy="35.14" r="15"/>          
                      <circle id="A4" @click="onSelectSeat('LOGGIA_01','A4')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('A4'), 'unselected': !isSelected('A4') }" cx="220.25" cy="125.14" r="15"/>
                      <circle id="A3" @click="onSelectSeat('LOGGIA_01','A3')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('A3'), 'unselected': !isSelected('A3') }" cx="180.25" cy="125.14" r="15"/>
                      <circle id="A2" @click="onSelectSeat('LOGGIA_01','A2')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('A2'), 'unselected': !isSelected('A2') }" cx="65.25" cy="125.14" r="15"/>
                      <circle id="A1" @click="onSelectSeat('LOGGIA_01','A1')" v-show="isSeatVisible === 'LOGGIA_01'" :class="{ 'selected': isSelected('A1'), 'unselected': !isSelected('A1') }" cx="30.25" cy="125.14" r="15"/>
                      
                      <circle id="B1" @click="onSelectSeat('LOGGIA_02','B1')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('B1'), 'unselected': !isSelected('B1') }" cx="30.25" cy="35.14" r="15"/>
                      <circle id="B2" @click="onSelectSeat('LOGGIA_02','B2')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('B2'), 'unselected': !isSelected('B2') }" cx="65.25" cy="35.14" r="15"/>
                      <circle id="B3" @click="onSelectSeat('LOGGIA_02','B3')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('B3'), 'unselected': !isSelected('B3') }" cx="180.25" cy="35.14" r="15"/>
                      <circle id="B4" @click="onSelectSeat('LOGGIA_02','B4')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('B4'), 'unselected': !isSelected('B4') }" cx="220.25" cy="35.14" r="15"/>          
                      <circle id="A4" @click="onSelectSeat('LOGGIA_02','A4')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('A4'), 'unselected': !isSelected('A4') }" cx="220.25" cy="125.14" r="15"/>
                      <circle id="A3" @click="onSelectSeat('LOGGIA_02','A3')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('A3'), 'unselected': !isSelected('A3') }" cx="180.25" cy="125.14" r="15"/>
                      <circle id="A2" @click="onSelectSeat('LOGGIA_02','A2')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('A2'), 'unselected': !isSelected('A2') }" cx="65.25" cy="125.14" r="15"/>
                      <circle id="A1" @click="onSelectSeat('LOGGIA_02','A1')" v-show="isSeatVisible === 'LOGGIA_02'" :class="{ 'selected': isSelected('A1'), 'unselected': !isSelected('A1') }" cx="30.25" cy="125.14" r="15"/>
                    
                      <circle id="B1" @click="onSelectSeat('LOGGIA_03','B1')" v-show="isSeatVisible === 'LOGGIA_03'" :class="{ 'selected': isSelected('B1'), 'unselected': !isSelected('B1') }" cx="105.25" cy="35.14" r="15"/>
                      <circle id="B2" @click="onSelectSeat('LOGGIA_03','B2')" v-show="isSeatVisible === 'LOGGIA_03'" :class="{ 'selected': isSelected('B2'), 'unselected': !isSelected('B2') }" cx="145.25" cy="35.14" r="15"/>
                      <circle id="A4" @click="onSelectSeat('LOGGIA_03','A4')" v-show="isSeatVisible === 'LOGGIA_03'" :class="{ 'selected': isSelected('A4'), 'unselected': !isSelected('A4') }" cx="220.25" cy="125.14" r="15"/>
                      <circle id="A3" @click="onSelectSeat('LOGGIA_03','A3')" v-show="isSeatVisible === 'LOGGIA_03'" :class="{ 'selected': isSelected('A3'), 'unselected': !isSelected('A3') }" cx="180.25" cy="125.14" r="15"/>
                      <circle id="A2" @click="onSelectSeat('LOGGIA_03','A2')" v-show="isSeatVisible === 'LOGGIA_03'" :class="{ 'selected': isSelected('A2'), 'unselected': !isSelected('A2') }" cx="65.25" cy="125.14" r="15"/>
                      <circle id="A1" @click="onSelectSeat('LOGGIA_03','A1')" v-show="isSeatVisible === 'LOGGIA_03'" :class="{ 'selected': isSelected('A1'), 'unselected': !isSelected('A1') }" cx="30.25" cy="125.14" r="15"/>

                      <circle id="D1" @click="onSelectSeat('LOGGIA_04','D1')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B1'), 'unselected': !isSelected('D1') }" cx="30.25" cy="35.14" r="15"/>
                      <circle id="D2" @click="onSelectSeat('LOGGIA_04','D2')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B2'), 'unselected': !isSelected('D2') }" cx="65.25" cy="35.14" r="15"/>
                      <circle id="D3" @click="onSelectSeat('LOGGIA_04','D3')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B3'), 'unselected': !isSelected('D3') }" cx="180.25" cy="35.14" r="15"/>
                      <circle id="D4" @click="onSelectSeat('LOGGIA_04','D4')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B4'), 'unselected': !isSelected('D4') }" cx="220.25" cy="35.14" r="15"/>          
                      
                      <circle id="C1" @click="onSelectSeat('LOGGIA_04','C1')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('C1'), 'unselected': !isSelected('C1') }" cx="30.25" cy="78.14" r="15"/>
                      <circle id="C2" @click="onSelectSeat('LOGGIA_04','C2')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('C2'), 'unselected': !isSelected('C2') }" cx="65.25" cy="78.14" r="15"/>
                      <circle id="C3" @click="onSelectSeat('LOGGIA_04','C3')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('C3'), 'unselected': !isSelected('C3') }" cx="180.25" cy="78.14" r="15"/>
                      <circle id="C4" @click="onSelectSeat('LOGGIA_04','C4')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('C4'), 'unselected': !isSelected('C4') }" cx="220.25" cy="78.14" r="15"/>

                      <circle id="B4" @click="onSelectSeat('LOGGIA_04','B4')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B4'), 'unselected': !isSelected('B4') }" cx="220.25" cy="125.14" r="15"/>
                      <circle id="B3" @click="onSelectSeat('LOGGIA_04','B3')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B3'), 'unselected': !isSelected('B3') }" cx="180.25" cy="125.14" r="15"/>
                      <circle id="B2" @click="onSelectSeat('LOGGIA_04','B2')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B2'), 'unselected': !isSelected('B2') }" cx="65.25" cy="125.14" r="15"/>
                      <circle id="B1" @click="onSelectSeat('LOGGIA_04','B1')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('B1'), 'unselected': !isSelected('B1') }" cx="30.25" cy="125.14" r="15"/>
                    
                      <circle id="A4" @click="onSelectSeat('LOGGIA_04','A4')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('A4'), 'unselected': !isSelected('A4') }" cx="220.25" cy="165.14" r="15"/>
                      <circle id="A3" @click="onSelectSeat('LOGGIA_04','A3')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('A3'), 'unselected': !isSelected('A3') }" cx="180.25" cy="165.14" r="15"/>
                      <circle id="A2" @click="onSelectSeat('LOGGIA_04','A2')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('A2'), 'unselected': !isSelected('A2') }" cx="65.25" cy="165.14" r="15"/>
                      <circle id="A1" @click="onSelectSeat('LOGGIA_04','A1')" v-show="isSeatVisible === 'LOGGIA_04'" :class="{ 'selected': isSelected('A1'), 'unselected': !isSelected('A1') }" cx="30.25" cy="165.14" r="15"/>
                    
                    </svg>
                  <hr class="my-4" />
                  <button class="w-100 btn btn-warning btn-lg" @click="closeModal">Close</button>
              </DemoModal>
          </section>
        </main>
    </div>
</template>

  <script>
  import PalmEvent from '../apis/PalmEvent';
  import * as d3 from 'd3';
  import DemoModal from '../components/Modal.vue';
  import Api from '../apis/Api';
  import data from '@/assets/amahoro.json';
  
  export default {
    components: {
      DemoModal,
    },
    data() {
      return {
        isSeatVisible: null,
        isDivSeatVisible: false,
        selectedSeat: '', 
        priceId: null,
        eventId: null,
        ticketCategory: null,
        selectedSeats: [],
        activeGroup: null,
        allowedSections: [],
        showModal: false,
        showSeatSection: false,
        circleContainer: false,
        responseStatus: null,
        errorMessage: '',
        zoomInstance: null,
        seats: data,
        isZoomed: false
      }
    },
    mounted() {

       this.isZoomed = false;

       this.initializeZoom();

       PalmEvent.getVenueSpaces(this.priceId)
                .then((result) => { 
                  this.allowedSections = result.data;
              });

    },
    created(){
        this.ticketCategory = this.$route.params.type;
        this.priceId = this.$route.params.priceId;
        this.eventId = this.$route.params.eventId;
    },
    methods: {
    
      initializeZoom() {
        const svg = d3.select('svg');
        const group = svg.selectAll('g');

        this.zoomInstance = d3.zoom()
          .scaleExtent([1, 80]) 
          .on("zoom", () => {
              const t = d3.event.transform;
              group.attr("transform","translate(" + [t.x, t.y] + ")scale(" + t.k + ")")
          });

        svg.call(this.zoomInstance);
      },
      openModal(box_name) {
        this.showModal = true;   
        this.isSeatVisible = box_name; 
      },
      closeModal() {
        this.showModal = false;
      },
      onSelectSeat(sectionId, seatId){

         PalmEvent.CheckSeatAvailability({event_price_id:this.$route.params.priceId,seat_number:seatId, section_name:sectionId})
                .then(response => {
                    this.responseStatus = response.data.status;
                      const index = this.selectedSeats.indexOf(seatId);
                      if (index > -1) {
                          this.selectedSeats.splice(index, 1);
                      } else {
                          this.selectedSeats.push(seatId);
                      }
                      this.isDivSeatVisible = true;
                }).catch(error => {
       //           if (error.response) {
                      alert('Seat taken');
      //            } 
      //            else {
      //                alert('Seat taken');
      //            }
                });
        
      },
      isSelected(seatId){
        return this.selectedSeats.includes(seatId);
      },  
      handleSeatSelection() {
        this.$router.push({ name: "Checkout", params: {id:this.eventId, priceId:this.priceId  ,reserved_seats:this.selectedSeats} }); 
      },
      setActiveGroup(groupId) {
        this.activeGroup = groupId;
      },
      isSectionEnabled(sectionName) {
        return this.allowedSections.find(allowedSection => allowedSection.venue_space_name == sectionName)!== undefined ? true : false;
      },
      handleZoom(section_name) {
        const svg = d3.select(this.$refs.svg);
        const section = document.getElementById(section_name);
        const bbox = section.getBBox();
        this.isSeatVisible = section_name;

        const width = this.$refs.svg.clientWidth;
        const height = this.$refs.svg.clientHeight;
        const scale = Math.min(width / bbox.width, height / bbox.height) * 0.8; // Scale with padding
        const translate = [
          width / 2 - (bbox.x + bbox.width / 2) * scale,
          height / 2 - (bbox.y + bbox.height / 2) * scale,
        ];

        // Smooth transition to the zoomed section
        svg.transition()
          .duration(750)
          .call(
            this.zoomInstance.transform,
            d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
          );

           this.isZoomed = true;
      },
    }
  }
  
  </script>
  <style>
        
      .selected { 
        fill: #20603e; /* Example background color for selected seats */
      }
      .enabled {
        fill: #10a1dc; /* Example background color for selected seats */
      }
      .unselected {
        fill: #f8d10a; /* Example background color for selected seats */
      }
      .disabled {
        fill: #d8d8d8;
      }
      polygon:hover{
        fill: #C8F2CC!important; 
        cursor:pointer;
      }

      svg text::selection { background: none; }
  
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
          fill: #fff;
      }
  
      .cls-7 {
          fill: #719132;
      }
  
      .cls-7, .cls-3 {
          opacity: .78;
      }
  
      .cls-8 {
          fill: #a5a5a5;
      }
  
      .cls-9 {
          fill: #d8d8d8;
      }
  
      .cls-10 {
          fill: #18325a;
      }
  
      .cls-2 {
          font-size: 4.12px;
      }
  
      .cls-2, .cls-11, .cls-4, .cls-5, .cls-12, .cls-6 {
          font-family: MyriadPro-Bold, 'Myriad Pro';
          font-weight: 700;
      }
  
      .cls-13 {
          fill: #bf5100;
      }
  
      .cls-11 {
          font-size: 1.61px;
      }
  
      .cls-11, .cls-12 {
          fill: #231f20;
      }
  
      .cls-4, .cls-12 {
          font-size: 2.12px;
      }
  
      .cls-14 {
          letter-spacing: -.07em;
      }
  
      .cls-15 {
          fill: #20603e;
      }
  
      .cls-16 {
          letter-spacing: -.08em;
      }
  
      .cls-17 {
          letter-spacing: -.02em;
      }
  
      .cls-5 {
          font-size: 2.12px;
      }
  
      .cls-18 {
          letter-spacing: -.03em;
      }
  
      .cls-19 {
          fill: #f8d10a;
      }
  
      .cls-20 {
          fill: #ba9600;
      }
  
      .cls-21 {
          letter-spacing: 0em;
      }
  
      .cls-22 {
          fill: #c1c1c1;
      }
  
      .cls-23 {
          fill: #383838;
      }
  
      .cls-24 {
          letter-spacing: -.03em;
      }
  
      .cls-26 {
          fill: #d1cfc1;
      }
  
      .cls-27 {
          fill: #ffffff;
      }
  
      .cls-28 {
          fill: #6bbf62;
      }
  
      .cls-29 {
          fill: #154c2c;
      }
  
      .cls-6 {
          font-size: 2.65px;
      }

      svg {
      
        user-select: none; /* Prevent text selection on drag */
        touch-action: none; /* Disable default browser pinch-zoom */

      }

.circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ddd; 
    display: flex;
    border: 1px solid #42526E;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }

  .label {
    font-size: 0.6em;
  }
  
  .circle:hover {
    background-color: #ccc;
  }
    
  </style>