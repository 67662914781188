<template>
    <div>
        <main data-aos="fade" class="pb-3">
            <section class="ticket-area site-sec bg-light">
                <div class="site-sec-wrap container-xxl">
                    <div class="bg-white my-3 px-3 px-md-5 py-5 border min-vh-100">
                        <div class="">
                            <h2>My Account</h2>
                            
                            <p><span v-show="loading" class="spinner-border text-warning"></span></p>
                        </div>
                        
                        <div class="row" >
                            <div class="col-md-4">
                                <button @click="logout" class="btn btn-danger">Logout</button>
                            </div>
                            <div class="col-md-4">
                                <a href="/season-tickets" class="btn btn-info">Buy Season Tickets</a>
                            </div>
                            
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <h5>My Season Tickets</h5>

                                <table class="table table-stripped">
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>Ticket</th>
                                            <th>Valid Until</th>
                                            <th>Matches Watched</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="ticket in seasonTickets" :key="ticket.id">
                                            <td>{{ ticket.season_pricing.organiser.name }}</td>
                                            <td>{{ ticket.season_pricing.name }}</td>
                                            <td>{{ ticket.season_pricing.valid_to }}</td>
                                            <td>{{ ticket.match_tickets.length}}</td>
                                            <td><btn @click="goToTicket(ticket.id)" class="btn-sm btn-warning">View</btn></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import PalmEvent from '../apis/PalmEvent';

export default {
    components: {
        
    },
    data() {
        return {
            loading: false,
            seasonTickets: [],
            user: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        logout() {
            localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
        }, 
        fetchTickets() {
            PalmEvent.getMySeasonTickets().then(
                (response) => {
                    this.seasonTickets = response.data.data
                },
                (error) => {
                    console.log(error.response)
                }
            )
        },
        goToTicket(id) {
            this.$router.push({ name: "SeasonTicket" ,params: {id: id}});
        }
    },
    
  
  created() {
    let isLoggedIn = !!localStorage.getItem("token");    
      if(!isLoggedIn){
        this.$router.push({ name: "Login" });
      }
    this.fetchTickets();  
  }
}
</script>