import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import store from "./store";
import 'bootstrap'
import './styles/styles.css'
import './customjs/custom.js'
import  './plugins/toast.js'

Vue.config.productionTip = false
Vue.prototype.$backendUrl = process.env.VUE_APP_BACKEND_URL;

new Vue({
  router,
  store,  
  render: h => h(App)
}).$mount('#app')
