import Api from "./Api";

export default { 
  getFeaturedEvents() {
    return Api().get("/events/get-featured-events");
  },
  getFeaturedCategories() {
    return Api().get("/events/get-featured-categories");
  },
  getUpcomingEvents(page) {
    return Api().get("/events/get-upcoming-events?page="+page);
  },
  getEvent(id){
    return Api().get("/events/get-event/"+id);
  },
  buyTickets(payload){
    return Api().post("/events/buy-tickets",payload)
  },
  filterEvents(category,page){
    return Api().post("/events/filter?page="+page,{category:category})
  },
  searchEvents(searchString){
    return Api().post("/events/search",{search:searchString})
  },

  getOrganisers() {
    return Api().get("/organisers")
  },

  getSeasonTicketPricing(organiserId) {
    return Api().get( (organiserId !="") ? `season-ticket-pricing/${organiserId}` : 'season-ticket-pricing')
  },

  getSeasonTicketPrice(id) {
    return Api().get(`season-ticket-price/${id}`);
  },

  getMySeasonTickets() {
    return Api().get('my-season-tickets')
  },
  getSingleSeasonTicket(id) {
    return Api().get(`my-season-tickets/${id}`)
  },
  generateMatchTicket(id) {
    return Api().get(`generate-match-ticket/${id}`)
  },
  getExchangeRate(formData){
    return Api().post("/get-exchange-rate", formData)
  },
  getVenueSpaces(id) {
    return Api().get(`/events/get-venue-spaces/${id}`);
  },
  CheckSeatAvailability(formData){
    return Api().post("/events/check-seat?event_price_id="+formData.event_price_id+"&seat_number="+formData.seat_number+"&section_name="+formData.section_name);
  },
  
};
