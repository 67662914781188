<template>
    <div>
        <main data-aos="fade" class="pb-3">
            <section class="ticket-area site-sec bg-light">
                <div class="site-sec-wrap container-xxl">
                    <div class="bg-white my-3 px-3 px-md-5 py-5 border min-vh-100">
                        <div class="">
                            <h2>Payment Processing..</h2>
                            <p>kindly check prompts on your phone to complete payment</p>
                            <p><span v-show="loading" class="spinner-border text-warning"></span>&nbsp;&nbsp;<countdown :endDate="stopDate" /></p>
                        </div>
                        
                        
                        
                        <div class="row" v-if="!loading">
                            <div v-if="transactionStatus == 'completed'" class="col-md-7 col-lg-8 border-bottom pb-3 mb-4">
                                <h4 class="mb-3">Successfully Processed</h4>
                                <p>Please Check your Email/Sms for tickets</p>
                                <router-link to="/" class="btn btn-primary mt-5">Home</router-link>
                            </div>
                            <div v-else class="col-md-7 col-lg-8 border-bottom pb-3 mb-4">
                                <h4 class="mb-3">Failed!</h4>
                                <p>Payment Failed : {{transactionOutcome}}</p>
                                <router-link to="/" class="btn btn-primary mt-5">Home</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import Countdown from '../components/common/Countdown.vue'
import Payment from '../apis/Payment'
export default {
    components: {
        Countdown
    },
    data() {
        return {
            loading: true,
            transactionId: null,
            transactionStatus: 'pending',
            transactionOutcome: '',
            timer: null,
            now: new Date(),
            stopDate: new Date(new Date().getTime() + 6*60000),
            ticket: null,
        }
    },
    methods: {
        fetchTransactionStatus(id){
            this.now = new Date();
            Payment.getTransactionStatus(id).then(
                (result) => {
                    const r = result.data;
                    this.transactionStatus = r.status;
                    this.transactionOutcome = r.result;

                },
                (error) => {
                     const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(resMessage) 
                    this.loading = false
                    this.$toast.error(resMessage,{
                        duration: 10000
                    });
                }
            )
        }
    },
    watch : {
    now : {
      immediate : true,
      handler(newVal){
        console.log("newvalue: "+newVal+"old value"+this.stopDate)
        if(this.transactionStatus != 'pending'){
             clearInterval(this.timer)
             this.loading = false;
        }
        if(newVal > this.stopDate){
            this.now = newVal            
            clearInterval(this.timer)
            this.loading = false;
          }
      }
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  created() {
      let tId = this.$route.params.id
      this.transactionId = tId;
      this.timer = setInterval(()=>{          
          this.fetchTransactionStatus(this.transactionId)
          
    }, 5000)
  }
}
</script>