import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Event from '../views/Event.vue'
import Events from '../views/Events.vue'
import Checkout from '../views/Checkout.vue'
import ProcessPayment from '../views/ProcessPayment.vue'
import SeasonTicketsListing from '../views/SeasonTicketsListing.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import MyAccount from '../views/MyAccount.vue'
import SeasonPrice from '../views/SeasonPrice.vue'
import SeasonTicket from '../views/SingleSeasonTicket.vue'
import Amahoro from '../views/Amahoro.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/event/:id?',
    name: 'Event',
    component: Event,
    meta: {pageTitle: ' Event',authOnly: false}
  },
  {
    path: '/season-ticket/:id?',
    name: 'SeasonTicket',
    component: SeasonTicket,
    meta: {pageTitle: ' Season Ticket',authOnly: true}
  },
  {
    path: '/season-price/:id?',
    name: 'SeasonPrice',
    component: SeasonPrice,
    meta: {pageTitle: ' SeasonPrice',authOnly: true}
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {pageTitle: ' Login',authOnly: false}
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {pageTitle: ' Register',authOnly: false}
  },
  {
    path: '/account',
    name: 'Account',
    component: MyAccount,
    meta: {pageTitle: ' MyAccount',authOnly: true}
  },
  {
    path: '/events/:categoryId?',
    name: 'Events',
    component: Events,
    meta: {pageTitle: ' Events',authOnly: false}
  },
  {
    path: '/season-tickets/:organiserId?',
    name: 'SeasonTicketsListing',
    component: SeasonTicketsListing,
    meta: {pageTitle: ' Season Tickets',authOnly: false}
  },
  {
    path: '/checkout/:id?/:eventId?/:reserved_seats?',
    name: 'Checkout',
    component: Checkout,
    meta: {pageTitle: ' Checkout',authOnly: false}
  },
  {
    path: '/venue/:eventId?/:priceId?/:type',
    name: 'Amahoro',
    component: Amahoro,
    meta: {pageTitle: ' Amahoro',authOnly: false}
  },
  {
    path: '/payment/:id?',
    name: 'PaymentProcessing',
    component: ProcessPayment,
    meta: {pageTitle: ' Processing',authOnly: false}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

export default router
