<template>
    <div>
        <main data-aos="fade" class="pb-3">
        <section class="ticket-area site-sec bg-light">
            <div v-if="!eventInView" class="site-sec-wrap container-xxl">
                <event-detail-skeleton />
            </div>
            <div class="site-sec-wrap container-xxl">
                <div v-if="eventInView" class="bg-white my-3 px-3 px-md-5 py-5 border rounded row">
                    <div class="abstract col-12">
                        <div class="row img-cover">
                            <img :src="eventInView.feature_image ? eventInView.feature_image : eventInView.banner_image" alt="">
                            <a @click.prevent="viewPricing(eventInView.id,eventInView)" href="" class="link-pay btn btn-warning w-auto">Pay for this</a>
                        </div>
                        <h3 class="mt-4">{{eventInView.name}}</h3>
                        <hr class="my-3">
                        <div class="row g-5 event-details">
                            <div class="col-auto">
                                <strong class="">Date</strong>
                                <h5>{{eventInView.start}}</h5>
                            </div>
                            <div class="col-auto">
                                <strong class="">Location</strong>
                                <h5 class="border-bottom pb-2 pe-5">{{eventInView.venue}}</h5>
                                <a href="">View Map</a>
                            </div>
                            <div class="col-auto">
                                <strong class="">Refund Policy</strong>
                                <h5 class="border-bottom pb-2 pe-5">Contact the organizer to request a refund.</h5>
                                <a href="">Contact Advertiser</a>
                            </div>
                        </div>
                    </div>
                    <div class="details mt-5 col-12">
                        <div class="event-header">
                            <h3 class="mb-0">Event Details</h3>
                            <hr class="my-3">
                            <p>{{eventInView.description}}</p>
                        </div>
                        <div class="event-body"></div>
                    </div>
                </div>
            </div>
        </section>
        <section class="brief-tickets site-sec">
            <div class="site-sec-wrap container-xxl">
                <h4 class="mb-4">Other Events</h4>
                <div class="row gx-3 gy-5">
                    <div v-for="upcomingEvent in relatedEvents" :key="upcomingEvent.id"  class="col-md-3">
                        <div class="card card-ticket border-0">
                            <div class="card-header p-0">
                                <div class="img img-cover rounded">
                                    <img :src="upcomingEvent.banner_image" alt="">
                                </div>
                                <a @click.prevent="goToEvent(upcomingEvent.id)" href="" class="link-to"></a>

                            </div>
                            <div class="card-body bg-transparent">
                                <h5 class="card-title mb-0 fw-bold">{{upcomingEvent.name}}</h5>
                                <p class="card-text">{{upcomingEvent.start}}</p>
                            </div>
                        </div>
                    </div>   
                  
                </div>
                <div class="row gx-3 gy-5" v-if="relatedEvents.length < 1">
                    <event-card-skeleton v-for="n in 4" :key="n" />
                </div>
               
            </div>
        </section>
    </main>
    </div>
</template>
<script>
import EventDetailSkeleton from '../components/common/EventDetailSkeleton.vue'
import EventCardSkeleton from '../components/common/EventCardSkeleton.vue'
import PalmEvent from '../apis/PalmEvent'

export default {
  components: { 
      EventDetailSkeleton,
      EventCardSkeleton 
   },
    data() {
        return {
            eventInView : null,
            loading: false,
            relatedEvents : [],
        }
    },
    methods: {
        goToEvent(eventId){
          this.$router.push({ name: "Event",params: {id: eventId} });  
          this.eventInView = null;
          this.relatedEvents = [];
          this.fetchEvent(eventId);
      },
      fetchEvent(eventId){
          PalmEvent.getEvent(eventId).then(
            (result) => {
                this.eventInView = result.data.event;
                this.relatedEvents = result.data.related_events;
            },
            (error) => {
                const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
              console.log(resMessage)
            }
        )
      },
      viewPricing(id,cEvent){
         this.$router.push({ name: "Checkout",params: {id: id,pEvent:cEvent} }); 
      }
    },
    created() {
        let eId = this.$route.params.id;
        this.fetchEvent(eId);
    }
}
</script>